import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";

export default function Faq() {
  return (
    <>
      <Navigation />
    
      <Box sx={{ marginTop: "4.4rem" }} component="div" className="faq">
        <h2 style={{ color: "white"}}>FAQ</h2>
        <Accordion sx={{width: "100%"}}>
          <AccordionSummary>
            <Typography sx={{ fontWeight: "bold" }}>
              How do I use Vstreamer?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We’ve got a video tutorial on the basics
              <br />
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/kyjreoivClA"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Typography>
            <br />
            <Typography>
              We also show you how to stream or record videos with a Vstreamer
              avatar using OBS’ free software
              <br />
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/a6_eeIKd8gY"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography sx={{ fontWeight: "bold" }}>
              Is this all the avatars I can pick from?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We’re in our early alpha, so we’ve created a few pre-made avatars
              giving you something to play with! You’re welcome to request new
              public avatars using our feature request.
            </Typography>
            <br />
            <Typography>
              In 2023 you’ll be able to create custom avatars using the
              Character Builder at BeNeverEnding.app. You’ll be able to use the
              custom avatar you created in Vstreamer.
            </Typography>
            <br />
            <Typography>
              We’re also releasing a marketplace, where you’ll be able to
              purchase premade avatars and unique commissioned avatars
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography sx={{ fontWeight: "bold" }}>
              Can I get a completely custom avatar?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Yes, you can! While we build out the market place you can visit
              our Ko-fi store to order your commission.{" "}
              <a
                href="https://ko-fi.com/vstreamer/commissions"
                target="_blank"
                rel="noreferrer"
              >
                https://ko-fi.com/vstreamer/commissions
              </a>
            </Typography>
            <br />
            <Typography>
              Custom animated avatars are $250 for an animated avatar with no
              extra facial emotes. You can purchase individual emotes for $50.
              We offer a package of 1 animated avatar and 3 animated facial
              emotes for only $300! And if you go to the store, you might see
              that they’re ON SALE!
            </Typography>
            <br />
            <Typography>
              When we launch the market place, you won’t need to go to Ko-fi.
              You’ll be able to order custom avatars right here in the app.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography sx={{ fontWeight: "bold" }}>
              I’m an artist, can I make and sell avatars, backgrounds, emotes on
              Vstreamer?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              When our marketplace opens (anticipated launch early 2023), select
              artists will be able to create and sell avatars and other art
              assets for use in Vstreamer. We’ll provide guidelines, and we may
              subsidize the creation of avatar packs.
            </Typography>
            <br />
            <Typography>
              We invite you to sign up to our Beta list, so you can be one of
              the first artists selling avatars for use in Vtreamer -{" "}
              <a
                href="https://airtable.com/shr13DXO4U1SgqRkY"
                target="_blank"
                rel="noreferrer"
              >
                https://airtable.com/shr13DXO4U1SgqRkY
              </a>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography sx={{ fontWeight: "bold" }}>
              Can I use Vstreamer in my commercial project?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We don’t suggest using any of the current premade avatars in your
              current commercial projects. The versions available are inspired
              by existing characters, and we’re relying on “Fair Use” guidelines
              to make them available to you at no charge, so you can creatively
              express yourself.
            </Typography>
            <br />
            <Typography>
              If you commission a custom avatar, you are welcome to use it in
              any commercial endeavor!
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography sx={{ fontWeight: "bold" }}>
              I think I recognize some of these avatars from popular comics or
              shows. Do you have a relationship with XYZ company?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We do not have a relationship and do not own the likenesses or
              perceived likenesses of any trademarked or copyrighted characters.
              The avatars have been inspired by certain pop culture characters,
              and are provided under “Fair Use” to allow you to creatively
              express yourself. We do not claim any ownership over these current
              pre-made avatars that have been made available for your enjoyment.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Footer />
      </Box>
    </>
  );
}
