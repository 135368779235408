import { create } from "zustand";
import * as faceapi from "face-api.js";
import {
  BackgroundType,
  Emote,
  ExpressionsListType,
  Size,
  StreamType,
} from "../../../utility/Types";

interface useAvatarDisplayState {
  // Microphone
  audioContext: AudioContext;
  isTalking: boolean;
  setTalking: (val: boolean) => void;
  isUsingMic: boolean;
  setUsingMic: (val: boolean) => void;

  // Webcam
  expressions: faceapi.FaceExpressions;
  setExpressions: (input: faceapi.FaceExpressions) => void;
  isUsingCam: boolean;
  setUsingCam: (val: boolean) => void;

  // Avatar Data & Preload
  stream: StreamType | null;
  setStream: (streamData: StreamType) => void;

  // Emotes
  emotes: Array<Emote>;
  setEmotes: (list: Emote[]) => void;
  emote: Emote | null;
  setEmote: (val: Emote | null) => void;
  showEmotes: boolean;
  setShowEmotes: (val: boolean) => void;

  // Expressions
  currentExpressionEmote: string;
  setCurrentExpressionEmote: (varient: string) => void;
  availableExpressions: ExpressionsListType;
  setAvailableExpressions: (arr: ExpressionsListType) => void;

  // Misc
  windowSize: Size;
  setWindowSize: (size: Size) => void;
  hotkeyPressed: string | null;
  setHotkeyPressed: (val: string | null) => void;
  isHotkeyHeld: boolean;
  setHotkeyHeld: (val: boolean) => void;
  refresh: number;
  toggleRefresh: () => void;

  // Background
  background: BackgroundType | undefined;
  setBackground: (val: BackgroundType | undefined) => void;

  // Asset loading
  isLoading: boolean;
  setLoading: (val: boolean) => void;
}

const useAvatarDisplay = create<useAvatarDisplayState>()((set) => ({
  // Microphone
  audioContext: new AudioContext(),
  isTalking: false,
  setTalking: (val) => set(() => ({ isTalking: val })),
  isUsingMic: false,
  setUsingMic: (val) => set(() => ({ isUsingMic: val })),

  // Webcam
  expressions: {
    angry: 0,
    disgusted: 0,
    fearful: 0,
    happy: 0,
    neutral: 0.99,
    sad: 0,
    surprised: 0,
    asSortedArray: () => [{ expression: "neutral", probability: 0.99 }],
  },
  setExpressions: (input) => set(() => ({ expressions: input })),
  isUsingCam: false,
  setUsingCam: (val) => set(() => ({ isUsingCam: val })),

  // Avatar Data & Preload
  stream: null,
  setStream: (streamData) => set(() => ({ stream: streamData })),

  // Emotes
  emotes: [],
  setEmotes: (list) => set(() => ({ emotes: list })),
  emote: null,
  setEmote: (val) => set(() => ({ emote: val })),
  showEmotes: true,
  setShowEmotes: (val) => set(() => ({ showEmotes: val })),

  // Expressions
  currentExpressionEmote: "neutral",
  setCurrentExpressionEmote: (varient) =>
    set(() => ({ currentExpressionEmote: varient })),
  availableExpressions: [],
  setAvailableExpressions: (arr) => set(() => ({ availableExpressions: arr })),

  // Misc
  windowSize: { width: 0, height: 0 },
  setWindowSize: (size) => set(() => ({ windowSize: size })),
  hotkeyPressed: null,
  setHotkeyPressed: (val) => set(() => ({ hotkeyPressed: val })),
  isHotkeyHeld: false,
  setHotkeyHeld: (val) => set(() => ({ isHotkeyHeld: val })),
  refresh: Math.random(),
  toggleRefresh: () => set(() => ({ refresh: Math.random() })),

  // Background
  background: undefined,
  setBackground: (val) => set(() => ({ background: val })),

  // Loading
  isLoading: true,
  setLoading: (val) => set(() => ({ isLoading: val })),
}));

export default useAvatarDisplay;
