import Select from "react-select";
import clsx from "clsx";

type VariantOptions = "primary" | "secondary";
type WidthSizeOptions = "sm" | "md" | "lg" | "responsive";

interface DropdownProps {
  className?: string;
  description?: string;
  errorMessage?: string;
  hasError?: boolean;
  id?: string;
  isSearchable?: boolean;
  variant?: VariantOptions;
  widthSize?: WidthSizeOptions;
  label?: string;
  classNamePrefix?: string;
  onChange: ((newValue: any) => void);
  [key: string]: any;
}

export function Dropdown({
  className,
  description,
  errorMessage,
  hasError,
  id = Math.random().toString(),
  isSearchable = false,
  variant = "primary", // primary, secondary,
  widthSize = "md", // sm, md, lg, responsive
  label,
  onChange,
  classNamePrefix = "c-dropdown",
  ...props
}: DropdownProps) {
  const classes = clsx("c-dropdown-container", className, {
    [`c-dropdown__${variant}`]: variant,
    "has-error": hasError,
    [`w-${widthSize}`]: widthSize,
  });

  return (
    <div className={classes}>
      {label && <label>{label}</label>}
      <Select
        classNamePrefix={classNamePrefix}
        id={id}
        isSearchable={isSearchable}
        onChange={onChange}
        {...props}
      />
      {description && (
        <div className="c-dropdown__description">{description}</div>
      )}
      {errorMessage && (
        <div className="c-dropdown__error-message">{errorMessage}</div>
      )}
    </div>
  );
}
