import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  Modal,
  TextField,
  Typography,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import Topbar from "../../../components/Topbar";
import { createAvatarSpecificBackground, deleteAvatarSpecificBackground, deleteBackground, getBackgroundAvatarSpecificList, getOrgAvatarList, updateBackground } from "../../../utility/Endpoints";
import Get from "../../../utility/Get";
import Post from "../../../utility/Post";
import UploadIcon from '@mui/icons-material/Upload';
import { V3Organization } from "../../../utility/context/V3Organization";


/**
 * Update a specific Background on the database
 * @returns
 */

const style = {
  backgroundColor: "white",
  padding: "1.5rem",
  borderRadius: "8px",
  width: "15rem",
};


export default function EditBackground(): JSX.Element {
  let navigator = useNavigate();
  const location = useLocation();
  const organization = useContext(V3Organization)
  const [background, setBackground] = useState({
    name: "",
    image: "",
    is_public: false,
    specific_avatar: {
      avatar: 0,
      pk: 0,
      changed: false
    },
  })
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({
    name: "",
    image: ""
  })
  const [infoMessage, setInfoMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [deleteModal, setDeleteModal] = useState(false)
  const [avatars, setAvatars] = useState<
    {
      avatar_type: object;
      name: string;
      image: string;
      pk: number;
    }[]
  >([]);

  useEffect(() => {
    getBackground()
    if(organization.pk) getAvatars(1)
    // eslint-disable-next-line
  }, [location, organization])

  function getAvatars(page: number) {
    Get(getOrgAvatarList(organization.pk, page)).then((val) => {
      if (val.status && val.status < 300) {
        setAvatars((prev) => [...prev, ...val.data.data]);
        //handle pages
        if (val.data.page_count > page) {
          getAvatars(page + 1)
        } else {
          setIsLoading(false)
        }
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    });
  }

  function getBackground() {
    setIsLoading(true)
    let background_pk = location.pathname.split("/")
    Get(updateBackground(background_pk[2])).then(val => {
      if (val.status && val.status < 300) {
        if (val.data && val.data.form && val.data.form.data) {
          setBackground(val.data.form.data)
          checkAvatarSpecificBackgrounds(background_pk[2])
        }
      }
      else {
        if (val.status === 401) {
          navigator("/login")
        }
      }
      setIsLoading(false)
    })

  }

  function checkAvatarSpecificBackgrounds(background_pk: string | number) {
    Get(getBackgroundAvatarSpecificList(background_pk)).then(val => {
      if (val.status && val.status < 300) {
        if (val.data && val.data.data && val.data.data[0]) {
          var background_objs = val.data.data[0].avatar_specific_background_objects as Array<any>
          var specific_background_avatar_index = background_objs.findIndex((e: any) => {
            return background_pk === e.background_id.toString()
          })
          setBackground(prev => {
            return ({
              ...prev,
              specific_avatar: {
                avatar: val.data.data[0].pk,
                pk: val.data.data[0].avatar_specific_background_objects[specific_background_avatar_index].pk,
                changed: false,
              }
            })
          })
        } else {
          setBackground(prev => {
            return ({
              ...prev,
              specific_avatar: {
                avatar: 0,
                pk: 0,
                changed: false,
              }
            })
          })
        }
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    })
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setBackground({ ...background, [e.target.name]: e.target.value })
  }

  function handleCheck(e: React.ChangeEvent<HTMLInputElement>) {
    setBackground({ ...background, [e.target.name]: e.target.checked })
  }

  function handleCloseDeleteModal() {
    setDeleteModal(false)
  }

  function handleSave() {
    var error = false;
    const FormData = require("form-data");
    const formData = new FormData();
    let imageFile = document.getElementById("image_file") as HTMLInputElement

    if (background.name === "" || background.name.length === 0) {
      setErrors({ ...errors, name: "Name cannot be empty" })
      error = true
    }

    let background_pk = location.pathname.split("/")

    if (!error) {
      setIsLoading(true)
      setErrors({
        name: "",
        image: ""
      })
      formData.append("name", background.name);
      if (imageFile === undefined || imageFile.files === undefined || imageFile.files?.length === 0) {
        formData.append("image", background.image)
      } else {
        formData.append("image", imageFile && imageFile.files ? imageFile.files[0] : "")
      }
      formData.append('is_public', background.is_public);

      Post(updateBackground(background_pk[2]), formData).then(val => {
        if (val.status && val.status < 300) {
          setInfoMessage("Background Updated")
          var updated_background = val.data.data

          //handle updating an avatar specific background
          /**
             * case 1: no specific avatar selected before and not updated (nothing)
             * case 2: no specific avatar selected before and is updated to avatar (create)
             * case 3: no specific avatar selected before and updated to "" (nothing)
             * case 4: specific avatar selected before and not updated (nothing)
             * case 5: specific avatar selected before and updated to new avatar (delete and create)
             * case 6: specific avatar selected before and updated to "" (delete)
             */
          //specific avatar selected before and updated to new avatar (delete and create)
          if (background.specific_avatar.changed && background.specific_avatar.pk && background.specific_avatar.avatar !== -1) {
            //if the specific avatar has changed, 
            //delete the old one and create a new one
            const formDatadelete = new FormData();
            Post(deleteAvatarSpecificBackground(background.specific_avatar.pk), formDatadelete).then(val1 => {
              if (val1.status && val1.status < 300) {
                const formDataCreate = new FormData();
                formDataCreate.append("background", background_pk[2]) //background pk
                Post(createAvatarSpecificBackground(background.specific_avatar.avatar), formDataCreate).then(val2 => {
                  if (val2.status && val2.status < 300) {
                    //new avatar specific background created
                    var background_objs = val2.data.data.avatar.avatar_specific_background_objects as Array<any>
                    var specific_background_avatar_index = background_objs.findIndex((e: any) => {
                      return background_pk[2] === e.background_id.toString()
                    })
                    //update updated_background
                    updated_background = {
                      ...updated_background,
                      specific_avatar: {
                        avatar: val2.data.data.avatar.pk,
                        pk: val2.data.data.avatar.avatar_specific_background_objects[specific_background_avatar_index].pk,
                        changed: false,
                      }
                    }
                    setBackground(updated_background)
                  } else {
                    if (val2.status === 401) {
                      navigator("/login");
                    }
                  }
                })
              } else {
                if (val1.status === 401) {
                  navigator("/login");
                }
              }
            })
          }
          //no specific avatar selected before and is updated to avatar (create)
          else if (background.specific_avatar.changed && !background.specific_avatar.pk && background.specific_avatar.avatar !== -1) {
            const formDataCreate = new FormData();
            formDataCreate.append("background", background_pk[2]) //background pk
            Post(createAvatarSpecificBackground(background.specific_avatar.avatar), formDataCreate).then(val2 => {
              if (val2.status && val2.status < 300) {
                //new avatar specific background created
                var background_objs = val2.data.data.avatar.avatar_specific_background_objects as Array<any>
                var specific_background_avatar_index = background_objs.findIndex((e: any) => {
                  return background_pk[2] === e.background_id.toString()
                })
                //update updated_background
                updated_background = {
                  ...updated_background,
                  specific_avatar: {
                    avatar: val2.data.data.avatar.pk,
                    pk: val2.data.data.avatar.avatar_specific_background_objects[specific_background_avatar_index].pk,
                    changed: false,
                  }
                }
                setBackground(updated_background)
              } else {
                if (val2.status === 401) {
                  navigator("/login");
                }
              }
            })
          }
          //specific avatar selected before and updated to -1 (delete)
          else if (background.specific_avatar.changed && background.specific_avatar.pk && background.specific_avatar.avatar === -1) {
            const formDatadelete = new FormData();
            Post(deleteAvatarSpecificBackground(background.specific_avatar.pk), formDatadelete).then(val1 => {
              if (val1.status && val1.status < 300) {
                //update updated_background
                updated_background = {
                  ...updated_background,
                  specific_avatar: {
                    avatar: 0,
                    pk: 0,
                    changed: false
                  }
                }
                setBackground(updated_background)
              } else {
                if (val1.status === 401) {
                  navigator("/login");
                }
              }
            })
          }
          else {
            //else keep the data the same but change changed to false
            updated_background = {
              ...updated_background,
              specific_avatar: {
                avatar: background.specific_avatar.avatar,
                pk: background.specific_avatar.pk,
                changed: false
              }
            }
            setBackground(updated_background)
          }

        }
        else {
          if (val.status === 401) {
            navigator("/login")
          }
          //handle errors
          var temp = Object.keys(val.data.form.errors)[0]
          setErrorMessage(val.data.form.errors[temp])
        }
        setIsLoading(false)
      })
    }

  }

  function handleDelete() {
    setIsLoading(true)
    const FormData = require("form-data");
    const formData1 = new FormData();
    let background_pk = location.pathname.split("/")
    Post(deleteBackground(background_pk[2]), formData1).then(val => {
      if (val.status && val.status < 300) {
        setInfoMessage("Background deleted")
        setBackground({
          name: "",
          image: "",
          is_public: false,
          specific_avatar: {
            avatar: 0,
            pk: 0,
            changed: false
          },
        })
      }
      else {
        if (val.status === 401) {
          navigator("/login")
        }
        //handle errors
        var temp = Object.keys(val.data.form.errors)[0]
        setErrorMessage(val.data.form.errors[temp])
      }
      setIsLoading(false)
    })
  }

  return !isLoading ? (
    <div className="edit-background">
      {infoMessage !== "" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {infoMessage}
        </Alert>
      )}
      {errorMessage !== "" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}
      {background.name === "" ? (
        <div>
          This background does not exist.
          <Button onClick={() => navigator("/backgroundmanagement")}>
            Return to Background List
          </Button>
        </div>
      ) : (
        <div>
          <Modal open={deleteModal} onClose={handleCloseDeleteModal}>
            <Box style={style}>
              <form onSubmit={handleDelete} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ fontSize: "2rem", textAlign: "center" }}>Delete Background?</div>
                <Button
                  variant="contained"
                  sx={{ my: 1 }}
                  type="submit"
                  onClick={handleDelete}
                  color="error"
                >
                  <Typography color="white">Delete Background</Typography>
                </Button>

                <Button
                  onClick={handleCloseDeleteModal}
                  variant="contained"
                  sx={{ mx: 2 }}
                >
                  <Typography color="white">Cancel</Typography>
                </Button>
              </form>
            </Box>
          </Modal>
          <Topbar
            title="Edit Background"
            buttonTitle="Save Background"
            handleButton={handleSave}
            secondaryButtonTitle="Delete"
            handleSecondaryButton={() => setDeleteModal(true)}
            searchbar={false}
          />
          <Grid container spacing={2} marginTop=".5rem">
            <Grid item container xs={6}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  required
                  value={background.name}
                  name="name"
                  onChange={handleChange}
                  error={errors.name !== ""}
                  helperText={errors.name}
                  disabled={isLoading}
                />
              </Grid>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="is_public"
                      checked={background.is_public}
                      onChange={handleCheck}
                      inputProps={{ 'aria-label': 'controlled' }}
                      disabled={isLoading}
                    />
                  }
                  label="Public"
                />
              </FormGroup>
              <FormControl fullWidth style={{ marginTop: ".8rem" }}>
                <InputLabel id="assetSelect">Specific Avatar</InputLabel>
                <Select
                  labelId="assetSelect"
                  label="Asset Select"
                  value={background.specific_avatar && background.specific_avatar.avatar > 0 ? background.specific_avatar.avatar : 0}
                  name={"specific_avatar"}
                  onChange={(e) => {
                    setBackground((prev) => ({
                      ...prev,
                      specific_avatar: {
                        avatar: e.target.value as number,
                        pk: prev.specific_avatar.pk,
                        changed: true //setting as true means that it has changed
                      },
                    }));
                  }}
                >
                  <MenuItem value={-1}>&nbsp;</MenuItem>
                  {avatars &&
                    avatars.map((item) => [
                      <MenuItem value={item.pk} key={item.pk}>
                        {item.name}
                      </MenuItem>
                    ])}
                </Select>
              </FormControl>
              {/* note: if a use sets this ^, the background will only show up for the selected avatar  */}
              <Grid item xs={12}>
                <div style={{ width: '100%' }}>
                  <FormLabel>Background</FormLabel>
                </div>
                <Button variant="outlined" component="label">
                  <UploadIcon />
                  <input
                    className="hide-file-btn"
                    required
                    id="image_file"
                    name="image"
                    type="file"
                    disabled={isLoading}
                  />
                </Button>
                {errors.image !== "" && (
                  <div style={{ color: "#dd8782" }}>
                    {errors.image}
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid item container xs={6}>
              <div className="preview">
                <InputLabel>Background</InputLabel>
                {["mov", "mp4"].includes(
                  background.image.split(".")[
                    background.image.split(".").length - 1
                  ]
                ) ? (
                  <Box
                    component={"video"}
                    sx={{
                      width: 350,
                      maxWidth: { xs: 350, md: 250 },
                      objectFit: "contain",
                    }}
                    muted
                    autoPlay
                    src={background.image}
                  />
                ) : (
                  <Box
                    component={"img"}
                    sx={{
                      width: 350,
                      maxWidth: { xs: 350, md: 250 },
                    }}
                    alt={`${background.name} background`}
                    src={background.image}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  ) : (
    <div>Loading...</div>
  );
}
