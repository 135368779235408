import { useState, useContext, useMemo } from "react";
import { Dropdown } from "../../components/Dropdown";
import { V3UserContext } from "../../utility/context/V3UserContext";
import SellerDashboard from "./SellerDashboard";
import FAQ from "./selling-info/FAQ";
import Fees from "./selling-info/Fees";
import Support from "./selling-info/Support";
import countryList from 'react-select-country-list'
import { Loader } from "../../components/Loader";
import Popup from "../../components/Popup";
import Post from "../../utility/Post";
import { postCreateStore } from "../../utility/MarketEndpoints";
import { Button, TextField } from "@mui/material";


export default function Sell(): JSX.Element {
  const { user, setUser } = useContext(V3UserContext);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [successStore, setSuccessStore] = useState<number>(0); //store pk
  const [session, setSession] = useState({ //form for creating store
    name: "",
    description: "",
    email: "",
    country: "",
    business_type: "",
  });
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [businessError, setBusinessError] = useState("");
  const [descriptionError, setDescriptionError] = useState("")
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const options = useMemo(() => countryList().getData(), []);
  const businessTypeOptions = [
    { label: "Individual", value: "individual" },
    { label: "Company", value: "company" },
    { label: "Non Profit", value: "non_profit" },
  ];

  function handleChange(e: any) {
    e.preventDefault();
    setSession({ ...session, [e.target.name]: e.target.value });
  }

  function handleSelectCountryChange(value: string) {
    setSession({ ...session, country: value });
  }

  function handleSelectBusinessChange(value: string) {
    setSession({ ...session, business_type: value });
  }

  function handleCreateStore() {
    setCountryError("")
    setEmailError("")
    setBusinessError("");
    setNameError("")
    setDescriptionError("")
    setIsLoading(true)

    Post(postCreateStore(), session).then(val => {
      if (val && val.status < 300) {
        if(val.data && val.data.data) {
          setSuccessStore(val.data.data.pk)
        }
      } else {
        if (val.data && val.data.form && val.data.form.errors) {
          if (val.data.form.errors.name) {
            setNameError(val.data.form.errors.name)
          }
          if (val.data.form.errors.description) {
            setDescriptionError(val.data.form.errors.description)
          }
          if (val.data.form.errors.email) {
            setEmailError(val.data.form.errors.email)
          }
          if (val.data.form.errors.country) {
            setCountryError(val.data.form.errors.country)
          }
          if (val.data.form.errors.business_type) {
            setBusinessError(val.data.form.errors.business_type);
          }
        } else {
          Popup("Something went wrong. Try again later", "error")
        }
      }
      setIsLoading(false)
    })
  }

  // splash page for selling on the platform with info
  function SellSplashPage() {
    return (
      <div className="start-selling">
        {/* header splash page  */}
        <div className="start-selling__header">
          <h2>Join the community and sell your own Avatars!</h2>
          &nbsp;&nbsp;&nbsp;
          <Button
            onClick={() => setShowForm(true)}
            variant={"contained"}
            color="secondary"
          >
            Get Started
          </Button>
        </div>
        &nbsp;&nbsp;&nbsp;
        <h4>Create and share your Avatars by joining the marketplace</h4>
        &nbsp;&nbsp;&nbsp;
        <Button
          onClick={() => setShowForm(true)}
          variant={"contained"}
        >
          Open your Shop
        </Button>
        &nbsp;&nbsp;&nbsp;
        {/* describe fees  */}
        <Fees />

        &nbsp;&nbsp;&nbsp;

        {/* describe support  */}
        <Support />

        &nbsp;&nbsp;&nbsp;

        {/* faq  */}
        <FAQ />

        &nbsp;&nbsp;&nbsp;
        <h3>Still have questions?</h3>
        <p>Please contact us&nbsp;
          <a
            className="unselectable"
            href="https://beneverending.com/contact"
            target="_blank"
            rel="noopener noreferrer"
          >
            HERE
          </a>
        </p>
        &nbsp;&nbsp;&nbsp;

        <div className="start-selling__header">
          <h2>Ready to start selling?</h2>
          &nbsp;&nbsp;&nbsp;
          <Button onClick={() => setShowForm(true)} variant="contained">Get Started</Button>
        </div>
      </div>
    )
  }

  function handleStoreComplete() {
    setIsLoading(true)
    if(user) {
      setUser({ ...user, store_id: successStore })
    }
  }

  return (
    <div className="sell">
      {user?.store_id ? ( 
        // if a user already has a store 
        <SellerDashboard store_id={user.store_id}/>
      ) : (
        // if a user doesn't already have a store 
        <>
          {showForm ? ( 
            <div className="store-form">
              {isLoading ? ( //loader
                <Loader />
              ) : (
                <>
                  {successStore ? ( //after the store has been created
                    <div className="store-form__submitted">
                      <h1>Form Submitted!</h1>
                      <p>Please allow a few days for our team to review your application. After your store is approved, finish the store activation on the Seller's Dashboard. </p>
                      <Button
                        style={{ marginTop: "5rem" }}
                        onClick={() => handleStoreComplete()}
                      >
                        Continue to Seller's Dashboard
                      </Button>
                    </div>
                  ) : ( //create store form
                    <>
                      <h2>Create a Store</h2>
                      &nbsp;&nbsp;&nbsp;
                      <form onSubmit={handleCreateStore}>
                        <TextField
                          label={"Store Name"}
                          placeholder=""
                          fullWidth
                          name="name"
                          value={session.name}
                          onChange={handleChange}
                          required
                          error={nameError !== ""}
                          helperText={nameError}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <TextField
                          label={"Store Description"}
                          placeholder=""
                          fullWidth
                          name="description"
                          value={session.description}
                          onChange={handleChange}
                          error={descriptionError !== ""}
                          helperText={descriptionError}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <TextField
                          label={"Store Email"}
                          placeholder=""
                          fullWidth
                          name="email"
                          // description="Email will recieve store and marketplace notifications."
                          value={session.email}
                          onChange={handleChange}
                          required
                          error={emailError !== ""}
                          helperText={emailError}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <Dropdown
                          options={options}
                          onChange={(data: any) => handleSelectCountryChange(data ? data.value : "")}
                          widthSize={"responsive"}
                          isSearchable
                          variant="secondary"
                          hasError={countryError !== ""}
                          errorMessage={countryError}
                          label={"Country"}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <Dropdown
                          options={businessTypeOptions}
                          onChange={(data) => handleSelectBusinessChange(data ? data.value : "")}
                          widthSize={"responsive"}
                          isSearchable
                          variant="secondary"
                          hasError={businessError !== ""}
                          errorMessage={businessError}
                          label={"Business Type"}
                        />
                        &nbsp;&nbsp;&nbsp;
                      </form>
                      <div className="store-form__btns">
                        <Button
                          onClick={() => setShowForm(false)}
                          // isLoading={isLoading}
                          disabled={isLoading}
                          variant={"outlined"}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={handleCreateStore}
                          type="submit"
                          // isLoading={isLoading}
                          disabled={isLoading}
                        >
                          Next
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          ) : ( // splash page for selling on the platform with info
            <SellSplashPage />
          )}
        </>
      )}
    </div>
  );
}

