import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Popup from "../../../components/Popup";
import { V3UserContext } from "../../../utility/context/V3UserContext";
import Get from "../../../utility/Get";
import Post from "../../../utility/Post";
import { userUpdateMailingList } from "../../../utility/Endpoints";
import { FormControlLabel, Checkbox } from "@mui/material";

export default function UpdateMailingList() {
  const { user, setUser } = useContext(V3UserContext);
  const [isSubscribed, setSubscribed] = useState<boolean>(false);
  const toggleSubscription = () => setSubscribed((prev) => !prev);
  const navigator = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const controller = new AbortController();

    if (user) {
      Get(userUpdateMailingList(user.pk), controller.signal).then((res) => {
        if (res && res.status) {
          if (res.status < 300) {
            setSubscribed(res.data.form.data.subscribed_to_mailing_list);
          } else if (res.status === 401) {
            navigator("/login");
          } else {
            Popup(
              "There was an error retrieving your mailing list status",
              "error"
            );
          }
        } else if ((res.message = "canceled")) {
          // Cancelled
        } else {
          Popup(
            "There was an error retrieving your mailing list status",
            "error"
          );
        }
      });
    }

    return () => {
      controller.abort();
    };
  }, [navigator, user]);

  function handleSubscription() {
    if (user) {
      toggleSubscription();
      setLoading(true);
      Post(userUpdateMailingList(user.pk), {
        subscribed_to_mailing_list: !isSubscribed,
      }).then((res) => {
        if (res && res.status) {
          if (res.status < 300) {
            if (isSubscribed) {
              Popup("Unsubscribed from mailing list", "info");
            } else {
              Popup("Subscribed to mailing list", "info");
            }
            setSubscribed(res.data.data.subscribed_to_mailing_list);
            setUser(res.data.data);
          } else if (res.status === 401) {
            navigator("/login");
          } else if (res.status === 422) {
            Popup("There was an error updating your mailing list", "error");
            toggleSubscription();
          }
        } else {
          Popup("There was an error updating your mailing list", "error");
          toggleSubscription();
        }
        setLoading(false);
      });
    }
  }

  return (
    <div className="account__container">
      <div className="account__title">Mailing List</div>
      <FormControlLabel
        checked={isSubscribed}
        onChange={handleSubscription}
        control={<Checkbox />}
        label={
          isSubscribed
            ? "Unsubscribe to mailing list"
            : "Subscribe to mailing list"
        }
        disabled={isLoading}
      />
    </div>
  );
}
