import clsx from "clsx";

type VariantOptions = "primary" | "secondary";

interface PaymentCardProps {
  children?: string | JSX.Element;
  className?: string;
  title?: string;
  subtitle?: string;
  thumbnail?: string;
  price?: number;
  status?: string | null;
  href?: string;
  variant?: VariantOptions;
}

export function PaymentCard({
  children,
  className,
  title,
  thumbnail,
  price,
  href,
  variant = "primary", // primary, secondary
  ...props
}: PaymentCardProps) {
  const classes = clsx("c-payment-card", className, {
    [`-${variant}`]: variant,
  });

  return (
    <div className={classes} {...props}>
      <img
        src={thumbnail ? thumbnail : "/npc_placeholder.png"}
        alt={`${title ? title + " " : ""}thumbnail`}
      />
      <div className="c-payment-card__layout">
        <a className="c-payment-card__title" aria-label={title} href={href}>
          <h3 className="c-payment-card__name">{title ? title : ""}</h3>
          <p className="c-payment-card__price">{price ? `$${(price / 100).toFixed(2)}` : null}</p>
        </a>

        <div className="c-payment-card__children">
          {children}
        </div>
      </div>
    </div>
  );
}
