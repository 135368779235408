import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router";
import Get from "../../utility/Get";
import Post from "../../utility/Post";
import { V3UserContext } from "../../utility/context/V3UserContext";
import Popup from "../../components/Popup";
import { Loader } from "../../components/Loader";
import { Tooltip } from "../../components/Tooltip";
import {
  // getFavoriteProduct,
  getProductDetailsPublic,
  // postCreateProductFavorite,
  postDeleteProduct,
  // postDeleteProductFavorite,
  postUpdateProductVisibility,
} from "../../utility/MarketEndpoints";
import { CartContext } from "../../utility/context/CartContext";
import { Icon } from "../../components/Icon";
import { Modal } from "../../components/Modal";
import { ProductType } from "../../utility/MarketplaceTypes";
import { Box, Button, Link, Tab, Tabs } from "@mui/material";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function MarketplaceProduct(): JSX.Element {
  const [product, setProduct] = useState<ProductType>();
  const { id } = useParams();
  const { user } = useContext(V3UserContext);
  const navigator = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [notFound, setNotFound] = useState<boolean>(false);
  const { cart, setCart } = useContext(CartContext);
  const [currentProduct, setCurrentProduct] = useState(0);
  // const [favorite, setFavorite] = useState<{
  //   isFavorite: boolean;
  //   productDetails: {
  //     pk: number;
  //     product: ProductType;
  //     user_id: number;
  //   } | null;
  //   isLoading: boolean;
  // }>({
  //   isFavorite: false,
  //   productDetails: null,
  //   isLoading: true,
  // });
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    const controller = new AbortController();

    Get(getProductDetailsPublic(id as string), controller.signal)
      .then((res) => {
        if (res && res.status < 300) {
          if (res.data && res.data.data && res.data.data.previews) {
            if (Array.isArray(res.data.data.previews)) {
              // setCurrentNpc(res.data.data.previews[0]);
            } else {
              // setCurrentNpc(res.data.data.previews);
            }
          }
          setProduct(res.data.data);
          //check if this is a favorite
          // Get(getFavoriteProduct(user?.pk as number, res.data.data.pk)).then(
          //   (res) => {
          //     if (res && res.status < 300) {
          //       if (res.data.data.length > 0) {
          //         setFavorite({
          //           isFavorite: true,
          //           isLoading: false,
          //           productDetails: res.data.data[0],
          //         });
          //       } else {
          //         setFavorite({
          //           isFavorite: false,
          //           isLoading: false,
          //           productDetails: res.data.data[0],
          //         });
          //       }
          //     } else if (res && res.status === 401) {
          //       navigator("/login");
          //     } else {
          //       Popup("There was an error getting favorites", "error");
          //     }
          //   }
          // );
        } else if (res.status === 401) {
          navigator("/login");
        } else {
          if (res.status === 404) {
            setNotFound(true);
          } else {
            Popup("There was an issue getting this product", "error");
          }
        }
        setLoading(false);
      })
      .catch(() => { });

    return () => {
      controller.abort();
      setNotFound(false);
    };
  }, [id, user?.pk, navigator]);


  function handleCart() {
    //adding an item to the cart
    if (product) {
      var tempcart = JSON.parse(JSON.stringify(cart)); //get pure copy
      var index = tempcart.findIndex((x: ProductType) => x.pk === product.pk);
      // only add the product if it isn't already in the cart
      if (index === -1) {
        tempcart.push(product);
        setCart(tempcart);
        //need to update local as well
        localStorage.setItem("vstreamer-cart-items", JSON.stringify(tempcart));
        Popup("Item added to cart!");
      } else {
        Popup("This item is already in your cart", "error");
      }
    }
  }

  // function handleFavorite() {
  //   setFavorite((prev) => ({ ...prev, isLoading: true }));
  //   if (favorite.isFavorite && favorite.productDetails) {
  //     Post(postDeleteProductFavorite(favorite.productDetails.pk), {}).then(
  //       (res) => {
  //         if (res && res.status < 300) {
  //           Popup("Product removed from favorites", "info");
  //           setFavorite({
  //             isFavorite: false,
  //             isLoading: false,
  //             productDetails: null,
  //           });
  //         } else if (res.status === 401) {
  //           navigator("/login");
  //         } else {
  //           Popup("There was an issue unfavoriting", "error");
  //         }
  //       }
  //     );
  //   } else {
  //     Post(postCreateProductFavorite(user?.pk as number), { product: id }).then(
  //       (res) => {
  //         if (res && res.status < 300) {
  //           Popup("Product added to favorites", "info");
  //           setFavorite({
  //             isFavorite: true,
  //             isLoading: false,
  //             productDetails: res.data.data,
  //           });
  //         } else if (res.status === 401) {
  //           navigator("/login");
  //         } else {
  //           Popup("There was an issue favoriting", "error");
  //         }
  //       }
  //     );
  //   }
  // }

  function handleDelete() {
    if (product) {
      Post(postDeleteProduct(product.pk), {}).then((val) => {
        if (val.status && val.status < 300) {
          Popup("Product Deleted", "info");
          navigator(-1);
        } else if (val.status === 401) {
          navigator("/");
        } else {
          Popup("Something went wrong. Try again later.", "error");
        }
      });
    }
  }

  function handleVisibility() {
    if (product) {
      Post(postUpdateProductVisibility(product.pk), {
        visible: !product.visible,
      }).then((val) => {
        if (val.status && val.status < 300) {
          if (val.data && val.data.data) {
            setProduct((prev) => { //only set visibility here 
              if (prev) return { ...prev, visible: val.data.data.visible };
            });
          }
          if (val.data.data.visible) {
            if (val.data.data.status === "live") {
              Popup("Product is Public", "info");
            } else {
              //if product is pending
              Popup("Product will be Public after approval", "info")
            }
          } else {
            if (val.data.data.status === "live") {
              Popup("Product is Private", "info");
            } else {
              //if product is pending
              Popup("Product will be Private after approval", "info")
            }
          }
        } else if (val.status === 401) {
          navigator("/");
        } else {
          Popup("Something went wrong. Try again later.", "error");
        }
      });
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentProduct(newValue);
  };

  if (isLoading)
    return (
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <Loader size={5} variant={"block"} />
      </div>
    );

  return product ? (
    <div className="product-preview">
      <div className="product-preview__product-info">
        <div className="product-preview__actions">
          {/* <button
            className="product-preview__favorite"
            onClick={handleFavorite}
          >
            {favorite.isLoading && <Loader size={1.6} />}
            {favorite.isFavorite && !favorite.isLoading && (
              <Tooltip
                id={"product-preview__favorite"}
                content={"Unfavorite"}
                widthSize="auto"
                position="left"
              >
                <Icon id="favorite" name="favorite-heart" size="sm" />
              </Tooltip>
            )}
            {!favorite.isFavorite && !favorite.isLoading && (
              <Tooltip
                id={"product-preview__unfavorite"}
                content={"Favorite"}
                widthSize="auto"
                position="left"
              >
                <Icon id="unfavorite" name="unfavorite-heart" size="sm" />
              </Tooltip>
            )}
          </button> */}
          {user?.store_id === product.store.pk && (
            <>
              {/* <button //no editing products
                onClick={() => {
                  navigator(`/product-edit/${id}`);
                }}
              >
                <Tooltip
                  id={"product-preview__edit"}
                  content="Edit"
                  widthSize="auto"
                  position="left"
                >
                  <Icon
                    name="edit"
                    size="sm"
                    className="product-preview__edit"
                  />
                </Tooltip>
              </button> */}
              <button onClick={handleVisibility}>
                <Tooltip
                  id={"product-preview__privacy"}
                  content={product.visible ? "Public" : "Private"}
                  widthSize="auto"
                  position="left"
                >
                  {product.visible ? (
                    <Icon
                      name="public"
                      size="sm"
                      className="product-preview__edit"
                    />
                  ) : (
                    <Icon
                      name="private"
                      size="sm"
                      className="product-preview__edit"
                    />
                  )}
                </Tooltip>
              </button>
              <button onClick={() => setDeleteModal(true)}>
                <Tooltip
                  id={"product-preview__delete"}
                  content="Delete"
                  widthSize="auto"
                  position="left"
                >
                  <Icon
                    name="delete"
                    size="sm"
                    className="product-preview__edit"
                  />
                </Tooltip>
              </button>
            </>
          )}
        </div>

        <div className="product-preview__title">
          <h3>{product.product_name}</h3>
          {product.discount && product.regular_price ? (
            <div className="product-preview__price">
              <s>${(product.regular_price / 100).toFixed(2)}</s>
              <div style={{color: "red"}}>{product.discount}% OFF</div>
              <h4 style={{color: "red"}}>${(product.price / 100).toFixed(2)}</h4>
            </div>
          ) : (
            <h4 className="product-preview__price">
              ${(product.price / 100).toFixed(2)}
            </h4>
          )}
        </div>
        {product.store && (
          <Link sx={{color: "#040666", textDecorationColor: "#040666"}} href={`/marketplace/seller/${product.store.pk}`}>
            {product.store.name}
          </Link>
        )}
        <div className="product-preview__description">
          {product.description}
        </div>
        {product.previews && Array.isArray(product.previews) ? (
          <div className="product-preview__description">{`Number of items in this pack: ${product.previews.length} Avatars`}</div>
        ) : (
          ""
        )}

        {user?.store_id !== product.store.pk && (
          <Button
            onClick={handleCart}
            className={"product-preview__add-cart-btn"}
            variant="contained"
          >
            Add to Cart
          </Button>
        )}
      </div>

      {/* handle packs  */}
      {Array.isArray(product.previews) && product.previews.length > 0 ? (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentProduct} onChange={handleChange} aria-label="basic tabs example">
              {product.previews && product.previews[0] ? (<Tab label="Preview One" {...a11yProps(0)} />) : ""}
              {product.previews && product.previews[1] ? (<Tab label="Preview Two" {...a11yProps(1)} />) : ""}
              {product.previews && product.previews[2] ? (<Tab label="Preview Three" {...a11yProps(2)} />) : ""}
            </Tabs>
          </Box>
          {product.previews && product.previews[0] ? (
            <TabPanel value={currentProduct} index={0}>
              <div className="product-preview__preview">
                <h3>{product.previews[0].name}</h3>
                <img src={product.previews[0].thumbnail_image} alt={`${product.previews[0].name} preview`}/>
              </div>
            </TabPanel>
          ) : ""}
          {product.previews && product.previews[1] ? (
            <TabPanel value={currentProduct} index={1}>
              <div className="product-preview__preview">
                <h3>{product.previews[1].name}</h3>
                <img src={product.previews[1].thumbnail_image} alt={`${product.previews[1].name} preview`} />
              </div>
            </TabPanel>
          ) : ""}
          {product.previews && product.previews[2] ? (
            <TabPanel value={currentProduct} index={2}>
              <div className="product-preview__preview">
                <h3>{product.previews[2].name}</h3>
                <img src={product.previews[2].thumbnail_image} alt={`${product.previews[2].name} preview`}/>
              </div>
            </TabPanel>
          ) : ""}

        </Box>
      ) : (
        <div className="product-preview__preview">
          <h3>{product.previews?.name}</h3>
          <img src={product.previews?.thumbnail_image} alt={`${product.previews?.name} preview`}/>
        </div>
      )}

      <Modal isOpen={deleteModal} onRequestClose={() => setDeleteModal(false)} title="Confirm Product Deletion">
        <div className="product-preview__delete-modal">
          <p>Are you sure you want to delete {product.name}?</p>
          <div className="product-preview__buttons">
            <Button onClick={() => setDeleteModal(false)} variant="contained">Cancel</Button>
            <Button onClick={handleDelete} variant="contained" color="error">Confirm</Button>
          </div>
        </div>
      </Modal>
    </div>
  ) : (
    <>
      {notFound && (
        <div className="product-preview__not-found">
          <h2>This product doesn't exist!</h2>
          &nbsp;&nbsp;&nbsp;
          <Button onClick={() => navigator("/marketplace")}>
            Back to Marketplace
          </Button>
        </div>
      )}
    </>
  );
}
