import { Navigate, Outlet, useLocation } from "react-router-dom";
import Footer from "../features/Footer/Footer";
import Navigation from "../features/Navigation/Navigation";
import { v3UserType } from "./Types";

/**
 * Show the appropriate screens if the user is logged in
 * @returns
 */

interface props {
  user: v3UserType | null;
}


export function PrivateRoute({ user }: props): JSX.Element {
  const location = useLocation();
  return user && user.pk ? (
    <>
      <Navigation />
      <div style={location.pathname !== "/display" ? {marginTop: "4.4rem", marginRight: "2rem", marginLeft: "2rem"} : {}}>
        <Outlet />
      </div>
      
      {location.pathname !== "/display" && <Footer />}
    </>
  ) : (
    <Navigate to={"/login"} />
  );
}
