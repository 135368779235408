import { ChangeEvent, useContext, useState } from "react";
import { useNavigate } from "react-router";
import { Loader } from "../../../components/Loader";
import Popup from "../../../components/Popup";
import { V3Organization } from "../../../utility/context/V3Organization";
import { V3UserContext } from "../../../utility/context/V3UserContext";
import { updatePassword, v3Login } from "../../../utility/Endpoints";
import { PasswordType } from "../../../utility/Types";
import Post from "../../../utility/Post";
import { Button, TextField } from "@mui/material";

export default function Password() {
  const { user, setUser } = useContext(V3UserContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<PasswordType>({
    current_password: "",
    password: "",
    password_confirm: "",
    errors: { current_password: [], password: [], password_confirm: [] },
  });
  const navigator = useNavigate();
  const organization = useContext(V3Organization);

  function handlePasswordSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (user) {
      setLoading(true);

      setPassword((prev) => ({
        ...prev,
        errors: { current_password: [], password: [], password_confirm: [] },
      }));

      let error = false;
      if (password.current_password === password.password) {
        error = true;
        setPassword((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            current_password: [
              "Current password and new password can't be the same",
            ],
          },
        }));
      }
      if (
        !/\d/.test(password.current_password) ||
        password.current_password.length < 8
      ) {
        error = true;
        setPassword((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            current_password: [
              "Password must contain 8 characters and at least 1 letter and number",
            ],
          },
        }));
      }
      if (!/\d/.test(password.password) || password.password.length < 8) {
        error = true;
        setPassword((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            password: [
              "Password must contain 8 characters and at least 1 letter and number",
            ],
          },
        }));
      }
      if (
        !/\d/.test(password.password_confirm) ||
        password.password_confirm.length < 8
      ) {
        error = true;
        setPassword((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            password_confirm: [
              "Password must contain 8 characters and at least 1 letter and number",
            ],
          },
        }));
      }

      if (!error) {
        Popup("Updating password...", "info");

        Post(updatePassword(user.pk), password).then((res) => {
          if (res && res.status < 300 && organization.pk) {
            Post(v3Login(organization.pk), {
              username: user.username,
              password: password.password,
            }).then((res) => {
              Popup("Password updated", "info");

              localStorage.setItem("vstreamer_user", JSON.stringify(res.data.data));
              localStorage.setItem("sessionid", res.data.data.sessionid);
              setUser(res.data.data);

              setLoading(false);

              setPassword({
                current_password: "",
                password: "",
                password_confirm: "",
                errors: {
                  current_password: [],
                  password: [],
                  password_confirm: [],
                },
              });
            });
          } else if (res && res.status === 401) {
            navigator("/login");
          } else {
            setLoading(false);
            if (res && res.status === 422) {
              setPassword((prev) => ({
                ...prev,
                errors: { ...prev.errors, ...res.data.form.errors },
              }));
            } else {
              Popup("There was an error changing your password", "error");
            }
          }
        });
      } else {
        setLoading(false);
      }
    }
  }

  return (
    <div className="account__container">
      <div className="account__title">Change Password</div>
      {!isLoading ? (
        <form onSubmit={handlePasswordSubmit}>
          <TextField
            fullWidth
            type="password"
            label="Current Password"
            name="current_password"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPassword((prev) => ({
                ...prev,
                current_password: e.target.value,
              }))
            }
            value={password.current_password}
            helperText={password.errors.current_password[0]}
            error={password.errors.current_password.length > 0}
            required
          />
          <TextField
            fullWidth
            type="password"
            label="New Password"
            name="password"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPassword((prev) => ({
                ...prev,
                password: e.target.value,
              }))
            }
            value={password.password}
            helperText={password.errors.password[0]}
            error={password.errors.password.length > 0}
            required
          />
          <TextField
            fullWidth
            type="password"
            label="Confirm New Password"
            name="password_confirm"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPassword((prev) => ({
                ...prev,
                password_confirm: e.target.value,
              }))
            }
            value={password.password_confirm}
            helperText={password.errors.password_confirm[0]}
            error={password.errors.password_confirm.length > 0}
            required
          />
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </form>
      ) : (
        <Loader />
      )}
    </div>
  );
}
