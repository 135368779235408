import { useState } from "react";
import Topbar from "../../../components/Topbar";
import AddUser from "./AddUser";

import UserList from "./UserList";

/**
 * Contains all User related components
 * @returns
 */
export default function UserManagement(): JSX.Element {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState("")
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [infoMessage, setInfoMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  function handleSearch(search: string) {
    setSearch(search)
  }

  return (
    <div className="user-management">
      <Topbar
        title="User List"
        buttonTitle="Add User"
        handleButton={handleOpen}
        searchbar
        handleSearch={handleSearch}
      />
      <UserList
        search={search}
        infoMessage={infoMessage}
        errorMessage={errorMessage}
      />
      <AddUser
        isOpen={isOpen}
        handleClose={handleClose}
        infoMessage={(message) => setInfoMessage(message)}
        errorMessage={(message) => setErrorMessage(message)}
      />
    </div>
  );
}
