import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { V3UserContext } from "../../utility/context/V3UserContext";
import { V3Organization } from "../../utility/context/V3Organization";
import Get from "../../utility/Get";
import { getOrgAvatarList, getStreamingPersonalAvatars } from "../../utility/Endpoints";
import { Loader } from "../../components/Loader";
import { Box, Button, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";


export default function AvatarManage(): JSX.Element {
  let navigator = useNavigate()
  const { user } = useContext(V3UserContext);
  const organization = useContext(V3Organization);
  const [orgavatars, setOrgAvatars] = useState< //organization avatars 
    {
      avatar_type: object;
      name: string;
      image: string;
      pk: number;
    }[]
  >([]);
  const [avatars, setAvatars] = useState< //personal avatars
    {
      avatar_type: object;
      name: string;
      image: string;
      pk: number;
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  //Get organization avatars if user is artist or manager
  useEffect(() => {
    const controller = new AbortController();
    if (organization) {
      if (user?.is_artist || user?.is_organization_manager || user?.is_user_manager) {
        getOrgAvatars(1, controller.signal)
      }
    }
    return () => {
      controller.abort();
      setOrgAvatars([])
    }
    // eslint-disable-next-line
  }, [organization]);

  //Get user's personal avatars
  useEffect(() => {
    const controller2 = new AbortController();

    getPersonalAvatars(1, controller2.signal)

    return () => {
      controller2.abort();
      setAvatars([])
    }
    // eslint-disable-next-line
  }, []);

  function getOrgAvatars(page: number, signal: AbortSignal) {
    if (organization.pk) {
      Get(getOrgAvatarList(organization.pk, page), signal).then((val) => {
        if (val.status && val.status < 300) {
          setOrgAvatars((prev) => [...prev, ...val.data.data]);

          //handle pages
          if (val.data.page_count > page) {
            getOrgAvatars(page + 1, signal)
          }
        } else {
          if (val.status === 401) {
            navigator("/login");
          }
        }
      });
    }
  }

  function getPersonalAvatars(page: number, signal: AbortSignal) {
    setIsLoading(true);
    Get(getStreamingPersonalAvatars(page), signal).then((val) => {
      if (val.status && val.status < 300) {
        setAvatars((prev) => [...prev, ...val.data.data]);

        //handle pages
        if (val.data.page_count > page) {
          getPersonalAvatars(page + 1, signal)
        } else {
          setIsLoading(false)
        }
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    });
  }

  return !isLoading ? (
    <div className="avatar-management">
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "right" }}>
        <Button
          variant="contained"
          onClick={() => navigator("/createavatar")}
        >
          New Avatar
        </Button>
      </div>

      <div>
        <h4>Avatars</h4>
        <TableContainer sx={{ display: "flex", justifyContent: "center" }}>
          <Table style={{ tableLayout: "fixed", whiteSpace: "nowrap" }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Thumbnail Image</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {avatars.map((data, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      <Link href={`/manageavatar/${data.pk}/edit`}>
                        {data.name ? data.name : `avatar${data.pk}`}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Box
                        component={"img"}
                        sx={{
                          width: 350,
                          maxWidth: { xs: "100%", md: 250 },
                        }}
                        alt={`${data.name} avatar`}
                        src={data.image}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {orgavatars.length > 0 && (
        <>
          <hr />
          <div>
            <h4>Organization Avatars</h4>
            <TableContainer sx={{ display: "flex", justifyContent: "center" }}>
              <Table style={{ tableLayout: "fixed", whiteSpace: "nowrap" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Thumbnail Image</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orgavatars.map((data, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>
                          <Link href={`/manageavatar/${data.pk}/edit`}>
                            {data.name ? data.name : `avatar${data.pk}`}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Box
                            component={"img"}
                            sx={{
                              width: 350,
                              maxWidth: { xs: "100%", md: 250 },
                            }}
                            alt={`${data.name} avatar`}
                            src={data.image}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}

    </div>
  ) : (
    <div>
      <Loader />
    </div>
  );
}