import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  TextField,
  Typography,
  Snackbar,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  SelectChangeEvent,
  FormLabel,
  RadioGroup,
  Radio,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import SelectableButton from "../../components/SelectableButton";
import {
  getUserAvatarList,
  getUserEmoteList,
  getUserAvatarBackgroundList,
  getPublicEmotes,
  getPublicAvatarBackgrounds,
  userAvatarBackgroundCreate,
  createBackgroundUserConnection,
  getAvatarSpecificBackgroundList,
  getAvatarSpecificEmoteList,
  sendFeatureRequest,
  getStreamingPublicAvatars,
  getStreamingObtainedAvatars,
  getStreamingPersonalAvatars,
  getAvatarDetails,
} from "../../utility/Endpoints";
import Get from "../../utility/Get";
import Preview from "./components/Preview";
import { Avatar, BackgroundType, Emote } from "../../utility/Types";
import { useNavigate } from "react-router";
import _ from "lodash";
import Post from "../../utility/Post";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AddIcon from "@mui/icons-material/Add";
import { V3Organization } from "../../utility/context/V3Organization";
import { V3UserContext } from "../../utility/context/V3UserContext";
import Microphone from "./components/Microphone";
import ScreenRecording from "./RecordVideo";

type StreamType = {
  avatar: Avatar;
  background: BackgroundType;
  emotes: Array<Emote>;
  showEmotes: boolean | null;
};

/**
 * Preview and select avatars, emotes, and backgrounds
 * @returns
 */
export default function Dashboard(): JSX.Element {
  const organization = useContext(V3Organization);
  const { user } = useContext(V3UserContext);
  const [selectedAvatar, setSelectedAvatar] = useState<Avatar>();
  const [selectedEmotes, setSelectedEmotes] = useState<Array<Emote>>([]);
  const [selectedBackground, setSelectedBackground] =
    useState<BackgroundType | null>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorDetail, setErrorDetail] = useState<string>("");

  const [avatarList, setAvatarList] = useState<Array<Avatar> | null>(null);
  const [isAvatarListLoading, setAvatarListLoading] = useState<{ pr: boolean, so: boolean, ua: boolean, pu: boolean }>({
    pr: true,
    so: true,
    ua: true,
    pu: true
  });
  const [backgroundList, setBackgroundList] =
    useState<Array<BackgroundType> | null>(null);
  const [isBackgroundListLoading, setBackgroundListLoading] = useState<{ public: boolean, private: boolean }>({ public: true, private: true });
  const [specificBackgroundList, setSpecificBackgroundList] =
    useState<Array<BackgroundType> | null>(null);
  const [emoteList, setEmoteList] = useState<Array<Emote> | null>(null);
  const [isEmoteListLoading, setEmoteListLoading] = useState<boolean>(true);
  const [specificEmoteList, setSpecificEmoteList] =
    useState<Array<Emote> | null>(null);

  const [isShowEmotesChecked, setShowEmotesChecked] = useState<boolean>(true);
  const [error, setError] = useState<{
    avatars: boolean;
    emotes: boolean;
    backgrounds: boolean;
  }>({ avatars: false, emotes: false, backgrounds: false });

  const [backgroundFormData, setBackgroundFormData] = useState<{
    name: string;
    image: File | undefined;
  }>({ name: "", image: undefined });

  const [open, setOpen] = useState<boolean>(false);
  const openModal = () => {
    setOpen(true);
    setBackgroundFormData({ name: "", image: undefined });
    setBackgroundFormErrors({ name: false, image: false });
  };

  const closeModal = () => setOpen(false);

  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const openSnackbar = () => setShowSnackbar(true);
  const closeSnackbar = () => setShowSnackbar(false);

  const [snackbarMessage, setSnackbarMessage] = useState<JSX.Element>(
    <Alert></Alert>
  );

  const [backgroundError, setBackgroundError] = useState<null | JSX.Element>(
    null
  );

  const [isReqFeatOpen, setReqFeatOpen] = useState<boolean>(false);
  const openReqFeat = () => setReqFeatOpen(true);
  const closeReqFeat = () => setReqFeatOpen(false);
  const [reqService, setReqService] = useState<string>("public");
  const [reqFeat, setReqFeat] = useState<string>("Feature");
  const [reqFeatBody, setReqFeatBody] = useState<string>("");

  const [backgroundFormErrors, setBackgroundFormErrors] = useState<{
    name: boolean;
    image: boolean;
  }>({
    name: false,
    image: false,
  });

  const [isVideoTutorialOpen, setVideoTutorialOpen] = useState<boolean>(false);

  const [threshold, setThreshold] = useState<number>(0.23);

  const [tabSelected, setTabSelected] = useState<string>('2d'); // 2d, 3d

  const handleBackgroundSubmit = () => {
    if (user) {
      if (
        backgroundFormData.name === "" ||
        backgroundFormData.image === undefined
      ) {
        if (backgroundFormData.name === "") {
          setBackgroundFormErrors((prev) => ({ ...prev, name: true }));
        }
        if (backgroundFormData.image === undefined) {
          setBackgroundFormErrors((prev) => ({ ...prev, image: true }));
        }
      } else {
        setBackgroundFormErrors({ name: false, image: false });
        const formData = new FormData();
        formData.append("name", backgroundFormData.name);
        formData.append("image", backgroundFormData.image as Blob);
        formData.append("is_public", "false");

        Post(userAvatarBackgroundCreate(user.pk), formData).then((res) => {
          if (res && res.status < 300) {
            const connectionFormData = new FormData();
            connectionFormData.append("user", user.pk.toString());
            Post(
              createBackgroundUserConnection(res.data.data.pk),
              connectionFormData
            ).then((res) => {
              if (res && res.status < 300) {
                // Append to backgroundList
                setBackgroundList((prev) => {
                  if (prev !== null)
                    return [...prev, res.data.data.avatar_background];
                  else return [res.data.data.avatar_background];
                });
                setSnackbarMessage(
                  <Alert severity="success">Background uploaded!</Alert>
                );
                openSnackbar();
                setBackgroundError(null);
                closeModal();
              } else if (res && res.status === 401) {
                navigator("/login");
              } else {
                openSnackbar();
                setSnackbarMessage(
                  <Alert severity="error">
                    "Something went wrong! Please try refreshing."
                  </Alert>
                );
              }
            });
          } else if (res && res.status === 401) {
            navigator("/login");
          } else {
            setBackgroundError(
              <Alert severity="error">
                {Object.values(res.data.form.errors)[0] as Object}
              </Alert>
            );
            openSnackbar();
            setSnackbarMessage(
              <Alert severity="error">"Unable to upload background."</Alert>
            );
          }
        });
      }
    }
  };

  const handleSelectAvatar = (item: Avatar) => {
    setSelectedEmotes([]);
    setSelectedAvatar(item);
    getSpecificBackgrounds(item);
    getSpecificEmotes(item);
  };

  const handleSelectEmote = (item: Emote) => {
    if (
      selectedEmotes.length < 10 ||
      selectedEmotes.find((x) => x.pk === item.pk)
    )
      selectedEmotes.find((x) => x.pk === item.pk)
        ? setSelectedEmotes((prev) => prev.filter((cur) => cur.pk !== item.pk))
        : setSelectedEmotes((prev) => [...prev, item]);
  };

  const handleSelectBackground = (item: BackgroundType) => {
    if (item === selectedBackground) {
      setSelectedBackground(null);
    } else {
      setSelectedBackground(item);
    }
  };

  const [prevSession, setPrevSession] = useState<StreamType>();

  const checkAvatar = useCallback(
    (item) => {
      if (selectedAvatar) {
        return selectedAvatar.pk === item.pk;
      }
      return false;
    },
    [selectedAvatar]
  );

  const checkBackground = useCallback(
    (item) => {
      if (selectedBackground) {
        return selectedBackground.pk === item.pk;
      }
      return false;
    },
    [selectedBackground]
  );

  const checkEmote = useCallback(
    (item) => {
      return selectedEmotes.find((x) => x.pk === item.pk) ? true : false;
    },
    [selectedEmotes]
  );

  const checkEmoteIndex = useCallback(
    (item) => {
      return selectedEmotes.findIndex((x) => x.pk === item.pk);
    },
    [selectedEmotes]
  );

  let navigator = useNavigate();

  const width: number = 500 + 64;
  const height: number = 500 + 80;

  const newWindowOptions: string = `fullscreen=no,height=${height},left=0,location=no,menubar=no,resizable=no,scrollbars=no,status=no,titlebar=no,toolbar=no,top=0,width=${width}`;

  useEffect(() => {
    if (prevSession === undefined) {
      if (localStorage.getItem("avatar-stream") !== null) {
        setPrevSession(
          JSON.parse(localStorage.getItem("avatar-stream") as string)
        );
      } else {
        localStorage.setItem("avatar-stream", "{}");
      }
    } else {
      if (prevSession && prevSession.avatar) {
        setSelectedAvatar(prevSession.avatar);
        getSpecificBackgrounds(prevSession.avatar);
        getSpecificEmotes(prevSession.avatar);
      }

      if (prevSession && prevSession.emotes) {
        setSelectedEmotes(prevSession.emotes);
      }

      if (prevSession && prevSession.background) {
        setSelectedBackground(prevSession.background);
      }

      if (prevSession && prevSession.showEmotes) {
        setShowEmotesChecked(prevSession.showEmotes);
      }
    }
    // eslint-disable-next-line
  }, [prevSession]);

  // Get the list of user Avatars, Emotes, and Backgrounds
  useEffect(() => {
    // NOTE: THIS IS A HOTFIX. THIS WILL BE REMOVED WHEN DEVELOPMENT GETS PUSHED TO MASTER!
    async function getAllUserAvatarList(pk: number, page: number = 1) {
      return Get(getUserAvatarList(pk, page)).then(async (res) => {
        const response = res;

        if (res.data.page < res.data.page_count) {
          const newData: any = await getAllUserAvatarList(
            pk,
            res.data.page + 1
          ).then((res) => {
            return res;
          });

          response.data.page = newData.data.page;
          response.data.page_count = newData.data.page_count;
          response.data.data = [...response.data.data, ...newData.data.data];
        }

        return response;
      });
    }

    async function getAllStreamingPublicAvatars(page: number = 1) {
      return Get(getStreamingPublicAvatars(page)).then(async (res) => {
        const response = res;

        if (res.data.page < res.data.page_count) {
          const newData: any = await getAllStreamingPublicAvatars(
            res.data.page + 1
          ).then((res) => {
            return res;
          });

          response.data.page = newData.data.page;
          response.data.page_count = newData.data.page_count;
          response.data.data = [...response.data.data, ...newData.data.data];
        }

        return response;
      });
    }

    if (user && organization.pk) {
      //get all avatars that a user can stream with
      //public avatars, personal, assigned, bought
      getSPerAvatars(1);
      getSOAvatars(1);
      getUAvatars(user.pk, 1);
      getSPubAvatars(1);

      Get(getPublicEmotes(organization.pk)).then((res) => {
        if (res.status && res.status < 300) {
          if (res.data && res.data.data) {
            Get(getUserEmoteList(user.pk)).then((res1) => {
              if (res1.status && res1.status < 300) {
                if (res1.data && res1.data.data) {
                  setEmoteList(() => {
                    return _.uniqWith(
                      res.data.data.concat(res1.data.data),
                      _.isEqual
                    );
                  });
                  setError((prev) => ({ ...prev, emotes: false }));
                }
              } else if (res1.status === 401) {
                navigator("/login");
              } else {
                setError((prev) => ({ ...prev, emotes: true }));
              }
            });
          }
        } else if (res.status === 401) {
          navigator("/login");
        } else {
          setError((prev) => ({ ...prev, emotes: true }));
        }
      }).finally(() => {
        setEmoteListLoading(false);
      });

      Get(getPublicAvatarBackgrounds(organization.pk)).then((res) => {
        if (res.status && res.status < 300) {
          if (res.data && res.data.data) {
            Get(getUserAvatarBackgroundList(user.pk)).then((res1) => {
              if (res1.status && res1.status < 300) {
                if (res1.data && res1.data.data) {
                  setBackgroundList(() => {
                    return _.uniqWith(
                      res.data.data.concat(res1.data.data),
                      _.isEqual
                    );
                  });
                  setError((prev) => ({ ...prev, backgrounds: false }));
                }
              } else if (res1.status === 401) {
                navigator("/login");
              } else {
                setError((prev) => ({ ...prev, backgrounds: true }));
              }
            });
          }
        } else if (res.status === 401) {
          navigator("/login");
        } else {
          setError((prev) => ({ ...prev, backgrounds: true }));
        }
      }).finally(() => {
        setBackgroundListLoading(prev => ({ ...prev, public: false }));
      });
    }
    // eslint-disable-next-line
  }, [navigator, organization, user]);

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
  }, [showError]);

  function getSPerAvatars(page: number) {
    Get(getStreamingPersonalAvatars(page)).then((res) => {
      if (res.status && res.status < 300) {
        if (res.data && res.data.data) {
          setAvatarList((prev) => {
            if (prev) {
              return _.uniqWith(prev.concat(res.data.data), _.isEqual);
            } else {
              return res.data.data;
            }
          });
          //handle pages
          if (res.data.page_count > page) {
            getSPerAvatars(page + 1);
          } else {
            setError((prev) => ({ ...prev, avatars: false }));
          }
        }
      } else if (res.status === 401) {
        navigator("/login");
      } else {
        setError((prev) => ({ ...prev, avatars: true }));
      }
    }).finally(() => {
      setAvatarListLoading(prev => ({ ...prev, pr: true }));
    });
  }
  function getSOAvatars(page: number) {
    Get(getStreamingObtainedAvatars(page)).then((res) => {
      if (res.status && res.status < 300) {
        if (res.data && res.data.data) {
          setAvatarList((prev) => {
            if (prev) {
              return _.uniqWith(prev.concat(res.data.data), _.isEqual);
            } else {
              return res.data.data;
            }
          });
          //handle pages
          if (res.data.page_count > page) {
            getSOAvatars(page + 1);
          } else {
            setError((prev) => ({ ...prev, avatars: false }));
          }
        }
      } else if (res.status === 401) {
        navigator("/login");
      } else {
        setError((prev) => ({ ...prev, avatars: true }));
      }
    }).finally(() => {
      setAvatarListLoading(prev => ({ ...prev, so: true }));
    });
  }
  function getUAvatars(userpk: number, page: number) {
    Get(getUserAvatarList(userpk, page)).then((res) => {
      if (res.status && res.status < 300) {
        if (res.data && res.data.data) {
          setAvatarList((prev) => {
            if (prev) {
              return _.uniqWith(prev.concat(res.data.data), _.isEqual);
            } else {
              return res.data.data;
            }
          });
          //handle pages
          if (res.data.page_count > page) {
            getUAvatars(userpk, page + 1);
          } else {
            setError((prev) => ({ ...prev, avatars: false }));
          }
        }
      } else if (res.status === 401) {
        navigator("/login");
      } else {
        setError((prev) => ({ ...prev, avatars: true }));
      }
    }).finally(() => {
      setAvatarListLoading(prev => ({ ...prev, ua: true }));
    });
  }
  function getSPubAvatars(page: number) {
    Get(getStreamingPublicAvatars(page)).then((res) => {
      if (res.status && res.status < 300) {
        if (res.data && res.data.data) {
          setAvatarList((prev) => {
            if (prev) {
              return _.uniqWith(prev.concat(res.data.data), _.isEqual);
            } else {
              return res.data.data;
            }
          });
          //handle pages
          if (res.data.page_count > page) {
            getSPubAvatars(page + 1);
          } else {
            setError((prev) => ({ ...prev, avatars: false }));
          }
        }
      } else if (res.status === 401) {
        navigator("/login");
      } else {
        setError((prev) => ({ ...prev, avatars: true }));
      }
    }).finally(() => {
      setAvatarListLoading(prev => ({ ...prev, pu: true }));
    });
  }

  function getSpecificBackgrounds(avatar: Avatar) {
    //check if selected background is in current specific background list
    //if so, we need to remove it
    if (
      selectedBackground &&
      specificBackgroundList?.findIndex(
        (e: BackgroundType) => e.pk === selectedBackground.pk
      ) !== undefined &&
      specificBackgroundList?.findIndex(
        (e: BackgroundType) => e.pk === selectedBackground.pk
      ) >= 0
    ) {
      setSelectedBackground(null);
    }
    //then get new list
    Get(getAvatarSpecificBackgroundList(avatar.pk)).then((val) => {
      if (val.status && val.status < 300) {
        setSpecificBackgroundList(val.data.data);
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    }).finally(() => {
      setBackgroundListLoading(prev => ({ ...prev, private: false }));
    });
  }

  function getSpecificEmotes(avatar: Avatar) {
    //dont need to check last emotes because we clear all emotes when the selected avatar changes
    //get new list
    Get(getAvatarSpecificEmoteList(avatar.pk)).then((val) => {
      if (val.status && val.status < 300) {
        setSpecificEmoteList(val.data.data);
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    });
  }

  const handleStartStreaming = () => {
    localStorage.removeItem("avatar-stream");

    if (selectedAvatar) {
      //Get avatar details from backend
      Get(getAvatarDetails(selectedAvatar.pk)).then((val) => {
        if (val.status && val.status < 300) {
          if (val.data && val.data.data) {
            // Set avatar
            let data = {};
            data = { ...data, avatar: val.data.data };

            // Set background
            data = { ...data, background: selectedBackground };

            // Set emotes
            data = { ...data, emotes: selectedEmotes };

            // Set show emotes
            data = { ...data, showEmotes: isShowEmotesChecked };

            // Set microphone threshold
            data = { ...data, threshold };

            localStorage.setItem("avatar-stream", JSON.stringify(data));
            window.open("/display", "_blank", newWindowOptions);
          } else {
            if (val.status === 401) {
              navigator("/login");
            }
          }
        }
      });
    } else {
      setErrorDetail("You must select at least 1 avatar");
      setShowError(true);
    }
  };

  const handleCheckbox = () => {
    setShowEmotesChecked((prev) => !prev);
  };

  function featureRequest() {
    const formDataCreate = new FormData();
    formDataCreate.append("service_type", reqService);
    formDataCreate.append("feature_type", reqFeat);
    formDataCreate.append("message_body", reqFeatBody);
    Post(sendFeatureRequest(), formDataCreate).then((val) => {
      if (val.status && val.status < 300) {
        openSnackbar();
        setSnackbarMessage(
          <Alert severity="info">"Feature request sent!"</Alert>
        );
      } else {
        if (val.status === 401) {
          navigator("/login");
        } else {
          //handle errors
          var temp = Object.keys(val.data.form.errors)[0];
          openSnackbar();
          setSnackbarMessage(
            temp ? (
              <Alert severity="error">{temp}</Alert>
            ) : (
              <Alert severity="error">
                "Unable to send request now. Try again later."
              </Alert>
            )
          );
        }
      }
      closeReqFeat();
    });
  }

  return (
    <Box className="dashboard">
      <Grid container spacing={2}>
        <Grid item textAlign="center" xs={12} sm={6} md={4}>
          <Grid item xs={12}>
            <Typography>Avatars</Typography>
          </Grid>
          <div className="buttons">
            <button onClick={() => { setTabSelected('2d') }}>2D</button>
            <button onClick={() => { setTabSelected('3d') }}>3D</button>
          </div>
          <Grid
            className="user-component"
            container
            item
            xs={12}
            maxHeight="40%"
            overflow="auto"
            paddingY="1rem"
            marginTop="0 !important"
          >
            {tabSelected === "2d" &&
              <>
              {avatarList && avatarList.length > 0 ? (
                avatarList.map((item) => {
                  return (
                    <Grid item xs={12} sm={6} md={6} lg={4} key={item.pk}>
                      <SelectableButton
                        selected={checkAvatar(item)}
                        onClick={() => {
                          handleSelectAvatar(item);
                        }}
                        height="auto"
                      >
                        <img
                          className="preview-image"
                          src={
                            typeof item.image === "string"
                              ? item.image
                              : URL.createObjectURL(item.image)
                          }
                          alt={`${item.name} avatar`}
                          style={{ width: "100%" }}
                        />
                        <p style={{ wordBreak: "break-all", padding: "0 .4rem" }}>
                          {item.name}
                        </p>
                      </SelectableButton>
                    </Grid>
                  );
                })
              ) : (
                <div
                  style={{
                    paddingLeft: ".4rem",
                    color: "white",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {isAvatarListLoading.pr && isAvatarListLoading.pu && isAvatarListLoading.so && isAvatarListLoading.ua && "Loading Avatars..."}
                  {error.avatars && "Avatars failed to load. Please refresh or contact an admin if this continues."}
                  {!isAvatarListLoading.pr && !isAvatarListLoading.pu && !isAvatarListLoading.so && !isAvatarListLoading.ua && !error.avatars && "You don't have any avatars."}
                </div>
              )}
              </>
            }
            {tabSelected === '3d' &&
              <div className="3d-text" style={{padding: '.4rem', fontFamily: 'Montserrat', fontWeight: 'bold'}}>
                <p style={{color: '#ff8900'}}>Completely customizable 3D Avatars with facial tracking are in Development.</p>
                <p style={{color: '#ff8900'}}>Help us make this feature for you!</p>
                <p style={{color: '#ff8900'}}>Subscribe for as little as $1.99 to help fund the development of your very own customizable 3D Avatar.</p>
              </div>
            }
          </Grid>

          <Grid item xs={12} marginTop="1rem">
            <Typography>Emotes</Typography>
          </Grid>
          <Grid
            className="user-component"
            container
            item
            xs={12}
            maxHeight="40%"
            overflow="auto"
            paddingY="1rem"
          >
            {specificEmoteList &&
              specificEmoteList.length > 0 &&
              // eslint-disable-next-line
              specificEmoteList.map((item) => {
                return (
                  <Grid item xs={12} sm={6} md={6} lg={4} key={item.pk}>
                    <SelectableButton
                      selected={checkEmote(item)}
                      onClick={() => {
                        handleSelectEmote(item);
                      }}
                      height="auto"
                      num={checkEmoteIndex(item)}
                    >
                      <img
                        className="preview-image"
                        src={item.thumbnail_image}
                        alt={`${item.name} emote`}
                        style={{ width: "100%" }}
                      />
                      <p style={{ wordBreak: "break-all", padding: "0 .4rem" }}>
                        {item.name}
                      </p>
                    </SelectableButton>
                  </Grid>
                );
              })}
            {emoteList && emoteList.length > 0 ? (
              // eslint-disable-next-line
              emoteList.map((item) => {
                return (
                  <Grid item xs={12} sm={6} md={6} lg={4} key={item.pk}>
                    <SelectableButton
                      selected={checkEmote(item)}
                      onClick={() => {
                        handleSelectEmote(item);
                      }}
                      height="auto"
                      num={checkEmoteIndex(item)}
                    >
                      <img
                        className="preview-image"
                        src={item.thumbnail_image}
                        alt={`${item.name} emote`}
                        style={{ width: "100%" }}
                      />
                      <p style={{ wordBreak: "break-all", padding: "0 .4rem" }}>
                        {item.name}
                      </p>
                    </SelectableButton>
                  </Grid>
                );
              })
            ) : (
              <div
                style={{
                  paddingLeft: ".4rem",
                  color: "white",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                  {error.emotes && "Emotes failed to load. Please refresh or contact an admin if this continues."}
                  {isEmoteListLoading && "Loading Emotes..."}
                  {!isEmoteListLoading && !error.emotes && "You don't have any emotes."}
              </div>
            )}
          </Grid>
        </Grid>

        <Grid item textAlign="center" xs={12} sm={6} md={4}>
          {showError && (
            <Grid
              item
              xs={12}
              position="fixed"
              bottom="2rem"
              left="2rem"
              sx={{ zIndex: "10000000000000" }}
            >
              <Alert
                variant="filled"
                severity="error"
                onClick={() => setShowError(false)}
                sx={{ zIndex: "10000000000000" }}
              >
                {errorDetail}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography>Preview</Typography>
          </Grid>
          <Grid item xs={12} height="50%">
            <Preview avatar={selectedAvatar} background={selectedBackground} />
          </Grid>
            <ScreenRecording
              screen={true}
              audio={true}
              video={true}
              handleStartStreaming={handleStartStreaming}
              downloadRecordingPath="Screen_Recording_Demo"
              downloadRecordingType="mp4"
              emailToSupport="support@xyz.com"
            />
            <Box component="a" href="#" onClick={() => setVideoTutorialOpen(true)} sx={{color: "#ff8900"}}>Quick Video Tutorial</Box>
     
          <Grid
            item
            xs={12}
            style={{
              border: "solid 1px black",
              borderRadius: ".4rem",
              marginTop: ".8rem",
              padding: "0 .8rem",
              boxSizing: "border-box",
            }}
          >
            <p>
              <b>Options</b>
            </p>

            {/* <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={() => navigator("/trim-video")}
              >
                <Typography>Start Treaming</Typography>
              </Button>
            </Grid> */}

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={handleCheckbox}
                    checked={isShowEmotesChecked}
                  />
                }
                label="Show Emotes"
                sx={{
                  position: { xs: "relative" },
                  marginLeft: "1rem",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Microphone
                setThreshold={setThreshold}
                threshold={threshold}
                isShown
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          textAlign="center"
          xs={12}
          sm={6}
          md={4}
          sx={{ marginTop: { xs: "1rem", md: 0 } }}
        >
          <Grid item xs={12}>
            <Typography>Backgrounds</Typography>
          </Grid>
          <Grid
            className="user-component"
            container
            item
            xs={12}
            maxHeight="40%"
            overflow="auto"
            paddingY="1rem"
          >
            <Grid item xs={12} sm={6} md={6} lg={4} key="add-bg">
              <SelectableButton
                selected={false}
                onClick={() => {
                  openModal();
                }}
                height="auto"
              >
                <div className="user-component__image">
                  <div className="preview-image">
                    <AddIcon
                      fontSize="large"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </div>
                <p style={{ wordBreak: "normal" }}>Add Custom Background</p>
              </SelectableButton>
            </Grid>
            {specificBackgroundList &&
              specificBackgroundList.length > 0 &&
              specificBackgroundList.map((item) => {
                return (
                  <Grid item xs={12} sm={6} md={6} lg={4} key={item.pk}>
                    <SelectableButton
                      selected={checkBackground(item)}
                      onClick={() => {
                        handleSelectBackground(item);
                      }}
                      height="auto"
                    >
                      <div className="user-component__image">
                        <img
                          className="preview-image"
                          src={item.image}
                          alt={`${item.name} background`}
                        />
                      </div>
                      <p style={{ wordBreak: "break-all" }}>{item.name}</p>
                    </SelectableButton>
                  </Grid>
                );
              })}
            {backgroundList && backgroundList.length > 0 ? (
              backgroundList.map((item) => {
                return (
                  <Grid item xs={12} sm={6} md={6} lg={4} key={item.pk}>
                    <SelectableButton
                      selected={checkBackground(item)}
                      onClick={() => {
                        handleSelectBackground(item);
                      }}
                      height="auto"
                    >
                      <div className="user-component__image">
                        {["mov", "mp4"].includes(
                          item.image.split(".")[
                            item.image.split(".").length - 1
                          ]
                        ) ? (
                          <video loop autoPlay muted src={item.image} />
                        ) : (
                          <img
                            className="preview-image"
                            src={item.image}
                            alt={`${item.name} background`}
                          />
                        )}
                      </div>
                      <p style={{ wordBreak: "break-all" }}>{item.name}</p>
                    </SelectableButton>
                  </Grid>
                );
              })
            ) : (
              <div
                style={{
                  paddingLeft: ".4rem",
                  padding: "0 .4rem",
                  color: "white",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                  {error.backgrounds && "Backgrounds failed to load. Please refresh or contact an admin if this continues."}
                  {isBackgroundListLoading.private && isBackgroundListLoading.public && "Loading Backgrounds..."}
                  {!isBackgroundListLoading.private && !isBackgroundListLoading.public && !error.backgrounds && "You don't have any backgrounds."}
              </div>
            )}
          </Grid>

          <Grid item xs={12} marginTop="1rem">
            <Typography>Accessories</Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            maxHeight="40%"
          >
            <img src="/images/ACCESSORIES.png" alt="coming soon" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
          </Grid>
        </Grid>
      </Grid>

      <Box component="div">
        <Button
          variant="contained"
          sx={{ marginTop: "1rem" }}
          onClick={() => {
            openReqFeat();
          }}
        >
          Request A Feature
        </Button>
      </Box>

      <Modal open={isVideoTutorialOpen} onClose={() => setVideoTutorialOpen(false)}>
        <Box component="div" sx={{backgroundColor: "#fff", borderRadius: "8px", padding: ".4rem", boxSizing: "border-box", display: "flex", flexDirection: "column", alignItems: "center", overflowY: "auto"}}>
          <Box component="h2">Screen Record Tutorial</Box>
          <Box
            component="img"
            src="/images/Instrucation.gif"
            width="60%"
            sx={{borderRadius: "8px", marginTop: ".4rem"}}
          />
          <Button variant="contained" onClick={() => setVideoTutorialOpen(false)} sx={{marginTop: ".4rem"}}>Close</Button>
        </Box>
      </Modal>

      <Modal open={open} onClose={closeModal}>
        <Box
          component="form"
          onSubmit={handleBackgroundSubmit}
          style={{
            backgroundColor: "white",
            padding: "2rem",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ textAlign: "center" }}>
            Add Custom Background
          </Typography>
          <TextField
            name="name"
            label="Name"
            value={backgroundFormData.name}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setBackgroundFormData((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
            sx={{ margin: ".4rem 0" }}
            required
            error={backgroundFormErrors.name}
            helperText={backgroundFormErrors.name ? "Please input a name." : ""}
          />
          <Button component="label" variant="outlined">
            <FileUploadIcon />
            {backgroundFormData.image === undefined
              ? " Background Image"
              : backgroundFormData.image.name}
            <input
              required
              type="file"
              hidden
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setBackgroundFormData((prev) => ({
                  ...prev,
                  image:
                    e.target.files !== null ? e.target.files[0] : undefined,
                }))
              }
            />
          </Button>
          {backgroundFormErrors.image && (
            <span
              style={{
                color: "#f03434",
                fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                fontWeight: "400",
                fontSize: " 0.75rem",
                lineHeight: "1.66",
                letterSpacing: "0.03333em",
                textAlign: "left",
                marginTop: "3px",
                marginRight: "14px",
                marginBottom: "0",
                marginLeft: "14px",
              }}
            >
              Please select a background image.
            </span>
          )}
          {backgroundError}
          <Button
            onClick={handleBackgroundSubmit}
            sx={{ marginTop: "1.2rem", marginBottom: ".4rem" }}
            variant="contained"
          >
            Add Background
          </Button>
          <Button
            onClick={() => closeModal()}
            color="error"
            variant="contained"
          >
            Close
          </Button>
        </Box>
      </Modal>

      <Modal open={isReqFeatOpen} onClose={closeReqFeat}>
        <Box
          component="form"
          sx={{ background: "white", padding: "2rem", borderRadius: "10px" }}
        >
          <Typography variant="h5" mb={2}>
            Request a Feature
          </Typography>
          <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
            <FormLabel id="request-row-radio-buttons-group-label">
              Service Type
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="request-radio-buttons-group-label"
              defaultValue="public"
              name="radio-buttons-group"
              value={reqService}
              onChange={(e) => setReqService(e.target.value)}
            >
              <FormControlLabel
                value="public"
                control={<Radio />}
                label="Public"
              />
              <FormControlLabel
                value="commission"
                control={<Radio />}
                label="Commission"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="email-feature">Feature Type</InputLabel>
            <Select
              labelId="email-feature"
              label="Feature Type"
              value={reqFeat}
              onChange={(e: SelectChangeEvent<string>) => {
                setReqFeat(e.target.value);
              }}
            >
              <MenuItem value="Avatar">Avatar</MenuItem>
              <MenuItem value="Emote">Emote</MenuItem>
              <MenuItem value="Background">Background</MenuItem>
              <MenuItem value="Feature">Feature</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ margin: "1rem 0" }}>
            <TextField
              id="outlined-multiline-static"
              label="Describe your Feature"
              multiline
              rows={4}
              onChange={(e) => {
                setReqFeatBody(e.target.value);
              }}
              value={reqFeatBody}
            />
          </FormControl>
          <FormControl fullWidth>
            <Button
              onClick={() => {
                featureRequest();
              }}
              variant="contained"
              sx={{ marginTop: ".4rem" }}
            >
              Request Feature
            </Button>
            <Button
              onClick={closeReqFeat}
              variant="contained"
              color="error"
              sx={{ marginTop: ".4rem" }}
            >
              <Typography color="white">Cancel</Typography>
            </Button>
          </FormControl>
        </Box>
      </Modal>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={closeSnackbar}
      >
        {snackbarMessage}
      </Snackbar>
    </Box>
  );
}
