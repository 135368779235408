import React, {useState} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import Popup from '../../components/Popup';
import { Button } from '@mui/material';

interface CheckoutProps {
  payment_intent: string;
}

export default function CheckoutForm({payment_intent}: CheckoutProps): JSX.Element {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = async (event: React.SyntheticEvent) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url:  window.location.origin + "/payment-complete/" + payment_intent, 
      },
    });


    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      if(error && error.message) {
        Popup(error.message, "error");
        setErrorMessage(error.message);
      }
      
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return  (
    <form className='cart__payment-form' onSubmit={handleSubmit}>
      <PaymentElement />
      &nbsp;&nbsp;&nbsp;
      <Button type="submit" onClick={handleSubmit} variant="contained" disabled={!stripe}>Submit</Button>
      {/* Show error message to your customers */}
      {errorMessage && <div style={{color: "red"}}>{errorMessage}</div>}
    </form>
  );
}