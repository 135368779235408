import { Box } from "@mui/material";

interface PreviewProps {
  avatar?: any | null;
  background?: any | null;
}

/**
 * Displays an avatar
 * @returns
 */
export default function Preview({
  avatar,
  background,
}: PreviewProps): JSX.Element {
  return (
    <Box className="preview" boxSizing="border-box">
      <div className="preview__container">
        <div className="preview__image">
          <img
            src={avatar ? avatar.image : "/images/placeholder.png"}
            alt="avatar"
          />
        </div>
        {background && (
          <>
            {["mov", "mp4"].includes(
              background.image.split(".")[
                background.image.split(".").length - 1
              ]
            ) ? (
              <video loop autoPlay muted src={background.image} />
            ) : (
              <div className="preview__background">
                <img src={background.image} alt="background" />
              </div>
            )}
          </>
        )}
      </div>
    </Box>
  );
}
