import { useEffect, useMemo, useState } from "react";
import { Button, Slider } from "@mui/material";
import { Dropdown } from "../../../components/Dropdown";
import useAvatarDisplay from "../hooks/useAvatarDisplay";

interface MicrophoneProps {
  threshold?: number;
  setThreshold?: (value: number) => void;
  isShown?: boolean;
}

/**
 * Handles everything microphone related.
 * @param {MicrophoneProps}
 * @returns {JSX.Element}
 */
export default function Microphone({
  threshold = 0.23,
  setThreshold,
  isShown = false,
}: MicrophoneProps): JSX.Element {
  const [deviceList, setDeviceList] = useState<MediaDeviceInfo[]>([]);
  const [deviceValue, setDeviceValue] = useState<MediaDeviceInfo | null>(null);
  const [nav, setNav] = useState<MediaStream | null>(null);
  const [isRejected, setRejected] = useState<boolean>(true);
  const setTalking = useAvatarDisplay((state) => state.setTalking);

  const audioOptions = useMemo(() => {
    return {
      audio: {
        autoGainControl: false,
        noiseSuppression: false,
        echoCancellation: false,
        deviceId: undefined,
      },
    };
  }, []);

  // This populates deviceList
  useEffect(() => {
    const handleAudioRejected = () => {
      setRejected(true);
    };

    // Grab the initial audio state
    if (localStorage.getItem("default_mic")) {
      audioOptions.audio.deviceId = JSON.parse(
        localStorage.getItem("default_mic") as string
      ).deviceId;
    }
    navigator.mediaDevices.getUserMedia(audioOptions).then((stream) => {
      setRejected(false);
      getDevices();
      stream.getAudioTracks().forEach((track) => {
        // Disable all audio tracks
        track.stop();
      });
    }, handleAudioRejected);

    async function getDevices() {
      // Only do this if the devices are being shown
      if (isShown) {
        // Get list of audio devices
        const devices = await navigator.mediaDevices.enumerateDevices();
        const tempDeviceList: MediaDeviceInfo[] = [];
        devices.forEach((device) => {
          if (device.kind === "audioinput") {
            tempDeviceList.push(device);
          }
        });
        setDeviceList(tempDeviceList);
      }
    }
  }, [audioOptions, isShown]);

  // This starts the microphone for displayWindow
  useEffect(() => {
    // Start the microphone if it isn't shown
    if (!isShown) {
      if (localStorage.getItem("default_mic")) {
        audioOptions.audio.deviceId = JSON.parse(
          localStorage.getItem("default_mic") as string
        ).deviceId;
      }
      navigator.mediaDevices.getUserMedia(audioOptions).then((stream) => {
        const audioContext = new AudioContext();
        const mediaStreamAudioSourceNode =
          audioContext.createMediaStreamSource(stream);
        const analyserNode = audioContext.createAnalyser();
        mediaStreamAudioSourceNode.connect(analyserNode);

        const pcmData = new Uint8Array(analyserNode.fftSize);

        setInterval(() => {
          analyserNode.getByteFrequencyData(pcmData);
          let sumSquares = 0.0;
          pcmData.forEach((amplitude) => {
            sumSquares += amplitude * amplitude;
          });
          let finalVal = Math.sqrt(sumSquares / pcmData.length) / 100;
          if (finalVal >= threshold) {
            setTalking(true);
          } else {
            setTalking(false);
          }
        }, 10);
      });
    }
  }, [isShown, setTalking, audioOptions, threshold]);

  async function handleTestMic() {
    if (localStorage.getItem("default_mic")) {
      audioOptions.audio.deviceId = JSON.parse(
        localStorage.getItem("default_mic") as string
      ).deviceId;
    }
    const stream = await navigator.mediaDevices.getUserMedia(audioOptions);

    // Referenced from https://jameshfisher.com/2021/01/18/measuring-audio-volume-in-javascript/
    const audioContext = new AudioContext();
    const mediaStreamAudioSourceNode =
      audioContext.createMediaStreamSource(stream);
    const analyserNode = audioContext.createAnalyser();
    mediaStreamAudioSourceNode.connect(analyserNode);

    const pcmData = new Uint8Array(analyserNode.fftSize);
    const volumeMeterEl = document.querySelector("#meter") as HTMLMeterElement;
    const onFrame = () => {
      analyserNode.getByteFrequencyData(pcmData);
      let sumSquares = 0.0;
      pcmData.forEach((amplitude) => {
        sumSquares += amplitude * amplitude;
      });
      if (volumeMeterEl)
        volumeMeterEl.value = Math.sqrt(sumSquares / pcmData.length);
      window.requestAnimationFrame(onFrame);
    };
    window.requestAnimationFrame(onFrame);

    setNav(stream);
  }

  function handleStopMicrophone() {
    // Remove the track
    nav?.getTracks().forEach((track) => {
      track.stop();
      nav?.removeTrack(track);
    });
    setNav(null);
  }

  return (
    <div
      className="microphone"
      style={{
        visibility: isShown ? "visible" : "hidden",
        position: isShown ? "initial" : "absolute",
      }}
    >
      {isRejected ? (
        <>
          <p>Please allow microphone use for full functionality</p>
          <a
            href="https://support.google.com/chrome/answer/2693767"
            target="_blank"
            rel="noreferrer"
          >
            How do I allow my microphone use?
          </a>
        </>
      ) : (
        <>
          <div>Select Audio Device</div>
          <Dropdown
            options={deviceList}
            getOptionLabel={(option: MediaDeviceInfo) => option.label.split(' ').slice(0, option.label.split(' ').length - 1).join(' ')}
            getOptionValue={(option: MediaDeviceInfo) => option.deviceId}
            value={deviceValue ? deviceValue : deviceList[0]}
            onChange={(e) => {
              setDeviceValue(e);
              localStorage.setItem("default_mic", JSON.stringify(e));
            }}
            widthSize="responsive"
          />
          <div className="microphone__meter">
            <meter className="microphone__ghost" id="meter" min={0} max={100} />
            <Slider
              className="microphone__slider"
              valueLabelDisplay="auto"
              min={0}
              max={1}
              defaultValue={0.23}
              value={threshold}
              step={0.01}
              onChange={(event) => {
                if (event.target && setThreshold) {
                  // Threshold goes here
                  setThreshold(
                    parseFloat((event.target as HTMLInputElement).value)
                  );
                }
              }}
              disabled={nav === null}
            />
          </div>
          {nav === null && (
            <Button disabled={nav !== null} onClick={handleTestMic}>
              Edit Threshold
            </Button>
          )}
          {nav !== null && (
            <Button disabled={nav === null} onClick={handleStopMicrophone}>
              Set Threshold
            </Button>
          )}
        </>
      )}
    </div>
  );
}
