import Webcam from "./components/Webcam";
import Microphone from "./components/Microphone";
import Preload from "./components/Preload";
import RenderAvatar from "./components/RenderAvatar";
import EmoteBar from "./components/EmoteBar";
import { useEffect } from "react";
import ExpressionEmotes from "./components/ExpressionEmotes";
import Watermark from "./components/Watermark";
import Background from "./components/Background";
import Loading from "./components/Loading";

/**
 * This is essentially the controller. It will handle the current state
 * of the microphone, webcam, and any emotes played and send it to the
 * component that needs it. All these states are managed by zustand in
 * useStore.tsx. This allows me to update specific state without re-rendering
 * the whole app.
 */
export default function Recog() {
  const threshold = JSON.parse(
    localStorage.getItem("avatar-stream") as string
  ).threshold;

  useEffect(() => {
    // Get rid of the global style padding
    document.body.classList.add("no-padding");
  }, []);

  return (
    <div className="display-window">
      <Loading />
      <Preload />
      <Microphone threshold={threshold} />
      <Webcam />
      <div className="display-window__hotkey-bar">
        <EmoteBar />
        <ExpressionEmotes />
        <Watermark color="white" />
      </div>
      <RenderAvatar />
      <Background />
    </div>
  );
}
