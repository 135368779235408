import clsx from "clsx";
import { Icon } from "./Icon";

type VariantOptions = "primary" | "secondary";

interface PaginationProps {
  className?: string;
  variant?: VariantOptions;
  numPages: number;
  currPage: number;
  pagesToDisplay?: number;
  onChange: (e: number) => void;
  [key: string]: any;
}

export function Pagination({
  className,
  variant = "primary", //primary | secondary
  numPages,
  currPage,
  pagesToDisplay = 3, //1 | 3 | 5 | 7
  onChange,
  ...props
}: PaginationProps) {
  const handleClick = (pageNum: number) => {
    onChange(pageNum);
  };
  //style classes
  const classes = clsx("c-pagination", className, {
    [`-${variant}`]: variant,
  });

  let pages = [];

  for (let i = 1; i < numPages + 1; i++) {
    if (shouldDisplay(i, numPages, currPage, pagesToDisplay)) {
      pages.push(
        <li
          key={i}
          className={clsx("c-pagination__item", {
            "is-active": i === currPage,
            [`-${variant}`]: variant,
          })}
        >
          <button
            className={clsx("c-pagination__btn", {
              "is-active": i === currPage,
              [`-${variant}`]: variant,
            })}
            onClick={() => handleClick(i)}
          >
            {i}
          </button>
        </li>
      );
    } else if (shouldDisplay(i + 1, numPages, currPage, pagesToDisplay)) {
      pages.push(
        <li key={i} className="c-pagination__item -gap">
          &hellip;
        </li>
      );
    }
  }

  function shouldDisplay(
    index: number,
    numPages: number,
    currentPage: number,
    pagesToDisplay: number
  ) {
    const offset = Math.floor(pagesToDisplay / 2);

    const isFirstPage = index === 1;
    const isLastPage = index === numPages;
    const isInRangeOfFirst =
      currentPage <= pagesToDisplay - offset && index <= pagesToDisplay + 1;
    const isInRangeOfLast = index >= numPages - pagesToDisplay;
    const isInRangeOfOffsetMin = index >= currentPage - offset;
    const isInRangeOfOffsetMax = index < currentPage - offset + pagesToDisplay;

    return (
      isFirstPage ||
      isLastPage ||
      isInRangeOfFirst ||
      ((isInRangeOfLast || isInRangeOfOffsetMin) && isInRangeOfOffsetMax)
    );
  }

  return (
    <nav
      aria-label="Pagination Navigation"
      className={classes}
      role="navigation"
      {...props}
    >
      <ul className="c-pagination__list">
        <li
          className={clsx("c-pagination__item", "-arrow", {
            "is-disabled": currPage === 1,
            [`-${variant}`]: variant,
          })}
        >
          <button
            className="c-pagination__btn"
            disabled={currPage === 1}
            onClick={() => handleClick(currPage - 1)}
          >
            <Icon
              name="arrowleft"
              variant={variant === "primary" ? "black" : "white"}
              size="xs"
            />
          </button>
        </li>
        {pages}
        <li
          className={clsx("c-pagination__item", "-arrow", {
            "is-disabled": currPage === numPages,
            [`-${variant}`]: variant,
          })}
        >
          <button
            className="c-pagination__btn"
            disabled={currPage === numPages}
            onClick={() => handleClick(currPage + 1)}
          >
            <Icon
              name="arrowright"
              variant={variant === "primary" ? "black" : "white"}
              size="xs"
            />
          </button>
        </li>
      </ul>
    </nav>
  );
}
