import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Popup from "../../../components/Popup";
import { V3UserContext } from "../../../utility/context/V3UserContext";
import {
  getStoreDetails,
  postStoreUpdate,
} from "../../../utility/MarketEndpoints";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { StoreType } from "../../../utility/MarketplaceTypes";
import Get from "../../../utility/Get";
import Post from "../../../utility/Post";

export default function UpdateStore() {
  const { user } = useContext(V3UserContext);
  const navigator = useNavigate();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [store, setStore] = useState<StoreType>();
  const [editStore, setEditStore] = useState<{
    name: string;
    description: string;
    visibility: boolean;
    email: string;
    errors: { name: string; description: string; email: string };
  }>({
    name: "",
    description: "",
    visibility: false,
    email: "",
    errors: { name: "", description: "", email: "" },
  });
  const [is404, set404] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (user && user.store_id)
      Get(getStoreDetails(user.store_id)).then((res) => {
        if (res && res.status) {
          if (res.status < 300) {
            setStore(res.data.data);
            setEditStore({
              name: res.data.data.name,
              description: res.data.data.description,
              visibility: res.data.data.visible,
              email: res.data.data.email,
              errors: { name: "", description: "", email: "" },
            });
          }
          if (res.status === 401) {
            navigator("/login");
          }
          if (res.status === 404) {
            set404(true);
          }
        } else {
          console.error(res);
        }
        setLoading(false);
      });
  }, [navigator, user]);

  function handleStoreUpdate() {
    const formData = new FormData();
    formData.append("name", editStore.name);
    formData.append("description", editStore.description);
    formData.append("email", editStore.email);
    formData.append("visible", editStore.visibility ? "true" : "false");

    setEditStore((prev) => ({
      ...prev,
      errors: { name: "", description: "", email: "" },
    }));

    if (store)
      Post(postStoreUpdate(store.pk), formData).then((res) => {
        if (res && res.status) {
          if (res.status < 300) {
            setStore(res.data.data);
            Popup("Store updated", "info");
          }
          if (res.status === 401) {
            navigator("/login");
          }
          if (res.status === 422) {
            setEditStore((prev) => ({ ...prev, errors: res.data.form.errors }));
          }
        } else {
          console.error(res);
        }
      });
  }

  if ((user && !user.store_id) || is404 || isLoading) return <></>;

  return (
    <div className="account__container">
      <div className="account__title">Update Store</div>
      <TextField
        fullWidth
        type="text"
        label="Store Name"
        value={editStore.name}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setEditStore((prev) => ({ ...prev, name: e.target.value }))
        }
        error={editStore.errors.name !== ""}
        helperText={editStore.errors.name}
      />
      <TextField
        fullWidth
        type="text"
        label="Description"
        value={editStore.description}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setEditStore((prev) => ({ ...prev, description: e.target.value }))
        }
        error={editStore.errors.description !== ""}
        helperText={editStore.errors.description}
      />
      <TextField
        fullWidth
        type="email"
        label="Store Email"
        value={editStore.email}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setEditStore((prev) => ({ ...prev, email: e.target.value }))
        }
        error={editStore.errors.email !== ""}
        helperText={editStore.errors.email}
      />
      <FormControlLabel
        checked={editStore.visibility}
        onChange={() => {
          setEditStore((prev) => ({
            ...prev,
            visibility: !prev.visibility,
          }));
        }}
        control={<Checkbox />}
        label={"Public"}
        disabled={isLoading}
      />
      &nbsp;
      <Button onClick={handleStoreUpdate} variant="contained">
        Update Store
      </Button>
    </div>
  );
}
