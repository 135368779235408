import { arrayToTree } from "performant-array-to-tree";
import { useEffect, useState } from "react";
import {
  AssetStateObject,
  ExpressionsListType,
  Tree,
} from "../../../utility/Types";
import useAvatarDisplay from "../hooks/useAvatarDisplay";
import convertAvatarSvg from "../helpers/convertAvatarSvg";

const expressions = [
  "happy",
  "sad",
  "neutral",
  "angry",
  "disguested",
  "fearful",
  "surprised",
];

/**
 * Preloads all assets and sets up state for avatar and emotes
 * @returns {JSX.Element}
 */
interface PreloadProps {
  data?: any;
}

export default function Preload(props: PreloadProps): JSX.Element {
  const stream = useAvatarDisplay((state) => state.stream);
  const setStream = useAvatarDisplay((state) => state.setStream);
  const setEmotes = useAvatarDisplay((state) => state.setEmotes);
  const [preloadList, setPreloadList] = useState<Array<string | null>>([]);
  const setWindowSize = useAvatarDisplay((state) => state.setWindowSize);
  const setHotkeyPressed = useAvatarDisplay((state) => state.setHotkeyPressed);
  const setAvailableExpressions = useAvatarDisplay(
    (state) => state.setAvailableExpressions
  );
  const setHotkeyHeld = useAvatarDisplay((state) => state.setHotkeyHeld);
  const hotkeyPressed = useAvatarDisplay((state) => state.hotkeyPressed);
  const setShowEmotes = useAvatarDisplay((state) => state.setShowEmotes);
  const setBackground = useAvatarDisplay((state) => state.setBackground);

  /**
   * Convert data into a tree
   */
  useEffect(() => {
    getAvatarStream();

    async function getAvatarStream() {
      //props comes from the avatar form
      // do this cause images that are files can't be saved in local
      const avatarStream =
        props && props.data
          ? props.data
          : JSON.parse(localStorage.getItem("avatar-stream") as string);

      setShowEmotes(avatarStream.showEmotes);
      setBackground(avatarStream.background ?? undefined);

      // Get list of expressions for this character here
      const expressionsIncluded: ExpressionsListType = [];
      const expressionStream = JSON.parse(
        localStorage.getItem("avatar-stream") as string
      ).avatar.avatar_assets;
      for (const { asset } of expressionStream) {
        if (asset) {
          for (const { avatar_state_list, frames } of asset.asset_varients) {
            if (
              avatar_state_list.find(
                (x: AssetStateObject) => x.name === "expression"
              ) &&
              frames.length > 0
            ) {
              for (const { name } of avatar_state_list) {
                if (
                  expressions.includes(name) &&
                  !expressionsIncluded.includes(name)
                ) {
                  expressionsIncluded.push(name);
                }
              }
            }
          }
        }
      }
      setAvailableExpressions(expressionsIncluded);

      //convert the svg urls to svg strings from s3
      var newAvatarSvgs = await convertAvatarSvg(avatarStream.avatar);
      // Map data into tree
      if (newAvatarSvgs.avatar_assets) {
        const tree = arrayToTree(newAvatarSvgs.avatar_assets, {
          id: "asset.asset_type.pk",
          parentId: "asset.parent_asset_type.pk",
        });
        var avatarIndex = tree.findIndex((t) => t.children.length > 0);
        avatarStream.avatar = tree[avatarIndex < 0 ? 0 : avatarIndex];
      }

      setStream(avatarStream);
    }
  }, [setStream, setAvailableExpressions, setShowEmotes, setBackground, props]);

  useEffect(() => {
    if (stream?.emotes) {
      for (const emote of stream?.emotes) {
        if (emote.image) setPreloadList((prev) => [...prev, emote.image]);
        if (emote.image_back)
          setPreloadList((prev) => [...prev, emote.image_back as string]);
      }
    }

    fillPreloadData(stream?.avatar);
    if (stream && stream.emotes && stream.emotes.length > 0) {
      // Modify the emotes to include hotkey
      const newEmotes = stream.emotes.map((prev, i) => ({
        ...prev,
        hotkey: (i + 1).toString(),
      }));
      setEmotes(newEmotes);
    }

    function fillPreloadData(inputStream: Tree | undefined) {
      if (
        inputStream !== undefined &&
        inputStream.data.asset !== null &&
        inputStream.data.asset.asset_varients
      ) {
        if (inputStream.children.length > 0) {
          for (const child of inputStream.children) {
            // Load any child data
            fillPreloadData(child);
          }
        }

        // Grab each frame for caching
        for (const variant of inputStream.data.asset.asset_varients) {
          for (const frame of variant.frames) {
            if (frame.image)
              setPreloadList((prev) => [...prev, frame.image as string]);
            if (frame.image_back)
              setPreloadList((prev) => [...prev, frame.image_back as string]);
          }
        }
      }
    }

    return () => {
      setPreloadList([]);
    };
  }, [stream, setPreloadList, setEmotes]);

  useEffect(() => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    const handleResize = (e: Event) => {
      const target = e.target as Window;
      setWindowSize({ width: target.innerWidth, height: target.innerHeight });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWindowSize]);

  /**
   * Setup hotkey listener
   */
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (!hotkeyPressed) {
        setHotkeyPressed(e.key);
        setHotkeyHeld(true);
      }
    };

    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [setHotkeyPressed, setHotkeyHeld, hotkeyPressed]);

  useEffect(() => {
    const handleKeyup = (e: KeyboardEvent) => {
      if (hotkeyPressed === e.key) {
        setHotkeyHeld(false);
        setHotkeyPressed(null);
      }
    };

    document.addEventListener("keyup", handleKeyup);

    return () => {
      document.removeEventListener("keyup", handleKeyup);
    };
  }, [setHotkeyHeld, setHotkeyPressed, hotkeyPressed]);

  return (
    <div className="preload">
      {preloadList.map((image, i) => (
        <img src={image as string} alt="preload" key={i} />
      ))}
    </div>
  );
}
