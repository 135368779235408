import moment from "moment";
import { useEffect, useContext, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Loader } from "../../components/Loader";
import { PaymentCard } from "../../components/PaymentCard";
import Popup from "../../components/Popup";
import { CartContext } from "../../utility/context/CartContext";
import { returnPaymentPurchaseAccess } from "../../utility/MarketEndpoints";
import Get from "../../utility/Get";
import useIntervalAsync from "../../utility/hooks/useIntervalAsync";
import { PurchaseHistoryType } from "../../utility/MarketplaceTypes";
import { Button, Link } from "@mui/material";


export default function PaymentComplete(): JSX.Element {
  const { id } = useParams();
  const { setCart } = useContext(CartContext);
  const navigator = useNavigate();
  const [purchase, setPurchase] = useState<PurchaseHistoryType>()

  //poll the details from the backend until we get information about the payment
  const getPaymentSuccessInfo = useCallback(async () => {
    if (id && !purchase) {
      Get(returnPaymentPurchaseAccess(id)).then(val => {
        if (val.status && val.status < 300) {
          setPurchase(val.data.data)
        } else if (val.status === 401) {
          navigator("/");
        } else if (val.status === 404) {
          // Nothing. keep polling
        } else {
          Popup("Opps, something went wrong. Please contact our support team.", "error");
          navigator("/")
        }
      })
    }
  }, [id, navigator, purchase]);

  const update = useIntervalAsync(getPaymentSuccessInfo, 3000);


  useEffect(() => {
    // remove local and context carts
    localStorage.setItem("vstreamer-cart-items", "[]");
    setCart([]);

    update();

  }, [id, setCart, update]);



  return (
    <div className="purchase-history">
      {purchase ? (
        <>
          <div className="purchase-history__payment-completed-title">
            <h2>Payment Complete!</h2>
            <div>
              <Button onClick={() => navigator("/")} variant="contained" color="secondary">View My Avatars</Button>
              &nbsp;&nbsp;&nbsp;
              <Button onClick={() => navigator("/marketplace")} variant="contained">Marketpalce</Button>
            </div>
          </div>

          <hr />

          <div className="purchase-history__view-products">
            {purchase.purchased_products.map((product) => (
              <div className="purchase-history__product" key={product.pk}>
                <div className="purchase-history__product-payment">
                  <div className="purchase-history__container">
                    <p>
                      Purchased from{" "}
                      <Link sx={{color: "#040666", textDecorationColor: "#040666"}} href={`/marketplace/seller/${product.store.pk}`}>
                        {product.store.name}
                      </Link>{" "}
                      on{" "}
                      {moment(purchase.time_created).format("MMM DD, YYYY")}
                    </p>
                    <a
                      href={purchase.stripe_receipt_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Receipt
                    </a>
                  </div>
                  {purchase.purchased_products.length > 1 && (
                    <p>
                      This item was a part of a ${product.price / 100}{" "}
                      purchase from multiple shops
                    </p>
                  )}
                </div>
                <PaymentCard
                  price={product.price}
                  thumbnail={product.image}
                  title={product.product_name}
                  // href={`/npc-view/${product.previews.pk}`}
                  href={`/`}
                >
                  <>
                    <hr />
                    <div className="purchase-history__product-item">
                      <p>{product.description}</p>
                    </div>
                  </>
                </PaymentCard>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <Loader variant="block" size={5} />
        </div>
      )}
    </div>
  );
}