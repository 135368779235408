import { useCallback, useEffect, useState } from "react";
import { getRandomIntRange } from "../../../utility/Helpers";
import {
  Activity,
  AssetType,
  AssetVarientUser,
  AvatarAsset,
  Frame,
  Point,
  Tree,
} from "../../../utility/Types";
import useAvatarDisplay from "../hooks/useAvatarDisplay";
import parse from "html-react-parser";
import _ from "lodash";

interface ItemProps {
  data: Tree;
  parent: AvatarAsset | null;
  parentFrame: Frame | null;
  isParentVisible?: boolean;
}

/**
 * Rendering each item in the tree recursively
 * @returns {JSX.Element}
 */
export default function RenderItem({
  data,
  parent,
  parentFrame,
  isParentVisible = true,
}: ItemProps): JSX.Element {
  const expressions = useAvatarDisplay((state) => state.expressions);
  const [currentFrame, setCurrentFrame] = useState<Frame>(
    data.data.asset.asset_varients[0].frames[0]
  );

  const [transform, setTransform] = useState<string>("");
  const [scale, setScale] = useState<number>(parent ? 1 : 0.24);
  const [translate, setTranslate] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [transformOrigin, setTransformOrigin] = useState<string>("50% 50%");
  const [zIndex, setZIndex] = useState<number>(0);
  const [backZIndex, setBackZIndex] = useState<number>(0);

  const isTalking = useAvatarDisplay((state) => state.isTalking);
  const isUsingCam = useAvatarDisplay((state) => state.isUsingCam);

  const { width, height } = useAvatarDisplay((state) => state.windowSize);

  const emote = useAvatarDisplay((state) => state.emote);

  const [rotate, setRotate] = useState<number>(0);

  const [activity, setActivity] = useState<Activity>({
    active: [],
    inactive: [],
  });

  const currentExpressionEmote = useAvatarDisplay(
    (state) => state.currentExpressionEmote
  );

  const isHotkeyHeld = useAvatarDisplay((state) => state.isHotkeyHeld);
  const setLoading = useAvatarDisplay((state) => state.setLoading);

  const refresh = useAvatarDisplay((state) => state.refresh);
  const toggleRefresh = useAvatarDisplay((state) => state.toggleRefresh);

  const [isActivitiesLoaded, setActivitiesLoaded] = useState<boolean>(false);
  const [isActivitiesRunning, setActivitiesRunning] = useState<boolean>(false);
  const [isVisible, setVisible] = useState<boolean>(true);

  const isEmoteAsset = useCallback((): boolean => {
    if (emote && emote.asset) {
      return emote.asset.pk === data.data.asset.pk;
    }
    return false;
  }, [emote, data.data.asset.pk]);

  /**
   * Checks the current data to see if it contains a state by name
   */
  const containsStateByName = useCallback(
    (name: string): boolean => {
      for (const { avatar_state_list } of data.data.asset.asset_varients) {
        if (avatar_state_list.find((x) => x.name === name)) {
          return true;
        }
      }
      return false;
    },
    [data]
  );

  const findStateByName = useCallback(
    (name: string): AssetVarientUser | undefined => {
      for (const varient of data.data.asset.asset_varients) {
        if (varient.avatar_state_list.find((x) => x.name === name))
          return varient;
      }
      return undefined;
    },
    [data]
  );

  // Used to specify when you want a refresh
  useEffect(() => {
    toggleRefresh();
  }, [toggleRefresh, isVisible]);

  useEffect(() => {
    if ((isEmoteAsset() && emote !== null) || !isParentVisible) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    return () => {
      setVisible(true);
    };
  }, [emote, isEmoteAsset, isParentVisible]);

  useEffect(() => {
    function runTilt() {
      const degree = 5; // Default: 5

      setTimeout(() => {
        const element: HTMLElement = document.getElementById(
          data.data.pk.toString()
        ) as HTMLElement;
        if (element && element.style) element.style.transitionDuration = "1.5s";

        if (Math.floor(Math.random() * 2) === 0) {
          setRotate(degree);
        } else {
          setRotate(-degree);
        }

        // Amount of time before it resets to normal
        setTimeout(() => {
          setRotate(0);
        }, 2500); // Default: 2500

        // Loop
        runTilt();

        // The amount of time it takes for the loop to start again
      }, getRandomIntRange(10, 20) * 1000); // Default: (10, 20)
    }

    if (data.data) {
      /**
       * Tilt loop for assets with tilt enabled
       */
      if (data.data.asset_type.tiltable) {
        runTilt();
      }

      /**
       * Activities setup
       */
      // Check if asset is static or active
      if (data.data.asset.asset_varients.length === 0) {
        // Error if there are no asset_varients (misconfiguration in the backend)
        alert(
          `Please contact support. Reason: ${data.data.asset.name} was misconfigured!`
        );
      } else {
        // Automatically set source to the first frame of the first varient
        if (findStateByName("static")) {
          const frame = findStateByName("static")?.frames[0];
          if (frame) {
            setCurrentFrame(frame);
          } else {
            setCurrentFrame(data.data.asset.asset_varients[0].frames[0]);
          }
        }

        // Active/Inactive if there's more than 1 varient
        for (const item of data.data.asset.asset_varients) {
          if (
            !!item.avatar_state_list.find((state) => state.name === "active")
          ) {
            // Add active frames to activity.frame
            setActivity((prev) => ({
              ...prev,
              active: _.uniqBy([...prev.active, item], "pk"),
            }));
          } else if (
            !!item.avatar_state_list.find(
              (state) => state.name === "passive" || state.name === "static"
            ) &&
            item.frames.length > 0 // Used to be "inactive"
          ) {
            // Add inactive frames to activity.frame
            setActivity((prev) => ({
              ...prev,
              inactive: _.uniqBy([...prev.inactive, item], "pk"),
            }));
          }
        }
        setActivitiesLoaded(true);
      }
    }
  }, [data.data, findStateByName]);

  /**
   * Handle Expressions
   */
  useEffect(() => {
    for (const { pk, avatar_state_list } of data.data.asset.asset_varients) {
      for (const { name } of avatar_state_list) {
        // Find the current asset based on the expression
        if (currentExpressionEmote === name) {
          // Don't update the current frame with the expression if the user is currently talking
          if (isTalking && containsStateByName("mouth")) {
            return;
          }
          // Set the current frame to that asset
          setCurrentFrame(
            data.data.asset.asset_varients.find((x) => x.pk === pk)
              ?.frames[0] ?? data.data.asset.asset_varients[0].frames[0]
          );
        }
      }
    }
  }, [currentExpressionEmote, data.data, isTalking, containsStateByName]);

  /**
   * Expressions selection
   */
  useEffect(() => {
    // Make sure that the camera is in use
    if (isUsingCam && !isHotkeyHeld) {
      for (const { pk, avatar_state_list } of data.data.asset.asset_varients) {
        for (const { name } of avatar_state_list) {
          // Find the current asset based on the expression
          if (expressions.asSortedArray()[0].expression === name) {
            // Set the current frame to that asset
            if (isTalking && containsStateByName("mouth")) {
              return;
            }

            setCurrentFrame(
              data.data.asset.asset_varients.find((x) => x.pk === pk)
                ?.frames[0] ?? data.data.asset.asset_varients[0].frames[0]
            );
          }
        }
      }
    }
  }, [
    expressions,
    data.data.asset.asset_varients,
    isUsingCam,
    isTalking,
    containsStateByName,
    isHotkeyHeld,
  ]);

  /**
   * Running activities
   */
  useEffect(() => {
    // Do default activities
    if (
      activity.active.length > 0 &&
      activity.inactive.length > 0 &&
      isActivitiesLoaded &&
      !isActivitiesRunning
    ) {
      for (let i = 0; i < activity.active.length; i++) {
        if (!containsStateByName("mouth")) {
          runActivity(i);
          setActivitiesRunning(true);
        }
      }
    }

    // Run Activity
    function runActivity(current: number) {
      // Start at the first inactive frame
      setCurrentFrame(activity.inactive[0].frames[0]);

      let intervalId: ReturnType<typeof setInterval> | undefined = undefined;
      if (intervalId !== undefined) clearInterval(intervalId);

      if (!containsStateByName("mouth")) loop();

      function loop() {
        const timeout = getRandomIntRange(
          activity.active[current].animation_start_interval_min ?? 0,
          activity.active[current].animation_start_interval_max ?? 0
        );

        // This should stop once it reaches the final frame
        let curChosenFrame: number = 0;
        // Interval will go through each frame at 'animation_interval' ms
        intervalId = setInterval(() => {
          // Check if there's any activity to run
          if (
            activity.active.length > 0 &&
            activity.active[current].frames.length > 0
          ) {
            setCurrentFrame(activity.active[current].frames[curChosenFrame]);
            if (curChosenFrame === activity.active[current].frames.length) {
              if (intervalId) {
                clearInterval(intervalId);
              }
              setCurrentFrame(
                (findStateByName("neutral") as AssetVarientUser)
                  ? (findStateByName("neutral") as AssetVarientUser).frames[0]
                  : activity.inactive[0].frames[0]
              );
              setTimeout(() => {
                // The animation cycle will repeat after 'timeout' ms between 'animation_start_interval_min' and 'animatino_start_interval_max'
                loop();
              }, timeout);
            }
            curChosenFrame++;
          }
        }, activity.active[current].animation_interval);
      }

      return () => {
        if (intervalId !== undefined) clearInterval(intervalId);
      };
    }
  }, [
    activity,
    data,
    containsStateByName,
    isActivitiesLoaded,
    isActivitiesRunning,
    findStateByName,
  ]);

  /**
   * Handle transform information
   */
  useEffect(() => {
    if (currentFrame) {
      setTranslate(getTranslate());
      setTransformOrigin(getOrigin());
      if (
        currentFrame.attachment_points[0] &&
        currentFrame.attachment_points[0].z_index
      ) {
        setZIndex(currentFrame.attachment_points[0].z_index);
      }
      if (
        currentFrame.attachment_points[0] &&
        currentFrame.attachment_points[0].z_index_back
      ) {
        setBackZIndex(currentFrame.attachment_points[0].z_index_back ?? 0);
      }
      setScale(getScale());
    }

    /**
     * Returns the origin for the ucrrent asset based on attachment point to parent
     * @returns  {string}
     */
    function getOrigin(): string {
      const assetToParent = currentFrame.attachment_points.find(
        (x) => (x.asset_type as AssetType).pk === parent?.asset_type.pk
      );
      if (assetToParent) {
        return `${assetToParent.x_position}% ${assetToParent.y_position}% `;
      }
      return `50% 50%`;
    }

    /**
     * Calculates the translate of an object based on its parent
     * @returns {Point}
     */
    function getTranslate(): Point {
      if (parent && parentFrame) {
        const parentElement = document.getElementById(parent.pk.toString());
        const element = document.getElementById(data.data.pk.toString());
        //If eyes disappear, this commented out line might be a fix? but it will break other things so needs testing
        // const element = document
        //   .getElementById(data.data.pk.toString())
        //   ?.getElementsByClassName("img")[0] as HTMLImageElement;

        if (parentElement && element) {
            const parentAttachmentPointX =
              (parentElement.offsetWidth *
                parseFloat(
                  parentFrame.attachment_points.find(
                    (x) =>
                      (x.asset_type as AssetType).pk === data.data.asset_type.pk
                  )?.x_position as string
                )) /
              100;
            const attachmentPointX =
              element.offsetWidth *
              (parseFloat(
                currentFrame.attachment_points.find(
                  (x) => (x.asset_type as AssetType).pk === parent.asset_type.pk
                )?.x_position as string
              ) /
                100);
  
            const parentAttachmentPointY =
              (parentElement.offsetHeight *
                parseFloat(
                  parentFrame.attachment_points.find(
                    (x) =>
                      (x.asset_type as AssetType).pk === data.data.asset_type.pk
                  )?.y_position as string
                )) /
              100;
            const attachmentPointY =
              element.offsetHeight *
              (parseFloat(
                currentFrame.attachment_points.find(
                  (x) => (x.asset_type as AssetType).pk === parent.asset_type.pk
                )?.y_position as string
              ) /
                100);
  
            const tempX =
              data.data.offset_x && data.data.offset_x !== "0.0"
                ? parseFloat(data.data.offset_x)
                : parentAttachmentPointX - attachmentPointX;
            const tempY =
              data.data.offset_y && data.data.offset_y !== "0.0"
                ? parseFloat(data.data.offset_y)
                : parentAttachmentPointY - attachmentPointY
            return {
              x: tempX,
              y: tempY,
            };
        }
      }
      return { x: 0, y: 0 };
    }

    function getScale(): number {
      if (!parent) {
        if (data.data.asset.origin === 'char_builder') {
          return (width < height ? width : height) * 0.0008;
        }
        return (width < height ? width : height) * 0.00033511;
      }
      return 1;
    }
  }, [currentFrame, data, parent, parentFrame, width, height, refresh]);

  /**
   * Set transform based on scale, translate, and origin
   */
  useEffect(() => {
    setTransform(
      `scale(${scale}) translate(${translate.x}px, ${translate.y}px) translateZ(${zIndex}px) rotate(${rotate}deg)`
    );
  }, [scale, translate, zIndex, rotate]);

  /**
   * Mouth movements
   */
  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> | undefined = undefined;
    if (intervalId !== undefined) clearInterval(intervalId);
    if (containsStateByName("mouth")) {
      let activeState: AssetVarientUser | undefined = undefined;
      for (const { avatar_state_list } of data.data.asset.asset_varients) {
        for (const { name } of avatar_state_list) {
          if (name === "active") {
            activeState = data.data.asset.asset_varients.find(
              (x) => x.avatar_state_list === avatar_state_list
            );
          }
        }
      }

      if (activeState) {
        intervalId = setInterval(() => {
          if (activeState && isTalking) {
            setCurrentFrame(
              activeState.frames[
                Math.floor(Math.random() * activeState.frames.length)
              ]
            );
          } else if (!isUsingCam) {
            // find the current expression or use default
            if (
              data.data.asset.asset_varients.find((x) =>
                x.avatar_state_list.find(
                  (x) => x.name === currentExpressionEmote
                )
              )
            ) {
              let newFrame = data.data.asset.asset_varients.find((x) =>
                x.avatar_state_list.find(
                  (x) => x.name === currentExpressionEmote
                )
              );

              if (newFrame) {
                setCurrentFrame(newFrame.frames[0]);
              } else {
                setCurrentFrame(data.data.asset.asset_varients[0].frames[0]);
              }
            } else {
              if (containsStateByName("neutral")) {
                setCurrentFrame(
                  (findStateByName("neutral") as AssetVarientUser).frames[0]
                );
              }
            }
          }
        }, activeState.animation_interval);
      }
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [
    isTalking,
    data,
    isUsingCam,
    currentExpressionEmote,
    containsStateByName,
    findStateByName,
  ]);

  useEffect(() => {
    const onPageLoad = () => {
      setLoading(false);
    };
    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => {
        window.removeEventListener("load", onPageLoad);
      };
    }
  }, [setLoading]);

  return currentFrame ? (
    <div
      id={`${data.data.pk}`}
      className="render-avatar__item"
      style={{ transform, transformOrigin }}
    >
      {currentFrame.image !== undefined &&
        currentFrame.image !== "" &&
        !isEmoteAsset() && (
          <>
            {typeof currentFrame.image === "string" &&
            JSON.parse(JSON.stringify(currentFrame.image)) &&
            JSON.parse(JSON.stringify(currentFrame.image)).indexOf("<svg") !==
              -1 ? (
              parse(JSON.parse(JSON.stringify(currentFrame.image)))
            ) : (
              <img
                src={
                  typeof currentFrame.image !== "string"
                    ? URL.createObjectURL(currentFrame.image)
                    : (currentFrame.image as string)
                }
                alt="item"
                style={{
                  width: data.data.asset.width,
                  transform: `translateZ(${zIndex}rem)`,
                  visibility: isVisible ? "visible" : "hidden",
                }}
              />
            )}
            {currentFrame.image_back && (
              <>
                {typeof currentFrame.image_back === "string" &&
                JSON.parse(JSON.stringify(currentFrame.image_back)) &&
                JSON.parse(JSON.stringify(currentFrame.image_back)).indexOf(
                  "<svg"
                ) !== -1 ? (
                  parse(JSON.parse(JSON.stringify(currentFrame.image_back)))
                ) : (
                  <img
                    src={
                      typeof currentFrame.image_back !== "string"
                        ? URL.createObjectURL(currentFrame.image_back)
                        : (currentFrame.image_back as string)
                    }
                    alt="backitem"
                    style={{
                      width: data.data.asset.width,
                      transform: `translateZ(${backZIndex}rem)`,
                      position: "absolute",
                      visibility: isVisible ? "visible" : "hidden",
                    }}
                  />
                )}
              </>
            )}
          </>
        )}
      {data.children.map((newData) => (
        <RenderItem
          key={newData.data.pk}
          data={newData}
          parent={data.data ?? null}
          parentFrame={currentFrame}
          isParentVisible={parent ? isParentVisible : isVisible}
        />
      ))}
      {emote && isEmoteAsset() && (
        <>
          <img
            src={emote.image}
            alt={emote.name}
            style={{
              transform: `translateZ(${emote.z_index}rem)`,
            }}
          />
          {emote.image_back && (
            <img
              src={emote.image_back}
              alt={emote.name}
              style={{
                transform: `translateZ(${emote.z_index_back}rem)`,
              }}
            />
          )}
        </>
      )}
    </div>
  ) : (
    <></>
  );
}
