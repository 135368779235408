import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { 
  createAvatarState, 
  deleteAvatarState, 
  updateAvatarState 
} from "../../../../utility/Endpoints";
import Post from "../../../../utility/Post";

const style = {
  backgroundColor: "white",
  padding: "1.5rem",
  borderRadius: "8px",
  width: "70vw",
  height: "90vh",
  overflow: "auto",
};

type AssetStateObject = {
  pk: number
  name: string
  visible: boolean
  enabled: boolean
}

interface EditAvatarStatesProps {
  openEditStateModal: boolean
  setOpenEditStateModal: (modalState: boolean) => void
  avatarStateList: Array<AssetStateObject>
  setAvatarStateList: (stateList: Array<AssetStateObject>) => void
}

export default function EditAvatarStates(props: EditAvatarStatesProps): JSX.Element {
  let navigator = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [infoMessage, setInfoMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [avatarStateList, setAvatarStateList] = useState<Array<AssetStateObject>>([])
  const [newState, setNewState] = useState<AssetStateObject>({
    name: "",
    visible: false,
    enabled: false,
    pk: 0
  })

  useEffect(() => {
    setAvatarStateList(props.avatarStateList)
  }, [props])

  function handleUpdateState(index: number) {
    setIsLoading(true)
    const formDataCreate = new FormData()
    formDataCreate.append("enabled", avatarStateList[index].enabled ? "true" : "false")
    formDataCreate.append("visible", avatarStateList[index].visible ? "true" : "false")
    formDataCreate.append("name", avatarStateList[index].name)
    formDataCreate.append("priority", "0")
    Post(updateAvatarState(avatarStateList[index].pk), formDataCreate).then(val => {
      if (val.status && val.status < 300) {
        setInfoMessage("State updated.")
        props.setAvatarStateList(avatarStateList)
      } else {
        if (val.status === 401) {
          navigator("/login")
        }
        else {
          //handle errors
          var temp = Object.keys(val.data.form.errors)[0]
          setErrorMessage(val.data.form.errors[temp])
        }
      }
      setIsLoading(false)
    })
  }

  function handleDelete(pk: number) {
    const deleteForm = new FormData()
    setIsLoading(true)
    Post(deleteAvatarState(pk), deleteForm).then(val => {
      if (val.status && val.status < 300) {
        setInfoMessage("State deleted.")
        setAvatarStateList(avatarStateList.filter(x => x.pk !== pk))
        props.setAvatarStateList(avatarStateList.filter(x => x.pk !== pk))
      } else {
        if (val.status === 401) {
          navigator("/login")
        }
        else {
          //handle errors
          var temp = Object.keys(val.data.form.errors)[0]
          setErrorMessage(val.data.form.errors[temp])
        }
      }
      setIsLoading(false)
    })
  }

  function createState() {
    //use new state
    setIsLoading(true)
    const formDataCreate = new FormData()
    formDataCreate.append("enabled", newState.enabled ? "true" : "false")
    formDataCreate.append("visible", newState.visible ? "true" : "false")
    formDataCreate.append("name", newState.name)
    formDataCreate.append("priority", "0")
    Post(createAvatarState(), formDataCreate).then(val => {
      if (val.status && val.status < 300) {
        setInfoMessage("State created.")
        setAvatarStateList(prev => [...prev, val.data.data])
        props.setAvatarStateList(avatarStateList.concat(val.data.data))
        setNewState({
          name: "",
          visible: false,
          enabled: false,
          pk: 0
        })
      } else {
        if (val.status === 401) {
          navigator("/login")
        }
        else {
          //handle errors
          var temp = Object.keys(val.data.form.errors)[0]
          setErrorMessage(val.data.form.errors[temp])
        }
      }
      setIsLoading(false)
    })
  }

  function handleNewStateChange(e: React.ChangeEvent<HTMLInputElement>) {
    setNewState({ ...newState, [e.target.name]: e.target.value })
  }

  function handleNewStateCheck(e: React.ChangeEvent<HTMLInputElement>) {
    setNewState({ ...newState, [e.target.name]: e.target.checked })
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) {
    setAvatarStateList(prev => {
      const newstate = prev.map((obj, i) => {
        if (i === index) {
          return { ...obj, [e.target.name]: e.target.value }
        }
        return obj
      })
      return newstate
    })
  }

  function handleCheck(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    setAvatarStateList(prev => {
      const newstate = prev.map((obj, i) => {
        if (i === index) {
          return { ...obj, [e.target.name]: e.target.checked }
        }
        return obj
      })
      return newstate
    })
  }

  return (
    <Modal open={props.openEditStateModal} onClose={() => props.setOpenEditStateModal(false)}>
      <Box style={style}>
        {!isLoading ? (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {infoMessage !== "" && (
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                {infoMessage}
              </Alert>
            )}
            {errorMessage !== "" && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {errorMessage}
              </Alert>
            )}
            <div style={{ fontSize: "2rem", textAlign: "center" }}>Edit States</div>
              <TableContainer sx={{ display: "flex", justifyContent: "center" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Enabled</TableCell>
                      <TableCell>Visible</TableCell>
                      <TableCell>Create/Update</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={0}>
                      <TableCell>
                        <TextField
                          fullWidth
                          label="Name"
                          required
                          value={newState.name}
                          name="name"
                          onChange={handleNewStateChange}
                        />
                      </TableCell>
                      <TableCell>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="enabled"
                                checked={newState.enabled}
                                onChange={handleNewStateCheck}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            }
                            label="Enabled"
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="visible"
                                checked={newState.visible}
                                onChange={handleNewStateCheck}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            }
                            label="Visible"
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          sx={{ my: 1 }}
                          onClick={() => createState()}
                        >
                          <Typography color="white">Create State</Typography>
                        </Button>
                      </TableCell>
                    </TableRow>
                    {avatarStateList.map((data, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell>
                            <TextField
                              fullWidth
                              label="Name"
                              required
                              value={data.name}
                              name="name"
                              onChange={(e) => handleChange(e, i)}
                            />
                          </TableCell>
                          <TableCell>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="enabled"
                                    checked={avatarStateList[i].enabled}
                                    onChange={(e) => handleCheck(e, i)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                }
                                label="Enabled"
                              />
                            </FormGroup>
                          </TableCell>
                          <TableCell>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="visible"
                                    checked={avatarStateList[i].visible}
                                    onChange={(e) => handleCheck(e, i)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                }
                                label="Visible"
                              />
                            </FormGroup>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              sx={{ my: 1 }}
                              onClick={() => handleUpdateState(i)}
                            >
                              <Typography color="white">Update State</Typography>
                            </Button>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              sx={{ my: 1 }}
                              onClick={() => handleDelete(avatarStateList[i].pk)}
                              color="error"
                            >
                              <Typography color="white">Delete State</Typography>
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            <Button
              onClick={() => props.setOpenEditStateModal(false)}
              variant="contained"
              sx={{ mx: 2 }}
            >
              <Typography color="white">Cancel</Typography>
            </Button>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </Box>
    </Modal>
  )
}