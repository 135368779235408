import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { Modal } from "../../../components/Modal";
import Popup from "../../../components/Popup";
import { V3UserContext } from "../../../utility/context/V3UserContext";
import Post from "../../../utility/Post";
import { v3DeleteUser } from "../../../utility/Endpoints";
import { Button, TextField } from "@mui/material";

export default function DeleteAccount() {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isConfirmDeleteModalOpen, setConfirmDeleteModalOpen] =
    useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const navigator = useNavigate();
  const [deleteError, setDeleteError] = useState<string>("");
  const { user, setUser } = useContext(V3UserContext);

  function handleAccountDeletion() {
    if (user) {
      setDeleteError("");

      Post(v3DeleteUser(user?.pk), { password: password }).then((res) => {
        if (res && res.status) {
          if (res.status < 300) {
            setPassword("");
            Popup("Account deleted", "info");
            localStorage.removeItem("sessionid");
            localStorage.removeItem("vstreamer_user");
            localStorage.removeItem("vstreamer-cart-items");
            setUser(null);
            navigator("/login");
          }
          if (res.status === 401) {
            navigator("/login");
          }
          if (res.status === 422) {
            setDeleteError(Object.values(res.data.form.errors)[0] as string);
          }
        }
      });
    }
  }

  return (
    <div className="account__container">
      <div className="account__title">Deactivation and Account Deletion</div>
      <Button onClick={() => setDeleteModalOpen(true)} variant="contained">
        Delete Account
      </Button>

      <Modal
        onRequestClose={() => setDeleteModalOpen(false)}
        title="Are you sure?"
        isOpen={isDeleteModalOpen}
      >
        <div className="account__modal">
          <div className="account__close-info">
            <p>Are you sure you want to delete your account?</p>
            <p>
              This will permanently remove all data related to your account.
            </p>
          </div>

          <div className="account__close-buttons">
            <Button
              onClick={() => {
                setDeleteModalOpen(false);
              }}
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setDeleteModalOpen(false);
                setConfirmDeleteModalOpen(true);
              }}
              variant="contained"
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        onRequestClose={() => setConfirmDeleteModalOpen(false)}
        title="We're sorry to see you go!"
        isOpen={isConfirmDeleteModalOpen}
      >
        <div className="account__modal">
          <div className="account__close-info">
            <p>Confirm password to permanently delete your account</p>
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
              error={deleteError !== ""}
              helperText={deleteError}
            />
          </div>
          <div className="account__close-buttons">
            <Button
              onClick={() => {
                setConfirmDeleteModalOpen(false);
              }}
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button onClick={handleAccountDeletion} variant="contained">
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
