import {
  getAssetList,
  getAvatarAssetsTypes,
  getAvatarTypeList,
} from "../../../../utility/Endpoints";
import Get from "../../../../utility/Get";
import { Asset, AssetTypeObject, AvatarType } from "../../../../utility/Types";

type assetListReturnType = {
  data: Array<{
    assetSetName: string;
    assetList: Array<Asset>;
  }>;
  error: string;
};

const returnError: assetListReturnType = {
  data: [],
  error: "There was an error getting Asset List",
};

async function recursiveGet(fn: any, arg: number, page: number = 1) {
  return await Get(fn(arg)).then(async (res) => {
    let tempArr = [];

    if (res && res.status < 300) {
      tempArr.push(...res.data.data);
      if (res.page < res.page_count) {
        recursiveGet(fn(arg, page), arg).then((res) => {
          tempArr.push(...res);
        });
      }
    } else if (res.status === 401) {
      window.location.href = "/login";
    } else {
      console.error("There was an issue fetching data!");
    }

    return tempArr;
  });
}

export default async function getFullAssetList(org: number) { //pass org pk
  let avatarTypes: Array<AvatarType> = await recursiveGet(
    getAvatarTypeList,
    org
  ).then((res) => {
    return res;
  });

  let assetTypes: Array<AssetTypeObject> = [];
  for (const avatarType of avatarTypes) {
    await recursiveGet(getAvatarAssetsTypes, avatarType.pk).then((res) => {
      assetTypes.push(...res);
    });
  }

  let assets: assetListReturnType = { data: [], error: "" };
  for (const assetType of assetTypes) {
    let tempList: Array<Asset> = [];
    await recursiveGet(getAssetList, assetType.pk).then((res) => {
      tempList.push(...res);
    });
    assets.data.push({ assetSetName: assetType.name, assetList: tempList });
  }

  if (assets.data.length > 0 || assets.error !== "") {
    return assets;
  } else {
    return returnError;
  }
}
