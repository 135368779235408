import { useEffect } from "react";
import useAvatarDisplay from "../hooks/useAvatarDisplay";

export default function RenderEmote() {
  const emote = useAvatarDisplay((state) => state.emote);

  useEffect(() => {
    let front: HTMLImageElement | null = document.querySelector(
      ".render-emote__back"
    );
    let back: HTMLImageElement | null = document.querySelector(
      ".render-emote__front"
    );

    if (front && emote && emote.image) {
      front.src = emote.image;
      front.style.zIndex = emote.z_index.toString();
      front.style.transform = `translateZ(${emote.z_index}rem)`;
    }
    if (back && emote && emote.image_back) {
      back.src = emote.image_back;
      back.style.zIndex = emote.z_index_back.toString();
      back.style.transform = `translateZ(${emote.z_index_back}rem)`;
    }
  }, [emote]);

  return emote && emote !== null ? (
    <>
      <div className="render-emote" style={{ height: "100%" }}>
        {emote && emote.image && !emote.asset && (
          <img
            className="render-emote__front"
            src={emote?.image}
            alt={`${emote.name}--front`}
            style={{
              transform: `translateZ(${emote.z_index}rem)`,
            }}
          />
        )}
        {emote && emote.image_back && !emote.asset && (
          <img
            className="render-emote__back"
            src={emote.image_back}
            alt={`${emote.name}--back`}
            style={{
              transform: `translateZ(${emote.z_index_back}rem)`,
            }}
          />
        )}
      </div>
    </>
  ) : (
    <></>
  );
}
