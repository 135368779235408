import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import { Dropdown } from "../../components/Dropdown";
import { Loader } from "../../components/Loader";
import Popup from "../../components/Popup";
import {
  postCreateSnapshotProduct,
  postCreateAvatarSnapshot,
} from "../../utility/MarketEndpoints";
import Get from "../../utility/Get";
import Post from "../../utility/Post";
import { ErrorType } from "../../utility/MarketplaceTypes";
import ProductForm from "./components/ProductForm";
import { Avatar } from "../../utility/Types";
import { V3UserContext } from "../../utility/context/V3UserContext";
import { Button } from "@mui/material";
import { getOrgAvatarList, getStreamingPersonalAvatars } from "../../utility/Endpoints";
import { V3Organization } from "../../utility/context/V3Organization";

export default function AddProduct() {
  const navigator = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [npcPk, setNpcPk] = useState<number>();
  const [npcOptions, setNpcOptions] = useState<Array<Avatar>>([]);
  const [errors, setErrors] = useState<ErrorType>({});
  const { user } = useContext(V3UserContext);
  const organization = useContext(V3Organization);

  useEffect(() => {
    const controller = new AbortController();

    if(user && user.pk === 736 && user?.store_id === 1) {
      //if this is the organzition user signed in, then also get organization avatars to sell
      getListofOrgAvatars(1, controller)
    }

    //get list of personal npcs
    if (npcOptions.length === 0) {
      getListofNpcs(1, controller);
    }

    return () => {
      controller.abort();
      setNpcOptions([]);
    };
    // eslint-disable-next-line
  }, []);

  function getListofOrgAvatars(page: number, controller: AbortController) {
    if (user) {
      Get(getOrgAvatarList(organization.pk, page), controller.signal)
        .then((val) => {
          if (val.status && val.status < 300) {
            if (val.data) {
              setNpcOptions((prev) => [...prev, ...val.data.data]);
              //recursively get all pages of npcs
              if (val.data.page_count > page) {
                getListofOrgAvatars(page + 1, controller);
              }
            }
            setLoading(false);
          } else if (val.status === 401) {
            navigator("/");
          } else {
            Popup("There was an error getting the list of Avatars", "error");
          }
        })
        .catch((e) => {
          // This probably happened because the cancel signal was sent
        });
    } else {
      getListofOrgAvatars(1, controller);
    }
  }

  function getListofNpcs(page: number, controller: AbortController) {
    //get list of user's npcs
    if (user) {
      Get(getStreamingPersonalAvatars(page), controller.signal)
        .then((val) => {
          if (val.status && val.status < 300) {
            if (val.data) {
              setNpcOptions((prev) => [...prev, ...val.data.data]);
              //recursively get all pages of npcs
              if (val.data.page_count > page) {
                getListofNpcs(page + 1, controller);
              }
            }
            setLoading(false);
          } else if (val.status === 401) {
            navigator("/");
          } else {
            Popup("There was an error getting the list of Avatars", "error");
          }
        })
        .catch((e) => {
          // This probably happened because the cancel signal was sent
        });
    } else {
      getListofNpcs(1, controller);
    }
  }

  function createProduct(product: FormData) {
    if (npcPk) {
      //we need to create the snapshot
      setErrors({});
      setLoading(true);
      //create npc snapshot
      Post(postCreateAvatarSnapshot(npcPk), {}).then((res) => {
        if (res.status && res.status < 300 && res.data && res.data.data) {
          //then create the product with the npc
          Post(postCreateSnapshotProduct(res.data.data.pk), product).then(
            (val) => {
              if (val.status && val.status < 300) {
                Popup("Product Submitted for review.");
                if (val.data && val.data.data) {
                  navigator(`/marketplace/product/${val.data.data.pk}`);
                } else {
                  navigator("/");
                }
              } else if (val.status === 401) {
                navigator("/");
              } else {
                setErrors(val.data.form.errors);
              }
              setLoading(false);
            }
          );
        } else if (res.status === 401) {
          navigator("/");
        } else {
          Popup(
            "There was an error creating your product. Contact support.",
            "error"
          );
        }
      });
    } else {
      Popup("Missing Avatar", "error");
      setLoading(false);
    }
  }

  return !isLoading ? (
    <div className="add-npc">
      <div className="add-npc__flexrow">
        <h3>Avatar Product Create</h3>
        <Button
          onClick={() => navigator(-1)}
          variant="contained"
          color="secondary"
        >
          Cancel
        </Button>
      </div>

      <hr />
      {!npcPk ? (
        <div className="add-npc__flexrow">
          <div className="add-npc__existing-avatar">
            <h5>Use an existing Avatar?</h5>
            <Dropdown
              options={npcOptions}
              getOptionLabel={(option: Avatar) => option.name}
              getOptionValue={(option: Avatar) => option.pk}
              isSearchable
              onChange={(e: any) => {
                if (setNpcPk && e) {
                  setNpcPk(e.pk);
                }
              }}
              widthSize="responsive"
            />
            <p>
              A frozen version of the Avatar will be sold so you can keep
              editing your personal Avatars.
            </p>
          </div>
        </div>
      ) : (
        <div className="add-npc__container">
          <h5 className="add-npc__title">Product Information</h5>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Loader size={5} variant={"block"} />
            </div>
          ) : (
            <ProductForm
              customCancel={() => {
                setNpcPk(undefined);
              }}
              submitFunction={createProduct}
              errors={errors}
            />
          )}
          <p>
            Our support team will review this product after submitting. Look out
            for an email of approval when this product goes live on the
            marketplace.
          </p>
        </div>
      )}
    </div>
  ) : (
    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <Loader size={5} variant={"block"} />
    </div>
  );
}
