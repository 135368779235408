// import { Button } from "@mui/material";
import { useEffect, useState, useContext, ChangeEvent } from "react";
import { useNavigate, useParams } from "react-router";
import { Pagination } from "../../components/Pagination";
import Popup from "../../components/Popup";
// import Sidebar from "../../components/Sidebar";
import { ThumbnailCard } from "../../components/ThumbnailCard";
import { V3UserContext } from "../../utility/context/V3UserContext";
import Get from "../../utility/Get";
import { getPublicStoreProducts, getStoreDetailsPublic, getStoreProducts } from "../../utility/MarketEndpoints";
import { FilterType, ProductListType, PublicStoreType } from "../../utility/MarketplaceTypes";
import { Searchbar } from "../../components/Searchbar";
import { Loader } from "../../components/Loader";

/**
 * Marketplace filtered by a certain seller's
 * products that are public and live (not a seller's dashboard)
 */


export default function MarketplaceSeller(): JSX.Element {
  const navigator = useNavigate();
  const { id } = useParams();
  const { user } = useContext(V3UserContext);
  const [isLoading, setLoading] = useState<boolean>(true);
  // const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.outerWidth);
  const [store, setStore] = useState<PublicStoreType>();
  const [npcList, setNpcList] = useState<ProductListType>({
    count: 0,
    data: [],
    page: 0,
    page_count: 0
  });
  // const [tagList, setTagList] = useState<Array<TagType>>([]);
  const [filter, setFilter] = useState<FilterType>({
    search: "",
    sort: "",
    in_favorites: false,
    has_voiceover: false,
    has_art: false,
    tags: [],
    page: 0
  });
  const [tempFilter, setTempFilter] = useState<FilterType>({
    search: "",
    sort: "",
    in_favorites: false,
    has_voiceover: false,
    has_art: false,
    tags: [],
    page: 0
  });

  useEffect(() => {
    const handleResize = (e: Event) => {
      setWindowWidth((e.target as Window).outerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    //get store's public info
    const abortController = new AbortController();

    Get(getStoreDetailsPublic(id as string), abortController.signal).then(val => {
      if (val && val.status < 300) {
        if (val.data) {
          setStore(val.data.data)
        }
      } else {
        if (val.status === 401) {
          navigator("/login");
        } else {
          Popup("Something went wrong. Try again later", "error")
        }
        setLoading(false);
      }
    }).catch(e => {
      //cancelled signal
    })

    return () => abortController.abort();
  }, [id, navigator])

  useEffect(() => {
    //get list of tags that will be used for filtering
    const abortController = new AbortController();
    // if (tagList.length === 0) {
    //   getTags(1, abortController)
    // }

    return () => abortController.abort();
    // eslint-disable-next-line
  }, []);

  // function getTags(page: number, abortController: AbortController) {
  // Get(getNpcTags(), abortController.signal).then(val => {
  //   if (val && val.status < 300) {
  //     if (val.data) {
  //       setTagList((prev) => [...prev, ...val.data.data])
  //       //recursively get all pages of tags
  //       if (val.data.page_count > page) {
  //         getTags(page + 1, abortController)
  //       }
  //     }
  //   } else {
  //     if (val.status === 401) {
  //       navigator("/login");
  //     } else {
  //       Popup("Something went wrong. Try again later", "error")
  //     }
  //   }
  // }).catch(e => {
  //   //cancelled signal
  // })
  // }

  // Handles product list
  useEffect(() => {
    const controller = new AbortController();

    setLoading(true)
    //if you are the store owner, you can see all your products here
    const endpoint = store?.pk === user?.store_id ? getStoreProducts(
      id as string,
      filter.search,
      filter.sort,
      filter.in_favorites,
      filter.has_voiceover,
      filter.has_art,
      filter.tags,
      filter.page,
    ) : getPublicStoreProducts(
      id as string,
      filter.search,
      filter.sort,
      filter.in_favorites,
      filter.has_voiceover,
      filter.has_art,
      filter.tags,
      filter.page,
    )
    // Get the user's list
    Get(
      endpoint,
      controller.signal
    )
      .then((res) => {
        if (res && res.status < 300) {
          setNpcList(res.data);
        } else if (res.status === 401) {
          navigator("/login");
        } else {
          // Popup("There was an error getting the list of Avatars", "error");
        }
        setLoading(false)
      })
      .catch((e) => {
        // This probably happened because the cancel signal was sent
      });

    return () => {
      controller.abort();
      // setTagList([]);
    };
  }, [filter, navigator, id, user, store]);


  function handleSearch() {
    setFilter(tempFilter);
    // setSidebarOpen(false);
  }

  // function handleTagUpdate(newValue: any) {
  //   if (newValue) {
  //     var temp = newValue as Array<TagType>;
  //     // setTempFilter((prev) => ({ ...prev, tags: temp }))
  //   }
  // }

  // function handleCancel() {
  //   setTempFilter({
  //     ...filter,
  //     sort: filter.sort,
  //     in_favorites: filter.in_favorites,
  //     has_voiceover: filter.has_voiceover,
  //     has_art: filter.has_art,
  //     tags: filter.tags,
  //     page: filter.page,
  //   });
  //   setSidebarOpen(false);
  // }


  return !isLoading ? (
    <div className="marketplace-seller">
      <div className="npc-list">
        <div className="npc-list__sort-search">
          {windowWidth <= 768 ? (
            <>
              {/* <div className="npc-list__container">
                <div className="npc-list__sort-filter">
                  <Button
                    onClick={() => setSidebarOpen((prev) => !prev)}
                  >
                    Sort/Filter
                  </Button>
                </div>
              </div> */}

              {/* <Sidebar
                isOpen={isSidebarOpen}
                handleClose={() => setSidebarOpen(false)}
                title="Filter & Sort"
                excludeList={["c-dropdown"]}
              >
                <>
                  <hr />
                  <Sort setFilter={setTempFilter} />
                  <hr />
                  <Filter filter={tempFilter} setFilter={setTempFilter} />
                  <hr />
                  <p>Tags</p>
                  <Dropdown
                    options={tagList}
                    getOptionLabel={(option: TagType) => option.name}
                    getOptionValue={(option: TagType) => option.pk}
                    onChange={handleTagUpdate}
                    isSearchable
                    isMulti
                  />
                  <div className="npc-list__mobile-buttons">
                    <Button
                      onClick={() => {
                        handleCancel();
                        setSidebarOpen(false);
                      }}
                      variant="secondary"
                    >
                      Cancel
                    </Button>
                    <Button onClick={handleSearch}>Search</Button>
                  </div>
                </>
              </Sidebar> */}

              {/* <Searchbar
                className="npc-list__searchbar"
                onClear={() => setTempFilter((prev) => ({ ...prev, search: "" }))}
                value={tempFilter.search}
                placeholder={"Search by name or occupation"}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setTempFilter((prev) => ({ ...prev, search: e.target.value }))
                }
                onSubmit={(e: React.MouseEvent) => {
                  e.preventDefault();
                  handleSearch();
                }}
              /> */}
            </>
          ) : (
            <>
              {/* <div className="npc-list__sort-filter">
                <Button onClick={() => setSidebarOpen((prev) => !prev)}>
                  Sort/Filter
                </Button>
              </div> */}
              {/* This is to give correct spacing */}
              <span style={{ opacity: "0", width: "100px" }}></span>

              {/* <Sidebar
                isOpen={isSidebarOpen}
                handleClose={() => setSidebarOpen(false)}
                title="Filter & Sort"
                excludeList={["c-dropdown"]}
              >
                <>
                  <hr />
                  <Sort setFilter={setTempFilter} />
                  <hr />
                  <Filter filter={tempFilter} setFilter={setTempFilter} />
                  <hr />
                  <p>Tags</p>
                  <Dropdown
                    options={tagList}
                    getOptionLabel={(option: TagType) => option.name}
                    getOptionValue={(option: TagType) => option.pk}
                    onChange={handleTagUpdate}
                    isSearchable
                    isMulti
                  />
                  <div className="npc-list__mobile-buttons">
                    <Button
                      onClick={() => {
                        handleCancel();
                        setSidebarOpen(false);
                      }}
                      variant="secondary"
                    >
                      Cancel
                    </Button>
                    <Button onClick={handleSearch}>Search</Button>
                  </div>
                </>
              </Sidebar> */}

              <Searchbar
                className="npc-list__searchbar"
                onClear={() => {
                  setFilter((prev) => ({ ...prev, search: "" }));
                  setTempFilter((prev) => ({ ...prev, search: "" }));
                }}
                value={tempFilter.search}
                placeholder={"Search by name or occupation"}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setTempFilter((prev) => ({ ...prev, search: e.target.value }))
                }
                onSubmit={(e: React.MouseEvent) => {
                  e.preventDefault();
                  handleSearch();
                }}
              />
              {/* This is to give correct spacing */}
              <span style={{ opacity: "0", width: "100px" }}></span>
            </>
          )}
        </div>

        <hr style={{ width: "100%" }} />

        <h3>{store?.name}</h3>

        {npcList.data ? (
          <>
            <div className="npc-list__list">
              {npcList.data.map((product, i) => (
                <ThumbnailCard
                  className="npc-list__item"
                  thumbnail={product.image ? product.image as string : ""}
                  title={product.product_name}
                  // subtitle={product.previews && !Array.isArray(product.previews) ? product.previews.occupation : product.description}
                  price={product.price}
                  status={
                    product.status === "pending" ? product.status.toUpperCase() : null
                  }
                  href={`/marketplace/product/${product.pk}`}
                  key={i}
                />
              ))}
            </div>
            <div className="npc-list__pagination">
              {npcList.count === 0 ? (
                <h4 style={{ textAlign: "center", width: "100%" }}>
                  No products found!
                </h4>
              ) : (
                <Pagination
                  numPages={npcList.page_count}
                  currPage={npcList.page}
                  onChange={(page: number) => setFilter((prev: any) => ({ ...prev, page: page }))}
                  pagesToDisplay={windowWidth <= 768 ? 7 : 3}
                />
              )}
            </div>
          </>
        ) : (
          <div>There are no Products</div>
        )}
      </div>
    </div>
  ) : (
    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <Loader size={5} variant={"block"} />
    </div>
  );
}
