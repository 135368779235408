/**
 * Note: The video needs to appear on screen but be hidden.
 * https://github.com/justadudewhohacks/face-api.js/issues/750
 * Instead of display: none, I use opacity: 0.
 */
import { LegacyRef, useEffect, useRef } from "react";
import * as faceapi from "face-api.js";
import useAvatarDisplay from "../hooks/useAvatarDisplay";

/**
 * To get the current detected expression, get the expressions value
 * from useStore and call the asSortedArray.
 * Ex: expressions.asSortedArray()[0] This will get the most probable expression.
 * @returns {JSX.Element}
 */
export default function Webcam(): JSX.Element {
  const videoRef = useRef<{ srcObject: MediaStream }>();
  const setExpressions = useAvatarDisplay((state) => state.setExpressions);
  const setUsingCam = useAvatarDisplay((state) => state.setUsingCam);

  useEffect(() => {
    startVideo();

    videoRef && loadModels();

    function loadModels() {
      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(
          "https://character-builder-light-assets.s3.us-east-2.amazonaws.com/NeverEnding/media/models"
        ),
        faceapi.nets.faceLandmark68Net.loadFromUri(
          "https://character-builder-light-assets.s3.us-east-2.amazonaws.com/NeverEnding/media/models"
        ),
        faceapi.nets.faceRecognitionNet.loadFromUri(
          "https://character-builder-light-assets.s3.us-east-2.amazonaws.com/NeverEnding/media/models"
        ),
        faceapi.nets.faceExpressionNet.loadFromUri(
          "https://character-builder-light-assets.s3.us-east-2.amazonaws.com/NeverEnding/media/models"
        ),
      ])
        .then(() => {
          faceDetection();
        })
        .catch(() => {
          // Do nothing
        });
    }

    const vidRefCopy = videoRef.current;

    function startVideo() {
      if (navigator.mediaDevices) {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((currentStream) => {
            if (vidRefCopy) vidRefCopy.srcObject = currentStream;
            setUsingCam(true);
          })
          .catch(() => {
            setUsingCam(false);
          });
      } else {
        // There was an error getting camera
        setUsingCam(false);
      }
    }

    async function faceDetection() {
      setInterval(async () => {
        const detections = await faceapi
          .detectAllFaces(
            vidRefCopy as faceapi.TNetInput,
            new faceapi.TinyFaceDetectorOptions()
          )
          .withFaceLandmarks()
          .withFaceExpressions();

        if (detections.length > 0) {
          setExpressions(detections[0].expressions);
        }
      }, 100);
    }

    return () => {
      const mediaStream = vidRefCopy?.srcObject;
      if (mediaStream) {
        const tracks = mediaStream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [setExpressions, setUsingCam]);

  return (
    <div className="webcam" id="webcam">
      <div className="webcam__video">
        <video
          crossOrigin="anonymous"
          ref={videoRef as LegacyRef<HTMLVideoElement>}
          autoPlay
          muted
        />
      </div>
    </div>
  );
}
