import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Post from "../../utility/Post";
import { sendVideoViaEmail, uploadVideoOnS3 } from "../../utility/Endpoints";
import { V3UserContext } from "../../utility/context/V3UserContext";
import { Loader } from "../../components/Loader";
import Popup from "../../components/Popup";
import CopyUrl from "./components/CopyUrl";
import { UserInfo } from "os";
import { v3UserType } from "../../utility/Types";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Icon } from "../../components/Icon";

interface ScreenRecordingTypes {
  screen: boolean;
  audio: boolean;
  video: boolean;
  downloadRecordingPath: string;
  downloadRecordingType: string;
  emailToSupport: string;
  handleStartStreaming: () => void;
}
interface RecordView {
  status: string;
  startRecording: () => void;
  mediaBlobUrl?: any;
}
type PresignedPostFields = {
  key: string;
  "x-amz-algorithm": string;
  "x-amz-credential": string;
  "x-amz-date": string;
  policy: string;
  "x-amz-signature": string;
  acl: string;
};

type PresignedPost = {
  url: string;
  fields: PresignedPostFields;
};

type videoDataType = {
  pk: number;
  presigned_post: PresignedPost;
};

const ScreenRecording = ({
  screen,
  audio,
  video,
  downloadRecordingPath,
  downloadRecordingType,
  emailToSupport,
  handleStartStreaming,
}: ScreenRecordingTypes) => {
  const [recordingNumber, setRecordingNumber] = useState(0);

  const RecordView = () => {
    const {
      status,
      startRecording: startRecord,
      mediaBlobUrl,
    }: RecordView = useReactMediaRecorder({
      screen,
      audio,
      video,
    });
    const startRecording = () => {
      return startRecord();
    };
    const { user }: { user?: v3UserType | null | undefined } =
      useContext(V3UserContext);

    const [openDialog, setOpenDialog] = useState(false);
    const [videoData, setVideoData] = useState({} as videoDataType);
    const [isLoading, setIsLoading] = useState(false);
    const [userEmail, setUserEmail] = useState<string | undefined>(user?.email);
    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };

    const handleCloseDialog = () => {
      setOpenDialog(false);
    };
    const handleShareVideo = async () => {
      const pathName = `${downloadRecordingPath}_${recordingNumber}.${downloadRecordingType}`;
      handleClickOpenDialog();
      setIsLoading(true);
      fetch(mediaBlobUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // Create a download link
          if (user && user.pk !== null && user.pk !== undefined) {
            const data = new Blob([blob], { type: "video/mp4" });
            const file = new FormData();
            file.append("video_file", data, `${pathName}.mp4`);
            file.append("created_by", user.pk.toString());

            Post(uploadVideoOnS3(user.pk), file)
              .then((res) => {
                const formData = new FormData();
                setVideoData(res.data?.data);
                for (const key in res?.data?.data.presigned_post?.fields) {
                  formData.append(
                    key,
                    res?.data?.data.presigned_post?.fields?.[key]
                  );
                }
                formData.append("file", data, `${pathName}.mp4`);

                fetch(res?.data?.data?.presigned_post?.url, {
                  method: "POST",
                  body: formData,
                }).then(() => {});
                Popup("Video Uploaded Successfull!", "info");

                localStorage.setItem(
                  "video_url",
                  JSON.stringify(
                    `${res?.data?.data?.presigned_post?.url}/${res?.data?.data.presigned_post?.fields?.key}`
                  )
                );
              })
              .finally(() => setIsLoading(false));
          }
        });
    };
    const shareVideoLinkViaEmail = async () => {
      const file = new FormData();
      if (!userEmail) {
        Popup("Please enter email", "error");
        return;
      }
      file.append("email", userEmail || "");

      file.append("avatar_video", videoData?.pk.toString());
      if (user && user.pk !== null && user.pk !== undefined) {
        Post(sendVideoViaEmail(user.pk), file).then(() => {
          Popup("Email Sent Successfull!", "info");
        });
      }
    };
    const viewRecording = () => {
      //@ts-ignore

      //@ts-ignore
      window.open(mediaBlobUrl, "_blank").focus();
    };
    const downloadRecording = () => {
      const pathName = `${downloadRecordingPath}_${recordingNumber}.${downloadRecordingType}`;
      try {
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
          // for IE
          //@ts-ignore
        } else {
          fetch(mediaBlobUrl)
            .then((response) => response.blob())
            .then((blob) => {
              // Create a download link
              const data = new Blob([blob], { type: "video/mp4" });
              const url = URL.createObjectURL(data);
              const link = document.createElement("a");

              link.href = url;
              link.download = pathName;

              // Simulate a click to trigger the download
              link.click();

              // Clean up
              URL.revokeObjectURL(url);
            });
          // for Chrome
          //@ts-ignore
          // const link = document.createElement("a");
          // link.href = mediaBlobUrl;
          // link.download = pathName;
          // document.body.appendChild(link);
          // link.click();
          // document.body.removeChild(link);
        }
      } catch (err) {
        console.error(err);
      }
    };
    return (
      <Grid container item xs={12} spacing={1} marginTop="1rem">
        <Grid item xs={5}>
          <Button fullWidth variant="contained" onClick={handleStartStreaming}>
            Start Streaming
          </Button>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Icon name="thenIcon" />
        </Grid>
        <Grid item xs={5}>
          {status && status !== "recording" && (
            <Grid item xs={12}>
              <Button fullWidth onClick={startRecording} variant="contained">
                {mediaBlobUrl ? "Record Again" : "Record Your Stream"}
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container item xs={12} spacing={1}>
          {mediaBlobUrl && status && status === "stopped" && (
            <Grid item xs={4}>
              <Button fullWidth onClick={viewRecording} variant="contained">
                View
              </Button>
            </Grid>
          )}
          {downloadRecordingType &&
            mediaBlobUrl &&
            status &&
            status === "stopped" && (
              <Grid item xs={4}>
                <Button
                  fullWidth
                  onClick={downloadRecording}
                  variant="contained"
                >
                  Download
                </Button>
              </Grid>
            )}
          <Grid item xs={4}>
            {mediaBlobUrl && status && status === "stopped" && (
              <Button fullWidth onClick={handleShareVideo} variant="contained">
                Share Video
              </Button>
            )}
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Box sx={{ p: 4 }}>
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <CopyUrl
                      title="Copy and share this page"
                      mainBtnTitle={""}
                      handleClose={() => {
                        handleCloseDialog();
                      }}
                      handleConfirm={() => {
                        Popup("Copied", "info");
                      }}
                    />
                    <Grid spacing={2} container>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          placeholder={"Share with friend"}
                          defaultValue={user?.email}
                          value={userEmail}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setUserEmail(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          onClick={shareVideoLinkViaEmail}
                          variant="contained"
                        >
                          Share Via Email
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button onClick={downloadRecording} variant="contained">
                          Share Via TikTok
                        </Button>
                      </Grid>
                      <Grid item xs={4}></Grid>
                    </Grid>
                  </>
                )}
              </Box>
            </Dialog>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return <div className="Scren-Record-Wrapper">{RecordView()}</div>;
};
export default ScreenRecording;
