// import { Avatar, AvatarAsset } from "../../../Utility/types/AvatarTypes";
import axios from "axios";
import { Avatar, AvatarAsset } from "../../../utility/Types";


//get the image from aws and convert to svg string
//id is avatar id, name is asset type name
async function convertToSvg(
  image: string,
  id: number,
  frame_id: number,
  name: string,
  z_index: number,
  width: number,
  is_back: boolean = false,
  colors: { [index: string]: string },
  isBuildAsset: boolean,
) {
  return await axios.get(image).then((response) => {
    var svg = response.data;
    svg = svg.substring(svg.indexOf("<svg"));
    var svg_unique_name = `character${id}-${frame_id}-${name.split(' ').join('_')}-svg`;

    if (is_back) {
      svg = svg.replace(
        "<svg",
        `<svg id="${svg_unique_name}" frame-id="${frame_id}" style="z-index: ${z_index}; width: ${width}px; position: absolute;"`
      );
    } else {
      svg = svg.replace(
        "<svg",
        `<svg id="${svg_unique_name}" frame-id="${frame_id}" style="z-index: ${z_index}; width: ${width}px"`
      );
    }

    svg = svg.replace(/\.fill_base/i, `#${svg_unique_name} .fill_base`);
    svg = svg.replace(/\.fill_accent/gi, `#${svg_unique_name} .fill_accent`);

    svg = svg.replace(/\.st0/i, `#${svg_unique_name} .st0`);
    svg = svg.replace(/\.st1/i, `#${svg_unique_name} .st1`);
    svg = svg.replace(/\.st2/i, `#${svg_unique_name} .st2`);
    svg = svg.replace(/\.st3/i, `#${svg_unique_name} .st3`);
    svg = svg.replace(/\.st4/i, `#${svg_unique_name} .st4`);
    svg = svg.replace(/\.st5/i, `#${svg_unique_name} .st5`);
    svg = svg.replace(/\.st6/i, `#${svg_unique_name} .st6`);
    svg = svg.replace(/\.st7/i, `#${svg_unique_name} .st7`);
    svg = svg.replace(/\.st8/i, `#${svg_unique_name} .st8`);
    svg = svg.replace(/\.st9/i, `#${svg_unique_name} .st9`);
    svg = svg.replace(/\.st10/i, `#${svg_unique_name} .st10`);
    svg = svg.replace(/\.st11/i, `#${svg_unique_name} .st11`);

    svg = svg.replace(/\.line_base/i, `#${svg_unique_name} .line_base`);
    svg = svg.replace(/\.line_accent/gi, `#${svg_unique_name} .line_accent`);


    //handle coloring the asset
    Object.keys(colors).map((colorKey: string) => {
      //used to find the color
      var regex = new RegExp(
        `.${colorKey}{fill:#[0-9A-F]{6};}`,
        "i"
      );

      //default colors
      var newColor = isBuildAsset ? "#ADADAD" : "#FFFFFF";

      //if there is a color, color it
      if (colors[colorKey]) {
        newColor = `#${colors[colorKey]}`
      }
      svg = svg.replace(
        regex,
        `.${colorKey}{fill:${newColor};}`
      );
      return regex;
    })

    return { svg, frame_id, is_back };
  });
}

export default async function convertAvatarSvg(avatar: Avatar) {
  var promiseArray: Array<any> = [];

  var assets = avatar.avatar_assets?.filter(x => x.asset)

  //for each frame with an image (and back image), 
  // push the promise to the array 
  // get the asset from aws and convert to an svg string
  assets?.map((asset) => {
    asset.asset.asset_varients.map((varient) => {
      varient.frames.map((frame, key) => {
        if (
          frame.image &&
          typeof (frame.image) === "string" &&
          frame.image.lastIndexOf(".svg") > 0
        ) {
          var svgImage = convertToSvg(
            frame.image,
            avatar.pk,
            frame.pk,
            asset.asset.asset_type ? asset.asset.asset_type.name : "image" + key,
            frame.attachment_points && frame.attachment_points[0] ? frame.attachment_points[0].z_index : 0,
            asset.asset.width,
            false,
            {
              fill_accent1: asset.color_accent_1 ? asset.color_accent_1 : "",
              fill_accent2: asset.color_accent_2 ? asset.color_accent_2 : "",
              fill_accent3: asset.color_accent_3 ? asset.color_accent_3 : "",
              fill_accent4: asset.color_accent_4 ? asset.color_accent_4 : "",
              fill_accent5: asset.color_accent_5 ? asset.color_accent_5 : "",
              fill_accent6: asset.color_accent_6 ? asset.color_accent_6 : "",
              fill_accent7: asset.color_accent_7 ? asset.color_accent_7 : "",
              fill_accent8: asset.color_accent_8 ? asset.color_accent_8 : "",
              fill_base: asset.color_base ? asset.color_base : "",
            },
            asset.asset_type.placement === "body" ? true : false
          )
          promiseArray.push(svgImage)
        }
        if (
          frame.image_back &&
          typeof (frame.image_back) === "string" &&
          frame.image_back.lastIndexOf(".svg") > 0
        ) {
          var svgImageBack = convertToSvg(
            frame.image_back,
            avatar.pk,
            frame.pk,
            asset.asset.asset_type ? asset.asset.asset_type.name + "-back" : "image" + key + "-back",
            frame.attachment_points && frame.attachment_points[0] && frame.attachment_points[0].z_index_back ? frame.attachment_points[0].z_index_back : 0,
            asset.asset.width,
            true,
            {
              fill_accent1: asset.color_accent_1 ? asset.color_accent_1 : "",
              fill_accent2: asset.color_accent_2 ? asset.color_accent_2 : "",
              fill_accent3: asset.color_accent_3 ? asset.color_accent_3 : "",
              fill_accent4: asset.color_accent_4 ? asset.color_accent_4 : "",
              fill_accent5: asset.color_accent_5 ? asset.color_accent_5 : "",
              fill_accent6: asset.color_accent_6 ? asset.color_accent_6 : "",
              fill_accent7: asset.color_accent_7 ? asset.color_accent_7 : "",
              fill_accent8: asset.color_accent_8 ? asset.color_accent_8 : "",
              fill_base: asset.color_base ? asset.color_base : "",
            },
            asset.asset_type.placement === "body" ? true : false
          )

          promiseArray.push(svgImageBack)
        }
        return frame
      })
      return varient
    })
    return asset
  })

  // after all promises in the array are done, return the final avatar object with svgs converted
  return await Promise.allSettled(promiseArray).then((results) => {
    var tempAvatar = avatar;

    //replace the svg urls with the converts svg strings
    tempAvatar.avatar_assets?.map((asset) => {
      if (asset && asset.asset) {
        asset.asset.asset_varients.map((varient) => {
          varient.frames.map((frame) => {
            results.map((svg) => {
              if ( //front images
                svg.status &&
                svg.status === 'fulfilled' &&
                svg.value && svg.value.frame_id === frame.pk &&
                !svg.value.is_back
              ) {
                frame.image = svg.value.svg
                return frame.image
              } else if ( //back images
                svg.status &&
                svg.status === 'fulfilled' &&
                svg.value && svg.value.frame_id === frame.pk &&
                svg.value.is_back
              ) {
                frame.image_back = svg.value.svg
                return frame.image
              } else return frame.image
            })
            return frame
          })
          return varient
        })
      }
      return asset
    })
    //then return the same avatar structure
    return tempAvatar;
  });
}


// VERYYY similar to the one above ^
export async function convertAssetSvg(avatarpk: number, asset: AvatarAsset) {
  var promiseArray: Array<any> = [];

  if (!asset.asset) return asset;

  asset.asset.asset_varients.map((varient) => {
    varient.frames.map((frame, key) => {
      if (
        frame.image &&
        typeof (frame.image) === "string" &&
        frame.image.lastIndexOf(".svg") > 0
      ) {
        var svgImage = convertToSvg(
          frame.image,
          avatarpk,
          frame.pk,
          asset.asset.asset_type ? asset.asset.asset_type.name : "image" + key,
          frame.attachment_points && frame.attachment_points[0] ? frame.attachment_points[0].z_index : 0,
          asset.asset.width,
          false,
          {
            fill_accent1: asset.color_accent_1 ? asset.color_accent_1 : "",
            fill_accent2: asset.color_accent_2 ? asset.color_accent_2 : "",
            fill_accent3: asset.color_accent_3 ? asset.color_accent_3 : "",
            fill_accent4: asset.color_accent_4 ? asset.color_accent_4 : "",
            fill_accent5: asset.color_accent_5 ? asset.color_accent_5 : "",
            fill_accent6: asset.color_accent_6 ? asset.color_accent_6 : "",
            fill_accent7: asset.color_accent_7 ? asset.color_accent_7 : "",
            fill_accent8: asset.color_accent_8 ? asset.color_accent_8 : "",
            fill_base: asset.color_base ? asset.color_base : "",
          },
          asset.asset_type.placement === "body" ? true : false
        )
        promiseArray.push(svgImage)
      }
      if (
        frame.image_back &&
        typeof (frame.image_back) === "string" &&
        frame.image_back.lastIndexOf(".svg") > 0
      ) {
        var svgImageBack = convertToSvg(
          frame.image_back,
          avatarpk,
          frame.pk,
          asset.asset.asset_type ? asset.asset.asset_type.name + "-back" : "image" + key + "-back",
          frame.attachment_points && frame.attachment_points[0] && frame.attachment_points[0].z_index_back ? frame.attachment_points[0].z_index_back : 0,
          asset.asset.width,
          true,
          {
            fill_accent1: asset.color_accent_1 ? asset.color_accent_1 : "",
            fill_accent2: asset.color_accent_2 ? asset.color_accent_2 : "",
            fill_accent3: asset.color_accent_3 ? asset.color_accent_3 : "",
            fill_accent4: asset.color_accent_4 ? asset.color_accent_4 : "",
            fill_accent5: asset.color_accent_5 ? asset.color_accent_5 : "",
            fill_accent6: asset.color_accent_6 ? asset.color_accent_6 : "",
            fill_accent7: asset.color_accent_7 ? asset.color_accent_7 : "",
            fill_accent8: asset.color_accent_8 ? asset.color_accent_8 : "",
            fill_base: asset.color_base ? asset.color_base : "",
          },
          asset.asset_type.placement === "body" ? true : false
        )
        promiseArray.push(svgImageBack)
      }
      return frame
    })
    return varient
  })

  return await Promise.allSettled(promiseArray).then((results) => {
    var tempAsset = asset;

    //replace the svg urls with the converts svg strings
    if (tempAsset && tempAsset.asset) {
      tempAsset.asset.asset_varients.map((varient) => {
        varient.frames.map((frame) => {
          results.map((svg) => {
            if ( //front images
              svg.status &&
              svg.status === 'fulfilled' &&
              svg.value && svg.value.frame_id === frame.pk &&
              !svg.value.is_back
            ) {
              frame.image = svg.value.svg
              return frame.image
            } else if ( //back images
              svg.status &&
              svg.status === 'fulfilled' &&
              svg.value && svg.value.frame_id === frame.pk &&
              svg.value.is_back
            ) {
              frame.image_back = svg.value.svg
              return frame.image
            } else return frame.image
          })
          return frame
        })
        return varient
      })
    }
    //then return the same avatar asset structure
    return tempAsset;
  });
}