import React, { useState } from "react";
import Cropper, { MediaSize, Point } from "react-easy-crop";
import { useNavigate } from "react-router";
// import { Button } from "../../../components/Button";
// import { Input } from "../../../components/Input";
import { Button, FormLabel, TextField } from "@mui/material";
import MoneyInput from "../../../components/MoneyInput";
import Popup from "../../../components/Popup";
import {
  ErrorType,
  pixelCrop,
  ProductType,
} from "../../../utility/MarketplaceTypes";
import getCroppedImg from "../../../utility/utils/canvasUtils";
import { Checkbox } from "../../../components/Checkbox";

interface ProductFormProps {
  data?: ProductType; //ProductCreateData;
  customCancel?: () => void;
  submitFunction: (product: FormData) => void;
  errors?: ErrorType;
  showIndividually?: boolean;
}

export default function ProductForm({
  data,
  customCancel,
  submitFunction,
  errors = {},
  showIndividually = false,
}: ProductFormProps) {
  const navigator = useNavigate();
  const [thumbnailImage, setThumbnailImage] = useState<File | undefined>(
    undefined
  );
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState<
    string | undefined
  >(data?.image ? data.image : undefined);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [imageDetails, setImageDetails] = useState<pixelCrop>({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });
  const [thumbnailImageError, setThumbnailImageError] = useState<string>("");

  const [unformattedMoneyValue, setUnformattedMoneyValue] = useState<number>(0);
  const [soldIndividually, setSoldIndividually] = useState<boolean>(true);

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    if (thumbnailImage && thumbnailImage?.size > 5242880) {
      setThumbnailImageError("Image size must be less than 5MB");
      Popup("Please fix thumbnail image errors", "error");
    } else if (unformattedMoneyValue < 75) {
      Popup("Please fix value error", "error");
    } else if (!thumbnailImage && !thumbnailImageUrl) {
      Popup("Please fix thumbnail image errors", "error");
    } else {
      // Popup("Creating Product", "info");
      const formData = new FormData(e.target as HTMLFormElement);

      // Handle thumbnail image before submitting
      const data = await getCroppedImg(thumbnailImageUrl, imageDetails);
      const blob = await fetch(data).then((r) => r.blob());
      formData.set("image", blob, thumbnailImage?.name);

      // Handle money input conversion
      formData.set("price", unformattedMoneyValue.toString());

      //add sold individually
      formData.set("sold_individually", JSON.stringify(soldIndividually));

      submitFunction(formData);
    }
  }

  const handleThumbnailImageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      if (
        e.target.files &&
        !["image/png", "image/jpeg", "image/svg"].includes(
          e.target.files[0].type
        )
      ) {
        setThumbnailImageError("Image must be PNG, JPG, or SVG");
      } else {
        setThumbnailImage(e.target.files[0]);
        setThumbnailImageUrl(URL.createObjectURL(e.target.files[0]));
      }
    } else {
      setThumbnailImage(undefined);
      setThumbnailImageUrl(undefined);
    }
  };

  const handleCropChange = (location: Point) => {
    setCrop(location);
  };

  const handleZoomChange = (zoom: number) => {
    setZoom(zoom);
  };

  const handleCropComplete = (
    croppedArea: pixelCrop,
    croppedAreaPixels: pixelCrop
  ) => {
    setImageDetails(croppedAreaPixels);
  };

  const handleMediaLoaded = (mediaSize: MediaSize) => {
    setCrop((prev) => ({ ...prev, x: mediaSize.width, y: mediaSize.height }));
    setImageDetails((prev) => ({
      ...prev,
      width: mediaSize.width,
      height: mediaSize.width,
    }));
  };

  return (
    <form className="add-npc__form" onSubmit={handleSubmit}>
      <div className="add-npc__field-container add-npc__field-container--custom">
        <TextField
          type="text"
          name="product_name"
          label="Product Title"
          className="add-npc__product-title"
          required
          error={Object.keys(errors).includes("product_name")}
          helperText={
            Object.keys(errors).includes("product_name")
              ? errors["product_name"][0]
              : ""
          }
          defaultValue={data?.product_name}
        />
        <MoneyInput
          name="price"
          label="Price"
          widthSize="responsive"
          required
          onValueChange={(values: any, sourceInfo: any) => {
            let unformattedValue = values.value
              .split(".")[0]
              .concat(values.value.split(".")[1]);
            setUnformattedMoneyValue(unformattedValue);
          }}
          hasError={
            Object.keys(errors).includes("price") ||
            (unformattedMoneyValue < 75 && unformattedMoneyValue !== 0)
          }
          errorMessage={
            Object.keys(errors).includes("price")
              ? errors["price"][0]
              : "Minimum 75 cents"
          }
          defaultValue={
            data?.price
              ? data.price
                  .toString()
                  .slice(0, data.price.toString().length - 2) +
                "." +
                data.price.toString().slice(data.price.toString().length - 2)
              : 0
          }
        />
      </div>
      {showIndividually ? (
        <div style={{ marginLeft: ".8rem", marginBottom: ".4rem" }}>
          <Checkbox
            checked={soldIndividually}
            onClick={() => setSoldIndividually((prev) => !prev)}
          >
            Sold Individually
          </Checkbox>
          <p>
            Check this if you want this product to be sold as part of a pack AND
            individually.
          </p>
        </div>
      ) : (
        ""
      )}

      <TextField
        name="description"
        label="Description"
        className="add-npc__description"
        error={Object.keys(errors).includes("description")}
        helperText={
          Object.keys(errors).includes("description")
            ? errors["description"][0]
            : ""
        }
        fullWidth
        defaultValue={data?.description}
        required
      />

      <div className="add-npc__cropper-wrapper">
        <div className="add-npc__thumbnail-image">
          <FormLabel>Thumbnail Image</FormLabel>
          <TextField
            id="thumbnailImage"
            type="file"
            name="thumbnail_image"
            error={thumbnailImageError !== ""}
            helperText={thumbnailImageError}
            onChange={handleThumbnailImageChange}
          />
        </div>
        {thumbnailImageUrl && (
          <div className="add-npc__cropper-container">
            <div className="add-npc__cropper">
              <Cropper
                image={thumbnailImageUrl}
                crop={crop}
                zoom={zoom}
                onCropChange={handleCropChange}
                onZoomChange={handleZoomChange}
                onCropComplete={handleCropComplete}
                aspect={1 / 1}
                zoomSpeed={0.1}
                onMediaLoaded={handleMediaLoaded}
              />
            </div>
          </div>
        )}
      </div>

      <div className="add-npc__buttons">
        <Button
          onClick={() => {
            if (customCancel) {
              customCancel();
            } else {
              navigator(-1);
            }
          }}
          variant="contained"
          color="secondary"
        >
          Back
        </Button>
        <Button onClick={() => {}} type="submit" variant="contained">
          Submit
        </Button>
      </div>
    </form>
  );
}
