import { useEffect } from "react";
import useAvatarDisplay from "../hooks/useAvatarDisplay";

export default function EmoteBar() {
  const emotes = useAvatarDisplay((state) => state.emotes);
  const emote = useAvatarDisplay((state) => state.emote);
  const setEmote = useAvatarDisplay((state) => state.setEmote);
  const hotkeyPressed = useAvatarDisplay((state) => state.hotkeyPressed);
  const setHotkeyPressed = useAvatarDisplay((state) => state.setHotkeyPressed);
  const showEmotes = useAvatarDisplay((state) => state.showEmotes);

  useEffect(() => {
    if (emote) {
      setEmote(emote);
    } else {
      setEmote(null);
    }

    let timeout = setTimeout(() => {
      setEmote(null);
    }, emote?.duration);

    return () => {
      setEmote(null);
      clearTimeout(timeout);
    };
  }, [emote, setEmote]);

  useEffect(() => {
    const matchedEmote = emotes.find((x) => x.hotkey === hotkeyPressed);

    if (matchedEmote) {
      setEmote(matchedEmote);
      // setHotkeyPressed(null);
    }
  }, [hotkeyPressed, emotes, setEmote, setHotkeyPressed]);

  return (
    <div className="emote-emotes">
      {showEmotes &&
        emotes.map((mappedEmote, i) => (
          <div
            className="emote-emotes__emote"
            key={mappedEmote.pk}
            onClick={() => setEmote(mappedEmote)}
          >
            <img src={mappedEmote.thumbnail_image} alt="emote" />
            <span className="emote-emotes__hotkey">{mappedEmote.hotkey}</span>
          </div>
        ))}
    </div>
  );
}
