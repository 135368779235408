import { NavigateFunction } from "react-router";
import { 
  createAssetVarientFrame, 
  createAttachmentPoint, 
  createAvatarAsset, 
  createAvatarAssetVarient, 
  createAvatarObject, 
  updateAvatarAsset, 
  updateAvatarOrgField
} from "../../utility/Endpoints";
import Post from "../../utility/Post";
import { Avatar, v3UserType } from "../../utility/Types";

/**
 * This function goes through the avatar object and creates the needed objects in the backend
 * Avatar type and asset types are already pre-set
 * We use the avatar type with pk 12
 * This goes through creating 
 *  1) avatar object
 *  2) assets
 *  3) avatar asset (connecting the created assets to the avatar)
 *  4) asset varients
 *  5) asset varient frames
 *  6) attachment points
 */
export async function CreateNewAvatarBackend(
  avatar: Avatar, //avatar that needs to be created
  user: v3UserType, //user
  navigator: NavigateFunction, //navigator for errors
  avatarCreatedRedirect: (avatarPk: number) => void, //function to do after everything created
  isOrgAvatar?: boolean, //if the user is an artist or manager, they can update organizition avatar status
  ) {
  const FormData = require("form-data");
  
  /**
   * Avatar:
   * user pk (local)
   * avatar type pk 
   * name
   * thumbnail image
   * is_public (artist or manager)
   * is_streaming_avatar (artist or manager)
   */
  const formDataAvatar = new FormData();
  formDataAvatar.append("name", avatar.name);
  formDataAvatar.append("avatar_type", avatar.avatar_type ? avatar.avatar_type.pk : "12");
  formDataAvatar.append("image", avatar.image);
  formDataAvatar.append("is_public", avatar.is_public);
  formDataAvatar.append("is_streaming_avatar", avatar.is_streaming_avatar);
  Post(createAvatarObject(user.pk), formDataAvatar).then(val1 => {
    if(val1.status && val1.status < 300) {
      // val1 = avatar object
      if(val1.data && val1.data.data) {

        //handle updating org avatar
        if(isOrgAvatar !== undefined && isOrgAvatar !== null) {
          const formDataOrgStatus = new FormData();
          formDataOrgStatus.append("is_organization_avatar", isOrgAvatar)
          Post(updateAvatarOrgField(val1.data.data.pk), formDataOrgStatus).then(res => {
            if (res.status && res.status < 300) {
              //org status set
            } else {
              if (res.status === 401) {
                navigator("/login");
              }
            }
          })
        }

        //for each asset type
        /**
         * Asset:
         * asset type (same asset type that is hard coded)
         * name (make a name so users don't need to -> avatar name + asset type name)
         * width
         * color_base
         */
        avatar.avatar_assets.forEach((asset) => {
          const formDataAssets = new FormData();
          formDataAssets.append("name", asset.asset.name === "" ? val1.data.data.name + asset.asset_type.name : asset.asset.name);
          formDataAssets.append("width", asset.asset.width);
          // Handle color_accent_count and asset_set later. Use default for now
          formDataAssets.append("color_accent_count", 0);
          formDataAssets.append("asset_set", 1);
          formDataAssets.append("origin", "vstreamer");
          Post(createAvatarAsset(asset.asset_type.pk), formDataAssets).then(val2 => {
            if(val2.status && val2.status < 300) {
              if(val2.data && val2.data.data) {
                // val2 = asset object(s)

                //Part 1: connect assets to avatar -> aka avatar assets
                /**
                 * Update avatar assets
                 * connect assets to avatar assets
                 */
                const formDataAvatarAsset = new FormData();
                formDataAvatarAsset.append("avatar", val1.data.data.pk);
                formDataAvatarAsset.append("asset", val2.data.data.pk);
                formDataAvatarAsset.append("asset_type", asset.asset_type.pk);
                //handle color_base later. Use default for now
                formDataAvatarAsset.append("color_base", "ffffff");
                //connect the right asset to the avatar asset type
                let type_index = val1.data.data.avatar_assets.findIndex(
                  (x: any) => x.asset_type.name ===asset.asset_type.name
                );
                Post(updateAvatarAsset(val1.data.data.avatar_assets[type_index].pk), formDataAvatarAsset).then(val4 => {
                  if(val4.status && val4.status < 300) {
                    if(val4.data && val4.data.data) {
                      // val4 = avatar asset object(s)
                      //do nothing since all successful
                    }
                  } else {
                    if (val4.status === 401) {
                      // navigator("/login");
                    } //Probably handle errors
                  }
                })

                // Part 2: Create asset varients
                // for each varient
                /**
                 * Asset varient:
                 * asset (from above)
                 * animation interval
                 * avatar state
                 */
                asset.asset.asset_varients.forEach(varient => {
                  const formDataVarient = new FormData();
                  varient.avatar_state_list?.forEach((state) => {
                    formDataVarient.append( "avatar_states", state.pk);
                  });
                  formDataVarient.append("animation_interval", varient.animation_interval);
                  if (varient.animation_start_interval_max) {
                    formDataVarient.append("animation_start_interval_max", varient.animation_start_interval_max);
                  }
                  if (varient.animation_start_interval_min) {
                    formDataVarient.append("animation_start_interval_min", varient.animation_start_interval_min);
                  }

                  Post(createAvatarAssetVarient(val2.data.data.pk), formDataVarient).then(val3 => {
                    if(val3.status && val3.status < 300) {
                      if(val3.data && val3.data.data) {
                        // val3 = asset varient object(s)

                        //For each frame
                        /**
                         * Asset varient frames:
                         * image
                         * priority
                         * asset varient (from above)
                         */
                        varient.frames.forEach((frame, frameKey) => {
                          const formDataVarientFrame = new FormData();
                          formDataVarientFrame.append("image", frame.image)
                          if(frame.image_back !== undefined) {
                            formDataVarientFrame.append("image_back", frame.image_back)
                          }
                          formDataVarientFrame.append("priority", frameKey.toString()); //use the order that they uploaded
                          //Note: this will return an error for null images for pre-made frames and it is ok
                          Post(createAssetVarientFrame(val3.data.data.pk), formDataVarientFrame).then(val5 => {
                            if(val5.status && val5.status < 300) {
                              if(val5.data && val5.data.data) {
                                //val5 = asset varient frame object(s)

                                //for each attachment point
                                /**
                                 * Attachment points:
                                 * asset varient frame (from above)
                                 * name (avatar name + attachment point name)
                                 * x position
                                 * y position
                                 * z index
                                 * z index back
                                 */
                                frame.attachment_points.forEach(async point => {
                                  const formDataAttachmentPoint = new FormData();
                                  formDataAttachmentPoint.append("name", avatar.name + point.name);
                                  formDataAttachmentPoint.append("x_position", point.x_position);
                                  formDataAttachmentPoint.append("y_position", point.y_position);
                                  formDataAttachmentPoint.append("z_index", point.z_index);
                                  formDataAttachmentPoint.append(
                                    "z_index_back", point.z_index_back ? point.z_index_back : 0
                                  );
                                    //Note: point.asset_type should never be null in this case, but if it is, attachment point attaches to itself (which is wrong)
                                  formDataAttachmentPoint.append( //asset type this attaches to
                                    "asset_type",
                                    point.asset_type ? point.asset_type.pk : val1.data.data.avatar_assets[type_index].pk
                                  );

                                  var promiseArray: Array<any> = [];
                                  promiseArray.push(Post(createAttachmentPoint(val5.data.data.pk), formDataAttachmentPoint).then(val6 => {
                                    if(val6.status && val6.status < 300) {
                                      if(val6.data && val6.data.data) {
                                        // val6 = attachment point object(s)
                                        // Avatar created!
                                      }
                                    } else {
                                      if (val6.status === 401) {
                                        navigator("/login");
                                      }
                                    }
                                  }))
                                  await Promise.allSettled(promiseArray).then(() => {
                                    //return and redirect to avatar seller page
                                    // with avatar pk
                                    avatarCreatedRedirect(val1.data.data.pk)
                                  })
                                })
                              }
                            } else {
                              if (val5.status === 401) {
                                navigator("/login");
                              }
                            }
                          })
                        })
                      }
                    } else {
                      if (val3.status === 401) {
                        navigator("/login");
                      }
                    }
                  })
                })
              }
            } else {
              if (val2.status === 401) {
                navigator("/login");
              }
            }
          })
        })
      }
    } else {
      if (val1.status === 401) {
        navigator("/login");
      }
    }
  })
}
