import { useState } from "react";
import Topbar from "../../../components/Topbar";

import AddEmote from "./AddEmote";
import EmoteList from "./EmoteList";

/**
 * Contains all Emote related components
 * @returns
 */
export default function EmoteManagement(): JSX.Element {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [infoMessage, setInfoMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  function handleSearch(search: string) {
    setSearch(search)
  }

  return (
    <div className="emote-management">
      <Topbar
        title="Emote List"
        buttonTitle="Add Emote"
        handleButton={handleOpen}
        searchbar
        handleSearch={handleSearch}
      />
      <EmoteList
        search={search}
        infoMessage={infoMessage}
        errorMessage={errorMessage}
      />
      <AddEmote
        isOpen={isOpen}
        handleClose={handleClose}
        infoMessage={(message) => setInfoMessage(message)}
        errorMessage={(message) => setErrorMessage(message)}
      />
    </div>
  );
}
