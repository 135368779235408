import { Icon } from "../../../components/Icon";

export default function Support(): JSX.Element {
  return (
    <div className="support">
      <div className="support__container">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Icon name="note" size="lg" />
          &nbsp;&nbsp;&nbsp;
          <h2>Support</h2>
        </div>
        &nbsp;&nbsp;&nbsp;
        <h3>We've got your back</h3>
        &nbsp;&nbsp;&nbsp;
        <p>We are committed to helping our sellers share their creative works with the community.</p>
        &nbsp;&nbsp;&nbsp;
        <div className="support__list">
          <div className="support__card">
            <h5>Integrated Product Editors</h5>
            &nbsp;&nbsp;&nbsp;
            <p>Every product created will go through a review process by our team so that you achieve the best products possible.</p>
          </div>

          &nbsp;&nbsp;&nbsp;
          <div className="support__card">
            <h5>Support Staff Waiting</h5>
            &nbsp;&nbsp;&nbsp;
            <p>Our support staff are ready and available for any questions you may have. We are just an email away.</p>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div className="support__card">
            <h5>User Feedback Welcome</h5>
            &nbsp;&nbsp;&nbsp;
            <p>Our team is dedicated to providing the best platform for our sellers and customers.</p>
          </div>

        </div>


      </div>
    </div>
  );
}
