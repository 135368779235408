import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import Topbar from "../../../components/Topbar";
import { createAvatarSpecificEmote, deleteAvatarSpecificEmote, deleteEmote, getEmoteAvatarSpecificList, getOrgAvatarList, updateEmote } from "../../../utility/Endpoints";
import Get from "../../../utility/Get";
import Post from "../../../utility/Post";
import { Asset } from "../../../utility/Types";
import getFullAssetList from "./helpers/getFullAssetList";
import UploadIcon from "@mui/icons-material/Upload";
import { V3Organization } from "../../../utility/context/V3Organization";

/**
 * Update a specific Emote on the database
 * @returns
 */

const style = {
  backgroundColor: "white",
  padding: "1.5rem",
  borderRadius: "8px",
  width: "15rem",
};


export default function EditEmote(): JSX.Element {
  let navigator = useNavigate();
  const location = useLocation();
  const organization = useContext(V3Organization)
  const [emote, setEmote] = useState({
    name: "",
    thumbnail_image: "",
    image: "",
    image_back: "",
    asset: 0,
    specific_avatar: {
      avatar: 0,
      pk: 0,
      changed: false
    },
    duration: 0,
    z_index: 0,
    z_index_back: 0,
    is_public: false,
    pk: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    thumbnail_image: "",
    image: "",
    duration: "",
    z_index: "",
  });
  const [infoMessage, setInfoMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [assetList, setAssetList] = useState<{
    data: Array<{
      assetSetName: string;
      assetList: Array<Asset>;
    }>;
    error: string;
  }>();
  const [avatars, setAvatars] = useState<
    {
      avatar_type: object;
      name: string;
      image: string;
      pk: number;
    }[]
  >([]);

  useEffect(() => {
    getEmote();
    // eslint-disable-next-line
  }, [location]);

  /**
   * Populates assetList with all Asset Types for all Avatar Types for an Organization
   */
  useEffect(() => {
    async function populateAssetList() {
      if (organization.pk) {
        setAssetList(await getFullAssetList(organization.pk));
        getAvatars(1)
      }
    }

    populateAssetList();

    return () => {
      setAssetList({ data: [], error: "" });
    };
    // eslint-disable-next-line
  }, [organization]);

  function getAvatars(page: number) {
    Get(getOrgAvatarList(organization.pk, page)).then((val) => {
      if (val.status && val.status < 300) {
        setAvatars((prev) => [...prev, ...val.data.data]);
        //handle pages
        if (val.data.page_count > page) {
          getAvatars(page + 1)
        } else {
          setIsLoading(false)
        }
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    });
  }

  function getEmote() {
    setIsLoading(true);
    let emote_pk = location.pathname.split("/");
    Get(updateEmote(emote_pk[2])).then((val) => {
      if (val.status && val.status < 300) {
        if (val.data && val.data.form && val.data.form.data) {
          setEmote(val.data.form.data);
          checkAvatarSpecificEmotes(emote_pk[2])
        }
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
      setIsLoading(false);
    });
  }

  function checkAvatarSpecificEmotes(emote_pk: string | number) {
    Get(getEmoteAvatarSpecificList(emote_pk)).then(val => {
      if (val.status && val.status < 300) {
        if (val.data && val.data.data && val.data.data[0]) {
          var emote_objs = val.data.data[0].avatar_specific_emote_objects as Array<any>
          var specific_emote_avatar_index = emote_objs.findIndex((e: any) => {
            return emote_pk === e.emote_id.toString()
          })
          setEmote(prev => {
            return ({
              ...prev,
              specific_avatar: {
                avatar: val.data.data[0].pk,
                pk: val.data.data[0].avatar_specific_emote_objects[specific_emote_avatar_index].pk,
                changed: false,
              }
            })
          })
        } else {
          setEmote(prev => {
            return ({
              ...prev, specific_avatar: {
                avatar: 0,
                pk: 0,
                changed: false,
              }
            })
          })
        }
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    })
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmote({ ...emote, [e.target.name]: e.target.value });
  }

  function handleCheck(e: React.ChangeEvent<HTMLInputElement>) {
    setEmote({ ...emote, [e.target.name]: e.target.checked })
  }

  function handleCloseDeleteModal() {
    setDeleteModal(false);
  }

  function handleSave() {
    setInfoMessage("")
    setErrorMessage("")
    var error = false;
    const FormData = require("form-data");
    const formData = new FormData();
    let thumbnailFile = document.getElementById(
      "thumbnail_image"
    ) as HTMLInputElement;
    let imageFile = document.getElementById("image_file") as HTMLInputElement;
    let imageBackFile = document.getElementById("image_back_file") as HTMLInputElement;

    if (emote.name === "" || emote.name.length === 0) {
      setErrors({ ...errors, name: "Name cannot be empty" });
      error = true;
    }
    if (emote.z_index === null) {
      setErrors({ ...errors, z_index: "Z Index can not be empty" });
    }

    let emote_pk = location.pathname.split("/");

    if (!error) {
      setIsLoading(true);
      setErrors({
        name: "",
        thumbnail_image: "",
        image: "",
        duration: "",
        z_index: "",
      });
      formData.append("name", emote.name);
      if (
        thumbnailFile === undefined ||
        thumbnailFile.files === undefined ||
        thumbnailFile.files?.length === 0
      ) {
        formData.append("thumbnail_image", emote.thumbnail_image);
      } else {
        formData.append(
          "thumbnail_image",
          thumbnailFile && thumbnailFile.files ? thumbnailFile.files[0] : ""
        );
      }
      if (
        imageFile === undefined ||
        imageFile.files === undefined ||
        imageFile.files?.length === 0
      ) {
        formData.append("image", emote.image);
      } else {
        formData.append(
          "image",
          imageFile && imageFile.files ? imageFile.files[0] : ""
        );
      }
      if (
        imageBackFile === undefined ||
        imageBackFile.files === undefined ||
        imageBackFile.files?.length === 0
      ) {
        formData.append("image_back", emote.image_back ? emote.image_back : "");
      } else {
        formData.append(
          "image_back",
          imageBackFile && imageBackFile.files ? imageBackFile.files[0] : ""
        );
      }
      if (emote.asset) {
        formData.append("asset", emote.asset);
      }
      formData.append("duration", emote.duration);
      formData.append("z_index", emote.z_index);
      formData.append("z_index_back", emote.z_index_back);
      formData.append("is_public", emote.is_public);

      Post(updateEmote(emote_pk[2]), formData).then((val) => {
        if (val.status && val.status < 300) {
          setInfoMessage("Emote Updated");
          if (val.data && val.data.data) {
            var updated_emote = val.data.data
            if (val.data.data.asset) {
              updated_emote = { ...val.data.data, asset: val.data.data.asset.pk }
            }
            //update avatar specific emote
            /**
             * case 1: no specific avatar selected before and not updated (nothing)
             * case 2: no specific avatar selected before and is updated to avatar (create)
             * case 3: no specific avatar selected before and updated to "" (nothing)
             * case 4: specific avatar selected before and not updated (nothing)
             * case 5: specific avatar selected before and updated to new avatar (delete and create)
             * case 6: specific avatar selected before and updated to "" (delete)
             */
            //specific avatar selected before and updated to new avatar (delete and create)
            if (emote.specific_avatar.changed && emote.specific_avatar.pk && emote.specific_avatar.avatar !== -1) {
              //if the specific avatar has changed, 
              //delete the old one and create a new one
              const formDatadelete = new FormData();
              Post(deleteAvatarSpecificEmote(emote.specific_avatar.pk), formDatadelete).then(val1 => {
                if (val1.status && val1.status < 300) {
                  const formDataCreate = new FormData();
                  formDataCreate.append("emote", emote_pk[2]) //emote pk
                  Post(createAvatarSpecificEmote(emote.specific_avatar.avatar), formDataCreate).then(val2 => {
                    if (val2.status && val2.status < 300) {
                      //new avatar specific emote created
                      var emote_objs = val2.data.data.avatar.avatar_specific_emote_objects as Array<any>
                      var specific_emote_avatar_index = emote_objs.findIndex((e: any) => {
                        return emote_pk[2] === e.emote_id.toString()
                      })
                      //update updated_emote
                      updated_emote = {
                        ...updated_emote,
                        specific_avatar: {
                          avatar: val2.data.data.avatar.pk,
                          pk: val2.data.data.avatar.avatar_specific_emote_objects[specific_emote_avatar_index].pk,
                          changed: false,
                        }
                      }
                      setEmote(updated_emote)
                    } else {
                      if (val2.status === 401) {
                        navigator("/login");
                      }
                    }
                  })
                } else {
                  if (val1.status === 401) {
                    navigator("/login");
                  }
                }
              })
            }
            //no specific avatar selected before and is updated to avatar (create)
            else if (emote.specific_avatar.changed && !emote.specific_avatar.pk && emote.specific_avatar.avatar !== -1) {
              const formDataCreate = new FormData();
              formDataCreate.append("emote", emote_pk[2]) //emote pk
              Post(createAvatarSpecificEmote(emote.specific_avatar.avatar), formDataCreate).then(val2 => {
                if (val2.status && val2.status < 300) {
                  //new avatar specific emote created
                  var emote_objs = val2.data.data.avatar.avatar_specific_emote_objects as Array<any>
                  var specific_emote_avatar_index = emote_objs.findIndex((e: any) => {
                    return emote_pk[2] === e.emote_id.toString()
                  })
                  //update updated_emote
                  updated_emote = {
                    ...updated_emote,
                    specific_avatar: {
                      avatar: val2.data.data.avatar.pk,
                      pk: val2.data.data.avatar.avatar_specific_emote_objects[specific_emote_avatar_index].pk,
                      changed: false,
                    }
                  }
                  setEmote(updated_emote)
                } else {
                  if (val2.status === 401) {
                    navigator("/login");
                  }
                }
              })
            }
            //specific avatar selected before and updated to -1 (delete)
            else if (emote.specific_avatar.changed && emote.specific_avatar.pk && emote.specific_avatar.avatar === -1) {
              const formDatadelete = new FormData();
              Post(deleteAvatarSpecificEmote(emote.specific_avatar.pk), formDatadelete).then(val1 => {
                if (val1.status && val1.status < 300) {
                  //update updated_emote
                  updated_emote = {
                    ...updated_emote,
                    specific_avatar: {
                      avatar: 0,
                      pk: 0,
                      changed: false
                    }
                  }
                  setEmote(updated_emote)
                } else {
                  if (val1.status === 401) {
                    navigator("/login");
                  }
                }
              })
            }
            else {
              //else keep the data the same but change changed to false
              updated_emote = {
                ...updated_emote,
                specific_avatar: {
                  avatar: emote.specific_avatar.avatar,
                  pk: emote.specific_avatar.pk,
                  changed: false
                }
              }
              setEmote(updated_emote)
            }
          }
        } else {
          if (val.status === 401) {
            navigator("/login");
          }
          //handle errors
          var temp = Object.keys(val.data.form.errors)[0];
          setErrorMessage(val.data.form.errors[temp]);
        }
        setIsLoading(false);
      });
    }
  }

  function handleDelete() {
    setIsLoading(true);
    const FormData = require("form-data");
    const formData1 = new FormData();
    let emote_pk = location.pathname.split("/");
    Post(deleteEmote(emote_pk[2]), formData1).then((val) => {
      if (val.status && val.status < 300) {
        setInfoMessage("Emote deleted");
        setEmote({
          name: "",
          thumbnail_image: "",
          image: "",
          image_back: "",
          asset: 0,
          specific_avatar: {
            avatar: 0,
            pk: 0,
            changed: false,
          },
          duration: 0,
          z_index: 0,
          z_index_back: 0,
          is_public: false,
          pk: 0,
        });
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
        //handle errors
        var temp = Object.keys(val.data.form.errors)[0];
        setErrorMessage(val.data.form.errors[temp]);
      }
      setIsLoading(false);
    });
  }

  return !isLoading ? (
    <div className="edit-emote">
      {infoMessage !== "" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {infoMessage}
        </Alert>
      )}
      {errorMessage !== "" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}
      {emote.name === "" ? (
        <div>
          This emote does not exist.
          <Button onClick={() => navigator("/emotemanagement")}>
            Return to Emote List
          </Button>
        </div>
      ) : (
        <div>
          <Modal open={deleteModal} onClose={handleCloseDeleteModal}>
            <Box style={style}>
              <form
                onSubmit={handleDelete}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ fontSize: "2rem", textAlign: "center" }}>
                  Delete Emote?
                </div>
                <Button
                  variant="contained"
                  sx={{ my: 1 }}
                  type="submit"
                  onClick={handleDelete}
                  color="error"
                >
                  <Typography color="white">Delete Emote</Typography>
                </Button>

                <Button
                  onClick={handleCloseDeleteModal}
                  variant="contained"
                  sx={{ mx: 2 }}
                >
                  <Typography color="white">Cancel</Typography>
                </Button>
              </form>
            </Box>
          </Modal>
          <Topbar
            title="Edit Emote"
            buttonTitle="Save Emote"
            handleButton={handleSave}
            secondaryButtonTitle="Delete"
            handleSecondaryButton={() => setDeleteModal(true)}
            searchbar={false}
          />
          <Grid container spacing={2} marginTop=".5rem">
            <Grid item container xs={6}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  required
                  value={emote.name}
                  name="name"
                  onChange={handleChange}
                  error={errors.name !== ""}
                  helperText={errors.name}
                  disabled={isLoading}
                />
              </Grid>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="is_public"
                      checked={emote.is_public}
                      onChange={handleCheck}
                      inputProps={{ 'aria-label': 'controlled' }}
                      disabled={isLoading}
                    />
                  }
                  label="Public"
                />
              </FormGroup>
              <Grid item xs={12}>
                <InputLabel>Thumbnail</InputLabel>
                <Button variant="outlined" component="label">
                  <UploadIcon />
                  <input
                    className="hide-file-btn"
                    required
                    id="thumbnail_image"
                    name="thumbnail_image"
                    type="file"
                    disabled={isLoading}
                  />
                </Button>
                {errors.thumbnail_image !== "" && (
                  <div style={{ color: "#dd8782" }}>
                    {errors.thumbnail_image !== ""
                      ? errors.thumbnail_image
                      : "Only GIF images"}
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Emote</InputLabel>
                <Button variant="outlined" component="label">
                  <UploadIcon />
                  <input
                    className="hide-file-btn"
                    required
                    id="image_file"
                    name="image"
                    type="file"
                    disabled={isLoading}
                  />
                </Button>
                {errors.image !== "" && (
                  <div style={{ color: "#dd8782" }}>
                    {errors.image !== "" ? errors.image : "Only GIF images"}
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Emote Back</InputLabel>
                <Button variant="outlined" component="label">
                  <UploadIcon />
                  <input
                    className="hide-file-btn"
                    required
                    id="image_back_file"
                    name="image_back"
                    type="file"
                    disabled={isLoading}
                  />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="assetSelect">Asset</InputLabel>
                  <Select
                    labelId="assetSelect"
                    label="Asset Select"
                    value={emote.asset}
                    onChange={(e) => {
                      setEmote((prev) => ({
                        ...prev,
                        asset: e.target.value as number,
                      }));
                    }}
                  >
                    <MenuItem value="">&nbsp;</MenuItem>
                    {assetList &&
                      assetList.data.map((item) => [
                        <ListSubheader>{item.assetSetName}</ListSubheader>,
                        item.assetList.map((assetItem) => (
                          <MenuItem value={assetItem.pk} key={assetItem.pk}>
                            {assetItem.name}
                          </MenuItem>
                        )),
                      ])}
                  </Select>
                  {assetList && assetList.error !== "" && (
                    <Alert style={{ marginBottom: ".8rem" }} severity="error">
                      {assetList.error}
                    </Alert>
                  )}
                </FormControl>
                <FormControl fullWidth style={{ marginTop: ".8rem" }}>
                  <InputLabel id="assetSelect">Specific Avatar</InputLabel>
                  <Select
                    labelId="assetSelect"
                    label="Asset Select"
                    value={emote.specific_avatar && emote.specific_avatar.avatar > 0 ? emote.specific_avatar.avatar : 0}
                    name={"specific_avatar"}
                    onChange={(e) => {
                      setEmote((prev) => ({
                        ...prev,
                        specific_avatar: {
                          avatar: e.target.value as number,
                          pk: prev.specific_avatar.pk,
                          changed: true //setting as true means that it has changed
                        },
                      }));
                    }}
                  >
                    <MenuItem value={-1}>&nbsp;</MenuItem>
                    {avatars &&
                      avatars.map((item) => [
                        <MenuItem value={item.pk} key={item.pk}>
                          {item.name}
                        </MenuItem>
                      ])}
                  </Select>
                </FormControl>
                {/* note: if a use sets this ^, the emote will only show up for the selected avatar  */}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Duration"
                  required
                  value={emote.duration}
                  name="duration"
                  type="number"
                  onChange={handleChange}
                  error={errors.duration !== ""}
                  helperText={
                    errors.duration !== ""
                      ? errors.duration
                      : "Emote duration expressed in miliseconds."
                  }
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Z Index"
                  required
                  value={emote.z_index}
                  name="z_index"
                  type="number"
                  onChange={handleChange}
                  error={errors.z_index !== ""}
                  helperText={errors.z_index}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Z Back Index"
                  required
                  value={emote.z_index_back}
                  name="z_index_back"
                  type="number"
                  onChange={handleChange}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
            <Grid item container xs={6}>
              <div className="preview">
                <InputLabel>Emote Thumbnail</InputLabel>
                <Box
                  component={"img"}
                  sx={{
                    width: 350,
                    maxWidth: { xs: 350, md: 250 },
                  }}
                  alt={`${emote.name} emote thumbnail`}
                  src={emote.thumbnail_image}
                />
                <InputLabel>Emote</InputLabel>
                <div style={{ position: "relative", height: "350px" }}>
                  <Box
                    component="img"
                    sx={{
                      height: 350,
                      maxHeight: { xs: 350, md: 250 },
                      position: "absolute",
                      zIndex: 2,
                    }}
                    alt={`${emote.name} emote`}
                    src={emote.image}
                  />
                  {emote.image_back && (
                    <Box
                      component="img"
                      sx={{
                        height: 350,
                        maxHeight: { xs: 350, md: 250 },
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 1,
                      }}
                      alt={`${emote.name} emote back`}
                      src={emote.image_back}
                    />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  ) : (
    <div>Loading...</div>
  );
}
