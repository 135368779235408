import clsx from "clsx";

type ColorOptions = "primary" | "secondary";

const ColorSelector = {
  primary: "#5e2d67",
  secondary: "#292929",
};

interface LoaderProps {
  variant?: "block" | "inline";
  size?: number;
  color?: ColorOptions;
}

export function Loader({
  variant = "inline",
  size = 1.25,
  color = "primary",
}: LoaderProps) {
  return (
    <div className={clsx("c-loader", `-${variant}`)}>
      <svg
        aria-hidden="true"
        focusable="false"
        height={`${size}em`}
        preserveAspectRatio="xMidYMid meet"
        role="img"
        viewBox={"0 0 50 50"}
        width={`${size}em`}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        enableBackground="new 0 0 50 50"
      >
        <path
          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
          fill={ColorSelector[color]}
        >
          <animateTransform
            attributeName="transform"
            attributeType="xml"
            dur="0.9s"
            from="0 25 25"
            repeatCount="indefinite"
            to="360 25 25"
            type="rotate"
          />
        </path>
      </svg>
    </div>
  );
}
