/**
 * This is the list of endpoints used in this app
 */

//Returns the default avatar type currently only using one avatar type for the builder
export function defaultAvatarType() {
  return 12;
}

//Token type pk is 169
export function tokenAssetPk() {
  return 169;
}

/***************************************************
 * AUTHENTICATION / ACCOUNT
 ***************************************************/

/**
 * GET call
 * @returns Organization object for preediction
 * - pk: number
 * - name: string
 */
export function getPreedictionOrganization() {
  return `/api/v3/organization/${process.env.REACT_APP_ORG}/detail`;
}

/**
 * GET
 * @returns default organization
 * - pk
 * - name
 */
export function getDefaultOrg() {
  return `/api/v3/organization/default/detail`;
}

/**
 * POST call that requires:
 * - username or email
 * - password
 * @param organization_pk number
 * @returns user data with sessionid
 * - sessionid
 * - organization
 * - is_organization_manager
 * - is_user_manager
 * - is_active
 * - username
 * - email
 */
export function v3Login(organization_pk = 0) {
  return `/api/v3/organization/${organization_pk}/user/auth/login`;
}

/**
 * POST call that requires:
 * - email {string}
 * - org pk
 * @returns
 * email
 */
export function resetPasswordRequest(organization_pk = 0) {
  return `/api/v3/organization/${organization_pk}/user/auth/email_verification/send`;
}
/**
 * POST call that requires:
 * - email {string}
 * - org pk
 * @returns
 * email
 */
export function uploadVideoOnS3(organization_pk = 0) {
  return `/api/v3/avatar/video/create`;
}
/**
 * POST call that requires:
 * - email {string}
 * - org pk
 * @returns
 * email
 */
export function sendVideoViaEmail(organization_pk = 0) {
  return `/api/v3/avatar/video/share/create`;
}

/**
 * POST call that requires:
 * - org pk
 * - email {string}
 * - verification_code {number}
 * - new_password {string}
 * - new_password_confirm {string}
 * @returns
 * user data with sessionid
 */
export function resetPasswordConfirm(organization_pk = 0) {
  return `/api/v3/organization/${organization_pk}/user/auth/email_verification/set_password`;
}

/**
 * POST Create organization user
 * Note: uses the manager's org to create a new user in that org
 * Note2: this will send an email immediately for the user to login
 * required:
 * - email
 * optional:
 * - password (don't pass)
 * @returns
 * user data without sessionid
 */
export function createUser(user_pk = 0) {
  // normal user create for later down the line
  // return `/api/v3/organization/${organization_pk}/user/create`;
  return `/api/v3/user/${user_pk}/user/create`;
}

/**
 * POST User registration form
 * required:
 * - email
 * - password
 * - username
 * - first_name
 * - last_name
 * - date_of_birth
 * @param organization number
 * @returns
 * user data without sessionid
 */
export function createRegister(organization = 0) {
  return `/api/v3/organization/${organization}/user/create`;
}

/**
 * POST call that requires:
 * - current_password {string}
 * - password {string} (new password)
 * - password_confirm {string} (confirm new password)
 * @param user_pk number
 * @returns user data with out sessionid (need to login again)
 */
export function updatePassword(user_pk = 0) {
  return `/api/v3/user/${user_pk}/update/password`;
}

/**
 * POST call that requires:
 * - username {string}
 * Note: this is the organization fields endpoint which means
 * that this could change on a dime
 * @param user_pk number
 * @returns user data with user data
 */
export function updateUsername(user_pk = 0) {
  return `/api/v3/user/${user_pk}/update/organization_fields`;
}

/**
 * GET/POST user basic info
 * required:
 * - user pk
 * optional:
 * - email
 * - username
 * - is_organization_manager
 * - is_user_manager
 * - is_active
 * @returns
 * - email
 * - username
 * - is_organization_manager
 * - is_user_manager
 * - is_active
 */
export function userBasics(user_pk = "") {
  return `/api/v3/user/${user_pk}/update/basics`;
}

/***************************************************
 * ORGANIZATION LISTS
 ***************************************************/

/**
 * GET list is users //NOTE: Currently not in use
 */
export function getUserListPublic(organization_pk = 0, page = 1) {
  return `/api/v3/organization/${organization_pk}/user/list/public?page=${page}`;
}

/**
 * GET list is users ONLY MANAGERS
 * requires:
 * - organization pk
 * optional:
 * - page {number} (default = 1)
 * - search {string} (default = "")
 * @returns array of user data with out sessionids
 */
export function getUserListPrivate(organization_pk = 0, page = 1, search = "") {
  return `/api/v3/organization/${organization_pk}/user/list/private?page=${page}&search=${search}`;
}

/**
 * GET organization's list of avatars
 *required:
 * - organization's pk {number}
 * @returns
 * - array of avatar data (avatar object)
 * - count of avatars
 */
export function getOrgAvatarList(organization_pk = 0, page = 1) {
  return `/api/v3/organization/${organization_pk}/avatar/list?page=${page}`;
}

/**
 * GET list of orgization's emotes
 * required:
 * - organization's pk {number}
 * @returns
 * - array of emote data (emote object)
 * - count of emotes
 */
export function getOrgEmoteList(organization_pk = 0) {
  return `/api/v3/organization/${organization_pk}/emote/list`;
}

/**
 * GET list of orgization's avatar backgrounds
 * required:
 * - organization's pk {number}
 * @returns
 * - array of backgrounds data (background object)
 * - count of backgrounds
 */
export function getOrgAvatarBackgrounds(organization_pk = 0) {
  return `/api/v3/organization/${organization_pk}/avatar_background/list`;
}

export function v3UpdateOrgFields(user_pk: string | number) {
  return `/api/v3/user/${user_pk}/update/organization_fields`;
}

// USER

/**
 * GET call that returns user's private data
 * @param {number} pk (user pk)
 * @returns
 * - background_image {url string}
 * - backstory {string}
 * - description {string}
 * - email {string}
 * - early_access {boolean}
 * - first_name {string}
 * - last_name {string}
 * - genre_system {string}
 * - headline {string}
 * - image {url string}
 * - location {string}
 * - pk {number}
 * - social_link {array of strings}
 * - status {string: "not_looking" | "looking_for_player" | "looking_for_group" | "looking_for_stream" | "publishing_content"}
 * - subscribed_to_mailing_list {boolean}
 * - username {string}
 * - visibility {string: "public" | "private"}
 * - subscription_id {number}
 * - proficiency_list {array of strings}
 * - creations {number}
 * - organizations: {array of name and pks}
 */
export function v3UserDetailPrivate(pk: number) {
  return `/api/v3/user/${pk}/detail/private`;
}

/**
 * POST update usser mailing list
 * @param {number} pk user pk
 * @returns
 */
export function userUpdateMailingList(pk: number) {
  return `/api/v3/user/${pk}/update/mailing_list`;
}

/**
 * Delete an account from database
 * @param pk user pk
 * @returns
 */
export function v3DeleteUser(pk: number) {
  return `/api/v3/user/${pk}/delete`;
}

/******************************************************
 * USER LISTS
 ******************************************************/

/**
 * GET lsit of user's emotes
 * @param user_pk number
 * @returns
 * - count
 * - list of emote objects
 */
export function getUserEmoteList(user_pk: number) {
  return `/api/v3/user/${user_pk}/emote/list`;
}

/**
 * GET lsit of user's avatars
 * List of avatars assigned to a user
 * @param user_pk number
 * @returns
 * - count
 * - list of avatar objects
 */
export function getUserAvatarList(user_pk: number, page: number = 1) {
  return `/api/v3/user/${user_pk}/avatar/list?page=${page}`;
}

/**
 * GET lsit of user's background
 * @param user_pk number
 * @returns
 * - count
 * - list of background objects
 */
export function getUserAvatarBackgroundList(user_pk: number) {
  return `/api/v3/user/${user_pk}/avatar_background/list`;
}

/**
 * Get user's assigned avatars with connection pk
 * @param user_pk number or string. User's pk
 * @returns
 * - array of avatar data (avatar object + avatar user connection pk + user object)
 * - count of avatars
 */
export function getUserAvatarUserList(user_pk: string | number) {
  return `/api/v3/user/${user_pk}/avatar_user/list`;
}

/**
 * GET user's emote list with connection pk
 * @param user_pk number or string. User's pk
 * @returns
 * - array of emote data (emote object + emote user connection pk + user object)
 * - count of emotes
 */
export function getUserEmoteUserList(user_pk: string | number) {
  return `/api/v3/user/${user_pk}/emote_user/list`;
}

/**
 * GET user's background list with connection pk
 * @param user_pk number or string. User's pk
 * @returns
 * - array of background data (background object + background user connection pk + user object)
 * - count of backgrounds
 */
export function getUserAvatarBackgroundUserList(user_pk: string | number) {
  return `/api/v3/user/${user_pk}/avatar_background_user/list`;
}

/*********************************************************
 * MANAGER FUNCTIONS
 * - create/delete connections between user and avatars/emotes/backgrounds
 ********************************************************/

/**
 * GET the list of users connected to an avatar
 * //NOTE: currently not in use
 */
export function getAvatarUserList(avatar_pk = 0) {
  return `/api/v3/avatar/${avatar_pk}/avatar_user/list`;
}

/**
 * POST create a connection between user and an avatar
 * @param avatar_pk number. avatar's pk
 * required:
 * - user {number} //user's pk
 * @returns
 * - avatar object
 * - avatar connection pk
 * - user object
 */
export function createAvatarUserConnection(avatar_pk = 0) {
  return `/api/v3/avatar/${avatar_pk}/avatar_user/create`;
}

/**
 * POST delete/remove a connection between user and an avatar
 * @param avatar_pk number. avatar's connection pk
 * @returns nothing if successful
 */
export function removeAvatarUserConnection(avatar_pk = 0) {
  return `/api/v3/avatar_user/${avatar_pk}/delete`;
}

/**
 * POST create a connection between user and an emote
 * must send in user: user_pk
 * @param emote_pk number. emote's pk
 * required:
 * - user {number} //user's pk
 * @returns
 * - emote object
 * - emote connection pk
 * - user object
 */
export function createEmoteUserConnection(emote_pk = 0) {
  return `/api/v3/emote/${emote_pk}/emote_user/create`;
}

/**
 * POST delete/remove a connection between user and an emote
 * @param emote_pk number. emote's connection pk
 * @returns nothing if successful
 */
export function removeEmoteUserConnection(emote_pk = 0) {
  return `/api/v3/emote_user/${emote_pk}/delete`;
}

/**
 * POST create a connection between user and an background
 * must send in user: user_pk
 * @param background_pk number. background's pk
 * @returns
 * - background object
 * - background connection pk
 * - user object
 */
export function createBackgroundUserConnection(background_pk = 0) {
  return `/api/v3/avatar_background/${background_pk}/avatar_background_user/create`;
}

/**
 * POST delete/remove a connection between user and an background
 * @param background_pk number. background's connection pk
 * @returns nothing if successful
 */
export function removeBackgroundUserConnection(background_pk = 0) {
  return `/api/v3/avatar_background_user/${background_pk}/delete`;
}

/*********************************************************
 * MANAGER FUNCTIONS
 * - create/update/delete emotes and backgrounds
 ********************************************************/

/**
 * POST create emote
 * required:
 * - name {string}
 * - thumbnail_image {file}
 * - is_public {boolean}
 * - image {file}
 * - duration {number}
 * - z_index {number}
 * optional:
 * - asset: {number} // the asset type this emote would replace
 * @param user_pk {number}
 * @returns emote object
 * - asset {asset object | null}
 * - duration {number}
 * - is_public {boolean}
 * - image {string}
 * - name {string}
 * - thumbnail_image {string}
 * - user_id {number}
 * - z_index {number}
 */
export function createEmote(user_pk = 0) {
  return `/api/v3/user/${user_pk}/emote/create`;
}

/**
 * GET/POST update emote
 * required:
 * - name {string}
 * - thumbnail_image {file}
 * - image {file}
 * - is_public {boolean}
 * - duration {number}
 * - z_index {number}
 * optional:
 * - asset: {number} // the asset type this emote would replace
 * @param emote_pk {number}
 * @returns emote object
 * - asset {asset object | null}
 * - duration {number}
 * - image {string}
 * - is_public {boolean}
 * - name {string}
 * - thumbnail_image {string}
 * - user_id {number}
 * - z_index {number}
 */
export function updateEmote(emote_pk: string | number) {
  return `/api/v3/emote/${emote_pk}/update`;
}

/**
 * DELETE/POST emote
 * @param emote_pk {number}
 * @returns nothing if successful
 */
export function deleteEmote(emote_pk: string | number) {
  return `/api/v3/emote/${emote_pk}/delete`;
}

/**
 * POST create background
 * required:
 * - name {string}
 * - is_public {boolean}
 * - image {file}
 * @param user_pk {number}
 * @returns background object
 * - image {string}
 * - is_public {boolean}
 * - name {string}
 * - pk {number}
 * - user_id {number}
 */
export function createBackground(user_pk = 0) {
  return `/api/v3/user/${user_pk}/avatar_background/create`;
}

/**
 * GET/POST update avatar_background
 * required:
 * - name {string}
 * - is_public {boolean}
 * - image {file}
 * @param background_pk {number}
 * @returns background object
 * - image {string}
 * - name {string}
 * - is_public {boolean}
 * - pk {number}
 * - user_id {number}
 */
export function updateBackground(background_pk: string | number) {
  return `/api/v3/avatar_background/${background_pk}/update`;
}

/**
 * DELETE/POST avatar_background
 * @param background_pk {number}
 * @returns nothing if successful
 */
export function deleteBackground(background_pk: string | number) {
  return `/api/v3/avatar_background/${background_pk}/delete`;
}

/*********************************************************
 * MANAGER FUNCTIONS
 * - create/update/delete avatar
 ********************************************************/

/**
 * GET avatar object
 * @param avatar_pk {number}
 * @returns avatar object
 * - pk {number}
 * - is_public {boolean}
 * - avatar_type {avatar type object}
 * - name {string}
 * - thumbnail image {string}
 * - avatar_assets {array of avatar assets}
 */
export function getAvatarDetails(avatar_pk: string | number) {
  return `/api/v3/avatar/${avatar_pk}/detail`;
}

/**
 * POST Create avatar Object
 * requires:
 * - avatar type {number}
 * - name {string}
 * optional:
 * - image {file} //thumbnail image
 * - is_public {boolean}
 * @param user_pk number {user's pk}
 * @returns avatar object
 * - name {string}
 * - is_public {boolean}
 * - avatar_type {number}
 * - pk {number}
 * - avatar_type {avatar type object}
 * - avatar_assets {array of avatar asset objects}
 */
export function createAvatarObject(user_pk = 0) {
  return `/api/v3/user/${user_pk}/avatar/create`;
}

/**
 * POST updates avatar object
 * requires:
 * - avatar type {number}
 * - name {string}
 * optional:
 * - image {file} //thumbnail image
 * - is_public {boolean}
 * @param avatar_pk number {avatar's pk}
 * @returns avatar object
 * - name {string}
 * - avatar_type {number}
 * - is_public {boolean}
 * - pk {number}
 * - avatar_type {avatar type object}
 * - avatar_assets {array of avatar asset objects}
 */
export function updateAvatarObject(avatar_pk = 0) {
  return `/api/v3/avatar/${avatar_pk}/update`;
}

export function updateAvatarOrgField(avatar_pk = 0) {
  return `/api/v3/avatar/${avatar_pk}/update/org`;
}

/**
 * DELETE/POST avatar
 * Note: this just removes the avatar object. Everything connected to
 * the avatar is still there. Ie assets
 * @param avatar_pk {number}
 * @returns nothing if successful
 */
export function deleteAvatar(avatar_pk: string | number) {
  return `/api/v3/avatar/${avatar_pk}/delete`;
}

/**
 * GET avatar type list
 * @param organization_pk {number}
 * @returns
 * - count {number}
 * - page_count {number}
 * - page {number}
 * - array of avatar type objects
 *     - pk {number}
 *     - name {string}
 *     - visible {boolean}
 *     - enabled {boolean}
 *     - priority {number}
 */
export function getAvatarTypeList(organization_pk = 0, page = 1) {
  // return `/api/v3/avatar_type/list`;
  return `/api/v3/organization/${organization_pk}/avatar_type/list?page=${page}`;
}

/**
 * POST create a new avatar type
 * @param organization_pk {number}
 * required:
 * - name {string}
 * - visible {boolean}
 * - enabled {boolean}
 * - priority {number}
 * @returns avatar type object
 * - pk {number}
 * - name {string}
 * - visible {boolean}
 * - enabled {boolean}
 * - priority {number}
 */
export function createAvatarType(organization_pk = 0) {
  // return `/api/v3/avatar_type/create`
  return `/api/v3/organization/${organization_pk}/avatar_type/create`;
}

/**
 * POST update a new avatar type
 * required:
 * - name {string}
 * - visible {boolean}
 * - enabled {boolean}
 * - priority {number}
 * @param type_pk number {avatar type pk}
 * @returns avatar type object
 * - pk {number}
 * - name {string}
 * - visible {boolean}
 * - enabled {boolean}
 * - priority {number}
 */
export function updateAvatarType(type_pk = 0) {
  return `/api/v3/avatar_type/${type_pk}/update`;
}

/**
 * POST / DELETE avatar type
 * @param type_pk number {avatar type pk}
 * @returns nothing if successful
 */
export function deleteAvatarType(type_pk = 0) {
  return `/api/v3/avatar_type/${type_pk}/delete`;
}

/**
 * GET list of states
 * @returns
 * - count {number}
 * - page_count {number}
 * - page {number}
 * - array of avatar state objects
 *     - pk {number}
 *     - name {string}
 *     - visible {boolean}
 *     - enabled {boolean}
 *     - priority {number}
 */
export function getAvatarStates(page = 1) {
  return `/api/v3/avatar_state/list?page=${page}`;
}

/**
 * POST create a new avatar state
 * required:
 * - name {string}
 * - visible {boolean}
 * - enabled {boolean}
 * - priority {number}
 * @returns avatar state object
 * - pk {number}
 * - name {string}
 * - visible {boolean}
 * - enabled {boolean}
 * - priority {number}
 */
export function createAvatarState() {
  return `/api/v3/avatar_state/create`;
}

/**
 * POST update a new avatar state
 * required:
 * - name {string}
 * - visible {boolean}
 * - enabled {boolean}
 * - priority {number}
 * @param state_pk number {avatar state pk}
 * @returns avatar state object
 * - pk {number}
 * - name {string}
 * - visible {boolean}
 * - enabled {boolean}
 * - priority {number}
 */
export function updateAvatarState(state_pk = 0) {
  return `/api/v3/avatar_state/${state_pk}/update`;
}

/**
 * POST / DELETE avatar state
 * @param state_pk number {avatar state pk}
 * @returns nothing if successful
 */
export function deleteAvatarState(state_pk = 0) {
  return `/api/v3/avatar_state/${state_pk}/delete`;
}

/**
 * GET list of asset types that are connected to an avatar type
 * @param avatar_type_pk number
 * @returns
 * - count {number}
 * - page_count {number}
 * - page {number}
 * - array of avatar asset type objects
 *     - pk {number}
 *     - parent_asset_type_id {number}
 *     - name {string}
 *     - visible {boolean}
 *     - enabled {boolean}
 *     - tiltable {boolean}
 *     - priority {number}
 */
export function getAvatarAssetsTypes(avatar_type_pk = 0, page = 1) {
  return `/api/v3/avatar_type/${avatar_type_pk}/asset_type/list?page=${page}`;
}

/**
 * POST create a new asset type field on an avatar type
 * (ie create mouth for bipedal)
 * required:
 * - name {string}
 * - visible {boolean}
 * - enabled {boolean}
 * - tiltable {boolean}
 * - priority {number}
 * optional:
 * - parent_asset_type {number} (pk of asset type that would be parent)
 * @param avatar_type_pk number {avatar type pk}
 * @returns avatar asset type object
 * - pk {number}
 * - parent_asset_type_id {number}
 * - name {string}
 * - visible {boolean}
 * - enabled {boolean}
 * - tiltable {boolean}
 * - priority {number}
 */
export function createAssetType(avatar_type_pk = 0) {
  return `/api/v3/avatar_type/${avatar_type_pk}/asset_type/create`;
}

/**
 * POST update an avatar asset type
 * required:
 * - name {string}
 * - visible {boolean}
 * - enabled {boolean}
 * - tiltable {boolean}
 * - priority {number}
 * optional:
 * - parent_asset_type {number} (pk of asset type that would be parent)
 * @param asset_type_pk number {avatar asset type pk}
 * @returns avatar asset type object
 * - pk {number}
 * - parent_asset_type_id {number}
 * - name {string}
 * - visible {boolean}
 * - enabled {boolean}
 * - tiltable {boolean}
 * - priority {number}
 */
export function updateAssetType(asset_type_pk = 0) {
  return `/api/v3/avatar/asset_type/${asset_type_pk}/update`;
}

/**
 * POST / DELETE avatar asset type
 * @param asset_type_pk number {avatar asset type pk}
 * @returns nothing if successful
 */
export function deleteAssetType(asset_type_pk = 0) {
  return `/api/v3/avatar/asset_type/${asset_type_pk}/delete`;
}

/**
 * POST create an avatar asset in an asset type field
 * required:
 * - name {string}
 * - width {number}
 * @param asset_type_pk number (asset type pk) (ie mouth (asset type) pk)
 * @returns avatar asset object
 * - pk {number}
 * - time_created {string}
 * - width {number}
 * - parent_asset_type {asset type object}
 * - asset_varients {array of asset varients. empty on create}
 * - asset_type {asset type object}
 */
export function createAvatarAsset(asset_type_pk = 0) {
  return `/api/v3/avatar/asset_type/${asset_type_pk}/asset/create`;
}

/**
 * GET gets the list of avatars for a specific avatar type
 * @param avatar_type_pk number (asset_type_pk)
 * @returns avatar asset object
 */
export function getAssetList(avatar_type_pk: number, page: number = 1) {
  return `/api/v3/avatar/asset_type/${avatar_type_pk}/asset/list?page=${page}`;
}

/**
 * POST update an avatar asset
 * required:
 * - name {string}
 * - width {number}
 * - asset_type {number} //pk of asset type
 * @param asset_pk number (asset pk)
 * @returns avatar asset object
 * - pk {number}
 * - time_created {string}
 * - width {number}
 * - parent_asset_type {asset type object}
 * - asset_varients {array of asset varients}
 * - asset_type {asset type object}
 */
export function updateAsset(asset_pk = 0) {
  return `/api/v3/avatar/asset/${asset_pk}/update`;
}

/**
 * POST connects asset to avatar asset
 * Note: this is not like ^ above. This connects the asset to the avatar
 * required:
 * - avatar {number} //avatar pk
 * - asset {number} // asset pk
 * - asset_type {number} //asset type pk
 * @param avatar_asset_pk number (avatar asset pk) (ie mouth asset pk)
 * @returns
 * - pk {number}
 * - asset object (pk, name, asset_type, asset_varients, parent_asset_type, width, time_created)
 * - asset_type {pk, parent_asset_type_id, name, visible, enabled, tiltable, priority}
 */
export function updateAvatarAsset(avatar_asset_pk = 0) {
  return `/api/v3/avatar_asset/${avatar_asset_pk}/update`;
}

/**
 * POST create asset varient
 * required:
 * - animation_interval {number}
 * - avatar_states {number} //note: can be multiple
 * @param asset_pk number {asset pk}
 * @returns varient object
 * - pk {number}
 * - animation_interval {number}
 * - avatar_state_list {array of avatar states}
 * - frames {array of varient frames}
 */
export function createAvatarAssetVarient(asset_pk = 0) {
  return `/api/v3/avatar/asset/${asset_pk}/asset_varient/create`;
}

/**
 * POST update asset varient
 * required:
 * - animation_interval {number}
 * - avatar_states {number} //note: can be multiple
 * - asset {number} //asset pk
 * @param varient_pk number {varient pk}
 * @returns varient object
 * - pk {number}
 * - animation_interval {number}
 * - avatar_state_list {array of avatar states}
 * - frames {array of varient frames}
 */
export function updateVarient(varient_pk = 0) {
  return `/api/v3/avatar/asset_varient/${varient_pk}/update`;
}

/**
 * POST / DELETE varient
 * @param varient_pk number {varient pk}
 * @returns nothing if successful
 */
export function deleteVarient(varient_pk = 0) {
  return `/api/v3/avatar/asset_varient/${varient_pk}/delete`;
}

/**
 * POST create asset varient frame
 * required:
 * - priority {number}
 * - image {file | string}
 * @param asset_varient_pk number {asset varient pk}
 * @returns varient frame object
 * - pk {number}
 * - image {string}
 * - priority {number}
 * - attachment_points {array of attachment point objects}
 */
export function createAssetVarientFrame(asset_varient_pk = 0) {
  return `/api/v3/asset_varient/${asset_varient_pk}/asset_varient_frame/create`;
}

/**
 * POST update asset varient frame
 * required:
 * - priority {number}
 * - asset_varient {number}
 * - image {file | string}
 * @param frame_pk number {varient frame pk}
 * @returns varient frame object
 * - pk {number}
 * - image {string}
 * - priority {number}
 * - attachment_points {array of attachment point objects}
 */
export function updateVarientFrame(frame_pk = 0) {
  return `/api/v3/asset_varient_frame/${frame_pk}/update`;
}

/**
 * POST / DELETE varient frame
 * @param varient_pk number {varient frame pk}
 * @returns nothing if successful
 */
export function deleteVarientFrame(frame_pk = 0) {
  return `/api/v3/asset_varient_frame/${frame_pk}/delete`;
}

/**
 * POST create attachment point on varient frame
 * required:
 * - name {string}
 * - x_position {float with 2 decimals}
 * - y_position {float with 2 decimals}
 * - z_index {number}
 * - asset_type {number} //note: asset pk that the attachment point attaches to
 * @param asset_varient_frame_pk number {varient frame pk}
 * @returns attachment point object
 * - pk {number}
 * - name {string}
 * - x_position {float with 2 decimals}
 * - y_position {float with 2 decimals}
 * - z_index {number}
 * - asset_type {asset type object}
 */
export function createAttachmentPoint(asset_varient_frame_pk = 0) {
  return `/api/v3/asset_varient_frame/${asset_varient_frame_pk}/attachment_point/create`;
}

/**
 * POST update attachment point
 * required:
 * - name {string}
 * - x_position {float with 2 decimals}
 * - y_position {float with 2 decimals}
 * - z_index {number}
 * - asset_type {number} //note: asset pk that the attachment point attaches to
 * @param attachment_point_pk number {attachment point pk}
 * @returns attachment point object
 * - pk {number}
 * - name {string}
 * - x_position {float with 2 decimals}
 * - y_position {float with 2 decimals}
 * - z_index {number}
 * - asset_type {asset type object}
 */
export function updateAttachmentPoint(attachment_point_pk = 0) {
  return `/api/v3/attachment_point/${attachment_point_pk}/update`;
}

/**
 * POST / DELETE attachment point
 * @param varient_pk number {attachment point pk}
 * @returns nothing if successful
 */
export function deleteAttachmentPoint(attachment_point_pk = 0) {
  return `/api/v3/attachment_point/${attachment_point_pk}/delete`;
}

/**Public object endpoints */

/**
 * GET the list of public avatars (only for admins)
 * @param organization_pk number
 * @returns
 * array of avatar objects that have been listed as public
 */
export function getPublicAvatars(organization_pk = 0) {
  return `/api/v3/organization/${organization_pk}/avatar/list/public`;
}

/**
 * GET the list of public avatars available for streaming
 * @param organization_pk number
 * @returns
 * array of avatar objects that have been listed as public
 */
export function getStreamingPublicAvatars(page = 1) {
  return `/api/v3/streaming/avatar/list/public?page=${page}`;
}

/**
 * GET the list of avatars that have been obtained for that user (purchased, assigned, etc..)
 * @param organization_pk number
 * @returns
 * array of avatar objects that have been listed as public
 */
export function getStreamingObtainedAvatars(page = 1) {
  return `/api/v3/streaming/avatar/list/obtained?page=${page}`;
}

/**
 * GET the list of personal avatars from a user that they have assigned to be streaming avatars
 * @param organization_pk number
 * @returns
 * array of avatar objects that have been listed as public
 */
export function getStreamingPersonalAvatars(page = 1) {
  return `/api/v3/streaming/avatar/list/personal?page=${page}`;
}

/**
 * GET the list of public emotes
 * @param organization_pk number
 * @returns
 * array of emote objects that have been listed as public
 */
export function getPublicEmotes(organization_pk = 0) {
  return `/api/v3/organization/${organization_pk}/emote/list/public`;
}

/**
 * GET the list of public backgrounds
 * @param organization_pk number
 * @returns
 * array of background objects that have been listed as public
 */
export function getPublicAvatarBackgrounds(organization_pk = 0) {
  return `/api/v3/organization/${organization_pk}/avatar_background/list/public`;
}

/**
 * Create a background tied to the user
 * @param userPk number
 * @returns
 */
export function userAvatarBackgroundCreate(userPk = 0) {
  return `/api/v3/user/${userPk}/avatar_background/create`;
}

/** Specific emotes and backgrounds for avatars */

/**
 * Create a connection between an emote and an avatar
 * @param avatar_pk number
 * @returns
 */
export function createAvatarSpecificEmote(avatar_pk = 0) {
  return `/api/v3/avatar/${avatar_pk}/avatar_specific_emote/create`;
}

/**
 * Create a connection between an background and an avatar
 * @param avatar_pk number
 * @returns
 */
export function createAvatarSpecificBackground(avatar_pk = 0) {
  return `/api/v3/avatar/${avatar_pk}/avatar_specific_background/create`;
}

/**
 * Get the list of avatar specific backgrounds
 * @param avatar_pk number
 * @returns
 */
export function getAvatarSpecificBackgroundList(avatar_pk = 0) {
  return `/api/v3/avatar_specific/avatar/${avatar_pk}/avatar_background/list`;
}

/**
 * Get the list of avatar specific emotes
 * @param avatar_pk number
 * @returns
 */
export function getAvatarSpecificEmoteList(avatar_pk = 0) {
  return `/api/v3/avatar_specific/avatar/${avatar_pk}/emote/list`;
}

/**
 * Get send in an emote pk and get the list of avatar specific emotes connected
 * @param emote_pk number
 * @returns
 */
export function getEmoteAvatarSpecificList(emote_pk: string | number) {
  return `/api/v3/avatar_specific/emote/${emote_pk}/avatar/list`;
}

/**
 * Get send in an background pk and get the list of avatar specific backgrounds connected
 * @param background_pk number
 * @returns
 */
export function getBackgroundAvatarSpecificList(
  background_pk: string | number
) {
  return `/api/v3/avatar_specific/avatar_background/${background_pk}/avatar/list`;
}

/**
 * delete a connection between emote and avatar
 * @param specific_pk number
 * @returns
 */
export function deleteAvatarSpecificEmote(specific_pk = 0) {
  return `/api/v3/avatar_specific_emote/${specific_pk}/delete`;
}

/**
 * delete a connection between background and avatar
 * @param specific_pk number
 * @returns
 */
export function deleteAvatarSpecificBackground(specific_pk = 0) {
  return `/api/v3/avatar_specific_background/${specific_pk}/delete`;
}

/**
 * POST feature request
 */
export function sendFeatureRequest() {
  return `/api/v3/report/feature_request/create`;
}
