import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Button,
  createTheme,
  CssBaseline,
  PaletteColor,
  ThemeProvider,
} from "@mui/material";

import { PrivateRoute } from "./utility/PrivateRoute";

import Authentication from "./features/Authentication/Authentication";
import UserManagement from "./features/Admin/Users/UserManagement";
// import AvatarManagement from "./features/Admin/Avatars/AvatarManagement";
import BackgroundManagement from "./features/Admin/Backgrounds/BackgroundManagement";
import EmoteManagement from "./features/Admin/Emotes/EmoteManagement";

import "./styles/index.scss";
import EditUser from "./features/Admin/Users/EditUser";
import EditAvatar from "./features/Admin/Avatars/EditAvatar";
import EditBackground from "./features/Admin/Backgrounds/EditBackground";
import EditEmote from "./features/Admin/Emotes/EditEmote";
import Dashboard from "./features/User/Dashboard";
import { useEffect, useMemo, useState } from "react";
import {
  getDefaultOrg,
  v3UpdateOrgFields,
  v3UserDetailPrivate,
} from "./utility/Endpoints";
import Get from "./utility/Get";
// import AddAvatarManager from "./features/Admin/Avatars/AddAvatar/AddAvatarManager";
import DisplayWindow from "./features/User/DisplayWindow";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import FAQ from "./features/FAQ/Faq";
import Marketplace from "./features/Marketplace/Marketplace";
import Post from "./utility/Post";
import { OrganizationType, v3UserType } from "./utility/Types";
import { V3Organization } from "./utility/context/V3Organization";
import { V3UserContext } from "./utility/context/V3UserContext";
import { CartContext } from "./utility/context/CartContext";
import { Modal } from "./components/Modal";
import DynamicForm from "./utility/DynamicForm";
import { ProductType } from "./utility/MarketplaceTypes";
import Sell from "./features/Sell/Sell";
import ViewCart from "./features/cart-payment/ViewCart";
import PaymentComplete from "./features/cart-payment/PaymentCompleted";
import PaymentHistory from "./features/PaymentHistory/PaymentHistory";
import MarketplaceProduct from "./features/marketplace-product/MarketplaceProduct";
import SellerSales from "./features/Sell/SellerSales";
import MarketplaceSeller from "./features/marketplace-seller/MarketplaceSeller";
import AddProductPack from "./features/create-edit-product/AddProductPack";
import AddProduct from "./features/create-edit-product/AddProduct";
import Account from "./features/Account/Account";
import AvatarForm from "./features/AvatarForm/AvatarForm";
import AvatarManage from "./features/AvatarForm/AvatarManager";
import Cookies from "./components/Cookies";

declare module "@mui/material/styles" {
  interface Palette {
    white: string;
  }
  interface PaletteOptions {
    white: PaletteColor;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    white: true;
  }
}

/* Material UI theme settings */
const { palette } = createTheme();
const theme = createTheme({
  palette: {
    background: {
      default: "#000",
    },
    text: {
      primary: "#000",
    },
    primary: { main: "#ff8900" },
    secondary: { main: "#fff" },
    error: { main: "#f03434" },
    white: palette.augmentColor({
      color: {
        main: "#fff",
      },
    }),
    // custom: {
    //   light: '#ffa726'
    //   main: '#f57c00',
    //   dark: '#ef6c00',
    //   contrastText: 'rgba(0, 0, 0, 0.87)',
    // }
  },
  shape: {
    borderRadius: "0.5rem",
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

/**
 * Start of the app
 */
function App(): JSX.Element {
  // user object obtained from backend or local
  const [user, setUser] = useState<v3UserType | null>(
    localStorage.getItem("vstreamer_user")
      ? JSON.parse(localStorage.getItem("vstreamer_user") ?? "")
      : null
  ); //user info and not just sessionid
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);
  const isProduction = process.env.NODE_ENV === "production";
  const [organization, setOrganization] = useState<OrganizationType>({
    pk: 0,
    name: "",
  });
  const [cart, setCart] = useState<Array<ProductType>>([]);
  const [showUpdateOrgForm, setShowUpdateOrgForm] = useState({
    show: false,
    data: {},
    errors: {},
    loading: false,
  });

  useEffect(() => {
    //get vstreamer org data
    Get(getDefaultOrg()).then((val) => {
      if (val.status && val.status < 300) {
        if (val.data && val.data.data) {
          setOrganization(val.data.data);
        }
      } else {
        console.error("something went wrong");
      }
    });

    //get cart items
    setCart(
      localStorage.getItem("vstreamer-cart-items")
        ? JSON.parse(localStorage.getItem("vstreamer-cart-items") ?? "")
        : []
    );

    // call backend for user profile info and save in state
    let temp_user = { pk: 0 };
    if (localStorage.getItem("vstreamer_user")) {
      temp_user = JSON.parse(localStorage.getItem("vstreamer_user") ?? "");
      setUser(temp_user);
    }
    // get user's most update-to-date info
    Get(v3UserDetailPrivate(temp_user.pk)).then((res) => {
      if (res.status && res.status < 300) {
        if (res.data && res.data.data) {
          //update our version of user
          setUser(res.data.data);
          localStorage.setItem("vstreamer_user", JSON.stringify(res.data.data));
        }
      } else {
        //remove user data
        localStorage.removeItem("sessionid");
        localStorage.removeItem("vstreamer_user");
        localStorage.removeItem("vstreamer-cart-items");
        setUser(null);
      }
    });

    //handle update org modal
    //get check if user's org info is all filled out
    Get(v3UpdateOrgFields(temp_user.pk)).then((res) => {
      if (res.status && res.status < 300) {
        //if any fields are empty
        Object.keys(res.data.form.data).forEach((key) => {
          if (
            res.data.form.data[key] === "" ||
            res.data.form.data[key] === null
          ) {
            //then open modal for user to update info
            setShowUpdateOrgForm({
              show: true,
              data: res.data.form.data,
              errors: {},
              loading: false,
            });
          }
        });
      }
    });

    // eslint-disable-next-line
  }, [navigator]);

  function handleUpdateOrgForm(data: any) {
    //if form is completed, close
    if (data && user) {
      const FormData = require("form-data");
      const formData = new FormData();
      Object.keys(showUpdateOrgForm.data).forEach((key) => {
        if (key === "date_of_birth") {
          formData.append(
            "date_of_birth",
            data.year + "-" + data.month + "-" + data.day
          );
        } else {
          formData.append(key, data[key]);
        }
      });

      Post(v3UpdateOrgFields(user.pk), formData).then((val) => {
        if (val.status < 300) {
          setShowUpdateOrgForm({
            show: false,
            data: {},
            errors: {},
            loading: true,
          });
        } else {
          //handle errors
          var temp = Object.keys(val.data.form.errors)[0];
          setShowUpdateOrgForm({
            ...showUpdateOrgForm,
            data: val.data.form.data,
            errors: { [temp]: val.data.form.errors[temp] },
            loading: false,
          });
        }
      });
    }
  }

  //handle log out
  function handleLogOut() {
    //Note: dont clear because we still need org info
    // localStorage.clear();
    setShowUpdateOrgForm({
      show: false,
      data: {},
      errors: {},
      loading: false,
    });
    setUser(null);
    localStorage.removeItem("sessionid");
    localStorage.removeItem("vstreamer_user");
    localStorage.removeItem("vstreamer-cart-items");
  }

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<div>Loading...</div>} //If not pass, nothing appears at the time of new version check.
    >
      <div className="Vstreamer">
        <V3Organization.Provider value={organization}>
          <V3UserContext.Provider value={value}>
            <CartContext.Provider value={{ cart: cart, setCart: setCart }}>
              <Modal
                isOpen={showUpdateOrgForm.show}
                title={"Oh! We are missing some info"}
                onRequestClose={(e: any) => handleUpdateOrgForm(e)}
                hideClose={true}
                actions={
                  <Button onClick={() => handleLogOut()}>Log Out</Button>
                }
              >
                <DynamicForm
                  api={[v3UpdateOrgFields(user ? user.pk : 0)]}
                  handleSubmit={(data: any) => handleUpdateOrgForm(data)}
                  data={showUpdateOrgForm.data}
                  errors={showUpdateOrgForm.errors}
                  loading={showUpdateOrgForm.loading}
                />
              </Modal>

              <Router>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <Routes>
                    <Route
                      path="/login"
                      element={<Authentication setUser={(u) => setUser(u)} />}
                    />
                    <Route path="*" element={<div>Page not found.</div>} />

                    {/* Need to have start path here. Private route will redirect to login if no user  */}
                    <Route
                      path="/"
                      element={
                        <PrivateRoute
                          user={
                            user
                              ? user
                              : localStorage.getItem("vstreamer_user")
                              ? JSON.parse(
                                  localStorage.getItem("vstreamer_user") ?? ""
                                )
                              : null
                          }
                        />
                      }
                    >
                      <Route path="/" element={<Dashboard />} />
                    </Route>

                    <Route path="/faq" element={<FAQ />}></Route>

                    {user && (
                      <>
                        {/* Global Routes */}
                        <>
                          <Route
                            path="/account"
                            element={<PrivateRoute user={user} />}
                          >
                            <Route path="/account" element={<Account />} />
                          </Route>

                          <Route
                            path="/display"
                            element={<PrivateRoute user={user} />}
                          >
                            <Route
                              path="/display"
                              element={<DisplayWindow />}
                            />
                          </Route>

                          <Route
                            path="/marketplace"
                            element={<PrivateRoute user={user} />}
                          >
                            <Route
                              path="/marketplace"
                              element={<Marketplace />}
                            />
                          </Route>

                          {/* sell on app home -> seller's dashboard */}
                          <Route
                            path="/sell"
                            element={<PrivateRoute user={user} />}
                          >
                            <Route path="/sell" element={<Sell />} />
                          </Route>

                          {/* view marketplace seller home */}
                          <Route
                            path="/marketplace/seller/:id"
                            element={<PrivateRoute user={user} />}
                          >
                            <Route
                              path="/marketplace/seller/:id"
                              element={<MarketplaceSeller />}
                            />
                          </Route>
                          {/* view marketplace seller view all sales */}
                          <Route
                            path="/marketplace/seller/:id/sales"
                            element={<PrivateRoute user={user} />}
                          >
                            <Route
                              path="/marketplace/seller/:id/sales"
                              element={<SellerSales />}
                            />
                          </Route>

                          {/* view marketplace seller home */}
                          <Route
                            path="/marketplace/product/:id"
                            element={<PrivateRoute user={user} />}
                          >
                            <Route
                              path="/marketplace/product/:id"
                              element={<MarketplaceProduct />}
                            />
                          </Route>
                          {/* <Route
                    path="/product-edit/:id"
                    element={<PrivateRoute user={user} />}
                  >
                    <Route path="/product-edit/:id" element={<EditProduct />} />
                  </Route> */}

                          {/* add npc product */}
                          <Route
                            path="/product-add"
                            element={<PrivateRoute user={user} />}
                          >
                            <Route
                              path="/product-add"
                              element={<AddProduct />}
                            />
                          </Route>

                          {/* add product pack */}
                          <Route
                            path="/product-pack-add"
                            element={<PrivateRoute user={user} />}
                          >
                            <Route
                              path="/product-pack-add"
                              element={<AddProductPack />}
                            />
                          </Route>

                          {/* view payment history  */}
                          <Route
                            path="/purchase-history"
                            element={<PrivateRoute user={user} />}
                          >
                            <Route
                              path="/purchase-history"
                              element={<PaymentHistory />}
                            />
                          </Route>
                        </>

                        {user && ( //Note: checking if user exists will make all routes be a 404 if not signed it
                          <>
                            <Route
                              path="/cart"
                              element={<PrivateRoute user={user} />}
                            >
                              <Route path="/cart" element={<ViewCart />} />
                            </Route>
                            <Route
                              path="/payment-complete/:id"
                              element={<PrivateRoute user={user} />}
                            >
                              <Route
                                path="/payment-complete/:id"
                                element={<PaymentComplete />}
                              />
                            </Route>
                          </>
                        )}

                        {/* Management Routes */}
                        {(user.is_user_manager ||
                          user.is_organization_manager) && (
                          <>
                            <Route
                              path="/"
                              element={<PrivateRoute user={user} />}
                            >
                              <Route
                                path="/usermanagement"
                                element={<UserManagement />}
                              />
                            </Route>
                            <Route
                              path="/backgroundmanagement"
                              element={<PrivateRoute user={user} />}
                            >
                              <Route
                                path="/backgroundmanagement"
                                element={<BackgroundManagement />}
                              />
                            </Route>
                            <Route
                              path="/emotemanagement"
                              element={<PrivateRoute user={user} />}
                            >
                              <Route
                                path="/emotemanagement"
                                element={<EmoteManagement />}
                              />
                            </Route>
                            {/* Edit Routes */}
                            <Route
                              path="/:id/edit"
                              element={<PrivateRoute user={user} />}
                            >
                              <Route path="/:id/edit" element={<EditUser />} />
                            </Route>

                            {/* This is the old avatar form that shows ALL asset types
                            I will leave this for artists/manager just in case they need to edit something specific */}
                            <Route
                              path="/avatarmanagement/:id/edit"
                              element={<PrivateRoute user={user} />}
                            >
                              <Route
                                path="/avatarmanagement/:id/edit"
                                element={<EditAvatar />}
                              />
                            </Route>
                            <Route
                              path="/backgroundmanagement/:id/edit"
                              element={<PrivateRoute user={user} />}
                            >
                              <Route
                                path="/backgroundmanagement/:id/edit"
                                element={<EditBackground />}
                              />
                            </Route>
                            <Route
                              path="/emotemanagement/:id/edit"
                              element={<PrivateRoute user={user} />}
                            >
                              <Route
                                path="/emotemanagement/:id/edit"
                                element={<EditEmote />}
                              />
                            </Route>
                            {/* new avatar form routes
                            manage avatar lists avatars that a user can Edit
                            create avatar lets a user create an avatar
                            edit avatar lets a user edit an avatar  */}
                            <Route
                              path="/manageavatar"
                              element={<PrivateRoute user={user} />}
                            >
                              <Route
                                path="/manageavatar"
                                element={<AvatarManage />}
                              />
                            </Route>
                            <Route
                              path="/createavatar"
                              element={<PrivateRoute user={user} />}
                            >
                              <Route
                                path="/createavatar"
                                element={<AvatarForm />}
                              />
                            </Route>
                            <Route
                              path="/manageavatar/:id/edit"
                              element={<PrivateRoute user={user} />}
                            >
                              <Route
                                path="/manageavatar/:id/edit"
                                element={<AvatarForm />}
                              />
                            </Route>
                          </>
                        )}
                      </>
                    )}
                  </Routes>
                </ThemeProvider>
              </Router>
            </CartContext.Provider>
          </V3UserContext.Provider>
        </V3Organization.Provider>
        <Cookies />
      </div>
    </CacheBuster>
  );
}

export default App;
