import clsx from "clsx";

type VariantOptions = "primary" | "secondary";

interface ThumbnailCardProps {
  className?: string;
  title?: string;
  subtitle?: string;
  thumbnail?: string;
  price?: number;
  status?: string | null;
  href?: string;
  variant?: VariantOptions;
}

export function ThumbnailCard({
  className,
  title,
  subtitle,
  thumbnail,
  price,
  status,
  href,
  variant = "primary", // primary, secondary
  ...props
}: ThumbnailCardProps) {
  const classes = clsx("c-thumbnail-card", className, {
    [`-${variant}`]: variant,
  });

  return (
    <a className={classes} {...props} href={href}>
      <img
        src={thumbnail ? thumbnail : "/npc_placeholder.png"}
        alt={`${title ? title + " " : ""}thumbnail`}
      />

      {title ? (
        <div className="c-thumbnail-card__title">
          <p className="c-thumbnail-card__name">{title}</p>
          <p className="c-thumbnail-card__price">{price ? `$${(price / 100).toFixed(2)}` : null}</p>
        </div>
      ) : ""}

      {subtitle ? (
        <p className="c-thumbnail-card__subtitle">
          {subtitle}
        </p>
      ) : ""}

      {status ? (
        <p className="c-thumbnail-card__status" style={
          status === "PENDING" ?
            { backgroundColor: "yellow" } :
            (status === "LIVE" ?
              { backgroundColor: "#00B000" } : {}
            )}
        >
          {status}
        </p>
      ) : ""}

    </a>
  );
}
