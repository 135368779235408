import { useEffect } from "react";
import useAvatarDisplay from "../hooks/useAvatarDisplay";

export default function Loading() {
  const isLoading = useAvatarDisplay((state) => state.isLoading);

  useEffect(() => {
    if (!isLoading)
      setTimeout(() => {
        if (document.getElementById("LoadingComponent"))
          document.getElementById("LoadingComponent")?.remove();
      }, 5000);
  }, [isLoading]);

  return (
    <div
      id="LoadingComponent"
      className={isLoading ? "loading" : "loading loading--done"}
    >
      Loading...
    </div>
  );
}
