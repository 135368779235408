import {
  Alert,
  AlertTitle,
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Get from "../../../utility/Get";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import { getOrgEmoteList } from "../../../utility/Endpoints";
import { V3Organization } from "../../../utility/context/V3Organization";

interface EmoteListProps {
  search?: string;
  errorMessage?: string;
  infoMessage?: string;
}

/**
 * Displays a list of all emotes from the database
 * @returns
 */
export default function EmoteList(props: EmoteListProps): JSX.Element {
  let navigator = useNavigate();
  const [emotes, setEmotes] = useState<
    {
      name: string;
      thumbnail_image: string;
      image: string;
      pk: number;
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const organization = useContext(V3Organization);
  const [infoMessage, setInfoMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    getEmotes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.search === "") {
      getEmotes();
    } else {
      //search by name
      getEmotes(true);
    }
    // eslint-disable-next-line
  }, [props.search]);

  useEffect(() => {
    if (props.errorMessage) {
      setErrorMessage(props.errorMessage);
    } else {
      setErrorMessage("");
    }
    if (props.infoMessage) {
      setInfoMessage(props.infoMessage);
    } else {
      setInfoMessage("");
    }
    if(organization.pk) getEmotes();
    // eslint-disable-next-line
  }, [props.infoMessage, props.errorMessage, organization]);

  function getEmotes(filter = false) {
    setIsLoading(true);
    Get(getOrgEmoteList(organization.pk)).then((val) => {
      if (val.status && val.status < 300) {
        if (filter) {
          setEmotes(
            val.data.data.filter((a: { name: string | string[] }) =>
              a.name.includes(props.search ?? "")
            )
          );
        } else {
          setEmotes(val.data.data);
        }
        setIsLoading(false);
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    });
  }

  return !isLoading ? (
    <div className="emote-list">
      {infoMessage !== "" && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {infoMessage}
        </Alert>
      )}
      {errorMessage !== "" && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}
      {emotes.length === 0 ? (
        <div>No emotes found.</div>
      ) : (
        <TableContainer sx={{ display: "flex", justifyContent: "center" }}>
          <Table style={{ tableLayout: "fixed", whiteSpace: "nowrap" }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Thumbnail Image</TableCell>
                <TableCell>Emote</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emotes.map((data, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      <Link href={`/emotemanagement/${data.pk}/edit`}>
                        {data.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Box
                        component={"img"}
                        sx={{
                          width: 350,
                          maxWidth: { xs: "100%", md: 250 },
                        }}
                        alt={`${data.name} emote thumbnail`}
                        src={data.thumbnail_image}
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        component={"img"}
                        sx={{
                          width: 350,
                          maxWidth: { xs: "100%", md: 250 },
                        }}
                        alt={`${data.name} emote`}
                        src={data.image}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  ) : (
    <div>Loading...</div>
  );
}
