import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import { getStorePurchaseList } from "../../utility/MarketEndpoints";
import Get from "../../utility/Get";
import Popup from "../../components/Popup";
import { Loader } from "../../components/Loader";
import { PaymentCard } from "../../components/PaymentCard";
import { PurchaseHistoryType } from "../../utility/MarketplaceTypes";
import { Button } from "@mui/material";


export default function SellerSales(): JSX.Element {
  const navigator = useNavigate();
  const { id } = useParams();
  const [recentSales, setRecentSales] = useState<Array<PurchaseHistoryType>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const controller = new AbortController();

    if (id) {
      getAllSales(1, controller)
    }

    function getAllSales(page: number, controller: AbortController) {
      // get recent sales
      if (id) {
        Get(getStorePurchaseList(id as unknown as number, page), controller.signal).then(val => {
          if (val.status && val.status < 300) {
            if (val.data) {
              setRecentSales((prev) => [...prev, ...val.data.data]);
              //recursively get all pages of npcs
              if (val.data.page_count > page) {
                getAllSales(page + 1, controller)
              } else {
                setLoading(false)
              }
            }
          } else if (val.status === 401) {
            navigator("/login");
          } else {
            Popup("Something went wrong. Try again later", "error")
          }
        })
      }
    }

    return () => {
      setRecentSales([]);
      controller.abort();
    };
  }, [id, navigator]);


  return !isLoading ? (
    <div className="sell">
      <div className="seller-dashboard">
        <div className="seller-dashboard__header">
          <h3>Recent Sales</h3>
          <Button onClick={() => navigator("/sell")}>Back to Seller's Dashboard</Button>
        </div>
        <hr />
        {recentSales.length < 1 ? (
          <h3>No recent sales found</h3>
        ) : (
          <>
            {recentSales.map((purchase: PurchaseHistoryType, i) => {
              return (
                <div className="purchase-history__purchase" key={purchase.pk}>
                  <div className="purchase-history__title"></div>
                  <div className="purchase-history__view-products">
                    {purchase.purchased_products.map((product) => {
                      if (id && product.store.pk === parseInt(id, 10)) {
                        return (
                          <div className="purchase-history__product" key={product.pk}>
                            <div className="purchase-history__product-payment">
                              <div className="purchase-history__container">
                                <p>
                                  {purchase.user.username} purchased on{" "}
                                  {moment(purchase.time_created).format("MMM DD, YYYY")}
                                </p>
                                <div>
                                  Status:
                                  <p style={{ color: "black", backgroundColor: "#00B000", textAlign: "center", fontWeight: "700" }}>Complete</p>
                                </div>
                              </div>
                            </div>
                            <PaymentCard
                              price={product.price}
                              thumbnail={product.image}
                              title={product.product_name}
                              href={`/marketplace/product/${product.pk}`}
                            >
                              <>
                                <hr />
                                <div className="purchase-history__product-item">
                                  <p>{product.description}</p>
                                </div>
                              </>
                            </PaymentCard>
                          </div>
                        )
                      } else return <></>
                    })}
                  </div>
                </div>
              )
            })}
          </>
        )}
      </div>
    </div>
  ) : (
    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <Loader variant="block" size={5} />
    </div>
  );
}
