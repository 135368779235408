import React from "react";
import clsx from "clsx";

interface SelectableButtonProps {
  selected: boolean;
  onClick?(): any;
  children?: React.ReactNode;
  height?: any;
  num?: number;
}

/**
 * A custom button that changes when it's selected
 * @implements {SelectableButtonProps}
 * @param props
 * @returns
 */
export default function SelectableButton(
  props: SelectableButtonProps
): JSX.Element {
  const classes = clsx("selectable-button", { selected: props.selected });

  return (
    <button
      className={classes}
      onClick={props.onClick}
      style={{ height: props.height ? props.height : "" }}
    >
      {props.selected && (
        <span>{typeof props.num === "number" ? props.num + 1 : "✔"}</span>
      )}
      {props.children}
    </button>
  );
}
