import Loading from "../User/components/Loading";
import Preload from "../User/components/Preload";
import Microphone from "../User/components/Microphone";
import Webcam from "../User/components/Webcam";
import ExpressionEmotes from "../User/components/ExpressionEmotes";
import Watermark from "../User/components/Watermark";
import RenderAvatar from "../User/components/RenderAvatar";

interface PreviewDisplayProps {
  data: any
}

export default function PreviewDisplay(props: PreviewDisplayProps) {
  const threshold = JSON.parse(
    localStorage.getItem("avatar-stream") as string
  ).threshold;

  return (
    <div
      className="display-window"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Loading />
      <Preload data={props.data} />
      <Microphone threshold={threshold} />
      <Webcam />
      <div className="display-window__hotkey-bar">
        <ExpressionEmotes />
        <Watermark color="black" />
      </div>
      <RenderAvatar />
    </div>
  );
}