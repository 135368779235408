import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Dropdown } from "../../components/Dropdown";
import { Loader } from "../../components/Loader";
import Popup from "../../components/Popup";
import { V3UserContext } from "../../utility/context/V3UserContext";
import { Button } from "@mui/material";
import {
  getStoreSnapshotProducts,
  postCreateNpcPackProduct,
  postCreateAvatarSnapshot,
  postCreateSnapshotProduct,
} from "../../utility/MarketEndpoints";
import Get from "../../utility/Get";
import Post from "../../utility/Post";
import { ErrorType, ProductType } from "../../utility/MarketplaceTypes";
import ProductForm from "./components/ProductForm";
import { Modal } from "../../components/Modal";
import PackProductForm from "./components/PackProductForm";
import { Avatar } from "../../utility/Types";
import { getOrgAvatarList, getStreamingPersonalAvatars } from "../../utility/Endpoints";
import { V3Organization } from "../../utility/context/V3Organization";

export default function AddProductPack() {
  const navigator = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [productPkList, setProductPkList] = useState<Array<ProductType>>(); //list of npcs for a new pack
  const [showProductForm, setShowProductForm] = useState<boolean>(false);
  const [productOptions, setProductOptions] = useState<Array<ProductType>>([]);
  const [errors, setErrors] = useState<ErrorType>({});
  const { user } = useContext(V3UserContext);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [newNpcProductModal, setNewNpcProductModal] = useState<boolean>(false);
  const [npcOptions, setNpcOptions] = useState<Array<Avatar>>([]);
  const [npcPk, setNpcPk] = useState<number>();
  const organization = useContext(V3Organization);

  useEffect(() => {
    const controller = new AbortController();

    //get list of personal npcs
    getListofNpcProducts(controller);

    return () => {
      controller.abort();
      setProductOptions([]);
    };
    // eslint-disable-next-line
  }, [newNpcProductModal]); //get new list when modal opens and closes for easy refresh of products

  useEffect(() => {
    const controller = new AbortController();

    if(user && user.pk === 736 && user?.store_id === 1) {
      //if this is the organzition user signed in, then also get organization avatars to sell
      getListofOrgAvatars(1, controller)
    }

    //get list of personal npcs
    if (npcOptions.length === 0) {
      getListofNpcs(1, controller);
    }

    return () => {
      controller.abort();
      setNpcOptions([]);
    };
    // eslint-disable-next-line
  }, []);

  function getListofOrgAvatars(page: number, controller: AbortController) {
    if (user) {
      Get(getOrgAvatarList(organization.pk, page), controller.signal)
        .then((val) => {
          if (val.status && val.status < 300) {
            if (val.data) {
              setNpcOptions((prev) => [...prev, ...val.data.data]);
              //recursively get all pages of npcs
              if (val.data.page_count > page) {
                getListofOrgAvatars(page + 1, controller);
              }
            }
            setLoading(false);
          } else if (val.status === 401) {
            navigator("/");
          } else {
            Popup("There was an error getting the list of Avatars", "error");
          }
        })
        .catch((e) => {
          // This probably happened because the cancel signal was sent
        });
    } else {
      getListofOrgAvatars(1, controller);
    }
  }

  function getListofNpcs(page: number, controller: AbortController) {
    //get list of user's npcs
    if (user) {
      Get(getStreamingPersonalAvatars(page), controller.signal)
        .then((val) => {
          if (val.status && val.status < 300) {
            if (val.data) {
              setNpcOptions((prev) => [...prev, ...val.data.data]);
              //recursively get all pages of npcs
              if (val.data.page_count > page) {
                getListofNpcs(page + 1, controller);
              }
            }
            setLoading(false);
          } else if (val.status === 401) {
            navigator("/");
          } else {
            Popup("There was an error getting the list of Avatars", "error");
          }
        })
        .catch((e) => {
          // This probably happened because the cancel signal was sent
        });
    } else {
      getListofNpcs(1, controller);
    }
  }

  function getListofNpcProducts(controller: AbortController) {
    //get list of user's npcs
    if (user && user.store_id) {
      Get(getStoreSnapshotProducts(user?.store_id), controller.signal)
        .then((val) => {
          if (val.status && val.status < 300) {
            if (val.data) {
              setProductOptions(val.data.data);
            }
            setLoading(false);
          } else if (val.status === 401) {
            navigator("/");
          } else {
            Popup("There was an error getting the list of Avatars", "error");
          }
        })
        .catch((e) => {
          // This probably happened because the cancel signal was sent
        });
    }
  }

  function createProduct(product: FormData) {
    if (npcPk)
      Post(postCreateAvatarSnapshot(npcPk), {}).then((res) => {
        if (res.status && res.status < 300 && res.data && res.data.data) {
          //then create the product with the npc
          Post(postCreateSnapshotProduct(res.data.data.pk), product).then(
            (val) => {
              if (val.status && val.status < 300) {
                Popup("Product Submitted for review.");
                if (val.data && val.data.data) {
                  setNewNpcProductModal(false);
                } else {
                  navigator("/");
                }
              } else if (val.status === 401) {
                navigator("/");
              } else {
                setErrors(val.data.form.errors);
              }
              setLoading(false);
            }
          );
        } else if (res.status === 401) {
          navigator("/");
        } else {
          Popup(
            "There was an error creating your product. Contact support.",
            "error"
          );
        }
      });
  }

  function createProductPack(product: FormData) {
    setErrors({});
    // add products to formdata
    // var formdata: any = Object.fromEntries(product)
    // var product_pks = productPkList?.map(a => a.pk)
    // formdata.products =
    productPkList?.forEach((item) => {
      product.append("products", JSON.stringify(item.pk));
    });

    setSubmitting(true);
    Post(postCreateNpcPackProduct(), product).then((val) => {
      if (val.status && val.status < 300) {
        Popup("Product Submitted for review.");
        if (val.data && val.data.data) {
          navigator(`/marketplace/product/${val.data.data.pk}`);
        } else {
          navigator("/");
        }
      } else if (val.status === 401) {
        navigator("/");
      } else {
        setErrors(val.data.form.errors);
        Popup("There was an error creating your product.", "error");
      }
      setSubmitting(false);
    });
  }

  return !isLoading ? (
    <div className="add-npc">
      <Modal
        isOpen={newNpcProductModal}
        onRequestClose={() => setNewNpcProductModal(false)}
        title={"Create Avatar Product"}
        className="seller-dashboard__modal"
      >
        <>
          <div style={{ marginBottom: ".8rem" }}>
            <Dropdown
              label="Avatar"
              options={npcOptions}
              widthSize="responsive"
              getOptionLabel={(option: Avatar) => option.name}
              getOptionValue={(option: Avatar) => option.pk}
              isSearchable
              onChange={(e: any) => {
                if (setNpcPk && e) {
                  setNpcPk(e.pk);
                }
              }}
            />
          </div>
          <ProductForm
            customCancel={() => {
              setNpcPk(undefined);
              setNewNpcProductModal(false);
            }}
            submitFunction={createProduct}
            errors={errors}
            showIndividually
          />
        </>
      </Modal>
      <div className="add-npc__flexrow">
        <h3>Create Avatar Pack</h3>
        <Button
          onClick={() => navigator(-1)}
          variant="contained"
          color="secondary"
        >Cancel
        </Button>
      </div>

      <hr />
      {!showProductForm ? (
        <div>
          <div className="add-npc__whitebg">
            <div className="add-npc__flexrow">
              <h5>Select Avatar Products</h5>
              <Button 
              onClick={() => setNewNpcProductModal(true)} 
              variant="contained"
              color="secondary"
              >
                Add New Avatar Product
              </Button>
            </div>
            &nbsp;&nbsp;&nbsp;
            <Dropdown
              options={productOptions}
              getOptionLabel={(option: ProductType) => option.product_name}
              getOptionValue={(option: ProductType) => option.pk}
              isSearchable
              isMulti
              widthSize="responsive"
              onChange={(e: any) => {
                if (e) {
                  var temp = e as Array<ProductType>;
                  // setProductPkList(temp.map((e) => e.pk));
                  setProductPkList(temp);
                }
              }}
            />
            &nbsp;&nbsp;&nbsp;
            {productPkList && productPkList.length > 0 ? (
              <>
                <hr />
                &nbsp;&nbsp;&nbsp;
                <div className="add-npc__flexrow">
                  <h5>Product</h5>
                  <h5>Price</h5>
                </div>
              </>
            ) : (
              ""
            )}
            {productPkList?.map((product: ProductType, key: number) => {
              return (
                <div className="add-npc__flexrow" key={key}>
                  <p>{product.product_name}</p>
                  <p>${(product.price / 100).toFixed(2)}</p>
                </div>
              );
            })}
            &nbsp;&nbsp;&nbsp;
            <hr />
            &nbsp;&nbsp;&nbsp;
            {productPkList && (
              <div className="add-npc__flexrow">
                <h5>Number of products: {productPkList.length}</h5>
                <h5>
                  Subtotal Price: $
                  {(
                    productPkList.reduce((acc, o) => acc + o.price, 0) / 100
                  ).toFixed(2)}
                </h5>
              </div>
            )}
            &nbsp;&nbsp;&nbsp;
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Button
                disabled={
                  productPkList && productPkList.length > 0 ? false : true
                }
                onClick={() => setShowProductForm(true)}
                variant="contained"
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="add-npc__container">
          <h5 className="add-npc__title">Pack Product Information</h5>
          {isSubmitting ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Loader size={5} variant={"block"} />
            </div>
          ) : (
            <PackProductForm
              defaultPrice={
                productPkList
                  ? productPkList.reduce((acc, o) => acc + o.price, 0)
                  : 0
              }
              customCancel={() => {
                setProductPkList(undefined);
                setShowProductForm(true);
              }}
              submitFunction={createProductPack}
              errors={errors}
            />
          )}
          <p>
            Our support team will review this product after submitting. Look out
            for an email of approval when this product goes live on the
            marketplace.
          </p>
        </div>
      )}
    </div>
  ) : (
    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <Loader size={5} variant={"block"} />
    </div>
  );
}
