import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Icon } from "../../components/Icon";
import { Loader } from "../../components/Loader";
import { Modal } from "../../components/Modal";
import { PaymentCard } from "../../components/PaymentCard";
import Popup from "../../components/Popup";
import { ThumbnailCard } from "../../components/ThumbnailCard";
import { Tooltip } from "../../components/Tooltip";
import {
  getStoreDetails,
  getStoreProducts,
  getStorePurchaseList,
} from "../../utility/MarketEndpoints";
import Get from "../../utility/Get";
import {
  ProductType,
  PurchaseHistoryType,
  StoreType,
} from "../../utility/MarketplaceTypes";
import { Button, Link } from "@mui/material";

interface SellerDashboardProps {
  store_id: number; //store pk
}

export default function SellerDashboard({
  store_id,
}: SellerDashboardProps): JSX.Element {
  const navigator = useNavigate();
  const [store, setStore] = useState<StoreType>();
  const [productList, setProductList] = useState<Array<ProductType>>();
  const [newProductModal, setNewProductModal] = useState<boolean>(false);
  const [recentSales, setRecentSales] = useState<Array<PurchaseHistoryType>>(
    []
  );

  useEffect(() => {
    const controller = new AbortController();
    //get store info
    Get(getStoreDetails(store_id), controller.signal).then((val) => {
      if (val) {
        if (val.status && val.status < 300) {
          setStore(val.data.data);
        } else if (val.status === 401) {
          navigator("/login");
        } else {
          Popup("Something went wrong. Try again later", "error");
        }
      }
    });

    //get store's product list
    Get(getStoreProducts(store_id), controller.signal).then((val) => {
      if (val) {
        if (val.status && val.status < 300) {
          setProductList(val.data.data);
        } else if (val.status === 401) {
          navigator("/login");
        } else {
          Popup("Something went wrong. Try again later", "error");
        }
      }
    });

    // get recent sales
    // Note: only need the first page
    Get(getStorePurchaseList(store_id, 1), controller.signal).then((val) => {
      if (val) {
        if (val.status && val.status < 300) {
          setRecentSales(val.data.data);
        } else if (val.status === 401) {
          navigator("/login");
        } else {
          Popup("Something went wrong. Try again later", "error");
        }
      }
    });

    return () => {
      controller.abort();
    };
  }, [store_id, navigator]);

  return store && productList ? (
    <div className="seller-dashboard">
      <Modal
        isOpen={newProductModal}
        onRequestClose={() => setNewProductModal(false)}
        title={"Product Type"}
        className="seller-dashboard__modal"
        actions={
          <>
            <Button
              onClick={() => setNewProductModal(false)}
              variant="outlined"
            >
              Cancel
            </Button>
          </>
        }
      >
        <>
          <p>Please select the type of product you would like to list.</p>
          <div className="seller-dashboard__buttons">
            <Button
              onClick={() => navigator("/product-add")}
              variant="contained"
            >
              Single Avatar
            </Button>
            <Button
              onClick={() => navigator("/product-pack-add")}
              variant="contained"
            >
              Avatar Pack
            </Button>
          </div>
        </>
      </Modal>
      {/* header with balance amount */}
      <div className="seller-dashboard__header">
        <div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h3>{store.name}</h3>
            <Tooltip
              content={"Edit Store Information"}
              id={"editstore"}
              position={"right"}
            >
              <a aria-label={`Edit store information`} href={"/account"}>
                <Icon name="edit" size="sm" />
              </a>
            </Tooltip>
          </div>

          <div className="seller-dashboard__header__balance">
            <div className="seller-dashboard__header__column">
              <div>Store Status:</div>
              {store.stripe_onboard_url ? (
                <div
                  style={{
                    backgroundColor: "yellow",
                    textAlign: "center",
                    fontWeight: "700",
                  }}
                >
                  Store Activation Required
                </div>
              ) : (
                <div
                  style={
                    store.status === "pending"
                      ? {
                          backgroundColor: "yellow",
                          textAlign: "center",
                          fontWeight: "700",
                          width: "100%",
                        }
                      : store.status === "live"
                      ? {
                          backgroundColor: "#00B000",
                          textAlign: "center",
                          fontWeight: "700",
                          width: "100%",
                        }
                      : {}
                  }
                >
                  {store.status.toUpperCase()}
                </div>
              )}
            </div>
            <div className="seller-dashboard__header__column">
              Store Visibility:
              {store.visible ? (
                <div
                  style={{
                    backgroundColor: "#00B000",
                    textAlign: "center",
                    fontWeight: "700",
                    width: "100%",
                  }}
                >
                  PUBLIC
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "yellow",
                    textAlign: "center",
                    fontWeight: "700",
                    width: "100%",
                  }}
                >
                  PRIVATE
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="seller-dashboard__header__balance">
          {store.stripe_onboard_url ? (
            <div className="seller-dashboard__header__column">
              <div>Activate your store to complete the onboarding process.</div>
              &nbsp;&nbsp;&nbsp;
              <a
                href={store.stripe_onboard_url}
                target="_blank"
                rel="noreferrer"
              >
                Store Activation
              </a>
            </div>
          ) : (
            <>
              <div className="seller-dashboard__header__column">
                <div>Available Balance</div>
                <h5>
                  ${store.balance_available ? store.balance_available : 0}
                </h5>
                &nbsp;&nbsp;&nbsp;
                {store.stripe_dashboard_url ? (
                  <a
                    href={store.stripe_dashboard_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Payouts on Stripe
                  </a>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <hr />
      {/* recent sales  */}
      <div>
        <div className="seller-dashboard__header">
          <h4>Recent Sales</h4>
        </div>
        {recentSales.length < 1 ? (
          <h3>No recent sales found</h3>
        ) : (
          <>
            {recentSales.map((purchase: PurchaseHistoryType, i) => {
              if (i <= 3) {
                //only show the first 3 sales
                return (
                  <div className="purchase-history__purchase" key={purchase.pk}>
                    <div className="purchase-history__title"></div>
                    <div className="purchase-history__view-products">
                      {purchase.purchased_products.map((product) => {
                        if (product.store.pk === store_id) {
                          return (
                            <div
                              className="purchase-history__product"
                              key={product.pk}
                            >
                              <div className="purchase-history__product-payment">
                                <div className="purchase-history__container">
                                  <p>
                                    {purchase.user.username} purchased on{" "}
                                    {moment(purchase.time_created).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </p>
                                  <div>
                                    Status:
                                    <p
                                      style={{
                                        color: "black",
                                        backgroundColor: "#00B000",
                                        textAlign: "center",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Complete
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <PaymentCard
                                price={product.price}
                                thumbnail={product.image}
                                title={product.product_name}
                                href={`/marketplace/product/${product.pk}`}
                              >
                                <>
                                  <hr />
                                  <div className="purchase-history__product-item">
                                    <p>{product.description}</p>
                                  </div>
                                </>
                              </PaymentCard>
                            </div>
                          );
                        } else return <></>;
                      })}
                    </div>
                  </div>
                );
              } else return <></>;
            })}
            {recentSales.length > 0 ? (
              <div
                style={{
                  width: "100%",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Link sx={{color: "#040666", textDecorationColor: "#040666"}} href={`/marketplace/seller/${store.pk}/sales`}>
                  View All
                </Link>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      <hr />
      <div>
        <div className="seller-dashboard__header">
          <h4>Listed Products</h4>
          <Button onClick={() => setNewProductModal(true)} variant="contained">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Icon name={"plus"} size="xs" variant="black" /> List New Product
            </div>
          </Button>
        </div>
        {productList.length > 0 ? (
          <div className="seller-dashboard__list">
            {productList.map((product, i) => {
              if (i < 4) {
                //only show the first 4 products
                return (
                  <ThumbnailCard
                    className="npc-list__item"
                    thumbnail={product.image ? (product.image as string) : ""}
                    title={product.product_name}
                    // subtitle={
                    //   product.previews && !Array.isArray(product.previews)
                    //     ? product.previews.occupation
                    //     : product.description
                    // }
                    price={product.price}
                    status={
                      product.status ? product.status.toUpperCase() : null
                    }
                    href={`/marketplace/product/${product.pk}`}
                    key={i}
                  />
                );
              } else return "";
            })}
          </div>
        ) : (
          <div>You don't have any listed products yet.</div>
        )}
      </div>
      {productList.length > 0 ? (
        <div
          style={{ width: "100%", justifyContent: "flex-end", display: "flex" }}
        >
          <Link sx={{color: "#040666", textDecorationColor: "#040666"}} href={`/marketplace/seller/${store.pk}`}>View All</Link>
        </div>
      ) : (
        <></>
      )}
      &nbsp;&nbsp;&nbsp;
    </div>
  ) : (
    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <Loader variant="block" size={5} />
    </div>
  );
}
