import moment, { MomentInput } from "moment";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Loader } from "../../../components/Loader";
import Popup from "../../../components/Popup";
import { V3UserContext } from "../../../utility/context/V3UserContext";
import Post from "../../../utility/Post";
import { Button, TextField } from "@mui/material";
import { v3UpdateOrgFields } from "../../../utility/Endpoints";
import { userOrgInfoType } from "../../../utility/Types";

export default function UpdateInfo() {
  const { user, setUser } = useContext(V3UserContext);

  const [info, setInfo] = useState<userOrgInfoType>({
    username: user?.username,
    first_name: user?.first_name,
    last_name: user?.last_name,
    date_of_birth: user?.date_of_birth,
  });

  const [isLoading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<userOrgInfoType>({
    username: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
  });

  useEffect(() => {
    if (user) {
      setInfo({
        username: user.username,
        first_name: user.first_name,
        last_name: user.last_name,
        date_of_birth: user.date_of_birth,
      });
    }
  }, [user]);

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setLoading(true);
    setErrors({
      username: "",
      first_name: "",
      last_name: "",
      date_of_birth: "",
    });

    if (user) {
      const formData = new FormData(e.target as HTMLFormElement);
      let dateError = false;

      formData.forEach((value, name) => {
        if (name === "date_of_birth") {
          if (
            moment(value as MomentInput).isAfter() ||
            moment(value as MomentInput).isBefore(
              moment().subtract(150, "years")
            )
          ) {
            dateError = true;
            return;
          }
        }
      });

      if (dateError) {
        setLoading(false);
        setErrors((prev) => ({
          ...prev,
          date_of_birth: "Please enter a valid date",
        }));
      } else {
        Post(v3UpdateOrgFields(user.pk), formData).then((res) => {
          if (res && res.status < 300) {
            Popup("Information updated!", "info");
            setInfo(res.data.data);
            setUser(res.data.data);
          } else {
            setErrors(res.data.form.errors);
          }
          setLoading(false);
        });
      }
    }
  }

  if (!user) return <Loader />;

  return (
    <div className="account__container">
      <div className="account__title">Change Account Information</div>
      {isLoading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            label="Username"
            name="username"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setInfo((prev) => ({
                ...prev,
                username: e.target.value,
              }))
            }
            value={info.username}
            helperText={errors.username as string | undefined}
            error={errors.username !== ""}
            required
          />
          <TextField
            fullWidth
            type="text"
            label="First Name"
            name="first_name"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setInfo((prev) => ({
                ...prev,
                first_name: e.target.value,
              }))
            }
            value={info.first_name}
            helperText={errors.first_name as string | undefined}
            error={errors.first_name !== ""}
            required
          />
          <TextField
            fullWidth
            type="text"
            label="Last Name"
            name="last_name"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setInfo((prev) => ({
                ...prev,
                last_name: e.target.value,
              }))
            }
            value={info.last_name}
            helperText={errors.last_name as string | undefined}
            error={errors.last_name !== ""}
            required
          />
          <TextField
            fullWidth
            type="date"
            label="Date of Birth"
            name="date_of_birth"
            defaultValue={info.date_of_birth}
            error={errors.date_of_birth !== ""}
            helperText={errors.date_of_birth as string}
            InputLabelProps={{ shrink: true }}
          />
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </form>
      )}
    </div>
  );
}
