import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY ? process.env.REACT_APP_STRIPE_PUBLIC_KEY : "");

interface PaymentProps {
  client_secret: string;
  payment_intent: string;
}

export default function Payment({client_secret, payment_intent}: PaymentProps): JSX.Element {
  const options = {
    // passing the client secret obtained in step 3
    clientSecret: client_secret,
    // Fully customizable with appearance API.
    appearance: {/*...*/},
  };
  
  
  return  (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm payment_intent={payment_intent} />
    </Elements>
  );
}