import { useEffect, useState } from "react";
import useAvatarDisplay from "../hooks/useAvatarDisplay";
import RenderEmote from "./RenderEmote";
import RenderItem from "./RenderItem";

export default function RenderAvatar() {
  const stream = useAvatarDisplay((state) => state.stream);
  const [height, setHeight] = useState<number>(0);
  const refresh = useAvatarDisplay((state) => state.refresh);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    setHeight(getHeight());

    function handleResize() {
      setHeight(getHeight());
    }

    function getHeight(): number {
      if (stream) {
        if (stream.avatar.data.asset.origin !== "char_builder") {
          let element = document.getElementById(stream.avatar.data.pk.toString());
          if (element) {
            return element.getBoundingClientRect().height;
          }
        }
      }
      return 0;
    }

    return () => window.removeEventListener("resize", handleResize);
  }, [stream, refresh]);

  return stream === null ? (
    <div>Loading...</div>
  ) : (
    <div className="render-avatar" style={{ height: `${height}px` }}>
      <RenderEmote />
      <RenderItem data={stream.avatar} parent={null} parentFrame={null} />
    </div>
  );
}
