// Endpoints relating to the marketplace

import { TagType } from "./MarketplaceTypes";

// STORES

/**
 * POST
 * creates a store object that needs to be approved by admins
 */
export function postCreateStore() {
  return `/api/v3/marketplace/store/create`;
}

/**
 * GET
 * get the store's private information (account balance, etc...)
 * @param pk store pk
 */
export function getStoreDetails(pk: number) {
  return `/api/v3/marketplace/store/${pk}/detail`;
}

/**
 * GET
 * get the store's public information
 * @param pk store pk
 */
export function getStoreDetailsPublic(pk: number | string) {
  return `/api/v3/marketplace/store/${pk}/detail/public`;
}

/**
 * GET
 * get the list of approved stores
 */
export function getStoreList() {
  return `/api/v3/marketplace/store/list`;
}

/**
 * POST
 * Update store details
 * @param {number | string} pk The store's pk
 */
export function postStoreUpdate(pk: number | string) {
  return `/api/v3/marketplace/store/${pk}/update`;
}

// PRODUCT DETAILS

/**
 * GET
 * get public product data
 * @param pk product pk
 */
export function getProductDetailsPublic(pk: string | number) {
  return `/api/v3/marketplace/product/${pk}/detail/public`;
}

/**
 * GET
 * get private product data
 * @param pk product pk
 */
export function getProductDetailsPrivate(pk: number | string) {
  return `/api/v3/marketplace/npc_product/${pk}/detail/private`;
}

// PRODUCT CREATE/UPDATE

/**
 * POST
 * create a product with an existing npc object
 * @param pk npc pk
 */
export function postCreateNpcProduct(pk: number) {
  return `/api/v3/marketplace/npc/${pk}/product/create`;
}

/**
 * POST
 * update a product npc that already exists (this is npc object + product object)
 * @param pk product npc pk
 */
export function postUpdateProductNpc(pk: number | string) {
  return `/api/v3/marketplace/npc/product/${pk}/update`;
}

/**
 * POST
 * creating a new version of a product
 * @param pk product npc pk
 */
export function postProductNpcVersionCreate(pk: number | string) {
  return `/api/v3/marketplace/npc/version/${pk}/product/create`;
}


// PRODUCT LISTS

/**
 * GET
 * get list of all marketplace products
 */
export function getMarketplaceProducts(
  search = "",
  sort = "",
  in_favorites = false,
  has_voiceover = false,
  has_art = false,
  tags: Array<TagType> = [],
  page = 0,
  packs_only = false
) {
  var tempTagString = "";
  if (tags.length > 0) {
    Object.values(tags).forEach((value) => {
      tempTagString = tempTagString + `&tags=${value.pk}`;
    });
  }
  return `/api/v3/marketplace/product/list?is_avatar_product=true&in_purchased=false${search ? `&search=${search}` : ""}${
    sort ? `&sort=${sort}` : ""
  }${in_favorites ? `&in_favorites=${in_favorites}` : ""}${
    has_voiceover ? `&has_voiceover=${has_voiceover}` : ""
  }${has_art ? `&has_art=${has_art}` : ""}${page ? `&page=${page}` : ""}${
  tags ? `${tempTagString}` : ""}${
    packs_only ? `&packs_only=${packs_only}` : ""
  }`;
}


export function getUserAssignedAvatars(user_pk: number) { //Note: not yet in use
  return `/api/v3/user/${user_pk}/avatar/list/assigned`;
}

/**
 * GET
 * get list of only npc products in marketplace
 */
export function getNpcProducts() {
  return `/api/v3/marketplace/npc/product/list`;
}

/**
 * GET
 * get list of only npc pack products in marketplace
 */
export function getNpcPacksProducts() {
  return `/api/v3/marketplace/npc_pack/product/list`;
}

/**
 * GET
 * get list of products for a store. This is private ones too (aka pending products)
 * @param pk store pk
 */
export function getStoreProducts(
  pk: number | string,
  search = "",
  sort = "",
  in_favorites = false,
  has_voiceover = false,
  has_art = false,
  tags: Array<TagType> = [],
  page = 0
) {
  var tempTagString = "";
  if (tags.length > 0) {
    Object.values(tags).forEach((value) => {
      tempTagString = tempTagString + `&tags=${value.pk}`;
    });
  }
  return `/api/v3/marketplace/store/${pk}/product/list?is_avatar_product=true${
    search ? `&search=${search}` : ""
  }${sort ? `&sort=${sort}` : ""}${
    in_favorites ? `&in_favorites=${in_favorites}` : ""
  }${has_voiceover ? `&has_voiceover=${has_voiceover}` : ""}${
    has_art ? `&has_art=${has_art}` : ""
  }${page ? `&page=${page}` : ""}${tags ? `${tempTagString}` : ""}`;
}

/**
 * GET
 * get list of public/live products for a store
 * @param pk store pk
 */
export function getPublicStoreProducts(
  pk: number | string,
  search = "",
  sort = "",
  in_favorites = false,
  has_voiceover = false,
  has_art = false,
  tags: Array<TagType> = [],
  page = 0
) {
  var tempTagString = "";
  if (tags.length > 0) {
    Object.values(tags).forEach((value) => {
      tempTagString = tempTagString + `&tags=${value.pk}`;
    });
  }
  return `/api/v3/marketplace/store/${pk}/product/list/public?is_avatar_product=true${
    search ? `&search=${search}` : ""
  }${sort ? `&sort=${sort}` : ""}${
    in_favorites ? `&in_favorites=${in_favorites}` : ""
  }${has_voiceover ? `&has_voiceover=${has_voiceover}` : ""}${
    has_art ? `&has_art=${has_art}` : ""
  }${page ? `&page=${page}` : ""}${tags ? `${tempTagString}` : ""}`;
}

/**
 * POST
 * create a payment intent for the user
 * Pass in the list of pks of products
 */
export function postCreatePaymentIntent() {
  return `/api/v3/marketplace/payment_intent/create`;
}

/**
 * POST
 * validate before a payment intent is created for the user
 * Pass in the list of pks of products
 */
export function postValidatePaymentIntent() {
  return `/api/v3/marketplace/payment_intent/valid`;
}


/**
 * Post transfer's the store's available balance to the user's bank account
 * @param pk store pk
 * 
 * No longer in use since we aren't doing instant payouts
 */
// export function postPayoutStore(pk: number) {
//   return `/api/v3/marketplace/store/${pk}/payout`;
// }

/**
 * Stripe confirm payment return to backend
 * call after a successful purchase to give access to the user
 * @param payment_intent the orginal payment intent created
 */
export function returnPaymentPurchaseAccess(payment_intent: string) {
  return `/api/v3/marketplace/purchase/${payment_intent}/detail`;
}

// FAVORITING

/**
 * GET
 * Get a list if the user has favorited the product
 * @param pk user pk
 * @param product_pk product pk
 */
export function getFavoriteProduct(pk: number, product_pk?: number) {
  return `/api/v3/user/${pk}/marketplace/favorite_product/list?${
    product_pk ? `product=${product_pk}` : ""
  }`;
}

/**
 * POST
 * create a favorite connection between user and product
 * @param pk user pk
 * @returns
 */
export function postCreateProductFavorite(pk: number) {
  return `/api/v3/user/${pk}/marketplace/favorite_product/create`;
}

/**
 * POST
 * delete a favorite connection between user and product
 * @param pk product pk
 */
export function postDeleteProductFavorite(pk: number) {
  return `/api/v3/marketplace/favorite_product/${pk}/delete`;
}

/**
 * GET
 * Get the purchase list of a user
 * @param pk user pk
 * @returns
 */
export function getUserPurchaseList(pk: number, page = 1) {
  return `/api/v3/marketplace/user/${pk}/purchase/list?page=${page}&is_avatar_product=true`;
}

/**
 * GET
 * Get the purchase list of a store
 * @param pk store pk
 * @returns
 */
export function getStorePurchaseList(pk: number, page = 1) {
  return `/api/v3/marketplace/store/${pk}/purchase/list?page=${page}&is_avatar_product=true`;
}

// Product Additional Field
/**
 * List npc's additional fields by npc pk
 * @param pk npc pk
 * @returns
 */
export function getNpcProductAdditionalFieldList(pk: number | string) {
  return `/api/v3/marketplace/npc_product/${pk}/field/list`;
}

/**
 * Delete npc's additional field by npc pk
 * @param pk npc pk
 * @returns
 */
export function postNpcProductAdditionalFieldCreate(pk: number) {
  return `/api/v3/marketplace/npc_product/${pk}/field/create`;
}

/**
 * Update npc's additional field by field pk
 * @param pk field pk
 * @returns
 */
export function postNpcProductAdditionalFieldUpdate(pk: number) {
  return `/api/v3/marketplace/npc_product/field/${pk}/update`;
}

/**
 * Delete field by field pk
 * @param pk field pk
 * @returns
 */
export function postNpcProductAdditionalFieldDelete(pk: number) {
  return `/api/v3/marketplace/npc_product/field/${pk}/delete`;
}

// DELETE Product
export function postDeleteProduct(pk: number) {
  return `/api/v3/marketplace/product/${pk}/delete`;
}

// UPDATE visibility of product
export function postUpdateProductVisibility(pk: number) {
  return `/api/v3/marketplace/product/${pk}/visible/update`;
}

/**
 * GET
 * get list single snapshot products for a store (your own store)
 */
export function getStoreSnapshotProducts(pk: number) {
  return `/api/v3/marketplace/store/${pk}/snapshot_product/list?is_avatar_product=true`;
}

// PRODUCT CREATE/UPDATE

/**
 * POST
 * create a product with an existing snapshot
 * @param pk npc pk
 */
export function postCreateSnapshotProduct(pk: number) {
  return `/api/v3/snapshot/${pk}/marketplace/product/create`;
}

/**
 * POST
 * create a pack product from products
 * @param pk npc pack pk
 */
export function postCreateNpcPackProduct() {
  return `/api/v3/marketplace/snapshot_collection_product/create`;
}

// SNAPSHOTs

export function postCreateAvatarSnapshot(avatar_pk: number) {
  return `/api/v3/snapshot/avatar/${avatar_pk}/create`;
}