import { Icon } from "../../../components/Icon";

export default function Fees(): JSX.Element {
  return (
    <div className="fees">
      <div className="fees__container">
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Icon name="subscription" size="lg" />
        &nbsp;&nbsp;&nbsp;
        <h2>Fees</h2>
      </div>

      &nbsp;&nbsp;&nbsp;
      <div className="fees__pointcontainer">
        <div className="fees__points">
          <Icon name="check" />
          &nbsp;&nbsp;&nbsp;
          <h5>No monthly fees</h5>
        </div>
        <div className="fees__points">
          <Icon name="check" />
          &nbsp;&nbsp;&nbsp;
          <h5>Secure transactions</h5>
        </div>
        <div className="fees__points">
          <Icon name="check" />
          &nbsp;&nbsp;&nbsp;
          <h5>Fast & predictable payouts</h5>
        </div>
        <div className="fees__points">
          <Icon name="check" />
          &nbsp;&nbsp;&nbsp;
          <h5>Global payment integration</h5>
        </div>
      </div>

      &nbsp;&nbsp;&nbsp;

      <h3>Simple and Secure</h3>
      &nbsp;&nbsp;&nbsp;

      <h5>14% Transaction Fee and payment processing fee</h5> 
      <p>When you sell an item, there's a small commission and standard payment processing fee.</p>
      &nbsp;&nbsp;&nbsp;
      <div className="fees__stripe">
        <h3>
          These easy payment and protection services are provided by
        </h3>
        <Icon name="stripe" size="xxl" />
      </div>
      </div>
    </div>
  );
}
