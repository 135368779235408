import { Button, Checkbox, FormControlLabel } from "@mui/material";
import ReactGA from "react-ga4";
import { SyntheticEvent, useEffect, useState } from "react";

export default function Cookies() {
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const toggleExpanded = () => setExpanded((prev) => !prev);
  const [isGAChecked, setGAChecked] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const isSet = localStorage.getItem("cookies") === null ? false : true;

  useEffect(() => {
    // Check localstorage for cookies
    if (localStorage.getItem("cookies")) {
      const cookies = JSON.parse(localStorage.getItem("cookies") as string);
      if (cookies.ga) {
        setGAChecked(true);
        ReactGA.initialize("G-SJQYP7TCT1");
      }
    }
    setLoading(false);
  }, []);

  const handleGAChecked = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    setGAChecked(checked);
  };

  const handleCookies = () => {
    const cookies = { necessary: true, session: true, ga: false };
    if (isGAChecked) {
      cookies.ga = true;
      ReactGA.initialize("G-SJQYP7TCT1");
    }
    localStorage.setItem("cookies", JSON.stringify(cookies));
    setLoading(true); // Hide cookies container after accepting
  };

  const handleAllCookies = () => {
    const cookies = { necessary: true, session: true, ga: true };
    ReactGA.initialize("G-SJQYP7TCT1");
    localStorage.setItem("cookies", JSON.stringify(cookies));
    setLoading(true); // Hide cookies container after accepting
  };

  return isLoading || isSet ? (
    <></>
  ) : (
    <div className={`cookies ${isExpanded && "cookies--expanded"}`}>
      <div className="cookies__disclaimer">
        <p>This website uses cookies to enhance your experience.</p>
        <div className="cookies__buttons">
          <Button onClick={toggleExpanded} style={{marginRight: '.4rem'}}>Manage Cookies</Button>
          {isExpanded ? (
            <Button variant="contained" onClick={handleCookies} style={{marginLeft: '.4rem'}}>
              Accept Cookies
            </Button>
          ) : (
            <Button variant="contained" onClick={handleAllCookies} style={{marginLeft: '.4rem'}}>
              Accept All Cookies
            </Button>
          )}
        </div>
      </div>
      <div
        className={`cookies__preferences ${
          isExpanded && "cookies__preferences--expanded"
        }`}
      >
        <div className="cookies__general">
          <h1>General Information</h1>
          <p>
            This website uses cookies to improve your experience while you
            navigate through the website. Out of these cookies, the cookies that
            are categorized as necessary are stored on your browser as they are
            essential for the working of basic functionalities of the website.
            We also use third-party cookies that help us analyze and understand
            how you use this website, to store user preferences and provide them
            with content and advertisements that are relevant to you. These
            cookies will only be stored on your browser with your consent to do
            so. You also have the option to opt-out of these cookies. But opting
            out of some of these cookies may have an effect on your browsing
            experience.
          </p>
        </div>

        <div className="cookies__info">
          <div className="cookies__checkbox">
            <FormControlLabel
              control={<Checkbox />}
              label={"Necessary Cookies"}
              checked={true}
              disabled
            />
          </div>
          <div className="cookies__text">
            <p>
              These cookies enable the website to provide enhanced functionality
              and personalization. They may be set by us or by third party
              providers whose services we have added to our pages. If you do not
              allow these cookies then some or all of these services may not
              function properly.
            </p>
          </div>
        </div>

        <div className="cookies__info">
          <div className="cookies__checkbox">
            <FormControlLabel
              control={<Checkbox />}
              label={"Session"}
              checked={true}
              disabled
            />
          </div>
          <div className="cookies__text">
            <p>
              The cookie is used to store and identify a users' unique session
              ID for the purpose of managing user session on the website.
            </p>
          </div>
        </div>

        <div className="cookies__info">
          <div className="cookies__checkbox">
            <FormControlLabel
              control={<Checkbox />}
              label={"Google Analytics"}
              onChange={handleGAChecked}
              checked={isGAChecked}
            />
          </div>
          <div className="cookies__text">
            <p>
              This cookie is installed by Google Analytics. The cookie is used
              to store information of how visitors use a website and helps in
              creating an analytics report of how the website is doing. The
              cookie is used to calculate visitor, session, campaign data and
              keep track of site usage for the site's analytics report. The data
              collected including the number visitors, the source where they
              have come from, and the pages visted in an anonymous form.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
