import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import { Loader } from "../../components/Loader";
import { PaymentCard } from "../../components/PaymentCard";
import Popup from "../../components/Popup";
import { CartContext } from "../../utility/context/CartContext";
import { getProductDetailsPublic, postCreatePaymentIntent, postValidatePaymentIntent } from "../../utility/MarketEndpoints";
import Get from "../../utility/Get";
import Post from "../../utility/Post";
import Payment from "./Payment";
import { PaymentType, ProductType } from "../../utility/MarketplaceTypes";
import { Button, Link } from "@mui/material";


export default function ViewCart(): JSX.Element {
  const navigator = useNavigate();
  const [checkout, setCheckout] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [subtotal, setSubTotal] = useState<number>(0);
  const [cartError, setCartError] = useState<string | undefined>();
  const [clientSecret, setClientSecret] = useState<PaymentType>();
  const { cart, setCart } = useContext(CartContext);

  useEffect(() => {
    const controller = new AbortController();

    //get cart from local (which SHOULD be the same as our cart)
    var tempcart = localStorage.getItem("vstreamer-cart-items")
      ? JSON.parse(localStorage.getItem("vstreamer-cart-items") ?? "")
      : []

    // for each item in cart, get updated details from backend
    var cart: Array<ProductType> = [];
    var subtotal: number = 0; //initial subtotal count

    if (tempcart.length < 1) {
      setLoading(false);
    } else if (tempcart.length >= 1) {
      tempcart.map((item: ProductType) => {
        Get(getProductDetailsPublic(item.pk), controller.signal).then(res => {
          if (res && res.status < 300) {
            cart.push(res.data.data);
            subtotal += res.data.data.price;
          } else if (res.status === 401) {
            navigator("/login");
          } else {
            if (res.status === 404) {
              // handle not found product by removing it and prompting user
              var newCart = cart.filter(x => x.pk !== item.pk)
              setCart(newCart)
              localStorage.setItem("vstreamer-cart-items", JSON.stringify(newCart))
              Popup("Oh! Looks like one of the items in your cart is no longer available.", "error")
            } else {
              Popup("There was an issue getting your cart items", "error");
            }
          }
          setSubTotal(subtotal);
          setCart(cart);
          setLoading(false);
        })
          .catch(() => { });
        return ""
      })
    } else {
      Popup("Oh! Something went wrong. Please contact our support team.", "error");
    }

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // calc subtotal when cart changes
    setSubTotal(cart.reduce((x, o) => x + o.price, 0));

    validatePaymentIntent()
    // eslint-disable-next-line
  }, [cart])

  function updateCart(id: number) {
    // Deletes item from the cart
    var newCart = cart.filter(x => x.pk !== id)
    setCart(newCart)
    localStorage.setItem("vstreamer-cart-items", JSON.stringify(newCart))
    Popup("Item has been removed from cart");
  }

  function validatePaymentIntent() {
    setCartError(undefined)
    if (cart.length !== 0) {
      var productPkList = cart.map(product => {
        return product.pk
      })

      Post(postValidatePaymentIntent(), { products: productPkList }).then(res => {
        if (res && res.status < 300) {
          //cart is good. Keep moving on
        } else if (res.status === 401) {
          navigator("/login");
        } else {
          if (res.status === 422) {
            if (res.data && res.data.form && res.data.form.errors && Object.values(res.data.form.errors)) {
              setCartError(`${Object.values(res.data.form.errors)[0]}`)
            }
            else {
              setCartError("There is a problem with the items in your cart. Please remove all items and try again later")
            }
          } else {
            Popup("There was an issue. Please try again later", "error");
          }
        }
      })
    }
  }

  function createPaymentIntent() {
    setLoading(true);

    var productPkList = cart.map(product => {
      return product.pk
    })
    //send to backend and save client secret
    Post(postCreatePaymentIntent(), { products: productPkList }).then(res => {
      if (res && res.status < 300) {
        setCheckout(true);
        setClientSecret(res.data.data);
        setCart(res.data.data.products);
        // update cart with newest information with products
      } else if (res.status === 401) {
        navigator("/login");
      } else {
        if (res.status === 404) {

        } else {
          Popup("There was an issue. Please try again later", "error");
        }
      }

      setLoading(false);
    })
  }

  return !isLoading ? (
    <div className="cart">
      {checkout ? (
        <div>
          {clientSecret ? (
            //cart info updated from backend
            <div className="cart__payment">
              &nbsp;&nbsp;&nbsp;
              <h3>Checkout</h3>
              &nbsp;&nbsp;&nbsp;
              <hr />
              &nbsp;&nbsp;&nbsp;
              <div className="cart__payment-checkout">
                <Payment
                  client_secret={clientSecret.client_secret}
                  payment_intent={clientSecret.payment_intent_id}
                />
                &nbsp;&nbsp;&nbsp;
                <div className="cart__checkout-section">
                  <div style={{ fontWeight: "600" }}>Order Summary</div>
                  <div className="cart__checkout-line">
                    <p>Number of items: </p>
                    <p>{clientSecret.products.length}</p>
                  </div>
                  <div className="cart__checkout-line">
                    <p>Subtotal: </p>
                    <p>${clientSecret.subtotal / 100}</p>
                  </div>
                  {/* <div className="cart__checkout-line">
                    <p>tax: </p>
                  </div> */}
                  <hr />
                  <div className="cart__checkout-line">
                    <p>Total: </p>
                    <p>${clientSecret.subtotal ? clientSecret.subtotal / 100 : subtotal / 100}</p>
                  </div>
                </div>
              </div>
              &nbsp;&nbsp;&nbsp;
              <hr />
              &nbsp;&nbsp;&nbsp;
              <div className="cart__list full-list">
                {cart.map((item, index) => {
                  return (
                    <PaymentCard
                      price={item.price}
                      title={item.product_name}
                      thumbnail={typeof(item.image) === "string" ? item.image : ""}
                      key={index}
                      href={`/marketplace/product/${item.pk}`}
                    >
                      <div className="cart__list-item">
                        <p>{item.description}</p>
                        <p>Store: <Link sx={{color: "#040666", textDecorationColor: "#040666"}} href={`/marketplace/seller/${item.store.pk}`}>{item.store.name}</Link></p>
                        <Button
                          onClick={() => updateCart(item.pk)}
                          variant="outlined"
                        >
                          Remove
                        </Button>
                      </div>
                    </PaymentCard>
                  )
                })}
              </div>
            </div>
          ) : (
            <div>There was a problem with your cart. Please try again later or contact our support team.</div>
          )}
        </div>
      ) : (
        // normal shopping cart with local and context data
        <>
          <div className="cart__title">
            <h3>Shopping Cart</h3>
            <Button
              onClick={() => navigator("/marketplace")}
              variant="contained"
              color="secondary"
            >
              Keep shopping
            </Button>
          </div>

          <hr />

          {cartError !== undefined && (
            <div className="cart__errorMsg">{cartError}</div>
          )}

          {cart && cart.length > 0 ? (
            <div className="cart__content">
              <div className="cart__list">
                {cart.map((item, index) => {
                  return (
                    <PaymentCard
                      price={item.price}
                      thumbnail={typeof(item.image) === "string" ? item.image : ""}
                      title={item.product_name}
                      key={index}
                      href={`/marketplace/product/${item.pk}`}
                    >
                      <div className="cart__list-item">
                        <p>{item.description}</p>
                        <p>Store: <Link sx={{color: "#040666", textDecorationColor: "#040666"}} href={`/marketplace/seller/${item.store.pk}`}>{item.store.name}</Link></p>
                        <Button
                          onClick={() => updateCart(item.pk)}
                          variant="outlined"
                        >
                          Remove
                        </Button>
                      </div>
                    </PaymentCard>
                  )
                })}
              </div>
              <div className="cart__checkout-section">
                <div className="cart__checkout-line">
                  <p>Number of items: </p>
                  <p>{cart.length}</p>
                </div>
                <div className="cart__checkout-line">
                  <p>Subtotal: </p>
                  <p>${subtotal / 100}</p>
                </div>
                <Button disabled={cartError !== undefined} variant="contained" onClick={createPaymentIntent}>Proceed to checkout</Button>
              </div>
            </div>
          ) : (
            <div className="cart__no-items">
              <h4>You have no items in your cart</h4>
              &nbsp;&nbsp;&nbsp;
              <Button variant="contained" onClick={() => navigator("/marketplace")}>Go to Marketplace</Button>
            </div>
          )}
        </>
      )}

    </div>
  ) : (
    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <Loader variant="block" size={5} />
    </div>
  );
}