import clsx from "clsx";

/**
 * General
 */
const Back = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 334.08 341.28">
    <path
      d="M109.533,172.75c7.394-0.416,14.787-0.845,22.183-1.239c2.627-0.14,5.257-0.205,7.887-0.285
	c7.875-0.24,15.751-0.486,23.627-0.7c9.919-0.269,19.838-0.518,29.757-0.757c10.04-0.242,20.08-0.476,30.12-0.689
	c12.42-0.264,24.84-0.499,37.26-0.752c9.68-0.197,19.361-0.331,29.037-0.642c3.567-0.115,7.12-0.669,10.681-1.002
	c1.66-0.155,3.323-0.319,4.988-0.365c0.683-0.019,1.374,0.264,2.061,0.409c-0.254,0.634-0.389,1.357-0.782,1.887
	c-2.064,2.786-4.101,5.6-6.323,8.258c-2.994,3.582-6.262,6.937-9.192,10.568c-1.97,2.441-4.518,3.435-7.396,3.901
	c-3.533,0.572-7.077,1.093-10.629,1.527c-6.987,0.855-13.98,1.663-20.976,2.438c-6.408,0.71-12.835,1.255-19.232,2.046
	c-8.036,0.993-16.05,2.176-24.076,3.252c-4.721,0.633-9.455,1.177-14.173,1.835c-4.962,0.692-9.907,1.505-14.868,2.207
	c-5.189,0.734-10.386,1.407-15.582,2.092c-2.838,0.374-5.677,0.739-8.521,1.068c-4.858,0.562-9.725,1.049-14.578,1.647
	c-5.43,0.67-10.846,1.447-16.273,2.144c-3.899,0.5-7.808,0.92-11.704,1.444c-0.493,0.066-0.929,0.554-1.392,0.847
	c0.278,0.359,0.496,0.794,0.842,1.066c3.762,2.956,7.579,5.84,11.312,8.831c9.994,8.007,19.775,16.259,29.086,25.071
	c3.996,3.781,8.326,7.205,12.404,10.901c3.278,2.971,6.461,6.054,9.582,9.191c2.37,2.382,3.609,5.323,3.62,8.738
	c0.007,2.252,0.136,4.507,0.036,6.754c-0.061,1.361-0.491,1.492-1.51,0.836c-10.852-6.983-21.863-13.653-33.398-19.501
	c-14.366-7.282-28.419-15.183-42.675-22.687c-12.357-6.505-24.878-12.701-37.198-19.273c-9.79-5.222-19.414-10.758-29.051-16.262
	c-2.795-1.596-5.394-3.542-8.06-5.361c-3.448-2.352-3.751-4.369-0.71-7.195c4.554-4.233,9.19-8.392,13.992-12.337
	c6.387-5.247,12.607-10.655,18.495-16.47c4.51-4.453,9.291-8.63,13.962-12.919c9.613-8.826,19.278-17.596,28.838-26.479
	c10.611-9.858,21.154-19.789,31.674-29.746c3.652-3.456,7.135-7.092,10.714-10.626c1.192-1.177,2.417-2.325,3.685-3.418
	c0.315-0.271,0.856-0.279,1.293-0.408c0.081,0.343,0.214,0.682,0.237,1.029c0.259,3.936,0.214,7.919,0.822,11.799
	c0.753,4.805-0.554,8.997-2.819,13.03c-7.622,13.57-15.518,26.963-24.637,39.607c-5.249,7.277-10.198,14.772-15.278,22.171
	c-0.27,0.394-0.518,0.805-0.756,1.22c-0.477,0.831-0.317,1.358,0.723,1.381c0.956,0.021,1.912,0.005,2.868,0.005
	C109.532,172.809,109.533,172.779,109.533,172.75z"
    />
  </svg>
);

const FavoriteHeart = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.68 79.68">
    <path
      d="M24.806,12.693c5.309,0.239,10.109,1.851,14.156,5.393c0.569,0.498,0.825,0.408,1.338-0.023
	c4.686-3.941,10.096-5.705,16.202-5.251c2.669,0.199,4.98,1.365,6.992,3.038c5.622,4.675,8.754,10.73,9.033,18.02
	c0.215,5.61-2.058,10.366-6.167,14.218c-3.328,3.12-7.234,5.472-10.843,8.219c-4.22,3.211-8.414,6.447-12.017,10.369
	c-1.131,1.231-2.222,2.504-3.269,3.808c-0.501,0.624-0.766,0.52-1.247-0.023c-2.219-2.502-4.297-5.127-6.828-7.352
	c-4.645-4.084-9.638-7.701-14.655-11.297c-3.08-2.208-6.107-4.509-8.05-7.879c-4.632-8.035-3.11-15.683,1.645-23.022
	c1.858-2.869,4.288-5.233,7.376-6.831C20.461,13.05,22.58,12.654,24.806,12.693z"
    />
  </svg>
);

const Settings = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 334.08 341.28">
    <path
      d="M289.396,141.024c-0.07,1.076-0.076,2.161-0.222,3.227c-0.285,2.076-1.163,3.809-2.758,5.269
	c-2.518,2.305-5.483,3.772-8.536,5.163c-6.384,2.909-12.734,5.895-19.1,8.845c-1.483,0.687-2.106,1.821-1.964,3.462
	c0.155,1.789,0.381,3.595,0.278,5.377c-0.144,2.494-0.571,4.972-0.864,7.458c-0.309,2.617-0.044,3.036,2.398,3.927
	c4.467,1.631,8.917,3.31,13.407,4.875c1.789,0.623,3.681,0.943,5.499,1.49c7.764,2.336,10.298,6.673,8.109,14.355
	c-1.27,4.455-2.714,8.888-4.468,13.17c-3.085,7.535-7.337,14.464-12.044,21.071c-1.271,1.784-3.304,3.089-5.154,4.371
	c-2.019,1.399-4.377,0.899-6.607,0.532c-1.044-0.172-2.054-0.628-3.044-1.039c-5.935-2.463-11.853-4.968-17.792-7.422
	c-3.587-1.483-3.574-1.324-6.041,1.418c-2.466,2.742-5.113,5.337-7.844,7.815c-2.236,2.029-2.448,2.125-1.407,4.809
	c2.29,5.902,4.633,11.783,6.952,17.674c0.088,0.223,0.138,0.46,0.226,0.682c2.543,6.484,1.321,10.757-4.56,14.413
	c-9.992,6.212-20.728,10.676-32.294,12.963c-4.509,0.892-7.971-0.863-10.105-4.915c-0.552-1.048-0.93-2.194-1.336-3.314
	c-2.033-5.608-4.021-11.233-6.082-16.831c-1.002-2.722-1.208-2.651-4.223-2.466c-3.21,0.198-6.444,0.212-9.659,0.082
	c-2.837-0.114-3.054-0.233-4.108,2.257c-2.231,5.271-4.34,10.594-6.498,15.896c-0.539,1.325-0.95,2.72-1.635,3.965
	c-2.533,4.607-5.896,5.885-11.037,4.635c-9.481-2.305-18.256-6.204-26.304-11.68c-1.718-1.169-3.021-3.003-4.372-4.649
	c-0.948-1.154-1.199-2.614-0.806-4.089c0.52-1.953,0.963-3.946,1.698-5.82c2.132-5.437,4.43-10.809,6.588-16.236
	c1.068-2.685,0.892-2.738-1.04-4.613c-2.306-2.238-4.515-4.584-6.669-6.971c-1.725-1.912-1.679-2.008-3.823-0.948
	c-5.132,2.538-10.215,5.177-15.373,7.661c-6.219,2.994-8.715,1.91-12.074-3.258c-2.454-3.776-4.19-8.047-6.022-12.197
	c-2.224-5.037-3.542-10.388-4.466-15.795c-1.311-7.667,0.585-9.919,6.097-12.867c4.721-2.525,9.515-4.926,14.369-7.182
	c2.386-1.109,3.374-2.523,3.144-5.261c-0.28-3.328-0.004-6.703,0.029-10.057c0.024-2.473-0.039-2.699-2.166-3.519
	c-4.666-1.798-9.34-3.581-14.056-5.24c-6.813-2.396-8.763-4.552-7.263-12.716c1.052-5.728,2.66-11.403,4.576-16.907
	c1.68-4.828,3.982-9.464,6.322-14.022c0.954-1.858,2.653-3.374,4.182-4.871c0.614-0.601,1.663-0.945,2.551-1.036
	c2.423-0.249,4.671,0.475,6.93,1.378c4.867,1.946,9.813,3.693,14.724,5.528c1.489,0.557,2.411-0.083,3.341-1.256
	c2.522-3.179,5.099-6.321,7.793-9.353c1.374-1.546,1.615-2.946,0.794-4.867c-2.346-5.49-4.524-11.053-6.762-16.589
	c-0.134-0.331-0.216-0.683-0.335-1.022c-2.107-6.025-0.933-10.184,3.917-14.411c8.423-7.34,17.77-13.165,28.11-17.357
	c1.528-0.619,3.21-0.876,4.838-1.222c3.295-0.7,5.531,0.82,7.069,3.59c0.578,1.041,1.049,2.154,1.455,3.275
	c2.117,5.851,4.173,11.724,6.3,17.571c1.166,3.207,1.43,3.301,4.714,2.355c3.343-0.963,6.734-1.552,10.223-1.131
	c1.811,0.218,2.989-0.509,3.643-2.174c0.612-1.557,1.238-3.109,1.82-4.677c2.118-5.7,4.088-11.46,6.389-17.084
	c1.028-2.511,2.561-4.862,4.166-7.065c0.694-0.952,2.086-1.52,3.273-1.958c3.465-1.28,7.055-0.922,10.618-0.494
	c5.052,0.608,10.072,1.608,14.83,3.306c4.806,1.715,9.455,3.997,13.923,6.484c1.736,0.966,2.91,3.089,4.09,4.853
	c0.453,0.677,0.384,1.848,0.251,2.749c-0.53,3.579-1.949,6.853-3.432,10.137c-2.8,6.197-5.363,12.502-8.073,18.74
	c-0.558,1.284-0.499,2.301,0.679,3.136c3.54,2.506,6.587,5.54,9.32,8.89c1.062,1.301,2.179,1.207,3.473,0.505
	c5.963-3.236,11.959-6.41,17.884-9.712c3.237-1.804,6.374-3.706,10.181-4.164c1.426-0.172,2.584,0.061,3.709,0.811
	c2.204,1.469,4.141,3.161,5.536,5.508c5.396,9.081,9.363,18.701,11.273,29.131c0.341,1.862,0.645,3.73,0.966,5.595
	C289.553,140.99,289.474,141.007,289.396,141.024z M204.798,177.455c-0.189-0.497-0.353-0.938-0.523-1.376
	c-0.562-1.442-1.267-2.845-1.666-4.331c-1.396-5.201-3.318-10.193-5.548-15.084c-0.939-2.058-1.128-4.778-2.612-6.182
	c-1.583-1.498-4.294-1.794-6.51-2.621c-0.335-0.125-0.651-0.301-0.986-0.425c-2.899-1.068-5.785-2.174-8.705-3.18
	c-2.802-0.966-5.666-1.756-8.451-2.766c-1.12-0.406-2.056-0.447-3.03,0.214c-0.691,0.469-1.36,0.985-2.094,1.374
	c-6.934,3.672-13.874,7.332-20.828,10.966c-1.121,0.585-1.955,1.28-2.176,2.624c-0.133,0.81-0.405,1.629-0.777,2.362
	c-3.625,7.151-5.796,14.878-8.83,22.261c-0.391,0.952-0.371,1.718,0.015,2.708c2.939,7.545,5.833,15.109,8.607,22.716
	c0.457,1.254,1.042,2.107,2.218,2.567c5.958,2.33,11.926,4.638,17.883,6.971c1.772,0.694,3.546,2.065,5.276,1.999
	c1.852-0.071,3.668-1.442,5.475-2.297c5.046-2.387,10.086-4.789,15.116-7.211c2.038-0.981,4.476-1.616,5.941-3.163
	c1.458-1.539,1.918-4.026,2.824-6.094c1.866-4.257,3.772-8.496,5.592-12.773c1.072-2.52,2.005-5.099,3.017-7.645
	C204.244,178.524,204.532,178.007,204.798,177.455z"
    />
  </svg>
);

const ChevronDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 334.08 341.28">
    <path
      d="M290.928,141.886c1.014,0.488,1.984,1.128,3.05,1.439c8.953,2.607,17.676,5.856,26.295,9.394
	c1.048,0.43,2.287,0.631,2.321,2.291c0.042,2.026-0.051,2.462-1.939,3.287c-3.612,1.578-7.263,3.065-10.872,4.651
	c-23.788,10.45-46.682,22.617-68.857,36.146c-19.604,11.961-38.809,24.54-57.601,37.745c-4.115,2.891-8.346,5.508-13.074,7.297
	c-1.681,0.636-3.239,0.774-4.834-0.041c-3.387-1.729-7.008-3.126-10.104-5.277c-28.5-19.798-57.465-38.848-87.934-55.51
	c-16.79-9.181-34.063-17.366-51.697-24.788c-1.06-0.446-2.345-0.597-2.605-2.115c-0.333-1.948,0.014-2.807,2.025-3.569
	c6.698-2.536,13.417-5.016,20.146-7.468c3.337-1.216,6.713-2.325,10.072-3.482c1.436,0,2.873,0,4.309,0
	c2.753,1.361,5.582,2.591,8.245,4.112c5.487,3.134,10.938,6.339,16.312,9.663c22.734,14.06,45.508,28.056,68.103,42.337
	c7.421,4.69,15.386,8.015,23.436,11.272c1.238,0.501,2.491,0.672,3.793,0.157c6.748-2.666,13.632-5.076,19.827-8.916
	c15.824-9.81,31.457-19.932,47.371-29.592c16.274-9.878,32.031-20.648,49.186-29.033
	C287.577,141.886,289.252,141.886,290.928,141.886z"
    />
  </svg>
);

const ChevronUp = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 334.08 341.28">
    <path
      d="M45.312,225.314c-1.419-0.563-2.803-1.254-4.264-1.67c-8.626-2.456-16.994-5.63-25.28-9.033
	c-1.282-0.526-2.728-0.731-2.785-2.735c-0.059-2.048,1.188-2.547,2.677-3.227c12.603-5.757,25.342-11.249,37.726-17.449
	c25.376-12.706,49.507-27.581,73.112-43.311c9.749-6.497,19.309-13.279,29.095-19.718c3.156-2.076,6.757-3.475,10.158-5.176
	c1.374-0.687,2.699-0.518,4.15-0.035c4.357,1.448,8.218,3.787,11.945,6.381c31.334,21.801,63.246,42.663,97.204,60.219
	c13.278,6.865,26.827,13.143,40.584,18.981c1.032,0.438,2.322,1.019,2.777,1.903c0.857,1.665,0.02,3.173-1.807,3.917
	c-8.738,3.561-17.561,6.885-26.633,9.516c-1.066,0.309-2.036,0.951-3.05,1.439c-1.676,0-3.351,0-5.027,0
	c-3.565-1.843-7.265-3.467-10.675-5.564c-27.853-17.124-55.69-34.273-83.44-51.563c-7.037-4.384-14.644-7.383-22.289-10.364
	c-0.909-0.354-2.199-0.43-3.071-0.062c-6.46,2.722-13.25,4.937-19.205,8.527c-20.539,12.384-40.718,25.366-61.158,37.916
	c-11.961,7.344-23.597,15.274-36.435,21.109C48.185,225.314,46.748,225.314,45.312,225.314z"
    />
  </svg>
);

const ChevronLeft = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 334.08 341.28">
    <path
      d="M197.521,51.76c-0.682,1.983-1.144,4.116-2.113,5.983c-2.417,4.659-4.905,9.297-7.646,13.768
	c-16.103,26.265-32.305,52.469-48.43,78.72c-3.948,6.426-6.482,13.521-9.204,20.52c-0.507,1.305-0.302,2.547,0.197,3.8
	c3.22,8.085,6.559,16.061,11.218,23.504c14.189,22.671,28.108,45.515,42.076,68.326c3.552,5.8,6.915,11.719,10.316,17.611
	c0.773,1.339,1.336,2.8,2.015,4.195c1.436,2.948,1.358,5.802,0.388,9c-2.883,9.508-6.145,18.865-9.884,28.059
	c-0.894,2.198-1.193,2.272-3.055,2.289c-1.541,0.014-2.017-0.975-2.505-2.107c-2.367-5.488-4.707-10.989-7.126-16.453
	c-11.214-25.326-24.461-49.562-39.28-72.897c-11.02-17.354-22.639-34.322-33.856-51.551c-1.997-3.067-3.338-6.568-4.973-9.873
	c-0.736-1.487-0.601-2.92-0.056-4.5c1.456-4.225,3.649-8.033,6.161-11.668c25.443-36.813,49.552-74.434,68.543-115.14
	c3.638-7.798,6.955-15.747,10.446-23.614c0.455-1.026,0.548-2.355,2.176-2.469c2.088-0.147,2.668,0.083,3.49,2.127
	c3.523,8.764,6.861,17.595,9.404,26.711C196.331,47.924,196.91,49.729,197.521,51.76z"
    />
  </svg>
);
const ThenIcon = () => (
  <svg
    width="48"
    height="65"
    viewBox="0 0 48 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_153_483"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="5"
      y="18"
      width="39"
      height="28"
    >
      <path d="M5 18H43.8909V45.9681H5V18Z" fill="white" />
    </mask>
    <g mask="url(#mask0_153_483)">
      <mask
        id="mask1_153_483"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="18"
        width="39"
        height="28"
      >
        <path
          d="M43.2068 30.686L29.0907 18.2399C28.8293 18.0097 28.4573 17.9537 28.1399 18.0972C27.8225 18.2404 27.6185 18.5564 27.6185 18.9045V23.5099C27.6185 24.0305 27.4118 24.5296 27.0438 24.8977C26.6757 25.2657 26.1766 25.4724 25.656 25.4724H6.96248C6.44197 25.4724 5.94279 25.6791 5.57474 26.0472C5.20669 26.4152 5 26.9144 5 27.4349V36.5334C5 37.0535 5.20669 37.5527 5.57474 37.9207C5.94279 38.2888 6.44197 38.4955 6.96248 38.4955H25.656C26.1766 38.4955 26.6757 38.7026 27.0438 39.0707C27.4118 39.4383 27.6185 39.9374 27.6185 40.4579V45.0634C27.6185 45.4115 27.8225 45.7275 28.1399 45.8711C28.4573 46.0142 28.8293 45.9582 29.0907 45.728L43.2068 33.2819C43.5793 32.9534 43.7926 32.4805 43.7926 31.9839C43.7926 31.4874 43.5793 31.0145 43.2068 30.686Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_153_483)">
        <path d="M5 16.9424H44.7199V47.0263H5V16.9424Z" fill="#FF8605" />
      </g>
    </g>
    <path
      d="M14.0954 10.9584C13.925 10.9584 13.8006 10.9406 13.7223 10.905C13.644 10.8654 13.6048 10.806 13.6048 10.7268V4.35412H11.2418C11.1543 4.35412 11.0921 4.32838 11.0553 4.27691C11.0184 4.22544 11 4.14625 11 4.03935V3.86711C11 3.75625 11.0184 3.67706 11.0553 3.62955C11.0921 3.57807 11.1543 3.55234 11.2418 3.55234H17.0043C17.0918 3.55234 17.154 3.57807 17.1908 3.62955C17.2277 3.67706 17.2461 3.75625 17.2461 3.86711V4.03935C17.2461 4.14625 17.2277 4.22544 17.1908 4.27691C17.154 4.32838 17.0918 4.35412 17.0043 4.35412H14.6896V10.7268C14.6896 10.8812 14.5238 10.9584 14.1921 10.9584H14.0954Z"
      fill="#FF8605"
    />
    <path
      d="M19.1185 10.9584C18.7915 10.9584 18.628 10.8812 18.628 10.7268V3.23163C18.628 3.07721 18.7915 3 19.1185 3H19.1669C19.4985 3 19.6644 3.07721 19.6644 3.23163V6.08241C19.9039 5.87256 20.1849 5.70032 20.5073 5.5657C20.8297 5.42712 21.1729 5.35783 21.5368 5.35783C22.0527 5.35783 22.5064 5.48255 22.898 5.732C23.2941 5.98144 23.4922 6.42489 23.4922 7.06236V10.7268C23.4922 10.8812 23.3286 10.9584 23.0016 10.9584H22.9532C22.6216 10.9584 22.4558 10.8812 22.4558 10.7268V7.15145C22.4558 6.83073 22.3498 6.57931 22.1379 6.39718C21.926 6.21505 21.6151 6.12398 21.2052 6.12398C20.9334 6.12398 20.6823 6.17941 20.452 6.29027C20.2263 6.40114 20.0375 6.5219 19.8855 6.65256C19.7381 6.77926 19.6644 6.85053 19.6644 6.86637V10.7268C19.6644 10.8812 19.4985 10.9584 19.1669 10.9584H19.1185Z"
      fill="#FF8605"
    />
    <path
      d="M28.1353 11C27.1173 11 26.3596 10.7723 25.8621 10.317C25.3692 9.85771 25.1228 9.15689 25.1228 8.21455C25.1228 7.28013 25.3646 6.57139 25.8483 6.08834C26.3319 5.60134 27.0436 5.35783 27.9833 5.35783C28.8216 5.35783 29.4527 5.59144 29.8764 6.05865C30.3048 6.52586 30.519 7.21084 30.519 8.11359C30.519 8.15318 30.4937 8.20861 30.443 8.27988C30.3969 8.34719 30.3394 8.40856 30.2703 8.46399C30.2058 8.51547 30.1505 8.5412 30.1044 8.5412H26.2283C26.2789 9.13116 26.454 9.56273 26.7534 9.83593C27.0574 10.1052 27.5134 10.2398 28.1214 10.2398C28.4623 10.2398 28.7548 10.2042 28.9989 10.1329C29.2477 10.0616 29.4872 9.97451 29.7175 9.87157C29.7405 9.86365 29.7751 9.84979 29.8212 9.82999C29.8672 9.8102 29.8995 9.8003 29.9179 9.8003C29.9686 9.8003 30.0261 9.83593 30.0906 9.9072C30.1597 9.97451 30.2173 10.0517 30.2634 10.1388C30.314 10.222 30.3394 10.2833 30.3394 10.3229C30.3394 10.41 30.238 10.5071 30.0353 10.614C29.8327 10.7209 29.5609 10.8119 29.22 10.8872C28.8838 10.9624 28.5222 11 28.1353 11ZM29.4204 7.78099C29.4204 6.67236 28.9298 6.11804 27.9487 6.11804C26.8939 6.11804 26.3204 6.67236 26.2283 7.78099H29.4204Z"
      fill="#FF8605"
    />
    <path
      d="M32.6333 10.9584C32.4582 10.9584 32.3316 10.9406 32.2533 10.905C32.1749 10.8654 32.1358 10.806 32.1358 10.7268V7.02079C32.1358 6.56149 32.1105 6.23088 32.0598 6.02895C32.0091 5.82306 31.9838 5.71022 31.9838 5.69042C31.9838 5.60332 32.0598 5.54195 32.2118 5.50631C32.3684 5.46672 32.5158 5.44692 32.654 5.44692C32.7692 5.44692 32.8544 5.50037 32.9096 5.60728C32.9649 5.71418 33.0041 5.82306 33.0271 5.93393C33.0547 6.04479 33.0732 6.11606 33.0824 6.14774C33.3403 5.89829 33.6374 5.70428 33.9737 5.5657C34.3146 5.42712 34.6577 5.35783 35.0032 5.35783C35.6296 5.35783 36.1179 5.51027 36.468 5.81514C36.8227 6.11606 37 6.5318 37 7.06236V10.7268C37 10.806 36.9608 10.8654 36.8825 10.905C36.8042 10.9406 36.6799 10.9584 36.5094 10.9584H36.4542C36.2883 10.9584 36.1663 10.9406 36.088 10.905C36.0097 10.8654 35.9705 10.806 35.9705 10.7268V7.15145C35.9705 6.82678 35.86 6.57535 35.6389 6.39718C35.4224 6.21505 35.116 6.12398 34.7199 6.12398C34.4435 6.12398 34.1649 6.19129 33.8839 6.32591C33.6075 6.45657 33.368 6.63672 33.1653 6.86637V10.7268C33.1653 10.806 33.1261 10.8654 33.0478 10.905C32.9695 10.9406 32.8475 10.9584 32.6816 10.9584H32.6333Z"
      fill="#FF8605"
    />
  </svg>
);
const ChevronRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 334.08 341.28">
    <path
      d="M115.966,48.989c0.484-1.017,1.121-1.988,1.429-3.057c2.589-8.974,5.816-17.717,9.33-26.356
	c0.427-1.051,0.626-2.292,2.275-2.326c2.012-0.042,2.445,0.051,3.264,1.944c1.567,3.62,3.044,7.28,4.619,10.897
	c10.378,23.843,22.461,46.79,35.897,69.015c11.879,19.65,24.371,38.899,37.485,57.734c2.871,4.124,5.47,8.365,7.247,13.104
	c0.631,1.684,0.768,3.247-0.041,4.846c-1.717,3.395-3.104,7.024-5.24,10.128c-19.661,28.566-38.581,57.598-55.127,88.137
	c-9.118,16.829-17.247,34.141-24.618,51.817c-0.443,1.063-0.593,2.351-2.1,2.611c-1.935,0.334-2.788-0.014-3.544-2.03
	c-2.518-6.713-4.981-13.448-7.417-20.192c-1.208-3.345-2.309-6.729-3.458-10.095c0-1.44,0-2.879,0-4.319
	c1.352-2.76,2.573-5.595,4.083-8.264c3.112-5.5,6.296-10.963,9.597-16.35c13.963-22.786,27.863-45.613,42.045-68.261
	c4.658-7.438,7.96-15.421,11.194-23.49c0.497-1.24,0.667-2.497,0.156-3.802c-2.648-6.763-5.041-13.664-8.855-19.873
	c-9.742-15.861-19.795-31.53-29.388-47.48c-9.81-16.311-20.506-32.105-28.833-49.299C115.966,52.348,115.966,50.668,115.966,48.989z
	"
    />
  </svg>
);

const Minus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 334.08 341.28">
    <path
      d="M265.678,213.075c-3.587,0-7.174,0-10.761,0c-0.371-0.231-0.733-0.646-1.115-0.665c-9.294-0.46-18.589-0.899-27.886-1.297
	c-8.106-0.347-16.216-0.61-24.322-0.966c-8.577-0.376-17.15-0.858-25.728-1.219c-8.466-0.356-16.939-0.556-25.404-0.94
	c-7.622-0.346-15.235-0.903-22.857-1.261c-4.773-0.225-9.558-0.166-14.331-0.39c-10.601-0.497-21.196-1.13-31.797-1.629
	c-5.13-0.242-10.268-0.295-15.402-0.46c-2.549-0.082-2.925-0.373-3.408-2.724c-1.73-8.428-3.437-16.861-5.144-25.294
	c-0.377-1.863-0.362-1.907,1.257-1.971c5.84-0.233,11.68-0.467,17.522-0.643c8.708-0.262,17.417-0.493,26.126-0.703
	c9.431-0.228,18.863-0.438,28.295-0.617c8.479-0.161,16.959-0.283,25.439-0.399c7.644-0.105,15.293-0.368,22.931-0.184
	c3.734,0.09,7.377,0.07,11.002-0.839c0.687-0.172,1.419-0.209,2.132-0.227c10.859-0.262,21.719-0.506,32.579-0.765
	c6.684-0.159,13.368-0.329,20.051-0.508c9.189-0.246,18.377-0.513,27.566-0.751c1.611-0.042,1.649,0.013,1.291,1.875
	c-2.033,10.564-4.101,21.122-6.121,31.688C266.902,205.805,266.314,209.444,265.678,213.075z"
    />
  </svg>
);

const Plus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.68 79.68">
    <path
      d="M35.19,21.236c0-4.074-0.003-8.148,0.004-12.222c0.001-0.497-0.129-1.097,0.777-0.886c2.826,0.66,5.501,1.671,7.133,4.207
	c0.788,1.224,1.479,2.749,1.533,4.164c0.21,5.543,0.179,11.096,0.161,16.645c-0.004,1.083,0.258,1.389,1.371,1.375
	c7.709-0.093,15.419-0.138,23.129-0.136c2.656,0.001,2.325-0.257,1.629,2.224c-1.146,4.083-4.18,7.478-8.971,7.409
	c-1.875-0.027-3.752,0.142-5.627,0.139c-3.435-0.005-6.87-0.044-10.304-0.133c-0.908-0.024-1.148,0.257-1.14,1.147
	c0.07,7.948,0.103,15.897,0.13,23.845c0.006,1.76-0.029,1.781-1.742,1.308c-3.808-1.051-6.843-3.034-7.678-7.174
	c-0.367-1.821-0.345-3.737-0.351-5.61c-0.015-4.073,0.039-8.147,0.125-12.219c0.02-0.933-0.236-1.214-1.192-1.206
	c-8.029,0.062-16.059,0.054-24.088,0.115c-1.134,0.009-1.573-0.3-1.082-1.395c0.746-1.662,1.337-3.438,2.347-4.925
	c1.325-1.95,3.331-3.151,5.759-3.197c5.589-0.106,11.18-0.133,16.77-0.118c1.08,0.003,1.421-0.253,1.401-1.377
	c-0.071-3.993-0.028-7.988-0.028-11.982C35.234,21.236,35.212,21.236,35.19,21.236z"
    />
  </svg>
);

const Edit = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.68 79.68">
    <path
      d="M71.88,27.872c-0.175,0.911-0.328,1.769-0.508,2.62c-0.106,0.5-0.213,1.008-0.396,1.482
	c-0.14,0.363-0.347,0.729-0.613,1.008c-1.735,1.819-3.458,3.65-5.255,5.407c-2.079,2.033-4.229,3.993-6.345,5.987
	c-0.378,0.356-0.732,0.739-1.117,1.087c-1.42,1.282-2.875,2.528-4.268,3.839c-1.28,1.204-2.496,2.477-3.745,3.714
	c-2.952,2.926-5.896,5.859-8.864,8.768c-1.196,1.172-2.459,2.275-3.67,3.431c-0.865,0.826-1.983,1.168-3.037,1.605
	c-2.052,0.85-4.129,1.647-6.225,2.38c-1.419,0.496-2.884,0.868-4.339,1.256c-2.683,0.716-5.369,1.421-8.063,2.092
	c-0.959,0.239-1.912,0.449-2.85,0.792c-1.516,0.554-3.58-0.42-4.202-2.392c-0.195-0.618-0.345-1.248-0.056-1.863
	c0.462-0.983,0.638-2.041,0.892-3.081c0.515-2.116,1.009-4.239,1.603-6.334c0.746-2.628,1.517-5.252,2.403-7.834
	c0.693-2.019,1.572-3.975,2.383-5.953c0.087-0.213,0.257-0.395,0.404-0.579c1.568-1.969,3.305-3.783,5.073-5.573
	c2.976-3.011,5.931-6.043,8.863-9.098c1.989-2.072,3.891-4.227,5.886-6.292c3.576-3.701,7.193-7.363,10.795-11.039
	c0.615-0.628,1.248-1.239,1.857-1.873c0.455-0.474,0.946-0.793,1.642-0.867c0.62-0.066,1.215-0.348,1.829-0.499
	c1.717-0.421,3.411-0.18,5.053,0.337c2.475,0.78,4.816,1.89,6.956,3.355c0.973,0.667,2.07,1.229,2.769,2.243
	c0.699,1.014,1.437,2.009,2.04,3.079c1.073,1.907,2.003,3.89,2.558,6.019C71.568,26.013,71.701,26.957,71.88,27.872z M33.24,61.152
	c0.161-0.088,0.318-0.144,0.436-0.246c0.268-0.23,0.505-0.497,0.777-0.722c0.549-0.456,1.164-0.843,1.66-1.348
	c3.192-3.246,6.506-6.362,9.826-9.475c3.053-2.863,5.897-5.947,8.855-8.914c1.719-1.724,3.487-3.398,5.215-5.113
	c0.706-0.701,1.376-1.44,2.044-2.178c0.507-0.56,0.978-1.152,1.478-1.718c0.237-0.269,0.498-0.518,0.76-0.765
	c0.292-0.275,0.301-0.513-0.005-0.8c-0.785-0.737-1.552-1.495-2.325-2.245c-0.485-0.471-0.977-0.936-1.453-1.417
	c-1.571-1.588-3.135-3.183-4.703-4.775c-0.64-0.65-1.283-1.297-1.925-1.946c-0.73-0.738-1.46-1.476-2.188-2.217
	c-0.251-0.255-0.469-0.212-0.717,0.021c-0.814,0.765-1.665,1.49-2.456,2.278c-5.153,5.131-10.297,10.27-15.434,15.416
	c-1.24,1.243-2.479,2.49-3.656,3.792c-2.034,2.25-4.069,4.494-6.298,6.56c-0.836,0.775-1.502,1.737-2.227,2.628
	c-0.285,0.351-0.255,0.639,0.118,1.003c2.144,2.092,4.252,4.222,6.368,6.343c1.834,1.838,3.663,3.681,5.496,5.521
	C32.996,60.947,33.119,61.045,33.24,61.152z M15.553,59.496c0.077,0.55-0.103,1.103,0.315,1.607c1.429,1.72,2.991,3.304,4.735,4.703
	c0.234,0.187,0.574,0.372,0.851,0.352c1.083-0.078,2.157-0.156,3.208-0.562c1.806-0.698,3.668-1.241,5.377-2.187
	c0.802-0.444,0.846-0.446,0.156-1.117c-1.2-1.166-2.432-2.3-3.621-3.477c-0.735-0.728-1.402-1.525-2.127-2.264
	c-1.226-1.249-2.474-2.476-3.712-3.714c-0.536-0.536-1.063-1.081-1.609-1.607c-0.198-0.191-0.444-0.207-0.604,0.053
	c-0.291,0.472-0.624,0.934-0.821,1.446c-0.682,1.773-1.332,3.56-1.95,5.357C15.599,58.529,15.614,59.025,15.553,59.496z
	 M67.899,25.305c-0.051-0.156-0.091-0.267-0.123-0.381c-0.516-1.799-1.364-3.449-2.288-5.062c-0.697-1.217-1.587-2.315-2.735-3.089
	c-1.365-0.921-2.812-1.747-4.368-2.332c-0.63-0.237-1.279-0.422-1.923-0.617c-0.08-0.024-0.184,0.032-0.277,0.051
	c0.021,0.075,0.026,0.16,0.067,0.222c0.065,0.098,0.15,0.185,0.235,0.27c3.586,3.587,7.173,7.173,10.762,10.757
	c0.111,0.111,0.231,0.225,0.369,0.288C67.679,25.44,67.797,25.347,67.899,25.305z"
    />
  </svg>
);

const Collection = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.68 79.68">
    <path
      d="M72.24,26.4c0,0.48,0,0.96,0,1.44c-0.151,0.228-0.336,0.441-0.446,0.687c-0.47,1.053-0.905,2.121-1.376,3.173
	c-1.738,3.883-3.473,7.767-5.231,11.641c-1.214,2.675-2.483,5.326-3.693,8.002c-1.933,4.276-3.839,8.564-5.756,12.847
	c-0.325,0.725-0.633,1.458-0.986,2.168c-0.136,0.274-0.327,0.601-0.579,0.719c-0.569,0.267-1.179,0.527-1.795,0.598
	c-1.698,0.196-3.407,0.404-5.113,0.412c-8.678,0.042-17.356,0.032-26.034,0.017c-1.35-0.002-2.704-0.083-4.049-0.201
	c-1.245-0.11-2.39-0.526-3.418-1.286c-2.864-2.117-5.02-4.775-6.218-8.143c-0.436-1.227-0.789-2.501-1.01-3.783
	c-0.279-1.618,0.198-3.164,0.86-4.63c1.015-2.248,2.087-4.471,3.114-6.714c1.342-2.931,2.665-5.871,3.992-8.81
	c1.635-3.622,3.266-7.246,4.896-10.87c0.766-1.702,1.533-3.404,2.285-5.112c0.496-1.128,0.981-2.261,1.445-3.403
	c0.63-1.553,1.791-2.228,3.438-2.226c9.718,0.008,19.436-0.015,29.153,0.009c2.392,0.006,4.783,0.16,7.175,0.223
	c0.998,0.026,1.671,0.424,2.176,1.33c1.532,2.746,3.119,5.462,4.729,8.164C70.56,23.933,71.423,25.153,72.24,26.4z M28.93,49.908
	c0-0.003,0-0.007,0-0.01c4.558,0,9.116-0.019,13.674,0.021c0.695,0.006,1.029-0.243,1.244-0.836c0.148-0.411,0.331-0.811,0.512-1.21
	c1.333-2.933,2.678-5.86,4.002-8.797c2.335-5.183,4.658-10.373,6.986-15.56c0.684-1.525,1.376-3.046,2.045-4.578
	c0.216-0.494,0.123-0.612-0.397-0.651c-0.159-0.012-0.32-0.003-0.48-0.003c-8.916-0.001-17.832,0.005-26.748-0.017
	c-0.64-0.002-0.999,0.139-1.27,0.769c-0.975,2.261-2.023,4.491-3.037,6.735c-1.604,3.551-3.2,7.105-4.804,10.655
	c-1.685,3.73-3.374,7.458-5.062,11.188c-0.278,0.615-0.544,1.236-0.84,1.843c-0.155,0.318-0.057,0.439,0.263,0.447
	c0.28,0.007,0.56,0.004,0.84,0.004C20.214,49.908,24.572,49.908,28.93,49.908z M29.493,54.974c0,0.001,0,0.003,0,0.004
	c-4.397,0-8.795,0-13.192,0.001c-0.32,0-0.642-0.013-0.957,0.028c-0.072,0.01-0.176,0.23-0.16,0.338
	c0.021,0.146,0.14,0.281,0.224,0.416c0.756,1.218,1.589,2.396,2.246,3.665c0.544,1.05,0.939,2.189,1.293,3.322
	c0.242,0.774,0.161,0.824,1.014,0.802c0.672-0.017,1.343-0.151,2.015-0.152c8.275-0.01,16.55-0.006,24.825-0.004
	c0.48,0,0.96,0.016,1.439,0.008c0.258-0.005,0.481-0.032,0.251-0.399c-0.423-0.675-0.837-1.358-1.2-2.066
	c-0.277-0.54-0.661-0.75-1.253-0.681c-0.79,0.091-1.583,0.213-2.374,0.215c-6.029,0.01-12.062,0.224-18.085-0.237
	c-0.58-0.044-1.157-0.247-1.71-0.446c-0.164-0.059-0.36-0.381-0.33-0.545c0.042-0.233,0.262-0.446,0.441-0.635
	c0.064-0.068,0.228-0.048,0.347-0.057c0.911-0.071,1.822-0.173,2.735-0.203c2.791-0.094,5.582-0.17,8.374-0.233
	c1.638-0.037,3.278-0.036,4.917-0.048c1.519-0.011,3.038-0.008,4.557-0.029c0.477-0.006,0.577-0.186,0.357-0.585
	c-0.289-0.523-0.612-1.027-0.886-1.557c-0.336-0.651-0.755-0.957-1.576-0.947C38.367,55.003,33.93,54.974,29.493,54.974z
	 M66.327,27.546c-0.111-0.294-0.231-0.626-0.366-0.951c-0.025-0.06-0.133-0.145-0.162-0.132c-0.091,0.043-0.197,0.112-0.236,0.198
	c-0.795,1.77-1.589,3.541-2.366,5.319c-0.26,0.595-0.525,1.121-1.27,1.265c-0.319,0.061-0.419,0.058-0.472-0.255
	c-0.09-0.538,0.053-1.023,0.283-1.501c0.568-1.181,1.143-2.36,1.685-3.553c0.427-0.94,0.824-1.894,1.2-2.855
	c0.093-0.237,0.163-0.58,0.059-0.778c-0.405-0.776-0.879-1.517-1.346-2.26c-0.024-0.038-0.272-0.009-0.324,0.059
	c-0.118,0.155-0.182,0.352-0.263,0.534c-0.891,1.996-1.777,3.995-2.674,5.988c-1.059,2.355-2.128,4.706-3.191,7.059
	c-1.178,2.608-2.355,5.217-3.529,7.827c-1.403,3.12-2.792,6.247-4.218,9.357c-0.191,0.417-0.226,0.736,0.051,1.092
	c0.146,0.188,0.276,0.389,0.414,0.584c0.381,0.537,0.487,0.539,0.77,0.006c0.093-0.175,0.167-0.36,0.255-0.539
	c2.025-4.109,4.05-8.217,6.078-12.324c0.122-0.248,0.245-0.512,0.432-0.705c0.114-0.118,0.374-0.19,0.529-0.145
	c0.159,0.046,0.349,0.241,0.384,0.402c0.047,0.216,0.006,0.481-0.081,0.692c-0.604,1.469-1.129,2.983-1.868,4.383
	c-1.765,3.344-3.15,6.861-4.805,10.254c-0.097,0.198-0.182,0.499-0.1,0.668c0.223,0.459,0.508,0.898,0.828,1.297
	c0.224,0.278,0.593,0.217,0.796-0.103c0.212-0.336,0.395-0.694,0.557-1.057c1.17-2.615,2.333-5.233,3.496-7.852
	c1.713-3.857,3.427-7.714,5.133-11.574c0.353-0.798,0.629-1.634,1.025-2.41c1.177-2.306,2.19-4.679,3.056-7.116
	C66.18,28.16,66.234,27.888,66.327,27.546z M16.073,62.268c0.041-0.013,0.081-0.027,0.122-0.04c-0.174-0.516-0.289-1.062-0.537-1.54
	c-0.403-0.773-0.862-1.523-1.356-2.242c-0.427-0.622-0.913-1.209-1.417-1.771c-0.335-0.374-0.798-0.229-0.854,0.268
	c-0.043,0.381-0.035,0.816,0.108,1.161c0.581,1.402,1.367,2.694,2.374,3.832c0.309,0.35,0.741,0.596,1.131,0.868
	c0.198,0.137,0.379,0.09,0.425-0.179C16.09,62.51,16.073,62.388,16.073,62.268z"
    />
  </svg>
);

const Close = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.68 79.68">
    <path
      d="M11.736,61.295c-0.117-1.912,0.507-3.741,1.534-5.438c0.347-0.573,0.804-1.097,1.284-1.568
 c1.335-1.311,2.671-2.624,4.076-3.859c1.823-1.602,3.724-3.114,5.57-4.69c1.515-1.294,3-2.623,4.497-3.939
 c0.599-0.526,1.193-1.058,1.792-1.584c0.258-0.226,0.279-0.441,0.042-0.712c-1.497-1.71-2.983-3.431-4.477-5.143
 c-1.704-1.953-3.405-3.908-5.125-5.847c-0.874-0.985-1.801-1.923-2.677-2.906c-1.803-2.022-3.592-4.056-5.379-6.093
 c-0.946-1.078-1.891-2.158-2.806-3.263c-0.427-0.516-0.42-0.595,0.2-0.912c1.587-0.814,3.186-1.568,4.975-1.914
 c1.961-0.38,3.886-0.341,5.795,0.135c1.498,0.374,2.757,1.248,3.85,2.316c1.998,1.953,3.931,3.967,5.702,6.136
 c1.762,2.159,3.58,4.273,5.393,6.389c1.037,1.21,2.117,2.383,3.175,3.576c0.191,0.216,0.389,0.258,0.611,0.06
 c0.175-0.157,0.338-0.326,0.513-0.483c1.75-1.567,3.492-3.143,5.255-4.695c1.555-1.368,3.142-2.698,4.699-4.062
 c0.96-0.841,1.884-1.721,2.836-2.57c2.082-1.857,4.197-3.679,6.25-5.569c1.233-1.135,2.434-2.298,3.788-3.293
 c0.508-0.374,0.512-0.38,0.797,0.096c1.224,2.042,2.034,4.263,2.254,6.607c0.258,2.758-0.193,5.453-2.188,7.567
 c-1.692,1.794-3.514,3.467-5.32,5.148c-1.077,1.003-2.237,1.915-3.356,2.874c-2.239,1.918-4.475,3.839-6.711,5.762
 c-0.242,0.208-0.474,0.429-0.707,0.648c-0.84,0.786-0.822,0.772-0.105,1.592c2.099,2.399,4.176,4.817,6.275,7.215
 c0.919,1.05,1.874,2.069,2.81,3.105c2.271,2.514,4.552,5.018,6.805,7.548c1.43,1.606,2.824,3.244,4.219,4.881
 c0.471,0.552,0.411,0.737-0.236,1.091c-2.076,1.136-4.293,1.894-6.637,2.133c-2.584,0.264-5.024-0.324-7.036-2.021
 c-1.396-1.177-2.639-2.539-3.914-3.854c-0.888-0.916-1.731-1.877-2.561-2.847c-1.401-1.635-2.768-3.299-4.164-4.939
 c-0.955-1.123-1.933-2.226-2.902-3.337c-0.55-0.631-1.104-1.258-1.65-1.892c-0.226-0.262-0.421-0.287-0.697-0.038
 c-1.511,1.358-3.031,2.706-4.559,4.046c-2.335,2.047-4.68,4.084-7.017,6.129c-0.3,0.263-0.573,0.557-0.871,0.823
 c-2.14,1.912-4.287,3.816-6.425,5.731c-1.01,0.904-2,1.831-3.011,2.734c-0.475,0.424-0.973,0.823-1.47,1.222
 c-0.349,0.28-0.524,0.251-0.75-0.159C12.642,66.793,11.651,64.252,11.736,61.295z"
    />
  </svg>
);

const Hamburger = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.68 79.68">
    <path
      d="M48.072,32.157c-7.747,0-15.494-0.022-23.241,0.008c-5.233,0.02-10.465,0.12-15.698,0.168
	c-1.639,0.015-1.827-0.174-1.253-1.719c0.523-1.409,1.132-2.829,1.96-4.073c1.456-2.191,3.612-3.386,6.268-3.413
	c5.508-0.057,11.018-0.016,16.526-0.05c4.63-0.029,9.26-0.132,13.89-0.157c7.31-0.04,14.621-0.048,21.931-0.053
	c1.266-0.001,1.479,0.228,1.045,1.432c-0.51,1.415-1.066,2.853-1.863,4.117c-1.535,2.435-3.873,3.646-6.752,3.671
	c-4.271,0.037-8.542,0.01-12.813,0.01C48.072,32.117,48.072,32.137,48.072,32.157z"
    />
    <path
      d="M47.845,47.029c-7.787,0-15.574-0.022-23.361,0.008c-5.233,0.02-10.465,0.12-15.698,0.167
	c-1.494,0.013-1.709-0.2-1.184-1.59c0.559-1.481,1.188-2.98,2.063-4.285c1.438-2.144,3.581-3.302,6.189-3.329
	c5.508-0.056,11.018-0.017,16.526-0.051c4.63-0.029,9.26-0.131,13.89-0.157c7.31-0.04,14.621-0.048,21.931-0.054
	c1.278-0.001,1.491,0.223,1.061,1.419c-0.509,1.416-1.063,2.854-1.858,4.119c-1.532,2.436-3.867,3.654-6.747,3.681
	c-4.271,0.039-8.542,0.01-12.813,0.01C47.845,46.988,47.845,47.008,47.845,47.029z"
    />
    <path
      d="M47.823,61.66c-7.747,0-15.494-0.022-23.241,0.008c-5.233,0.02-10.465,0.12-15.698,0.168
	c-1.627,0.015-1.743-0.151-1.265-1.736c0.717-2.378,1.734-4.622,3.929-5.969c1.124-0.69,2.458-1.343,3.734-1.421
	c3.66-0.224,7.338-0.167,11.009-0.184c4.593-0.021,9.187,0.005,13.78-0.011c2.194-0.008,4.387-0.09,6.581-0.099
	c7.071-0.029,14.141-0.043,21.212-0.053c1.704-0.002,1.943,0.212,1.3,1.791c-0.669,1.644-1.44,3.317-2.513,4.71
	c-1.456,1.891-3.61,2.721-6.016,2.73c-4.271,0.017-8.542,0.005-12.813,0.005C47.823,61.619,47.823,61.64,47.823,61.66z"
    />
  </svg>
);

const Search = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.68 79.68">
    <path
      d="M36.462,17.944c6.483,0.138,11.606,2.374,15.697,6.826c2.642,2.875,4.331,6.276,4.955,10.155
	c0.208,1.291,0.452,2.609,0.402,3.904c-0.123,3.159-0.719,6.233-2.25,9.06c-0.247,0.455-0.703,0.981-0.62,1.383
	c0.096,0.465,0.663,0.848,1.057,1.236c3.62,3.558,7.247,7.11,10.871,10.664c0.314,0.308,0.622,0.622,0.928,0.938
	c0.721,0.747,0.707,0.727-0.115,1.251c-1.596,1.017-3.31,1.708-5.183,2.012c-2.55,0.414-4.855-0.156-6.754-1.903
	c-2.38-2.189-4.665-4.481-6.992-6.728c-0.029-0.028-0.055-0.058-0.083-0.086c-0.558-0.56-0.549-0.542-1.194-0.202
	c-0.843,0.445-1.679,0.915-2.559,1.275c-2.449,1.002-4.989,1.54-7.658,1.563c-3.962,0.036-7.625-0.943-10.986-3.013
	c-3.888-2.395-6.682-5.739-8.422-9.959c-1.218-2.955-1.7-6.051-1.424-9.216c0.342-3.916,1.62-7.54,3.988-10.713
	c3.209-4.301,7.476-6.995,12.728-8.067C34.166,18.053,35.536,18.036,36.462,17.944z M35.854,51.157
	c6.953,0.621,13.11-4.624,13.533-11.813c0.401-6.823-4.607-12.941-11.94-13.366c-6.364-0.37-12.983,4.057-13.319,12.37
	C23.873,44.669,28.546,50.662,35.854,51.157z"
    />
  </svg>
);

const UnfavoriteHeart = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.68 79.68">
    <path
      d="M24.461,12.589c5.596,0.276,10.383,1.83,14.423,5.326c0.588,0.509,0.921,0.537,1.53,0.026
	c4.663-3.91,10.052-5.645,16.123-5.203c2.675,0.195,4.992,1.35,7.009,3.02c5.673,4.696,8.823,10.785,9.075,18.122
	c0.193,5.609-2.111,10.351-6.242,14.187c-3.345,3.107-7.253,5.462-10.866,8.21c-4.193,3.189-8.361,6.402-11.945,10.29
	c-1.134,1.23-2.216,2.511-3.276,3.806c-0.459,0.561-0.743,0.654-1.248,0.027c-1.1-1.364-2.236-2.705-3.431-3.986
	c-4.13-4.425-8.959-8.023-13.806-11.602c-3.075-2.27-6.33-4.302-9.109-6.966c-3.971-3.807-6.171-8.471-6.008-13.94
	c0.195-6.55,2.818-12.183,7.534-16.788c2.115-2.066,4.517-3.653,7.471-4.218C22.708,12.707,23.749,12.666,24.461,12.589z
	 M66.062,34.208c0.043-0.827-0.094-1.883-0.494-2.902c-1.228-3.126-3.105-5.793-5.519-8.13c-2.733-2.646-5.853-2.957-9.237-1.688
	c-3.406,1.277-6.431,3.261-9.369,5.371c-1.637,1.176-1.938,1.168-3.596-0.012c-3.108-2.212-6.27-4.351-9.939-5.54
	c-2.929-0.949-5.666-0.745-8.115,1.435c-2.147,1.911-3.907,4.088-5.181,6.648c-2.377,4.777-1.824,8.328,1.809,12.222
	c1.835,1.968,4.082,3.435,6.128,5.146c5.199,4.347,10.353,8.746,15.519,13.133c1.503,1.276,1.683,1.28,3.211-0.033
	c2.57-2.209,5.124-4.437,7.73-6.603c4.106-3.413,8.305-6.717,12.358-10.191C63.979,40.825,66.214,38.266,66.062,34.208z"
    />
  </svg>
);

const Cart = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.68 79.68">
    <path
      d="M47.043,65.015c-4.559,0-9.117,0.009-13.675-0.01c-0.663-0.003-0.983,0.153-1.322,0.834
	c-1.531,3.075-3.947,4.927-7.527,4.988c-1.374,0.023-2.688-0.125-3.94-0.757c-2.336-1.179-4.048-2.962-4.571-5.515
	c-0.933-4.554,0.584-8.642,5.562-10.566c0.89-0.344,1.813-0.319,2.72-0.254c0.943,0.068,1.577-0.238,2.101-1.029
	c0.681-1.029,1.458-1.995,2.211-2.975c0.392-0.511,0.521-0.965,0.2-1.618c-1.193-2.428-2.314-4.891-3.459-7.342
	c-2.511-5.375-5.018-10.751-7.533-16.125c-1.062-2.269-2.14-4.53-3.207-6.797c-0.271-0.576-0.555-1.147-0.783-1.74
	c-0.215-0.558-0.556-0.768-1.17-0.777c-2.913-0.04-5.825-0.138-8.738-0.196c-0.873-0.018-1.638-0.234-2.277-0.867
	c-0.776-0.768-0.912-1.706-0.586-2.655c0.344-1.004,1.107-1.642,2.214-1.763c0.198-0.022,0.4-0.009,0.599-0.008
	C5.861,9.855,7.861,9.848,9.86,9.885c1.913,0.036,3.825,0.125,5.739,0.175c0.91,0.024,1.652,0.393,2.109,1.154
	c0.552,0.918,1.019,1.889,1.486,2.855c0.589,1.22,1.137,2.459,1.709,3.687c0.145,0.311,0.316,0.456,0.741,0.382
	c0.489-0.086,1.015,0.057,1.526,0.068c1.119,0.023,2.24,0.007,3.358,0.04c2.752,0.081,5.504,0.176,8.255,0.273
	c2.032,0.071,4.064,0.16,6.097,0.229c2.792,0.095,5.585,0.175,8.377,0.266c2.273,0.074,4.546,0.154,6.819,0.233
	c2.272,0.079,4.543,0.157,6.815,0.239c2.113,0.076,4.226,0.145,6.338,0.236c0.914,0.039,1.829,0.099,2.738,0.198
	c1.286,0.141,2.253,1.194,2.418,2.58c0.019,0.158,0.015,0.319,0.019,0.479c0.09,3.672,0.179,7.344,0.268,11.017
	c0.082,3.353,0.153,6.706,0.256,10.058c0.03,0.99-0.24,1.871-0.664,2.751c-0.585,1.215-1.588,1.744-2.845,1.891
	c-2.843,0.332-5.688,0.65-8.534,0.958c-1.738,0.188-3.481,0.327-5.218,0.528c-1.655,0.192-3.301,0.46-4.955,0.65
	c-1.819,0.209-3.645,0.351-5.464,0.552c-1.855,0.205-3.705,0.454-5.559,0.668c-0.947,0.109-1.9,0.172-2.848,0.272
	c-1.58,0.166-3.161,0.333-4.738,0.526c-0.52,0.064-0.939,0.288-1.259,0.776c-0.546,0.832-1.192,1.599-1.786,2.4
	c-0.218,0.294-0.28,0.528-0.014,0.922c0.53,0.786,0.943,1.657,1.346,2.52c0.159,0.34,0.336,0.517,0.704,0.508
	c0.24-0.006,0.48,0,0.72,0c8.717,0,17.434,0,26.151,0c0.16,0,0.324-0.024,0.479,0.004c0.532,0.096,0.839-0.134,0.97-0.635
	c0.278-1.063,0.841-1.972,1.524-2.813c1.084-1.334,2.438-2.263,4.082-2.807c0.986-0.326,1.979-0.163,2.968-0.239
	c1.023-0.078,1.974,0.172,2.914,0.532c2.723,1.045,5.174,4.412,5.148,7.187c-0.017,1.776-0.078,3.498-1.006,5.082
	c-1.274,2.175-3.112,3.766-5.562,4.182c-4.19,0.712-7.632-0.614-9.744-4.921c-0.241-0.492-0.491-0.532-0.898-0.532
	c-2.239,0.001-4.478,0.001-6.718,0.001C51.761,65.015,49.402,65.015,47.043,65.015z M69.714,66.758
	c2.571,0.154,4.911-2.084,4.95-4.755c0.04-2.762-2.189-4.917-4.932-4.898c-2.823,0.02-4.787,2.292-4.857,4.802
	C64.797,64.728,67.163,66.876,69.714,66.758z M24.408,66.987c2.676,0.132,4.925-2.244,4.934-4.886
	c0.008-2.492-2.178-4.754-4.804-4.782c-2.822-0.029-4.87,2.354-4.942,4.927C19.523,64.884,22.181,67.187,24.408,66.987z
	 M26.139,23.985c-0.387,0-1.027-0.01-1.666,0.003c-0.515,0.01-0.565,0.065-0.339,0.554c1.185,2.561,2.393,5.11,3.579,7.67
	c0.16,0.345,0.382,0.445,0.747,0.429c1.792-0.08,3.585-0.135,5.377-0.208c0.463-0.019,0.541-0.14,0.446-0.599
	c-0.232-1.123-0.461-2.247-0.694-3.37c-0.241-1.163-0.469-2.329-0.738-3.485c-0.084-0.361-0.141-0.757-0.723-0.769
	C30.215,24.171,28.303,24.071,26.139,23.985z M42.272,32.402c0.001,0.007,0.002,0.013,0.003,0.02
	c1.228-0.073,2.456-0.175,3.685-0.208c0.653-0.018,0.626-0.228,0.569-0.745c-0.221-2.005-0.458-4.009-0.67-6.016
	c-0.076-0.722-0.052-0.738-0.738-0.758c-0.876-0.025-1.754-0.007-2.629-0.041c-1.27-0.05-2.538-0.142-3.807-0.191
	c-0.483-0.018-0.599,0.119-0.511,0.548c0.47,2.28,0.96,4.556,1.426,6.837c0.092,0.449,0.347,0.57,0.761,0.559
	C40.997,32.39,41.635,32.402,42.272,32.402z M54.354,24.94c-1.009,0-1.726,0.001-2.443,0c-0.836-0.002-0.863,0.018-0.771,0.88
	c0.046,0.432,0.15,0.858,0.197,1.289c0.169,1.535,0.329,3.072,0.482,4.608c0.027,0.271,0.091,0.462,0.409,0.451
	c1.947-0.067,3.894-0.142,5.841-0.192c0.426-0.011,0.544-0.19,0.535-0.584c-0.037-1.634-0.051-3.268-0.086-4.902
	c-0.005-0.234-0.101-0.466-0.116-0.701c-0.026-0.421-0.177-0.62-0.651-0.637C56.522,25.11,55.295,25.003,54.354,24.94z
	 M45.791,37.468c0,0.021,0,0.041,0.001,0.062c-1.471,0.042-2.943,0.079-4.414,0.127c-0.527,0.017-0.53,0.029-0.428,0.528
	c0.507,2.479,1.029,4.956,1.514,7.44c0.086,0.439,0.31,0.502,0.665,0.462c0.75-0.083,1.499-0.168,2.249-0.254
	c0.751-0.086,1.501-0.174,2.252-0.259c0.236-0.026,0.364-0.145,0.339-0.388c-0.233-2.249-0.466-4.498-0.705-6.746
	c-0.097-0.915-0.172-0.972-1.112-0.972C46.03,37.468,45.911,37.468,45.791,37.468z M59.084,41.261
	c0.014-0.002,0.027-0.004,0.041-0.005c-0.091-1.15-0.201-2.298-0.264-3.45c-0.026-0.484-0.245-0.606-0.68-0.574
	c-0.912,0.069-1.824,0.134-2.737,0.166c-0.878,0.031-1.757,0.015-2.636,0.025c-0.265,0.003-0.38,0.044-0.34,0.409
	c0.224,2.053,0.393,4.112,0.578,6.169c0.075,0.832,0.184,0.938,1.056,0.863c0.631-0.054,1.259-0.153,1.889-0.224
	c0.828-0.094,1.658-0.176,2.486-0.271c0.416-0.048,0.629-0.254,0.614-0.712C59.065,42.859,59.084,42.06,59.084,41.261z
	 M33.652,37.696c-0.001-0.014-0.003-0.028-0.004-0.042c-0.87,0.073-1.74,0.139-2.609,0.22c-0.614,0.057-0.63,0.085-0.383,0.616
	c1.17,2.52,2.332,5.044,3.525,7.552c0.467,0.981,0.362,1.179,1.575,0.819c0.334-0.099,0.712-0.054,1.071-0.065
	c0.304-0.009,0.42-0.167,0.398-0.455c-0.012-0.159-0.004-0.322-0.036-0.476c-0.477-2.32-0.953-4.64-1.443-6.958
	c-0.259-1.228-0.271-1.225-1.495-1.213C34.051,37.698,33.852,37.696,33.652,37.696z M68.873,28.974c-0.011,0-0.023,0-0.034,0
	c0-0.876,0.029-1.753-0.009-2.628c-0.033-0.757-0.188-0.881-0.928-0.894c-1.154-0.02-2.309-0.032-3.464-0.04
	c-0.803-0.005-0.793-0.001-0.683,0.879c0.044,0.354,0.047,0.714,0.053,1.071c0.023,1.314,0.053,2.628,0.05,3.941
	c-0.001,0.457,0.153,0.641,0.619,0.631c1.274-0.029,2.548-0.019,3.822-0.044c0.554-0.011,0.563-0.033,0.571-0.648
	C68.881,30.487,68.873,29.731,68.873,28.974z M69.153,40.208c-0.022,0-0.044,0-0.066,0c0-0.797,0.011-1.594-0.004-2.39
	c-0.012-0.632-0.031-0.652-0.692-0.654c-1.235-0.004-2.47,0.004-3.705,0.025c-0.549,0.009-0.608,0.109-0.567,0.638
	c0.07,0.909,0.14,1.818,0.178,2.729c0.035,0.835,0.015,1.673,0.043,2.509c0.02,0.576,0.075,0.632,0.632,0.572
	c1.26-0.137,2.516-0.309,3.775-0.451c0.304-0.034,0.405-0.194,0.405-0.467C69.153,41.881,69.153,41.044,69.153,40.208z"
    />
  </svg>
);

const Stripe = (props: any) => (
  <svg viewBox="0 0 60 25" xmlns="http://www.w3.org/2000/svg">
    <path d="M59.64 14.28h-8.06c.19 1.93 1.6 2.55 3.2 2.55 1.64 0 2.96-.37 4.05-.95v3.32a8.33 8.33 0 0 1-4.56 1.1c-4.01 0-6.83-2.5-6.83-7.48 0-4.19 2.39-7.52 6.3-7.52 3.92 0 5.96 3.28 5.96 7.5 0 .4-.04 1.26-.06 1.48zm-5.92-5.62c-1.03 0-2.17.73-2.17 2.58h4.25c0-1.85-1.07-2.58-2.08-2.58zM40.95 20.3c-1.44 0-2.32-.6-2.9-1.04l-.02 4.63-4.12.87V5.57h3.76l.08 1.02a4.7 4.7 0 0 1 3.23-1.29c2.9 0 5.62 2.6 5.62 7.4 0 5.23-2.7 7.6-5.65 7.6zM40 8.95c-.95 0-1.54.34-1.97.81l.02 6.12c.4.44.98.78 1.95.78 1.52 0 2.54-1.65 2.54-3.87 0-2.15-1.04-3.84-2.54-3.84zM28.24 5.57h4.13v14.44h-4.13V5.57zm0-4.7L32.37 0v3.36l-4.13.88V.88zm-4.32 9.35v9.79H19.8V5.57h3.7l.12 1.22c1-1.77 3.07-1.41 3.62-1.22v3.79c-.52-.17-2.29-.43-3.32.86zm-8.55 4.72c0 2.43 2.6 1.68 3.12 1.46v3.36c-.55.3-1.54.54-2.89.54a4.15 4.15 0 0 1-4.27-4.24l.01-13.17 4.02-.86v3.54h3.14V9.1h-3.13v5.85zm-4.91.7c0 2.97-2.31 4.66-5.73 4.66a11.2 11.2 0 0 1-4.46-.93v-3.93c1.38.75 3.1 1.31 4.46 1.31.92 0 1.53-.24 1.53-1C6.26 13.77 0 14.51 0 9.95 0 7.04 2.28 5.3 5.62 5.3c1.36 0 2.72.2 4.09.75v3.88a9.23 9.23 0 0 0-4.1-1.06c-.86 0-1.44.25-1.44.9 0 1.85 6.29.97 6.29 5.88z" />
  </svg>
  // <img src="/icon/other/power-stripe.png" alt="Powered by Stripe" {...props} />
);

const FAQ = (props: any) => (
  <img
    src="/icons/question-mark.png"
    alt="FAQ and Suppost and Feedback"
    {...props}
  />
);

const Subscription = (props: any) => (
  <img src="/icons/SUBSCRIPTION.png" alt="Subscription" {...props} />
);

//Action icons with token frames
const Add = (props: any) => (
  <img src="/icon/actions-token-frames/Add-Icon.png" alt="Add" {...props} />
);

const CharacterBio = (props: any) => (
  <img
    src="/icon/actions-token-frames/CharacterBio.png"
    alt="Character Bio"
    {...props}
  />
);

const Delete = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.68 79.68">
    <path
      d="M40.199,9.876c6.271,0.051,12.539-0.042,18.807-0.224c0.677-0.02,1.361-0.009,2.033,0.066
		c1.127,0.126,1.959,0.614,2.474,1.744c1.661,3.641,3.506,7.199,4.95,10.939c0.114,0.296,0.254,0.586,0.325,0.892
		c0.38,1.63-0.012,2.183-1.696,2.422c-0.473,0.067-0.971,0.24-1.419,0.158c-1.274-0.235-1.543,0.477-1.641,1.501
		c-0.622,6.502-1.584,12.963-2.346,19.448c-0.614,5.223-1.141,10.456-1.523,15.704c-0.078,1.071-0.185,2.14-0.235,3.214
		c-0.055,1.18-0.686,1.922-1.819,2.226c-4.155,1.112-8.354,1.884-12.669,2.124c-2.754,0.153-5.502,0.227-8.248,0.142
		c-5.019-0.155-9.97-0.864-14.818-2.214c-1.215-0.338-1.858-1.126-1.933-2.407c-0.488-8.276-1.446-16.507-2.465-24.729
		c-0.563-4.544-1.191-9.081-1.644-13.639c-0.09-0.907-0.337-1.573-1.495-1.368c-0.451,0.08-0.948-0.075-1.421-0.144
		c-1.931-0.281-2.317-0.882-1.663-2.759c1.45-4.168,3.535-8.054,5.409-12.033c0.489-1.039,1.554-1.202,2.554-1.246
		c1.475-0.065,2.958-0.086,4.431-0.006C29.497,9.977,34.849,9.804,40.199,9.876z M40.189,25.574c0,0.001,0,0.003,0,0.004
		c-0.16,0-0.319,0-0.479,0c-5.552,0.002-11.103,0.025-16.655-0.013c-1.091-0.007-1.602,0.246-1.466,1.459
		c0.292,2.612,0.499,5.234,0.728,7.854c0.338,3.853,0.601,7.714,1.013,11.559c0.569,5.304,1.267,10.594,1.86,15.896
		c0.105,0.936,0.461,1.307,1.397,1.486c5.829,1.111,11.697,1.554,17.621,1.201c3.385-0.201,6.744-0.626,10.072-1.29
		c0.544-0.109,0.8-0.382,0.864-0.934c0.478-4.151,1.002-8.297,1.454-12.451c0.384-3.527,0.699-7.062,1.029-10.594
		c0.389-4.165,0.798-8.33,1.125-12.5c0.13-1.661,0.04-1.674-1.667-1.675C51.452,25.572,45.821,25.574,40.189,25.574z M40.081,20.865
		c0-0.006,0-0.011,0-0.017c5.509,0,11.018-0.002,16.527,0.001c1.557,0.001,3.113,0.023,4.67,0.02
		c0.777-0.002,1.282-0.147,0.775-1.129c-0.6-1.162-1.117-2.371-1.597-3.588c-0.448-1.135-1.262-1.534-2.423-1.533
		c-11.898,0.01-23.797,0.009-35.695-0.002c-1.168-0.001-1.974,0.433-2.414,1.56c-0.447,1.145-0.97,2.26-1.459,3.388
		c-0.551,1.271-0.55,1.271,0.899,1.301c0.16,0.003,0.319-0.001,0.479-0.001C26.588,20.865,33.334,20.865,40.081,20.865z"
    />
    <path
      d="M41.677,45.344c0,4.832,0.001,9.663-0.002,14.495c0,0.356,0.037,0.736-0.069,1.064
		c-0.207,0.637-0.608,1.18-1.359,1.128c-0.726-0.051-1.298-0.416-1.4-1.221c-0.065-0.513-0.093-1.033-0.094-1.55
		c-0.024-9.104-0.044-18.208-0.058-27.312c-0.002-1.078,0.073-2.151,0.491-3.168c0.197-0.479,0.53-0.809,1.064-0.821
		c0.54-0.013,0.849,0.313,1.076,0.79c0.339,0.712,0.371,1.463,0.372,2.221c0.005,4.791,0.003,9.583,0.003,14.374
		C41.692,45.344,41.684,45.344,41.677,45.344z"
    />
    <path
      d="M54.239,32.235c-0.33,4.525-0.648,9.411-1.188,14.283c-0.421,3.799-0.672,7.616-1.006,11.425
		c-0.09,1.029-0.216,2.059-0.61,3.021c-0.274,0.668-0.734,1.208-1.557,0.94c-0.755-0.246-0.944-0.859-0.885-1.622
		c0.471-6.08,0.9-12.164,1.39-18.242c0.317-3.933,0.718-7.859,1.072-11.79c0.048-0.53,0.196-1.013,0.492-1.451
		c0.274-0.406,0.378-1.022,1.107-0.856c0.655,0.149,1.027,0.561,1.202,1.19C54.51,30.05,54.219,30.959,54.239,32.235z"
    />
    <path
      d="M31.387,60.539c0.009,0.696-0.255,1.257-0.995,1.401c-0.771,0.15-1.197-0.349-1.431-0.986
		c-0.667-1.817-0.683-3.743-0.868-5.636c-0.394-4.04-0.786-8.081-1.114-12.127c-0.361-4.449-0.789-8.894-0.905-13.359
		c-0.011-0.441,0.05-0.871,0.313-1.255c0.595-0.869,1.387-0.849,1.953,0.023c1.026,1.58,0.724,3.404,0.902,5.116
		c0.474,4.558,0.866,9.127,1.148,13.701C30.661,51.796,31.269,56.146,31.387,60.539z"
    />
  </svg>
);

const Download = (props: any) => (
  <img
    src="/icon/actions-token-frames/Download.png"
    alt="Download"
    {...props}
  />
);

const PrivacyPublic = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.68 79.68">
    <path
      d="M73.487,39.751C73.515,57.529,58.85,72.366,40.28,71.694C23.245,71.078,8.937,57.252,9.449,38.578
		C9.921,21.371,24.122,7.572,41.616,7.626C59.478,7.682,73.669,22.438,73.487,39.751z M48.643,65.621
		c9.986-3.254,16.236-9.772,19.002-19.674c0.705-2.522,0.573-4.641-1.487-6.678c-2.343-2.318-2.873-5.333-1.94-8.547
		c0.266-0.916,0.433-1.861,0.717-2.77c0.219-0.702,0.102-1.261-0.272-1.896c-2.049-3.485-4.765-6.346-8.075-8.629
		c-2.364-1.63-4.948-2.841-7.73-3.596c-0.309-0.084-0.87-0.494-0.782,0.25c0.249,2.113,0.172,4.28,1.315,6.242
		c0.678,1.162,0.327,2.403-0.435,3.489c-0.581,0.827-1.292,1.55-2.148,2.063c-2.698,1.618-5.483,3.066-8.551,3.866
		c-0.507,0.132-0.829,0.412-1.13,0.8c-1.509,1.941-1.796,4.272-1.677,6.55c0.131,2.509,1.54,4.263,4.178,4.797
		c0.535,0.108,1.057,0.365,1.537,0.637c0.971,0.549,1.955,0.636,2.988,0.239c1.811-0.696,3.608-0.508,5.377,0.135
		c1.084,0.394,2.147,0.848,3.204,1.31c1.765,0.772,2.429,2.056,2.08,3.954c-0.267,1.449-0.495,2.908-0.817,4.345
		C52.967,57.099,52.097,61.761,48.643,65.621z M16.569,29.435c-3.241,9.052-2.554,17.481,2.694,25.287
		c4.384,6.52,10.624,10.312,18.614,11.573c-1.229-2.798-1.688-5.704-3.734-7.892c-0.34-0.363-0.567-0.877-0.719-1.362
		c-0.686-2.185-1.652-4.174-3.299-5.838c-1.487-1.502-2.343-3.393-2.143-5.527c0.204-2.174-0.231-4.23-0.886-6.226
		c-0.668-2.035-1.46-4.11-3.793-4.907C20.582,33.614,18.347,32.037,16.569,29.435z"
    />
  </svg>
);

const PrivacyPrivate = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.68 79.68">
    <path
      d="M41.413,71.674c-6.353-0.17-12.263-1.805-17.556-5.401c-0.745-0.506-1.147-0.524-1.791,0.179
		c-1.617,1.764-3.33,3.442-5.042,5.118c-0.997,0.976-2.186,1.035-2.977,0.206c-0.7-0.733-0.746-1.58-0.22-2.413
		c0.272-0.432,0.66-0.797,1.023-1.164c18.111-18.302,36.224-36.602,54.338-54.9c0.28-0.283,0.555-0.578,0.867-0.823
		c0.913-0.717,1.991-0.691,2.712,0.038c0.718,0.726,0.778,1.85-0.01,2.706c-1.406,1.526-2.833,3.042-4.365,4.437
		c-0.876,0.797-0.91,1.343-0.243,2.314c2.776,4.034,4.439,8.502,5.053,13.38c1.16,9.227-1.141,17.504-7.016,24.658
		c-4.749,5.784-10.884,9.475-18.288,10.947C45.755,71.38,43.606,71.687,41.413,71.674z M46.235,41.94
		c2.543,0.385,4.68,1.368,6.807,2.365c1.41,0.661,2.111,1.883,1.859,3.406c-0.336,2.039-0.494,4.154-1.097,6.093
		c-1.25,4.016-1.695,8.432-5.084,11.549c0.127,0.042,0.171,0.073,0.202,0.064c9.787-3.092,15.957-9.652,18.725-19.493
		c0.703-2.499,0.717-4.708-1.463-6.668c-1.428-1.284-2.167-3.121-2.325-5.11c-0.146-1.829,0.284-3.582,0.867-5.267
		c0.508-1.468,0.329-2.68-0.652-3.829c-0.139-0.163-0.248-0.352-0.173-0.243C58.025,30.507,52.13,36.223,46.235,41.94z
		 M32.857,55.824c-1.884,1.82-3.799,3.681-5.728,5.528c-0.436,0.418-0.541,0.623,0.116,1.005c1.08,0.628,2.16,1.255,3.299,1.755
		c2.294,1.008,4.671,1.75,7.166,2.057C36.987,62.213,34.655,59.095,32.857,55.824z"
    />
    <path
      d="M28.136,47.529c-0.22-2.362,0.069-4.723-0.648-7.025c-0.454-1.458-0.987-2.847-1.776-4.146
		c-0.494-0.813-1.172-1.447-2.062-1.742c-2.84-0.942-5.194-2.532-6.954-5.099c-0.923,1.891-1.407,3.829-1.696,5.775
		c-0.769,5.182-0.209,10.24,1.901,15.069c0.735,1.682,1.596,3.314,2.741,4.766c0.428,0.543,0.424,0.91-0.114,1.395
		c-0.888,0.8-1.718,1.669-2.53,2.548c-0.396,0.428-0.552,0.439-0.943-0.064c-2.719-3.496-4.608-7.405-5.617-11.689
		c-2.455-10.427-0.275-19.909,6.472-28.17c4.853-5.943,11.191-9.649,18.825-10.984c8.85-1.548,16.957,0.259,24.35,5.349
		c0.796,0.548,1.294,0.999,0.234,1.822c-0.75,0.583-1.389,1.32-2.023,2.037c-0.501,0.567-0.847,0.682-1.554,0.164
		c-2.455-1.798-5.227-2.983-8.141-3.837c-0.143-0.042-0.309-0.005-0.461-0.005c0.069,2.394,0.272,4.713,1.379,6.899
		c0.59,1.166,0.128,2.348-0.646,3.346c-1.203,1.55-2.774,2.665-4.58,3.351c-2.109,0.801-3.975,2.158-6.285,2.538
		c-0.952,0.157-1.566,1.284-1.74,2.141c-0.39,1.921-1.237,3.82-0.717,5.862c0.039,0.154-0.005,0.359,0.082,0.465
		c0.955,1.171,0.149,1.859-0.646,2.615C32.712,43.074,30.467,45.272,28.136,47.529z"
    />
  </svg>
);

const Share = (props: any) => (
  <img src="/icon/actions-token-frames/ShareIcon.png" alt="Share" {...props} />
);

const Tags = (props: any) => (
  <img src="/icon/actions-token-frames/Tags.png" alt="Add Tags" {...props} />
);

const View = (props: any) => (
  <img src="/icon/actions-token-frames/View-Icon.png" alt="View" {...props} />
);

const Background = (props: any) => (
  <img
    src="/icon/actions-token-frames/background.png"
    alt="Background"
    {...props}
  />
);

const User = (props: any) => (
  <img src="/icons/user.png" alt="User" {...props} />
);

const Info = (props: any) => (
  <img src="/icons/info.png" alt="Info" {...props} />
);

const Note = (props: any) => (
  <img src="/icons/note.png" alt="Note" {...props} />
);

const Duplicate = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.68 79.68">
    <path
      d="M6.26,54.231c0-0.48,0-0.959,0-1.439c0.83-1.286,1.161-2.788,1.813-4.16c2.337-4.92,4.539-9.904,6.771-14.873
	c2.638-5.874,5.303-11.738,7.859-17.648c1.157-2.674,1.75-3.169,4.715-3.208c2.866-0.038,5.736,0.156,8.599-0.124
	c0.338,0.183,0.701,0.108,1.058,0.108c6.271,0.002,12.541,0.013,18.812-0.005c0.971-0.003,1.823,0.22,2.484,0.936
	c1.466,1.588,2.777,3.302,3.769,5.224c0.667,1.292,0.901,2.611,0.307,4.104c-1.044,2.625-2.231,5.182-3.42,7.739
	c-0.394,0.848-0.187,1.002,0.655,0.965c1.196-0.053,2.409,0.101,3.592-0.036c2.022-0.234,3.45,0.578,4.576,2.167
	c1.536,2.166,3.413,4.159,3.426,7.06c-0.314,0.768-0.606,1.546-0.946,2.302c-4.319,9.61-8.651,19.214-12.96,28.828
	c-0.283,0.632-0.787,0.822-1.358,1.015c-1.866,0.632-3.804,0.736-5.744,0.747c-6.552,0.038-13.104,0.111-19.655,0.043
	c-4.752-0.05-9.501-0.319-14.253-0.45c-1.095-0.03-1.646-0.381-1.446-1.559c0.06-0.352,0.111-0.692,0.223-1.039
	c0.946-2.925,2.478-5.593,3.669-8.41c1.044-2.471,2.199-4.895,3.364-7.469c-3.932-0.12-7.678-0.234-11.424-0.35
	C9.239,54.65,7.725,54.675,6.26,54.231z M14.604,49.881c0.436,0,0.712,0,0.989,0c8.748-0.014,17.496-0.061,26.244-0.004
	c1.224,0.008,1.776-0.29,2.274-1.439c2.165-4.997,4.469-9.934,6.714-14.897c0.83-1.834,1.637-3.678,2.543-5.715
	c-0.849,0-1.484,0.029-2.115-0.008c-0.636-0.037-1.267-0.15-1.902-0.211c-1.649-0.158-2.469-1.255-2.149-2.843
	c0.38-1.886,1.225-3.599,1.971-5.347c0.332-0.779,0.324-1.071-0.68-1.065c-6.271,0.038-12.543,0.028-18.815-0.008
	c-0.689-0.004-0.977,0.238-1.218,0.834c-0.491,1.216-1.061,2.401-1.601,3.598C22.8,31.753,18.741,40.731,14.604,49.881z
	 M61.705,46.912c-1.455-0.131-2.758-0.236-4.059-0.368c-1.564-0.159-2.362-1.284-2.056-2.815c0.377-1.887,1.275-3.579,1.932-5.362
	c0.105-0.284,0.679-0.789,0.086-0.976c-0.475-0.15-1.198-0.387-1.607,0.306c-0.141,0.24-0.25,0.5-0.365,0.754
	c-2.172,4.817-4.353,9.631-6.502,14.458c-0.338,0.76-0.852,1.11-1.636,1.364c-1.922,0.623-3.893,0.684-5.869,0.711
	c-3.793,0.05-7.586,0.072-11.379,0.041c-0.825-0.007-1.208,0.3-1.502,1.015c-0.575,1.397-1.222,2.764-1.843,4.142
	c-1.283,2.849-2.567,5.698-3.896,8.646c0.416,0,0.688,0,0.96,0c8.748-0.014,17.495-0.028,26.243-0.04
	c0.842-0.001,1.623,0.216,2.118-0.938c1.96-4.566,4.054-9.075,6.093-13.607C59.512,51.826,60.59,49.405,61.705,46.912z
	 M52.546,22.527c1.444,0,2.68,0.012,3.916-0.005c0.621-0.008,1.463,0.112,1.759-0.365c0.254-0.409-0.501-0.889-0.786-1.352
	c-0.125-0.203-0.249-0.407-0.384-0.604c-0.609-0.885-1.19-1.792-1.845-2.642c-0.345-0.448-0.742-0.776-1.022,0.161
	C53.712,19.3,53.127,20.844,52.546,22.527z M66.847,41.182c-1.136-1.639-2.201-3.206-3.308-4.742
	c-0.207-0.287-0.647-0.693-0.885-0.026c-0.589,1.649-1.11,3.323-1.69,5.085C62.971,41.337,64.844,41.692,66.847,41.182z"
    />
  </svg>
);

const KebabHorizontal = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
    <path
      d="M838.309,596.442c-57.174-2.781-96.854-48.979-95.014-101.195c2.056-58.361,50.727-98.33,103.921-95.021
	c54.673,3.401,94.692,48.858,92.219,102.174C936.821,558.774,890.57,597.566,838.309,596.442z"
    />
    <path
      d="M499.944,400.021c53.765,0.017,98.147,44.59,97.99,98.409c-0.158,53.866-44.584,98.132-98.405,98.049
	c-53.892-0.082-98.23-44.389-98.288-98.217C401.184,444.218,445.606,400.003,499.944,400.021z"
    />
    <path
      d="M154.736,596.443c-57.15-2.764-96.867-48.922-95.043-101.169c2.037-58.36,50.693-98.343,103.893-95.05
	c54.656,3.383,94.704,48.809,92.248,102.148C253.24,558.722,207.027,597.547,154.736,596.443z"
    />
  </svg>
);

const KebabVertical = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
    <path
      d="M496.527,263.526c-57.174-2.781-96.854-48.979-95.014-101.195c2.056-58.361,50.727-98.33,103.921-95.021
    c54.673,3.401,94.692,48.858,92.22,102.174C595.039,225.858,548.788,264.65,496.527,263.526z"
    />
    <path
      d="M499.944,400.021c53.765,0.017,98.147,44.59,97.99,98.409c-0.158,53.866-44.584,98.132-98.405,98.049
    c-53.892-0.082-98.23-44.389-98.288-98.217C401.184,444.218,445.606,400.003,499.944,400.021z"
    />
    <path
      d="M496.555,929.403c-57.15-2.764-96.867-48.922-95.043-101.169c2.037-58.36,50.693-98.343,103.893-95.05
    c54.656,3.383,94.704,48.809,92.249,102.148C595.06,891.682,548.846,930.508,496.555,929.403z"
    />
  </svg>
);

const Check = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2400 2400">
    <g>
      <g>
        <path
          d="M1059.526,1683.591
			c2.674-2.605,5.312-5.118,7.887-7.693c255.431-255.422,510.857-510.848,766.283-766.274c1.885-1.885,3.809-3.734,5.639-5.671
			c1.104-1.168,2.633-2.135,2.414-4.817c-0.916-1.032-2.144-2.555-3.515-3.936c-7.045-7.094-14.127-14.151-21.197-21.22
			c-26.393-26.393-52.772-52.8-79.211-79.147c-1.804-1.797-3.155-4.406-6.095-4.564c-0.443,0.172-0.758,0.262-1.04,0.414
			c-0.29,0.157-0.557,0.362-0.815,0.57c-0.26,0.208-0.5,0.441-0.743,0.669c-0.243,0.228-0.48,0.463-0.718,0.696
			c-0.238,0.233-0.474,0.468-0.71,0.704c-0.472,0.471-0.943,0.942-1.414,1.413c-215.621,215.621-431.242,431.241-646.863,646.862
			c-0.707,0.707-1.414,1.414-2.121,2.12c-0.472,0.471-0.943,0.942-1.416,1.412c-0.236,0.235-0.472,0.471-0.713,0.701
			c-0.241,0.23-0.484,0.459-0.734,0.678c-0.25,0.219-0.499,0.441-0.768,0.635c-0.27,0.194-0.545,0.41-0.853,0.514
			c-0.304,0.102-0.651,0.077-2.18,0.219c-40.868-40.865-82.282-82.281-123.697-123.695c-41.711-41.71-83.42-83.421-125.131-125.131
			c-41.465-41.465-82.931-82.931-124.59-124.59c-1.279,0.989-2.373,1.685-3.291,2.566c-12.498,11.995-25.032,23.953-37.436,36.045
			c-16.943,16.516-33.77,33.152-50.68,49.703c-2.853,2.793-5.89,5.397-8.81,8.124c-3.134,2.928-6.442,5.704-8.852,9.052
			c0.984,3.459,3.135,5.541,5.16,7.699c21.197,22.593,42.407,45.175,63.601,67.771c8.887,9.475,17.733,18.989,26.621,28.463
			c21.425,22.836,42.871,45.653,64.294,68.491c8.432,8.989,16.809,18.028,25.24,27.018c21.192,22.598,42.405,45.176,63.599,67.772
			c8.887,9.475,17.736,18.986,26.622,28.462c20.964,22.355,41.944,44.695,62.909,67.049c9.115,9.719,18.191,19.474,27.315,29.183
			c12.776,13.595,25.572,27.17,38.375,40.74c14.861,15.751,29.745,31.48,44.601,47.235c1.369,1.452,2.562,3.068,3.927,4.524
			c5.47,5.833,10.96,11.647,16.486,17.426C1057.548,1682.449,1058.455,1682.862,1059.526,1683.591z"
        />
      </g>
    </g>
  </svg>
);

const Checkbox = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2400 2400">
    <g>
      <g>
        <path
          d="M2338,488c0,478,0,956,0,1434c-0.457,0.81-1.26,1.598-1.311,2.433c-0.242,3.989-0.483,7.997-0.352,11.985
			c0.395,12.033-1.084,23.953-2.633,35.8c-1.978,15.133-4.792,30.163-7.508,45.191c-3.85,21.3-9.687,42.125-16.66,62.568
			c-9.374,27.486-21.734,53.661-36.787,78.533c-12.287,20.302-26.787,38.93-42.882,56.314c-7.465,8.063-15.54,15.507-23.857,22.73
			c-21.23,18.438-44.343,34.023-69.228,47.038c-26.944,14.092-55.266,24.747-84.515,32.796
			c-16.634,4.578-33.495,8.399-50.615,10.971c-9.854,1.48-19.689,3.019-29.587,4.226c-13.531,1.65-27.035,3.418-40.687,3.683
			c-2.998,0.058-6.002,0.113-8.984,0.375c-0.832,0.073-1.599,0.883-2.396,1.355c-474.667,0-949.333,0-1424,0
			c-1.382-2.087-3.594-1.459-5.445-1.431c-14.022,0.205-27.925-1.516-41.825-2.834c-11.882-1.127-23.642-3.503-35.471-5.22
			c-23.082-3.35-45.622-9.018-67.905-15.78c-31.047-9.422-60.485-22.503-88.424-38.994c-36.286-21.417-67.414-48.825-93.454-81.822
			c-25.168-31.891-43.911-67.469-57.67-105.655c-7.786-21.609-13.739-43.729-18.468-66.227c-3.969-18.886-6.964-37.914-9.062-57.049
			c-1.448-13.203-3.065-26.464-2.752-39.821c0.031-1.33,0.121-2.713-0.174-3.988c-1.896-8.205-1.231-16.566-1.631-24.86
			c-0.112-2.32-0.19-4.647-0.449-6.952c-0.056-0.497-0.827-0.913-1.269-1.367c0-454,0-908,0-1362c1.494-0.626,1.6-1.973,1.645-3.297
			c0.377-11.288,0.462-22.559,1.748-33.839c1.163-10.203,0.684-20.565,2.226-30.807c1.234-8.198,1.635-16.519,2.579-24.766
			c0.604-5.274,1.329-10.55,2.34-15.759c2.274-11.722,4.876-23.38,7.132-35.105c3.084-16.023,7.72-31.647,13.02-47.006
			c8.688-25.174,19.189-49.59,32.692-72.668c14.011-23.946,30.349-46.106,49.371-66.271c7.305-7.744,15.122-14.959,23.207-21.927
			c17.201-14.824,35.536-28.011,55.332-39.075c25.902-14.478,52.915-26.434,81.386-35.045c28.663-8.67,57.756-15.149,87.479-18.946
			c5.931-0.758,11.905-1.182,17.796-2.105c12.501-1.959,25.13-2.251,37.699-3.221c9.942-0.768,19.965-0.476,29.95-0.706
			c1.573-0.036,3.392,0.404,4.398-1.457c454.667,0,909.333,0,1364,0c1.026,1.862,2.856,1.395,4.42,1.4
			c24.973,0.076,49.868,1.209,74.698,4.16c9.9,1.177,19.761,2.454,29.61,3.994c21.035,3.288,41.78,7.74,62.27,13.581
			c33.446,9.534,65.404,22.458,95.511,39.957c18.779,10.915,36.466,23.38,52.788,37.63c15.822,13.812,30.521,28.835,43.506,45.412
			c26.107,33.329,45.734,70.165,59.638,110.133c11.619,33.402,19.392,67.65,24.048,102.692c2.106,15.848,3.938,31.666,3.912,47.668
			c-0.004,2.658,0.175,5.322,0.413,7.97C2336.859,487.096,2337.587,487.534,2338,488z M1059.526,1683.591
			c2.674-2.605,5.312-5.118,7.887-7.693c255.431-255.422,510.857-510.848,766.283-766.274c1.885-1.885,3.809-3.734,5.639-5.671
			c1.104-1.168,2.633-2.135,2.414-4.817c-0.916-1.032-2.144-2.555-3.515-3.936c-7.045-7.094-14.127-14.151-21.197-21.22
			c-26.393-26.393-52.772-52.8-79.211-79.147c-1.804-1.797-3.155-4.406-6.095-4.564c-0.443,0.172-0.758,0.262-1.04,0.414
			c-0.29,0.157-0.557,0.362-0.815,0.57c-0.26,0.208-0.5,0.441-0.743,0.669c-0.243,0.228-0.48,0.463-0.718,0.696
			c-0.238,0.233-0.474,0.468-0.71,0.704c-0.472,0.471-0.943,0.942-1.414,1.413c-215.621,215.621-431.242,431.241-646.863,646.862
			c-0.707,0.707-1.414,1.414-2.121,2.12c-0.472,0.471-0.943,0.942-1.416,1.412c-0.236,0.235-0.472,0.471-0.713,0.701
			c-0.241,0.23-0.484,0.459-0.734,0.678c-0.25,0.219-0.499,0.441-0.768,0.635c-0.27,0.194-0.545,0.41-0.853,0.514
			c-0.304,0.102-0.651,0.077-2.18,0.219c-40.868-40.865-82.282-82.281-123.697-123.695c-41.711-41.71-83.42-83.421-125.131-125.131
			c-41.465-41.465-82.931-82.931-124.59-124.59c-1.279,0.989-2.373,1.685-3.291,2.566c-12.498,11.995-25.032,23.953-37.436,36.045
			c-16.943,16.516-33.77,33.152-50.68,49.703c-2.853,2.793-5.89,5.397-8.81,8.124c-3.134,2.928-6.442,5.704-8.852,9.052
			c0.984,3.459,3.135,5.541,5.16,7.699c21.197,22.593,42.407,45.175,63.601,67.771c8.887,9.475,17.733,18.989,26.621,28.463
			c21.425,22.836,42.871,45.653,64.294,68.491c8.432,8.989,16.809,18.028,25.24,27.018c21.192,22.598,42.405,45.176,63.599,67.772
			c8.887,9.475,17.736,18.986,26.622,28.462c20.964,22.355,41.944,44.695,62.909,67.049c9.115,9.719,18.191,19.474,27.315,29.183
			c12.776,13.595,25.572,27.17,38.375,40.74c14.861,15.751,29.745,31.48,44.601,47.235c1.369,1.452,2.562,3.068,3.927,4.524
			c5.47,5.833,10.96,11.647,16.486,17.426C1057.548,1682.449,1058.455,1682.862,1059.526,1683.591z"
        />
      </g>
    </g>
  </svg>
);

const UncheckedCheckbox = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2400 2400">
    <path
      d="M2336,492c0,474,0,948,0,1422c-1.648,0.475-1.641,1.848-1.666,3.158c-0.161,8.323-0.298,16.645-0.456,24.968
    c-0.044,2.33,0.363,4.805-0.278,6.964c-2.579,8.688-0.433,17.949-3.432,26.548c-1.057,12.537-3.597,24.826-5.949,37.162
    c-3.282,17.206-7.482,34.191-12.562,50.917c-4.608,15.173-10.34,29.975-16.522,44.618c-6.856,16.239-14.848,31.893-24.057,46.821
    c-16.425,26.627-35.564,51.144-58.907,72.256c-9.128,8.255-18.425,16.244-28.181,23.726c-11.332,8.691-23.191,16.599-35.67,23.523
    c-15.642,8.679-31.629,16.67-48.249,23.364c-18.105,7.292-36.596,13.323-55.506,18.18c-16.001,4.11-32.114,7.456-48.404,10.266
    c-12.061,2.081-24.191,3.789-36.294,5.001c-14.494,1.452-29.047,3.307-43.708,2.808c-2.978-0.101-5.975,0.153-8.952,0.386
    c-0.435,0.034-0.806,0.869-1.207,1.333c-467.333,0-934.667,0-1402,0c-0.49-1.662-1.876-1.583-3.182-1.61
    c-8.663-0.183-17.327-0.344-25.989-0.537c-2.331-0.052-4.827,0.309-6.958-0.395c-4.883-1.614-9.885-1.063-14.822-1.562
    c-4.935-0.499-9.844-1.242-14.769-1.837c-6.907-0.834-13.867-1.348-20.718-2.51c-9.111-1.544-18.123-3.666-27.206-5.384
    c-13.321-2.52-26.429-5.882-39.412-9.742c-13.953-4.148-27.7-8.857-41.277-14.159c-23.589-9.211-45.756-21.052-67.042-34.662
    c-14.02-8.964-27.214-19.082-39.592-30.126c-14.413-12.86-28.038-26.623-39.861-41.954c-8.703-11.286-16.88-22.953-24.386-35.141
    c-13.103-21.275-23.97-43.589-32.578-66.965c-8.8-23.896-15.741-48.335-20.743-73.378c-2.787-13.951-5.195-27.956-7.075-42.008
    c-1.181-8.828-3.07-17.692-2.608-26.724c0.051-0.993,0.137-2.074-0.177-2.981c-2.008-5.814-1.347-11.851-1.578-17.806
    c-0.233-5.993,0.504-12.149-0.63-17.95c-1.361-6.966-1.136-13.871-1.326-20.815c-0.154-5.662-0.071-11.33-0.071-16.996
    c-0.002-444.918-0.002-889.837,0.004-1334.756c0-8.331,0.083-16.663,0.141-24.994c0.014-1.999-0.331-4.118,0.205-5.975
    c2.072-7.18,1.565-14.537,1.497-21.794c-0.113-12.013,2.696-23.785,2.453-35.778c-0.007-0.331-0.017-0.688,0.088-0.993
    c2.516-7.303,0.881-15.243,3.465-22.533c0.904-12.246,3.744-24.163,6.151-36.153c2.927-14.582,6.714-28.958,10.912-43.211
    c6.657-22.598,15.456-44.432,25.936-65.475c9.167-18.406,19.661-36.131,32.009-52.685c10.154-13.614,21.626-26.087,32.927-38.712
    c1.552-1.733,3.278-3.321,5.013-4.876c3.214-2.882,6.578-5.6,9.73-8.547c8.753-8.187,18.171-15.545,27.964-22.416
    c10.32-7.241,20.949-13.969,31.858-20.347c15.244-8.912,31.131-16.435,47.413-22.992c17.767-7.155,35.92-13.459,54.647-17.764
    c10.298-2.367,20.457-5.293,30.92-7.045c7.818-1.31,15.597-2.819,23.445-4.031c10.807-1.67,21.552-3.643,32.517-3.938
    c0.666-0.018,1.373,0.06,1.99-0.133c7.129-2.23,14.587-0.895,21.768-1.985c7.599-1.153,15.184-1.507,22.816-1.56
    c18.995-0.132,37.99-0.271,56.985-0.272c427.255-0.015,854.51-0.051,1281.765,0.067c32.929,0.009,65.911-1.566,98.783,1.927
    c6.587,0.7,13.226-0.075,19.836,1.531c6.405,1.556,13.3,0.074,19.696,2.401c12.882,1.052,25.517,3.573,38.199,5.984
    c16.906,3.214,33.561,7.269,50.013,12.276c32.146,9.785,62.821,22.877,91.655,40.21c19.125,11.496,37.045,24.673,53.576,39.599
    c14.328,12.937,27.426,27.081,39.285,42.419c16.721,21.625,30.795,44.753,42.451,69.458c6.514,13.807,12.143,27.921,17.075,42.348
    c8.356,24.441,14.723,49.363,19.08,74.837c0.896,5.236,1.365,10.51,2.298,15.711c1.578,8.8,3.226,17.572,3.538,26.535
    c0.058,1.665-0.207,3.478,0.359,4.962c1.724,4.518,1.281,9.191,1.413,13.818c0.22,7.662,0.289,15.327,0.441,22.991
    C2334.344,490.171,2334.328,491.552,2336,492z M1203.981,157.755c-218.981,0-437.961-0.01-656.942,0.017
    c-18.997,0.002-37.994,0.236-56.991,0.385c-2.665,0.021-5.495-0.369-7.959,0.382c-4.908,1.498-9.892,1.03-14.835,1.487
    c-5.27,0.488-10.516,1.229-15.775,1.835c-7.242,0.835-14.594,1.147-21.688,2.665c-3.929,0.84-7.931,0.883-11.759,1.856
    c-12.801,3.253-25.794,5.611-38.539,9.207c-17.886,5.047-35.359,11.118-52.226,18.809c-16.632,7.583-32.529,16.559-47.238,27.516
    c-10.133,7.548-20.089,15.286-29.085,24.216c-8.99,8.923-17.125,18.542-24.872,28.554c-11.028,14.253-20.024,29.739-27.95,45.818
    c-5.996,12.164-11.048,24.784-15.34,37.688c-6.372,19.161-11.518,38.608-15.033,58.516c-0.925,5.238-1.041,10.64-2.398,15.682
    c-1.742,6.473-0.923,13.258-3.164,19.573c-0.479,12.611-2.967,25.091-2.459,37.769c0.067,1.661,0.22,3.403-0.166,4.985
    c-2.081,8.535-1.177,17.211-1.359,25.827c-0.175,8.329-0.059,16.664-0.059,24.997c-0.001,437.628-0.001,875.256,0,1312.884
    c0,9.333,0.303,18.676-0.048,27.995c-0.514,13.674,1.637,27.185,1.979,40.807c0.25,9.937,1.449,19.849,2.227,29.772
    c0.026,0.332-0.013,0.694,0.107,0.991c2.532,6.266,1.766,13.067,3.126,19.58c1.681,8.052,2.839,16.268,4.473,24.368
    c2.484,12.313,5.715,24.443,9.363,36.453c7.839,25.803,18.524,50.363,32.613,73.394c6.941,11.346,14.567,22.275,23.381,32.19
    c11.277,12.686,23.159,24.834,36.711,35.225c12.941,9.922,26.537,18.788,40.947,26.409c12.918,6.832,26.356,12.542,40.018,17.638
    c13.627,5.083,27.612,9.252,41.804,12.378c12.249,2.698,24.534,5.326,37.05,6.886c9.839,1.226,19.569,3.345,29.531,3.656
    c1.332,0.042,2.745-0.129,3.98,0.253c6.491,2.006,13.184,1.374,19.801,1.691c3.328,0.16,6.797-0.371,9.967,0.389
    c6.921,1.66,13.889,1.128,20.842,1.275c7.33,0.155,14.665,0.077,21.997,0.077c426.629,0.002,853.258,0.048,1279.887-0.086
    c33.267-0.01,66.548,1.233,99.822-1.309c15.207-1.162,30.403-1.969,45.545-4.04c9.508-1.301,18.981-2.708,28.45-4.262
    c13.401-2.199,26.623-5.246,39.587-9.101c25.427-7.561,50.175-16.837,73.294-30.213c18.507-10.707,35.573-23.222,51.144-37.797
    c8.022-7.509,15.624-15.452,22.284-24.23c4.416-5.82,9.042-11.509,13.072-17.59c12.135-18.314,22.315-37.741,30.089-58.255
    c8.319-21.952,14.882-44.475,19.084-67.655c0.884-4.878,1.63-9.781,2.5-14.661c1.334-7.486,3.077-14.915,3.3-22.567
    c0.058-1.998-0.223-4.151,0.42-5.959c1.727-4.853,1.33-9.851,1.603-14.81c0.11-1.996-0.198-4.085,0.283-5.98
    c2.163-8.519,1.47-17.199,1.636-25.816c0.237-12.328,0.222-24.663,0.223-36.995c0.009-436.295,0.041-872.589-0.067-1308.884
    c-0.007-28.933,1.443-57.917-1.902-86.797c-0.761-6.572,0.146-13.319-1.987-19.764c-0.368-10.965-2.431-21.715-4.334-32.468
    c-1.496-8.449-3.372-16.835-5.244-25.212c-4.046-18.101-9.468-35.765-16.35-53.016c-3.812-9.557-7.885-18.994-12.551-28.123
    c-6.949-13.597-15.061-26.548-24.144-38.84c-3.748-5.073-7.728-10.016-11.999-14.654c-11.286-12.254-22.958-24.149-36.295-34.217
    c-6.35-4.794-12.887-9.379-19.618-13.618c-12.07-7.602-24.591-14.363-37.686-20.129c-17.616-7.757-35.753-13.859-54.307-18.877
    c-11.173-3.021-22.593-4.883-33.823-7.626c-4.485-1.095-9.11-1.816-13.713-2.178c-7.278-0.573-14.504-1.755-21.694-2.74
    c-6.953-0.952-13.895-0.961-20.806-1.711c-6.924-0.751-13.802-1.715-20.803-1.697c-15.998,0.043-31.996-0.212-47.994-0.213
    C1644.609,157.748,1424.295,157.754,1203.981,157.755z"
    />
  </svg>
);

const ArrowRight = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.68 79.68">
    <path
      d="M38.88,12c2.08,1.159,3.969,2.597,5.84,4.056c2.297,1.791,4.575,3.607,6.862,5.412c1.885,1.487,3.786,2.955,5.651,4.466
	c1.533,1.242,3.013,2.551,4.541,3.8c0.973,0.796,1.998,1.527,2.986,2.305c0.558,0.44,1.1,0.902,1.629,1.377
	c1.615,1.449,3.323,2.781,5.025,4.126c1.16,0.917,1.626,2.278,1.244,3.72c-0.179,0.677-0.678,1.389-1.236,1.824
	c-1.744,1.357-3.438,2.767-5.116,4.204c-1.433,1.228-2.976,2.326-4.434,3.526c-1.282,1.056-2.498,2.192-3.772,3.257
	c-0.965,0.806-1.98,1.552-2.967,2.334c-0.927,0.734-1.833,1.494-2.77,2.216c-1.993,1.536-4.045,2.998-5.986,4.595
	c-2.039,1.678-4.204,3.171-6.372,4.665c-0.548,0.378-1.114,0.735-1.738,0.415c-0.648-0.333-0.836-0.994-0.825-1.724
	c0.023-1.519,0.023-3.039-0.001-4.558c-0.01-0.642,0.183-1.176,0.664-1.582c1.025-0.866,2.061-1.719,3.106-2.562
	c1.04-0.84,2.094-1.663,3.146-2.489c0.774-0.608,1.559-1.203,2.332-1.813c1.111-0.878,2.222-1.757,3.321-2.65
	c1.316-1.068,2.625-2.144,3.927-3.23c0.699-0.583,1.373-1.196,2.06-1.793c0.567-0.493,1.14-0.978,1.699-1.479
	c0.074-0.067,0.129-0.218,0.107-0.309c-0.019-0.077-0.159-0.17-0.251-0.176c-0.358-0.024-0.719-0.019-1.079-0.019
	c-17.233-0.001-34.465,0-51.698,0c-0.28,0-0.56,0.012-0.84-0.003c-0.693-0.038-1.271-0.594-1.28-1.29
	c-0.02-1.479-0.016-2.959-0.003-4.438c0.008-0.923,0.543-1.432,1.481-1.453c0.24-0.005,0.48-0.001,0.72-0.001
	c17.113,0,34.225,0,51.338,0c0.2,0,0.402,0.017,0.599-0.004c0.343-0.036,0.917-0.004,0.979-0.164c0.176-0.449-0.378-0.48-0.63-0.695
	c-0.692-0.591-1.369-1.201-2.053-1.801c-1.338-1.175-2.647-2.387-4.024-3.515c-1.764-1.446-3.591-2.814-5.376-4.235
	c-1.019-0.811-1.992-1.681-3.017-2.484c-1.205-0.944-2.454-1.833-3.663-2.772c-0.408-0.317-0.781-0.689-1.119-1.08
	c-0.283-0.328-0.449-0.723-0.444-1.19c0.015-1.639,0.025-3.279-0.002-4.918c-0.015-0.913,0.575-1.391,1.2-1.869
	C38.72,12,38.8,12,38.88,12z"
    />
  </svg>
);

const ArrowLeft = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.68 79.68">
    <path
      d="M36.72,12c0.173,0.138,0.354,0.266,0.517,0.415c0.463,0.42,0.695,0.928,0.683,1.574c-0.025,1.359-0.111,2.729,0.021,4.075
	c0.134,1.361-0.567,2.197-1.491,2.92c-1.484,1.161-3.044,2.225-4.53,3.384c-1.43,1.116-2.799,2.31-4.209,3.453
	c-1.56,1.264-3.147,2.495-4.698,3.77c-0.826,0.679-1.592,1.431-2.402,2.13c-0.926,0.799-1.871,1.575-2.807,2.361
	c-0.031,0.026-0.072,0.047-0.087,0.081c-0.062,0.136-0.136,0.273-0.156,0.416c-0.005,0.033,0.185,0.129,0.289,0.135
	c0.359,0.019,0.719,0.013,1.079,0.013c16.993,0,33.986,0,50.978,0c0.44,0,0.88-0.007,1.319,0.002
	c0.938,0.018,1.472,0.53,1.481,1.453c0.013,1.439,0.014,2.879-0.001,4.318c-0.009,0.861-0.544,1.383-1.402,1.411
	c-0.28,0.009-0.56,0.002-0.84,0.002c-17.193,0-34.385,0-51.578,0c-0.36,0-0.72-0.005-1.079,0.019
	c-0.092,0.006-0.232,0.099-0.251,0.176c-0.022,0.091,0.032,0.246,0.107,0.309c1.387,1.169,2.786,2.324,4.174,3.493
	c0.666,0.561,1.306,1.152,1.971,1.714c1.508,1.273,3.017,2.546,4.542,3.8c0.576,0.473,1.202,0.884,1.795,1.337
	c0.719,0.549,1.427,1.111,2.139,1.669c1.209,0.947,2.425,1.886,3.624,2.847c0.524,0.419,1.026,0.868,1.513,1.329
	c0.361,0.342,0.497,0.786,0.493,1.29c-0.012,1.599-0.004,3.199-0.004,4.798c0,0.08,0.006,0.16-0.002,0.24
	c-0.118,1.305-1.177,1.872-2.27,1.165c-1.121-0.724-2.21-1.5-3.285-2.291c-1.362-1.002-2.696-2.04-4.042-3.065
	c-1.97-1.5-3.946-2.991-5.904-4.507c-0.967-0.749-1.892-1.551-2.844-2.319c-1.133-0.914-2.28-1.812-3.411-2.729
	c-0.583-0.472-1.141-0.977-1.721-1.453c-1.279-1.05-2.577-2.078-3.845-3.142c-1.242-1.042-2.448-2.128-3.686-3.175
	c-0.873-0.738-1.76-1.462-2.666-2.158c-1.35-1.038-1.885-2.202-1.57-3.784c0.166-0.834,0.769-1.46,1.379-1.999
	c1.091-0.964,2.251-1.851,3.386-2.765c1.594-1.285,3.203-2.552,4.784-3.853c1.16-0.954,2.274-1.963,3.428-2.923
	c1.12-0.931,2.268-1.829,3.401-2.744c0.951-0.768,1.896-1.544,2.849-2.311c0.921-0.742,1.845-1.48,2.772-2.214
	c0.987-0.78,1.975-1.56,2.971-2.328c1.495-1.152,2.991-2.305,4.502-3.437c1.037-0.777,2.089-1.535,3.154-2.273
	c0.366-0.254,0.791-0.422,1.189-0.629C36.56,12,36.64,12,36.72,12z"
    />
  </svg>
);

const Cash = (props: any) => (
  <svg
    version="1.1"
    id="_x24__xA0_Image_1_"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 79.68 79.68"
  >
    <path
      d="M60.468,53.515c-0.448,0.422-0.302,1.028-0.43,1.536c-0.759,3.012-2.435,5.403-4.733,7.431
	c-1.88,1.659-3.993,2.91-6.348,3.723c-1.231,0.425-2.523,0.671-3.789,0.995c-0.153,0.039-0.312,0.058-0.467,0.088
	c-0.483,0.096-0.747,0.246-0.871,0.868c-0.417,2.085-1.725,3.636-3.343,4.947c-0.494,0.4-0.968,0.826-1.477,1.204
	c-0.279,0.207-0.597,0.389-0.926,0.495c-0.369,0.119-0.634-0.121-0.603-0.507c0.067-0.83,0.159-1.658,0.241-2.486
	c0.125-1.264,0.247-2.529,0.377-3.792c0.028-0.273-0.127-0.344-0.346-0.362c-0.951-0.077-1.903-0.142-2.854-0.228
	c-2.377-0.216-4.708-0.69-7.015-1.289c-0.076-0.02-0.144-0.088-0.219-0.094c-1.549-0.113-2.542-1.045-3.288-2.289
	c-1.391-2.319-2.292-4.797-2.321-7.539c-0.002-0.16-0.006-0.32,0.01-0.479c0.027-0.268,0.21-0.328,0.418-0.231
	c0.215,0.099,0.414,0.24,0.604,0.384c2.663,2.018,5.634,3.418,8.8,4.441c1.971,0.637,4.007,0.957,6.039,1.298
	c0.262,0.044,0.478-0.002,0.517-0.325c0.053-0.433,0.144-0.864,0.158-1.298c0.039-1.158-0.036-2.326,0.081-3.475
	c0.414-4.066,0.101-8.14,0.204-12.209c0.005-0.2-0.015-0.401,0.004-0.599c0.042-0.443-0.173-0.642-0.585-0.746
	c-1.576-0.398-3.149-0.806-4.717-1.234c-1.884-0.514-3.706-1.189-5.393-2.193c-3.414-2.03-5.318-5.055-5.873-8.956
	c-0.112-0.785-0.204-1.58-0.214-2.372c-0.044-3.441,1.059-6.469,3.279-9.111c2.806-3.339,6.387-5.313,10.628-6.15
	c1.77-0.349,1.789-0.32,2.809-1.818c1.315-1.93,2.9-3.588,4.825-4.914c0.096-0.066,0.199-0.138,0.309-0.168
	c0.215-0.06,0.4-0.024,0.414,0.254c0.023,0.479,0.066,0.959,0.049,1.437c-0.053,1.472-0.137,2.943-0.188,4.415
	c-0.004,0.105,0.147,0.293,0.249,0.31c0.587,0.1,1.178,0.187,1.771,0.228c2.667,0.183,5.251,0.792,7.829,1.454
	c0.568,0.146,0.988,0.45,1.39,0.863c1.202,1.238,1.839,2.785,2.305,4.383c0.364,1.246,0.491,2.561,0.718,3.847
	c0.021,0.116,0.01,0.24,0.001,0.359c-0.025,0.335-0.257,0.482-0.577,0.331c-0.25-0.118-0.478-0.285-0.713-0.434
	c-2.733-1.729-5.558-3.276-8.696-4.138c-1.334-0.367-2.71-0.582-4.07-0.854c-0.304-0.061-0.542,0.001-0.569,0.4
	c-0.038,0.554-0.171,1.105-0.173,1.658c-0.02,5.156-0.024,10.312-0.024,15.468c0,0.764,0.031,0.724,0.78,0.924
	c2.297,0.613,4.602,1.205,6.872,1.91c2.041,0.633,3.849,1.759,5.414,3.198c1.968,1.81,3.121,4.092,3.497,6.755
	c0.029,0.209,0.151,0.405,0.23,0.607C60.468,50.796,60.468,52.156,60.468,53.515z M43.671,52.724c0,1.559,0,3.118,0,4.678
	c0,1.279-0.003,2.559,0.006,3.838c0.001,0.196,0.013,0.345,0.337,0.303c1.671-0.218,3.271-0.644,4.756-1.458
	c1.648-0.904,2.785-2.221,3.318-4.044c0.535-1.829,0.588-3.662,0.277-5.547c-0.38-2.308-1.599-3.938-3.632-5.019
	c-1.415-0.753-2.913-1.263-4.479-1.585c-0.565-0.116-0.581-0.114-0.582,0.438C43.668,47.127,43.671,49.926,43.671,52.724z
	 M38.848,35.115c-0.035-0.222-0.144-0.651-0.162-1.083c-0.103-2.547-0.177-5.094-0.274-7.641c-0.08-2.108-0.175-4.216-0.273-6.323
	c-0.011-0.235-0.073-0.469-0.127-0.7c-0.164-0.699-0.264-0.797-0.947-0.617c-1.546,0.408-2.991,1.022-4.277,2.021
	c-1.248,0.969-2.085,2.196-2.492,3.687c-0.502,1.839-0.417,3.703,0.089,5.536c0.514,1.86,1.643,3.233,3.343,4.156
	c1.443,0.784,2.972,1.311,4.574,1.639C38.87,35.907,38.871,35.9,38.848,35.115z"
    />
  </svg>
);

const Icons: any = {
  //general
  "chevron-down": ChevronDown,
  "chevron-up": ChevronUp,
  "chevron-left": ChevronLeft,
  "chevron-right": ChevronRight,
  back: Back,
  minus: Minus,
  settings: Settings,
  "kebab-horizontal": KebabHorizontal,
  "kebab-vertical": KebabVertical,

  //stripe
  stripe: Stripe,

  //Nexus menu icons
  faq: FAQ,
  subscription: Subscription,
  note: Note,

  //vox storia icons
  edit: Edit,
  close: Close,
  plus: Plus,
  duplicate: Duplicate,
  search: Search,
  cart: Cart,
  "favorite-heart": FavoriteHeart,
  "unfavorite-heart": UnfavoriteHeart,
  hamburger: Hamburger,

  //Action icons with token frames
  add: Add,
  "character-bio": CharacterBio,
  delete: Delete,
  download: Download,
  collection: Collection,
  arrowleft: ArrowLeft,
  arrowright: ArrowRight,
  cash: Cash,
  thenIcon: ThenIcon,
  public: PrivacyPublic,
  private: PrivacyPrivate,

  //other
  info: Info,
  share: Share,
  tags: Tags,
  view: View,
  user: User,
  background: Background,
  checkbox: Checkbox,
  uncheckedCheckbox: UncheckedCheckbox,
  check: Check,
};

//options for size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
//primary is light theme(amber), secondary is dark(purple)

type SizeOptions = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
type VariantOptions = "primary" | "secondary" | "black" | "white";

interface IconProps {
  className?: string;
  name?: string;
  size?: SizeOptions;
  variant?: VariantOptions;
  items?: null | number; //number of items for cart
  [key: string]: any;
}

export function Icon({
  className = "",
  name,
  size = "md",
  variant = "primary", //primary, secondary, black, white
  items = null,
  ...props
}: IconProps) {
  //block starts on new lines
  //inline does not start on new line
  const IconTag = props?.isBlock ? "div" : "span";
  const Icon = Icons[name as string];
  const classes = clsx("c-icon", `-${size}`, className);

  const themes: any = {
    primary: "#040666",
    secondary: "#ff8900",
    white: "#ffffff",
    black: "#292929",
  };

  return (
    <IconTag style={{ fill: `${themes[variant]}` }} className={classes}>
      {items && <span className="c-icon__items">{items}</span>}
      <Icon {...props} />
    </IconTag>
  );
}
