import {
  Alert,
  Button,
  Container,
  FormControl,
  FormLabel,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { ChangeEvent, FormEvent, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import { createRegister, v3Login } from "../../utility/Endpoints";
import Get from "../../utility/Get";
import Post from "../../utility/Post";
import {
  registerDataType,
  registerFormType,
  registerInputTypes,
} from "../../utility/Types";
import moment from "moment";
import { V3Organization } from "../../utility/context/V3Organization";

interface registerProps {
  setUser: (user: any) => void;
  setLoginError: (x: boolean) => void;
  setScreen: (screen: string) => void;
}

export default function Register({
  setUser,
  setLoginError,
  setScreen,
}: registerProps): JSX.Element {
  const org = useContext(V3Organization)

  const [form, setForm] = useState<registerFormType>(null);
  const [errors, setErrors] = useState<registerDataType>({
    date_of_birth: undefined,
    email: undefined,
    first_name: undefined,
    last_name: undefined,
    password: undefined,
    username: undefined,
  });

  const inputTypes: registerInputTypes = {
    date_of_birth: "date",
    email: "email",
    first_name: "text",
    last_name: "text",
    password: "password",
    username: "text",
  };

  const navigator = useNavigate();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<boolean>(false);

  useEffect(() => {
    if (org.pk) {
      Get(createRegister(org.pk)).then((res) => {
        if (res.status && res.status < 300) {
          setForm({ data: res.data.form.data, fields: res.data.form.fields });
        } else {
          console.error("There was an error retrieving the form data.");
        }
      });
    }
  }, [org]);

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    if (form) {
      for (const item of form.fields) {
        formData.append(
          item.name,
          form.data[item.name as keyof registerDataType] as string
        );
      }
    }

    if (form && !moment(form.data.date_of_birth).isBefore(moment())) {
      setErrors((prev) => ({
        ...prev,
        date_of_birth: `You can't be older than the current date.`,
      }));
      setLoading(false);
    } else if (
      form &&
      moment(form.data.date_of_birth).diff(150, "year") * -1 > 150
    ) {
      setErrors((prev) => ({
        ...prev,
        date_of_birth: `You must be younger than 150 years old.`,
      }));
      setLoading(false);
    } else {
      Post(createRegister(org.pk), formData).then((res) => {
        if (res.status && res.status < 300) {
          // Login and redirect to dashboard
          if (form) {
            const loginFormData = new FormData();
            loginFormData.append(
              "username",
              form.data["username" as keyof registerDataType] ?? ""
            );
            loginFormData.append(
              "password",
              form.data["password" as keyof registerDataType] ?? ""
            );

            Post(v3Login(org.pk), loginFormData).then((res) => {
              if (res.status && res.status < 300) {
                //save user to local
                localStorage.setItem(
                  "vstreamer_user",
                  JSON.stringify(res.data.data)
                );
                localStorage.setItem("sessionid", res.data.data.sessionid);
                //update App with user info
                setUser(res.data.data);

                // Clear avatar-stream from local storage
                localStorage.removeItem("avatar-stream");

                navigator("/");
              } else {
                setLoginError(true);
                setScreen("login");
              }
            });
          } else {
            setFormError(true);
            setLoading(false);
          }
        } else {
          setLoading(false);
          setErrors(res.data.form.errors);
        }
      });
    }
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (form) {
      const tempForm: registerFormType = form;
      tempForm.data[e.target.name as keyof registerDataType] = e.target.value;
      setForm((prev) => ({ ...prev, ...tempForm }));
    }
  }

  if (form === null) {
    return (
      <Container
        className="register"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h3">Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container className="register">
      <Box className="register__form" component="form" onSubmit={handleSubmit}>
        <FormLabel sx={{ margin: ".5rem 0" }}>Register</FormLabel>
        {form.fields.map((field) => {
          return (
            <FormControl className="register__field" fullWidth key={field.name}>
              <TextField
                className="register__field"
                disabled={field.disabled || isLoading}
                name={field.name}
                label={field.label}
                value={form.data[field.name as keyof registerDataType] ?? ""}
                onChange={handleChange}
                error={
                  errors[field.name as keyof registerDataType] !== undefined
                }
                helperText={
                  errors[field.name as keyof registerDataType] !== null &&
                  errors[field.name as keyof registerDataType]
                }
                type={
                  inputTypes[field.name as keyof registerInputTypes] ?? "text"
                }
                InputLabelProps={
                  inputTypes[field.name as keyof registerInputTypes] === "date"
                    ? {
                      shrink: true,
                    }
                    : {}
                }
                required
              />
            </FormControl>
          );
        })}

        <Button
          type="submit"
          disabled={isLoading}
          variant="contained"
          fullWidth
        >
          Submit
        </Button>
        <hr />
        <Button onClick={() => setScreen("login")} disabled={isLoading}>
          Login
        </Button>
        {isLoading && (
          <Snackbar open={isLoading}>
            <Alert severity="info">Registering...</Alert>
          </Snackbar>
        )}
        {formError && (
          <Snackbar
            open={formError}
            autoHideDuration={5000}
            onClose={() => setFormError(false)}
          >
            <Alert severity="error">Something went wrong!</Alert>
          </Snackbar>
        )}
      </Box>
    </Container>
  );
}
