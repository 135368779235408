import { Button, Link, Box, Grid } from "@mui/material";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Loader } from "../../components/Loader";
import { Pagination } from "../../components/Pagination";
import { PaymentCard } from "../../components/PaymentCard";
import { V3UserContext } from "../../utility/context/V3UserContext";
import Get from "../../utility/Get";
import { getUserPurchaseList } from "../../utility/MarketEndpoints";
import { BackendReturnType, PurchaseHistoryType } from "../../utility/MarketplaceTypes";


export default function PaymentHistory(): JSX.Element {
  const { user } = useContext(V3UserContext);
  const navigator = useNavigate();
  const [purchaseHistory, setPurchaseHistory] = useState<
    BackendReturnType<Array<PurchaseHistoryType>>
  >({
    count: 0,
    data: [],
    page: 0,
    page_count: 0,
  });
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isNotFound, setNotFound] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const getPurchaseHistory = useCallback(
    (page: number = 1, signal: AbortSignal | undefined = undefined) => {
      if (user) {
        setLoading(true);
        setPurchaseHistory({
          count: 0,
          data: [],
          page: 0,
          page_count: 0,
        });
        Get(getUserPurchaseList(user.pk, page), signal).then((res) => {
          if (res) {
            if (res.status < 300) {
              setPurchaseHistory(res.data);
              setLoading(false);
            } else if (res.status === 401) {
              navigator("/login");
            } else if (res.status === 404) {
              setNotFound(true);
              setLoading(false);
            }
          } else {
            if (res === undefined) {
              // Cancelled
            } else {
              console.error(res);
              setError(true);
              setLoading(false);
            }
          }
        });
      }
    },
    [user, navigator]
  );

  useEffect(() => {
    const controller = new AbortController();

    getPurchaseHistory();

    return () => {
      controller.abort();
      setLoading(true);
      setPurchaseHistory({
        count: 0,
        data: [],
        page: 0,
        page_count: 0,
      });
      setNotFound(false);
    };
  }, [getPurchaseHistory]);

  return (
    <Box className="purchase-history">
      <Grid container>

      
      <div className="purchase-history__purchases">
        <h4>Purchase History</h4>
        <hr />
        {isLoading && <Loader />}
        {isNotFound && <div>Purchase history not found!</div>}
        {isError && <div>There was an error getting your purchase history</div>}
        {!isLoading && purchaseHistory.count <= 0 && !isError && (
          <div>
            <p>
              You haven't purchased anything yet. Visit the Marketplace to view
              available Avatars!
            </p>
            <Button
              onClick={() => {
                navigator("/marketplace");
              }}
            >
              Marketplace
            </Button>
          </div>
        )}
        {purchaseHistory.data.map((purchase: PurchaseHistoryType) => (
          <div className="purchase-history__purchase" key={purchase.pk}>
            <div className="purchase-history__title"></div>
            <div className="purchase-history__view-products">
              {purchase.purchased_products.map((product) => (
                <div className="purchase-history__product" key={product.pk}>
                  <div className="purchase-history__product-payment">
                    <div className="purchase-history__container">
                      <p>
                        Purchased from{" "}
                        <Link sx={{color: "#040666", textDecorationColor: "#040666"}} href={`/marketplace/seller/${product.store.pk}`}>
                          {product.store.name}
                        </Link>{" "}
                        on{" "}
                        {moment(purchase.time_created).format("MMM DD, YYYY")}
                      </p>
                      <a
                        href={purchase.stripe_receipt_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Receipt
                      </a>
                    </div>
                    {purchase.purchased_products.length > 1 && (
                      <p>
                        This item was a part of a ${purchase.price_paid / 100}{" "}
                        purchase from multiple shops
                      </p>
                    )}
                  </div>
                  <PaymentCard
                    price={product.price}
                    title={product.product_name}
                    thumbnail={product.image}
                    // href={`/npc-view/${product.previews.pk}`}
                    href={`/`}
                  >
                    <>
                      <hr />
                      <div className="purchase-history__product-item">
                        <p>{product.description}</p>
                      </div>
                    </>
                  </PaymentCard>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {purchaseHistory.page_count > 1 && (
        <div className="purchase-history__pagination">
          <Pagination
            numPages={purchaseHistory.page_count}
            currPage={purchaseHistory.page}
            onChange={(page) => {
              getPurchaseHistory(page);
            }}
          />
        </div>
      )}
      </Grid>
    </Box>
  );
}
