import { Box, Button, FormLabel, Link, TextField } from "@mui/material";
import React, { useState, useContext } from "react";
import Post from "../../utility/Post";
import { v3Login } from "../../utility/Endpoints";
import { useNavigate } from "react-router";
import { V3Organization } from "../../utility/context/V3Organization";

interface LoginProps {
  setScreen: (screen: string) => void;
  setUser: (user: any) => void;
}

/**
 * contains login page
 */
export default function Login(props: LoginProps): JSX.Element {
  let navigator = useNavigate();
  const [session, setSession] = useState({
    username: "",
    password: "",
  });
  const organization = useContext(V3Organization)
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (session.username === "" || session.username.length === 0) {
      setUsernameError("This field cannot be empty.");
    } else {
      setUsernameError("");
    }
    if (session.password === "" || session.password.length === 0) {
      setPasswordError("This field cannot be empty.");
    } else {
      setPasswordError("");
    }

    if (session.username !== "" && session.password !== "") {
      setIsLoading(true);
      const FormData = require("form-data");
      const formData = new FormData();
      formData.append("username", session.username);
      formData.append("password", session.password);
      Post(v3Login(organization.pk), formData).then((val) => {
        if (val.status && val.status < 300) {
          //save user to local
          localStorage.setItem(
            "vstreamer_user",
            JSON.stringify(val.data.data)
          );
          localStorage.setItem("sessionid", val.data.data.sessionid);
          //update App with user info
          props.setUser(val.data.data);
          //redirect to home page
          navigator("/");
        } else {
          setIsLoading(false);
          if (val.data && val.data.form && val.data.form.errors) {
            setUsernameError(
              val.data.form.errors[Object.keys(val.data.form.errors)[0]]
            );
            setPasswordError(
              val.data.form.errors[Object.keys(val.data.form.errors)[0]]
            );
          }
        }
      });
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSession({ ...session, [e.target.name]: e.target.value });
  }

  return (
    <Box className="login">
      <form onSubmit={handleSubmit}>
        <FormLabel sx={{ margin: ".5rem 0" }}>Sign In</FormLabel>
        <TextField
          name="username"
          label="Username or Email"
          fullWidth
          sx={{ margin: ".5rem 0" }}
          value={session.username}
          onChange={handleChange}
          error={usernameError !== ""}
          helperText={usernameError}
          disabled={isLoading}
        />
        <TextField
          label="Password"
          fullWidth
          sx={{ margin: ".5rem 0" }}
          type="password"
          value={session.password}
          onChange={handleChange}
          name="password"
          error={passwordError !== ""}
          helperText={passwordError}
          disabled={isLoading}
        />
        <Button
          sx={{ width: "65%" }}
          variant="contained"
          type="submit"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          Login
        </Button>
        <Button
          sx={{ width: "65%", marginTop: "1rem" }}
          variant="contained"
          color="secondary"
          onClick={() => props.setScreen("register")}
          disabled={isLoading}
        >
          Create Account
        </Button>
        <Link
          href="https://beneverending.com/terms-of-service"
          target="_blank"
          sx={{ marginTop: "1rem" }}
        >
          Terms of Service
        </Link>
        <hr />
        <Button
          disabled={isLoading}
          onClick={() => props.setScreen("forgot-password")}
        >
          Forgot Password
        </Button>
      </form>
    </Box>
  );
}
