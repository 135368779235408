import { Button, FormLabel, TextField } from "@mui/material";
import React, { useState, useContext } from "react";
import Post from "../../utility/Post";
import { resetPasswordConfirm, resetPasswordRequest } from "../../utility/Endpoints";
import { useNavigate } from "react-router";
import emailValidation from "../../utility/Helpers";
import { V3Organization } from "../../utility/context/V3Organization";

interface ForgotPasswordProps {
  setScreen: (screen: string) => void;
  setUser: (user: any) => void;
}

export default function ForgotPassword(props: ForgotPasswordProps) {
  let navigator = useNavigate()
  const [requestSent, setRequestSet] = useState(false) //0: email input, 1: verify, update password, login
  const [session, setSession] = useState({
    email: "",
    new_password: "",
    new_password_confirm: "",
    verification_code: "",
  })
  const [isLoading, setIsLoading] = useState(false)
  const [emailError, setEmailError] = useState("")
  const [codeError, setCodeError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [confirmPasswordError, setConfirmPasswordError] = useState("")
  const organization = useContext(V3Organization)

  function sendRequest(e: React.FormEvent) {
    e.preventDefault()
    if (
      session.email === "" ||
      session.email.length === 0 ||
      !emailValidation(session.email)
    ) {
      setEmailError("Enter valid email")
    }
    else {
      setEmailError("")
      setIsLoading(true)     
      const FormData = require("form-data");
      const formData = new FormData();
      formData.append("email", session.email);
      Post(resetPasswordRequest(organization.pk), formData).then(val => {
        if (val.status && val.status < 300) {
          setRequestSet(true)
          setIsLoading(false)
        } else {
          setIsLoading(false)
          setEmailError("Something went wrong. Try again later.")
        }
      })

    }
  }

  function updatePassword(e: React.FormEvent) {
    e.preventDefault()
    var error = false
    // handle errors
    //check fields are non empty
    //check if code is numbers
    if (session.verification_code.length === 0 || session.verification_code === "") {
      setCodeError("Enter verification code")
      error = true
    }
    else if (!(/^\d+$/.test(session.verification_code))) {
      setCodeError("Verification code must be numbers")
      error = true
    }
    else {
      setCodeError("")
    }
    if (session.new_password_confirm.length === 0 || session.new_password_confirm === "") {
      setConfirmPasswordError("Enter confirm new password")
      error = true
    }
    // check if passwords match
    //check is passwords are at least 8 letters and contains at least one number
    if (session.new_password.length === 0 || session.new_password === "") {
      setPasswordError("Enter new password")
      error = true
    }
    else if (!(/\d/.test(session.new_password)) || session.new_password.length < 8) {
      error = true
      setPasswordError("Password must contain 8 Characters, Letters AND Numbers")
    }
    else if (session.new_password !== session.new_password_confirm) {
      error = true
      setPasswordError("Passwords do not match")
      setConfirmPasswordError("Passwords do not match")
    }
    else {
      setPasswordError("")
      setConfirmPasswordError("")
    }

    if (!error) {
      setIsLoading(true)
      //send verify code
      const FormData = require("form-data");
      const formData = new FormData();
      formData.append("email", session.email);
      formData.append("verification_code", session.verification_code)
      formData.append("new_password", session.new_password)
      formData.append("new_password_confirm", session.new_password_confirm)
      Post(resetPasswordConfirm(organization.pk), formData).then(val => {
        if (val.status < 300) {
          //save new sessionid
          localStorage.setItem("sessionid", val.data.data.sessionid);
          localStorage.setItem("vstreamer_user", JSON.stringify(val.data.data));
          //update App with user info
          props.setUser(val.data.data)
          // navigate to home
          navigator("/")
        }
        else {
          //error message
          setCodeError("Something went wrong. Please try again later.")
        }
        setIsLoading(false)
      })

    } else {
      setIsLoading(false)
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSession({ ...session, [e.target.name]: e.target.value })
  }

  return (
    <div className="forgot-password">
      {requestSent ? (
        <form onSubmit={updatePassword}>
          <FormLabel sx={{ margin: ".5rem 0" }}>Forgot Password</FormLabel>
          <TextField
            name="verification_code"
            label="Email Verification Code"
            fullWidth
            sx={{ margin: ".5rem 0" }}
            disabled={isLoading}
            value={session.verification_code}
            onChange={handleChange}
            error={codeError !== ""}
            helperText={codeError}
          />
          <TextField
            name="new_password"
            label="New Password"
            fullWidth
            sx={{ margin: ".5rem 0" }}
            disabled={isLoading}
            value={session.new_password}
            onChange={handleChange}
            error={passwordError !== ""}
            type={"password"}
            helperText={passwordError}
          />
          <TextField
            name="new_password_confirm"
            label="Confirm New Password"
            fullWidth
            sx={{ margin: ".5rem 0" }}
            disabled={isLoading}
            value={session.new_password_confirm}
            onChange={handleChange}
            type={"password"}
            error={confirmPasswordError !== ""}
            helperText={confirmPasswordError}
          />
          <Button
            sx={{ width: "65%" }}
            variant="contained"
            disabled={isLoading}
            type="submit"
            onClick={updatePassword}
          >
            Update Password
          </Button>
          <hr />
          <Button
            onClick={() => props.setScreen("login")}
            disabled={isLoading}
          >
            Login
          </Button>
        </form>
      ) : (
        <form onSubmit={sendRequest}>
          <FormLabel sx={{ margin: ".5rem 0" }}>Forgot Password</FormLabel>
          <TextField
            name="email"
            label="Email"
            fullWidth
            sx={{ margin: ".5rem 0" }}
            disabled={isLoading}
            value={session.email}
            onChange={handleChange}
            error={emailError !== ""}
            helperText={emailError}
          />
          <Button
            sx={{ width: "65%" }}
            variant="contained"
            disabled={isLoading}
            type="submit"
            onClick={sendRequest}
          >
            Send Verification Code
          </Button>
          <hr />
          <Button
            onClick={() => props.setScreen("login")}
            disabled={isLoading}
          >
            Login
          </Button>
        </form>
      )}
    </div>
  )
}