import { TextField, Button, Checkbox, FormLabel, TextareaAutosize } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import Get from "./Get";
import emailValidation from "./Helpers";

type FormProps = {
  api: string[],
  className?: string
  handleSubmit: (data: any) => void,
  submitText?: string,
  data?: {
    [key: string]: object,
  }, // previous data to fill the fields with
  actions?: any, //other actions that isn't the submit button
  widthSize?: boolean,
  errors?: any,
  loading?: boolean,
}

export default function DynamicForm({
  api = [""],
  className = "",
  handleSubmit = (data: any) => {  },
  submitText = "Submit",
  data = {}, // previous data to fill the fields with
  actions = <></>, //other actions that isn't the submit button
  widthSize = true, //fullwidth
  errors = {}, //errors for fields
  loading = true,
  ...props
}: FormProps) {
  const classes = clsx("d-form", className);
  const [isLoading, setIsLoading] = useState(true);
  const [fields, setFields] = useState<{
    name: string,
    input_type: string,
    label: string,
    required: boolean,
    choices: any
  }[]>([]);
  const [session, setSession] = useState<{
    [x: string]: string | boolean,
  }>({});
  const [sessionErrors, setSessionErrors] = useState<{
    [x: string]: string | boolean,
  }>({});

  useEffect(() => {
    var tempFields: any[] = [];
    var tempSession: any = {};
    api.forEach((a) => {
      setIsLoading(true);
      Get(a).then((val) => {
        if (val && val.status < 300) {
          setIsLoading(false);
          var fields = val.data.form.fields;
          tempFields = tempFields.concat(fields);
          setFields(tempFields);

          val.data.form.fields.forEach((field: { name: string, input_type: string }) => {
            var temp;
            if (field.input_type === "checkbox") {
              temp = data[field.name] ? data[field.name] : false;
            } else {
              temp = data[field.name] ? data[field.name] : "";
            }
            tempSession[field.name] = temp;
          });

          setSession(tempSession);
        } else {
          //TODO handle error message
        }
      });
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      setSessionErrors(errors);
    }
  }, [errors]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setSession({ ...session, [e.target.name]: e.target.value });
  }

  function handleCheck(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setSession({ ...session, [e.target.name]: e.target.checked });
  }

  function handleAreaChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    e.preventDefault();
    setSession({ ...session, [e.target.name]: e.target.value });
  }

  // function handleSelectChange(name, value) {
  //   setSession({ ...session, [name]: value });
  // }

  function submit(e: React.FormEvent) {
    e.preventDefault();
    var error = false;
    var tempErrors: {
      [x: string]: string | boolean,
    } = {};

    //Check all are not empty if not required
    Object.keys(session).forEach((key) => {
      let fieldIndex = fields.findIndex(f => f.name === key)
      if (fields[fieldIndex].required && (session[key] === "")) {
        error = true;
        tempErrors[key] = "Field can not be empty";
      }
    });

    //validate emails
    if (session.email && typeof (session.email) === "string" && !emailValidation(session.email)) {
      tempErrors["email"] = "Enter a valid email";
      error = true;
    }

    //handle password validation
    if (
      session.password && typeof (session.password) === "string" &&
      (!/\d/.test(session.password) || session.password.length < 8)
    ) {
      tempErrors["password"] =
        "Password must contain 8 Characters, Letters AND Numbers";
      error = true;
    }


    if (!error) {
      handleSubmit(session);
      setIsLoading(true);
    } else {
      setSessionErrors(tempErrors);
    }
  }


  return !isLoading ? (
    <div className={classes}>
      <form className={"d-form__form"} onSubmit={submit} {...props}>
        {fields.map((field, key) => {
          switch (field.input_type) {
            case "text":
              return (
                <TextField
                  label={field.label}
                  placeholder={field.label}
                  name={field.name}
                  required={field.required}
                  key={key}
                  fullWidth={widthSize}
                  type={
                    field.name === "password" ? "password" : field.input_type
                  }
                  value={session[field.name] ? session[field.name] : ""}
                  onChange={handleChange}
                  error={sessionErrors[field.name] ? true : false}
                  helperText={sessionErrors[field.name]}
                />
              );
            case "email":
              return (
                <TextField
                  label={field.label}
                  placeholder={field.label}
                  name={field.name}
                  required={field.required}
                  key={key}
                  fullWidth={widthSize}
                  type={field.input_type}
                  value={session[field.name] ? session[field.name] : ""}
                  onChange={handleChange}
                  error={sessionErrors[field.name] ? true : false}
                  helperText={sessionErrors[field.name]}
                />
              );
            case "checkbox":
              return (
                //TODO handle errors
                <>
                  <Checkbox
                    key={key}
                    name={field.name}
                    checked={typeof(session[field.name]) === "boolean" ? !!session[field.name] : false}
                    onChange={handleCheck}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <FormLabel>{field.label}</FormLabel>
                </>
              );
              case "file":
                return (
                  <>
                  <TextField
                  label={field.label}
                  placeholder={field.label}
                  name={field.name}
                  required={field.required}
                  key={key}
                  fullWidth={widthSize}
                  type={field.input_type}
                  value={session[field.name] ? session[field.name] : ""}
                  onChange={handleChange}
                  error={sessionErrors[field.name] ? true : false}
                  helperText={sessionErrors[field.name]}
                />
                  </>
                );
            // case "select":
            //   //Get selection field choices
            //   let obj = [];
            //   if (field.choices) {
            //     for (const [value, label] of field.choices) {
            //       obj.push({ value, label });
            //     }
            //   }
            //   return (
            //     <Dropdown
            //       options={field.choices ? obj : []}
            //       placeholder={field.label}
            //       hasError={sessionErrors[field.name] ? true : false}
            //       errorMessage={sessionErrors[field.name]}
            //       variant={variant}
            //       widthSize={widthSize}
            //       key={key}
            //       onChange={(data) =>
            //         handleSelectChange(field.name, data.value)
            //       }
            //     />
            //   );
            default:
              return (
                <>
                  <TextareaAutosize
                    key={key}
                    name={field.name}
                    value={(typeof(session[field.name]) === "string") ? (session[field.name] as string) : ""}
                    onChange={handleAreaChange}
                    // error={sessionErrors[field.name] ? true : false}
                    // helperText={sessionErrors[field.name]}
                  />
                  <FormLabel>{field.label}</FormLabel>
                </>
              );
          }
        })}

        <div
          className="d-form__actions"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button onClick={submit} type="submit">
            {submitText}
          </Button>
          {actions && <div>{actions}</div>}
        </div>
      </form>
    </div>
  ) : (
    <div className={classes}>
      Loading...
    </div>
  );
}
