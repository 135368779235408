import { useState, useEffect } from "react";
import clsx from "clsx";

type PositionOptions = "left" | "right" | "top" | "bottom";
type VariantOptions = "primary" | "light" | "secondary";
type WidthSizeOptions = "sm" | "md" | "lg" | "auto";

interface TooltipProps {
  id: string;
  className?: string;
  content: string | JSX.Element;
  children: string | JSX.Element;
  position?: PositionOptions;
  variant?: VariantOptions;
  widthSize?: WidthSizeOptions;
}

export function Tooltip({
  id = Math.random().toString(),
  className,
  content,
  children,
  position = "right", // left, right, top, bottom
  variant = "primary", // primary (default), light, secondary
  widthSize = "md", // sm (100px), md (200px), lg (300px)
}: TooltipProps) {
  const [isOpen, setOpen] = useState(false);
  const defaultClassName = "c-tooltip";
  const classes = clsx(defaultClassName, className, {
    [`${defaultClassName}__${variant}`]: variant,
    [`${defaultClassName}__ws-${widthSize}`]: widthSize,
  });

  const open = () => setOpen(true);
  const close = () => setOpen(false);

  useEffect(() => {
    const el = document.getElementById(id);

    if (el) {
      el.addEventListener("mouseenter", open);
      el.addEventListener("mouseleave", close);
    }

    return () => {
      if (el) {
        el.removeEventListener("mouseenter", open);
        el.removeEventListener("mouseleave", close);
      }
    };
  }, [id]);

  const Content = () => {
    return (
      <div
        className={`${defaultClassName}__wrapper ${defaultClassName}__${position}`}
      >
        <span className={`${defaultClassName}__content`}>{content}</span>
      </div>
    );
  };

  return (
    <>
      <div id={id} className={classes}>
        {isOpen && <Content />}
        <div className={`${defaultClassName}__children`}>{children}</div>
      </div>
    </>
  );
}
