import useAvatarDisplay from "../hooks/useAvatarDisplay";

export default function Background() {
  const background = useAvatarDisplay((state) => state.background);

  return background ? (
    <div className="background">
      {background && (
        <>
          {["mov", "mp4"].includes(
            background.image.split(".")[background.image.split(".").length - 1]
          ) ? (
            <video loop autoPlay muted src={background.image} />
          ) : (
            <img
              src={background.image}
              alt={background.name}
              style={{ zIndex: 0 }}
            />
          )}
        </>
      )}
    </div>
  ) : (
    <></>
  );
}
