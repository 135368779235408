//This file is to generate the specific avatar/asset types that are used in the vstreamer app
//As of creation, the specific avatar that Jamie would like made only has
// body, head, eyes, eyebrows, and mouth

import { 
  Asset, 
  AssetType, 
  AssetVarientUser, 
  AttachmentPointUser, 
  Avatar, 
  AvatarAsset, 
  Frame 
} from "../../utility/Types";


//returns a dictionary of pks and asset type name that are only used in this app
export function specificAssetTypes() {
  return [
    103, //mouth
    97, //eyes
    98, //eyebrows
    55, //body
    66, //head
  ]
}


//export an avatar object that is empty but based on the specific avatar type
export function createEmptyAvatar(assetList: Array<AssetType>) {
  //get the list of asset types we are using
  const list = assetList.filter(assetType => specificAssetTypes().some(type => type === assetType.pk))
  const avatar_assets = list.map(type => {
    return emptyAvatarAsset(type)
  })

  const emptyAvatar: Avatar = {
    pk: 0,
    name: "",
    image: "",
    avatar_assets: avatar_assets,
    avatar_type: {
      enabled: true,
      name: "",
      pk: 12,
      visible: true,
      priority: 0,
    },
    is_public: false,
    avatar_specific_background_objects: [],
    avatar_specific_emote_objects: [],
    is_streaming_avatar: true,
    outfit: null,
    token_image: null,
    is_favorite: false
  };

  return emptyAvatar;
}

function emptyAvatarAsset(type: AssetType): AvatarAsset {
  return {
    asset: emptyAsset(type),
    asset_type: type,
    color_accent_1: "",
    color_accent_2: "",
    color_accent_3: "",
    color_accent_4: "",
    color_accent_5: "",
    color_accent_6: "",
    color_accent_7: "",
    color_accent_8: "",
    color_base: "",
    offset_x: "0.0",
    offset_y: "0.0",
    pk: Math.random() * -1,
    scale: "1.0"
  }
}

export function emptyAsset(type: AssetType): Asset {
  //note: missing asset_set
  return {
    asset_type: type,
    asset_varients: emptyAssetVarient(type),
    color_accent_count: 0,
    name: "",
    parent_asset_type: {pk: type.parent_asset_type_id},
    pk: Math.random() * -1,
    width: 2400,
    default_offset_x: "0.0",
    default_offset_y: "0.0",
    default_scale: "1.0",
  }
}

function emptyAssetVarient(type: AssetType): Array<AssetVarientUser> {
  // return a different amount of varients depending on the asset type
  if (type.pk === 66) { //head
    return [ //return one varient. User can add more later
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [{
          pk: 3,
          name: "static",
          visible: true,
          enabled: true,
        }],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 3000,
        animation_start_interval_max: 12000,
        animation_start_interval_min: 8000,
        avatar_state_list: [
          {
            pk: 2,
            name: "active",
            visible: true,
            enabled: true,
          },
        ],
        frames: [emptyFrame(type), emptyFrame(type)], //blinking eyes
        pk: Math.random() * -1,
      },
    ]
  } else if (type.pk === 55) { //body
    return [
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [{
          pk: 3,
          name: "static",
          visible: true,
          enabled: true,
        }],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 3000,
        animation_start_interval_max: 12000,
        animation_start_interval_min: 8000,
        avatar_state_list: [
          {
            pk: 2,
            name: "active",
            visible: true,
            enabled: true,
          },
        ],
        frames: [emptyFrame(type), emptyFrame(type)], //blinking eyes
        pk: Math.random() * -1,
      },
    ]
  } else if (type.pk === 103) { //mouth
    return [
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 4,
            name: "mouth",
            visible: true,
            enabled: true,
          },
          {
            pk: 10,
            name: "neutral",
            visible: true,
            enabled: true,
          }
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 8,
            name: "disgusted",
            visible: true,
            enabled: true,
          }
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 7,
            name: "sad",
            visible: true,
            enabled: true,
          }
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 11,
            name: "angry",
            visible: true,
            enabled: true,
          }
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 12,
            name: "fearful",
            visible: true,
            enabled: true,
          }
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 6,
            name: "happy",
            visible: true,
            enabled: true,
          }
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 9,
            name: "surprised",
            visible: true,
            enabled: true,
          }
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 100,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 2,
            name: "active",
            visible: true,
            enabled: true,
          },
          {
            pk: 4,
            name: "mouth",
            visible: true,
            enabled: true,
          },
        ],
        frames: [emptyFrame(type), emptyFrame(type)], //active mouths have more than 1 frame
        pk: Math.random() * -1,
      },
    ]
  } else if (type.pk === 97) { //eyes
    return [
      {
        animation_interval: 3000,
        animation_start_interval_max: 12000,
        animation_start_interval_min: 8000,
        avatar_state_list: [
          {
            pk: 2,
            name: "active",
            visible: true,
            enabled: true,
          },
        ],
        frames: [emptyFrame(type), emptyFrame(type)], //blinking eyes
        pk: Math.random() * -1,
      },
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 9,
            name: "surprised",
            visible: true,
            enabled: true,
          },
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 6,
            name: "happy",
            visible: true,
            enabled: true,
          },
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 12,
            name: "fearful",
            visible: true,
            enabled: true,
          },
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 7,
            name: "sad",
            visible: true,
            enabled: true,
          },
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 11,
            name: "angry",
            visible: true,
            enabled: true,
          },
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 10,
            name: "neutral",
            visible: true,
            enabled: true,
          },
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 8,
            name: "disgusted",
            visible: true,
            enabled: true,
          },
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      
    ]
  } else if (type.pk === 98) { //eyebrows
    return [
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 10,
            name: "neutral",
            visible: true,
            enabled: true,
          },
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 12,
            name: "fearful",
            visible: true,
            enabled: true,
          },
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 11,
            name: "angry",
            visible: true,
            enabled: true,
          },
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 9,
            name: "surprised",
            visible: true,
            enabled: true,
          },
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 6,
            name: "happy",
            visible: true,
            enabled: true,
          },
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 7,
            name: "sad",
            visible: true,
            enabled: true,
          },
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 0,
        animation_start_interval_max: null,
        animation_start_interval_min: null,
        avatar_state_list: [
          {
            pk: 1,
            name: "passive",
            visible: true,
            enabled: true,
          },
          {
            pk: 5,
            name: "expression",
            visible: true,
            enabled: true,
          },
          {
            pk: 8,
            name: "disgusted",
            visible: true,
            enabled: true,
          },
        ],
        frames: [emptyFrame(type)],
        pk: Math.random() * -1,
      },
      {
        animation_interval: 3000,
        animation_start_interval_max: 12000,
        animation_start_interval_min: 8000,
        avatar_state_list: [
          {
            pk: 2,
            name: "active",
            visible: true,
            enabled: true,
          },
        ],
        frames: [emptyFrame(type), emptyFrame(type)], //blinking eyes
        pk: Math.random() * -1,
      },
    ];
  } else {
    return []
  }
}

export function emptyFrame(type: AssetType, framePk?: number): Frame {
  return {
    attachment_points: emptyAttachmentPoint(type),
    image: "",
    image_back: "",
    pk: framePk ? framePk : Math.random() * -1,
    priority: 0,
  }
}

//return the attachment points based on type pk
function emptyAttachmentPoint(type: AssetType): Array<AttachmentPointUser> {
  if (type.pk === 66) { //head
    return [ //return one varient. User can add more later
      {
        asset_type: {
          pk: 103,
          parent_asset_type_id: 66,
          name: "Mouth",
          priority: 0,
        },
        name: "head to mouth",
        pk: 0,
        x_position: "50.00",
        y_position: "50.00",
        z_index: 10,
        z_index_back: 0
      },
      {
        asset_type: {
          pk: 55,
          parent_asset_type_id: null,
          name: "Body",
          priority: 0,
        },
        name: "head to body",
        pk: 0,
        x_position: "50.00",
        y_position: "50.00",
        z_index: 10,
        z_index_back: 0
      },
      {
        asset_type: {
          pk: 97,
          parent_asset_type_id: 66,
          name: "Eyes",
          priority: 0,
        },
        name: "head to eyes",
        pk: 0,
        x_position: "50.00",
        y_position: "50.00",
        z_index: 10,
        z_index_back: 0
      },
      {
        asset_type: {
          pk: 98,
          parent_asset_type_id: 66,
          name: "Eyebrows",
          priority: 0,
        },
        name: "head to eyebrows",
        pk: 0,
        x_position: "50.00",
        y_position: "50.00",
        z_index: 10,
        z_index_back: 0
      },


    ]
  } else if (type.pk === 55) { //body
    return [
      {
        asset_type: {
          pk: 66,
          parent_asset_type_id: 55,
          name: "Human Head",
          priority: 0,
        },
        name: "body to head",
        pk: 0,
        x_position: "50.00",
        y_position: "50.00",
        z_index: 5,
        z_index_back: 0
      },
    ]
  } else if (type.pk === 103) { //mouth
    return [
      {
        asset_type: {
          pk: 66,
          parent_asset_type_id: 55,
          name: "Human Head",
          priority: 0,
        },
        name: "mouth to head",
        pk: 0,
        x_position: "50.00",
        y_position: "50.00",
        z_index: 20,
        z_index_back: 0
      },
    ]
  } else if (type.pk === 97) { //eyes
    return [
      {
        asset_type: {
          pk: 66,
          parent_asset_type_id: 55,
          name: "Human Head",
          priority: 0,
        },
        name: "eyes to head",
        pk: 0,
        x_position: "50.00",
        y_position: "50.00",
        z_index: 15,
        z_index_back: 0
      },
    ]
  } else if (type.pk === 98) { //eyebrows
    return [
      {
        asset_type: {
          pk: 66,
          parent_asset_type_id: 55,
          name: "Human Head",
          priority: 0,
        },
        name: "eyebrows to head",
        pk: 0,
        x_position: "50.00",
        y_position: "50.00",
        z_index: 25,
        z_index_back: 0
      },
    ]
  } else {
    return []
  }
}