import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  ListSubheader,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import { createAvatarSpecificEmote, createEmote, getOrgAvatarList } from "../../../utility/Endpoints";
import Post from "../../../utility/Post";
import UploadIcon from "@mui/icons-material/Upload";
import { Asset } from "../../../utility/Types";
import getFullAssetList from "./helpers/getFullAssetList";
import Get from "../../../utility/Get";
import { V3Organization } from "../../../utility/context/V3Organization";
import { V3UserContext } from "../../../utility/context/V3UserContext";

const style = {
  backgroundColor: "white",
  padding: "1.5rem",
  borderRadius: "8px",
  width: "15rem",
};

interface AddEmoteProps {
  isOpen: boolean;
  handleClose(): any;
  infoMessage: (message: string) => void;
  errorMessage: (message: string) => void;
}

/**
 * Add an Emote to the database
 * @param props
 * @returns
 */

export default function AddEmote(props: AddEmoteProps): JSX.Element {
  const { user } = useContext(V3UserContext)
  let navigator = useNavigate();
  const organization = useContext(V3Organization)
  const [session, setSession] = useState({
    name: "",
    duration: 0,
    z_index: 0,
    asset: "",
    specific_avatar: 0,
    z_index_back: 0,
    is_public: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    thumbnail_image: "",
    image: "",
    duration: "",
    z_index: "",
  });
  const [assetList, setAssetList] = useState<{
    data: Array<{
      assetSetName: string;
      assetList: Array<Asset>;
    }>;
    error: string;
  }>();
  const [avatars, setAvatars] = useState<
    {
      avatar_type: object;
      name: string;
      image: string;
      pk: number;
    }[]
  >([]);

  /**
   * Populates assetList with all Asset Types for all Avatar Types for an Organization
   */
  useEffect(() => {
    async function populateAssetList() {
      if (organization.pk) {
        setAssetList(await getFullAssetList(organization.pk));
        getAvatars(1)
      }
    }

    populateAssetList();

    return () => {
      setAssetList({ data: [], error: "" });
    };
    // eslint-disable-next-line
  }, [organization]);

  function getAvatars(page: number) {
    Get(getOrgAvatarList(organization.pk, page)).then((val) => {
      if (val.status && val.status < 300) {
        setAvatars((prev) => [...prev, ...val.data.data]);
        //handle pages
        if (val.data.page_count > page) {
          getAvatars(page + 1)
        } else {
          setIsLoading(false)
        }
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    });
  }

  function handleAssetChange(e: SelectChangeEvent<any>): void {
    setSession({ ...session, [e.target.name]: e.target.value });
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    var error = false;
    const FormData = require("form-data");
    const formData = new FormData();
    let thumbnailFile = document.getElementById(
      "thumbnail_image"
    ) as HTMLInputElement;
    let imageFile = document.getElementById("image_file") as HTMLInputElement;
    let imageBackFile = document.getElementById("image_back_file") as HTMLInputElement;

    if (session.name === "" || session.name.length === 0) {
      setErrors({ ...errors, name: "Name cannot be empty" });
      error = true;
    }
    if (
      thumbnailFile === undefined ||
      thumbnailFile.files === undefined ||
      thumbnailFile.files?.length === 0
    ) {
      setErrors({
        ...errors,
        thumbnail_image: "Thumbnail image cannot be empty",
      });
      error = true;
    }
    if (
      imageFile === undefined ||
      imageFile.files === undefined ||
      imageFile.files?.length === 0
    ) {
      setErrors({ ...errors, image: "Image cannot be empty" });
      error = true;
    }
    if (session.z_index === null) {
      setErrors({ ...errors, z_index: "Z Index can not be empty" });
    }

    if (!error && user) {
      setIsLoading(true);
      formData.append("name", session.name);
      formData.append(
        "thumbnail_image",
        thumbnailFile && thumbnailFile.files ? thumbnailFile.files[0] : ""
      );
      formData.append(
        "image",
        imageFile && imageFile.files ? imageFile.files[0] : ""
      );
      if (imageFile !== undefined &&
        imageFile.files !== undefined &&
        imageFile.files?.length !== 0) {
        formData.append(
          "image_back",
          imageBackFile && imageBackFile.files ? imageBackFile.files[0] : ""
        );
      }
      formData.append("duration", session.duration);
      formData.append("z_index", session.z_index);
      formData.append("z_index_back", session.z_index_back);
      formData.append("is_public", session.is_public);
      if (session.asset !== "") formData.append("asset", session.asset);

      Post(createEmote(user.pk), formData).then((val) => {
        if (val.status && val.status < 300) {
          props.infoMessage("Emote created!");
          //create emote and avatar connection
          if (session.specific_avatar > 0) {
            const formDataSpecific = new FormData();
            formDataSpecific.append("emote", val.data.data.pk)
            Post(createAvatarSpecificEmote(session.specific_avatar), formDataSpecific).then(val1 => {
              if (val1.status && val1.status < 300) {
                //connection made
              } else {
                if (val.status === 401) {
                  navigator("/login");
                } else {
                  //handle errors
                  var temp = Object.keys(val.data.form.errors)[0];
                  props.errorMessage(val.data.form.errors[temp]);
                }
              }
            })
          }
        } else {
          if (val.status === 401) {
            navigator("/login");
          } else {
            //handle errors
            var temp = Object.keys(val.data.form.errors)[0];
            props.errorMessage(val.data.form.errors[temp]);
          }
        }
        setIsLoading(false);
        props.handleClose();
      });
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSession({ ...session, [e.target.name]: e.target.value });
  }

  function handleCheck(e: React.ChangeEvent<HTMLInputElement>) {
    setSession({ ...session, [e.target.name]: e.target.checked })
  }

  return (
    <Modal open={props.isOpen} onClose={props.handleClose}>
      <Box style={style}>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <FormLabel>Add Emote</FormLabel>
            <TextField
              margin="dense"
              label="Name"
              required
              value={session.name}
              name="name"
              onChange={handleChange}
              error={errors.name !== ""}
              helperText={errors.name}
              disabled={isLoading}
            />
            <FormControl style={{ marginTop: ".4rem" }}>
              <FormLabel>Thumbnail Image</FormLabel>
              <Button variant="outlined" component="label">
                <UploadIcon />
                <input
                  className="hide-file-btn"
                  required
                  id="thumbnail_image"
                  name="thumbnail_image"
                  type="file"
                  disabled={isLoading}
                />
              </Button>
              {errors.thumbnail_image !== "" && (
                <div style={{ color: "#dd8782" }}>{errors.thumbnail_image}</div>
              )}
            </FormControl>
            <FormControl style={{ marginTop: ".4rem" }}>
              <FormLabel>Emote</FormLabel>
              <Button variant="outlined" component="label">
                <UploadIcon />
                <input
                  className="hide-file-btn"
                  required
                  id="image_file"
                  name="image"
                  type="file"
                  disabled={isLoading}
                />
              </Button>
              {errors.image !== "" && (
                <div style={{ color: "#dd8782" }}>{errors.image}</div>
              )}
            </FormControl>
            <FormControl style={{ marginTop: ".4rem" }}>
              <FormLabel>Emote Back</FormLabel>
              <Button variant="outlined" component="label">
                <UploadIcon />
                <input
                  className="hide-file-btn"
                  required
                  id="image_back_file"
                  name="image_back"
                  type="file"
                  disabled={isLoading}
                />
              </Button>
            </FormControl>
            <FormControl fullWidth style={{ marginTop: ".8rem" }}>
              <InputLabel id="assetSelect">Asset</InputLabel>
              <Select
                labelId="assetSelect"
                value={session.asset}
                label="asset"
                name={"asset"}
                onChange={handleAssetChange}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {assetList &&
                  assetList.data.map((item) => [
                    <ListSubheader>{item.assetSetName}</ListSubheader>,
                    item.assetList.map((assetItem) => (
                      <MenuItem value={assetItem.pk} key={assetItem.pk}>
                        {assetItem.name}
                      </MenuItem>
                    )),
                  ])}
              </Select>
              {assetList && assetList.error !== "" && (
                <Alert style={{ marginBottom: ".8rem" }} severity="error">
                  {assetList.error}
                </Alert>
              )}
            </FormControl>
            <FormControl fullWidth style={{ marginTop: ".8rem" }}>
              <InputLabel id="assetSelect">Specific Avatar</InputLabel>
              <Select
                labelId="assetSelect"
                value={session.specific_avatar}
                label="asset"
                name={"specific_avatar"}
                onChange={handleAssetChange}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {avatars &&
                  avatars.map((item) => [
                    <MenuItem value={item.pk} key={item.pk}>
                      {item.name}
                    </MenuItem>
                  ])}
              </Select>
            </FormControl>
            {/* note: if a use sets this ^, the emote will only show up for the selected avatar  */}
            <TextField
              margin="dense"
              label="Duration"
              required
              value={session.duration}
              name="duration"
              type="number"
              onChange={handleChange}
              error={errors.duration !== ""}
              helperText={
                errors.duration !== ""
                  ? errors.duration
                  : "Emote duration expressed in miliseconds."
              }
              disabled={isLoading}
            />
            <TextField
              margin="dense"
              label="Z Index"
              required
              value={session.z_index}
              name="z_index"
              type="number"
              onChange={handleChange}
              error={errors.z_index !== ""}
              helperText={errors.z_index}
              disabled={isLoading}
            />
            <TextField
              margin="dense"
              label="Z Back Index"
              value={session.z_index_back}
              name="z_index_back"
              type="number"
              onChange={handleChange}
              disabled={isLoading}
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_public"
                    checked={session.is_public}
                    onChange={handleCheck}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled={isLoading}
                  />
                }
                label="Public"
              />
            </FormGroup>
            <Button
              variant="contained"
              sx={{ my: 1 }}
              type="submit"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              <Typography color="white">Add Emote</Typography>
            </Button>
            <Button
              onClick={() => {
                setErrors({
                  name: "",
                  thumbnail_image: "",
                  image: "",
                  duration: "",
                  z_index: "",
                });
                props.handleClose();
              }}
              variant="contained"
              color="error"
              sx={{ mx: 2 }}
              disabled={isLoading}
            >
              <Typography color="white">Cancel</Typography>
            </Button>
          </FormControl>
        </form>
      </Box>
    </Modal>
  );
}
