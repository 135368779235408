import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  TextField,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from '@mui/icons-material/Settings';
import { Asset, AssetType, AssetVarientUser } from "../../utility/Types";
import { Modal } from "../../components/Modal";
import Popup from "../../components/Popup";
import { FileUploader } from "react-drag-drop-files";

interface ActivePartsSectionProps {
  name: string;
  asset: Asset;
  updateAssetVarientFrameImage: (file: File, assettype: number, frameIndex: number) => void;
  updateAdvancedSettings: (
    advancedSettings: {
      width: number,
      animation_interval: number,
      animation_start_interval_max: number,
      animation_start_interval_min: number,
      x_position: number,
      y_position: number,
      z_index: number,
      z_index_back: number,
      image_back: string | File,
    },
    varient: AssetVarientUser | null,
    assetType: AssetType | null,
    frameIndex: number,
  ) => void;
  handleDeleteFrame: (assetType: AssetType, frameIndex: number) => void;
  handleAddFrame: (assetType: AssetType | undefined) => void;
}

const fileTypes = ["JPEG", "PNG", "GIF", "SVG"];

export default function ActivePartsSection(props: ActivePartsSectionProps) {
  const handleClose = (option: string, varient: AssetVarientUser, frameIndex: number) => {
    if (option === "Delete" && props.asset.asset_type !== null) {
      setDeleteModal({ id: props.asset.asset_type, frameIndex: frameIndex })
    } else if (option === "Advanced Settings" && props.asset.asset_type !== null) {
      setAdvancedSettingsModal({ varient: varient, id: props.asset.asset_type, frameIndex: frameIndex })
    }
  };
  const [selectedVarients, setSelectedVarients] = useState<Array<AssetVarientUser>>(
    props.asset.asset_varients.filter(varient => varient.avatar_state_list.some(state => state.pk === 2)) //active state
  )
  const [deleteModal, setDeleteModal] = useState<{ id: AssetType, frameIndex: number } | null>(null);
  const [advancedSettingsModal, setAdvancedSettingsModal] = useState<{ varient: AssetVarientUser, id: AssetType, frameIndex: number } | null>(null);
  const [tempAdvancedSettings, setTempAdvancedSettings] = useState<{ //used to keep track of the advanced settings form. Data sent to parent on save
    width: number,
    animation_interval: number,
    animation_start_interval_max: number,
    animation_start_interval_min: number,
    x_position: number,
    y_position: number,
    z_index: number,
    z_index_back: number,
    image_back: string | File,
  }>({
    width: 2400,
    animation_interval: 0,
    animation_start_interval_max: 0,
    animation_start_interval_min: 0,
    x_position: 50,
    y_position: 50,
    z_index: 0,
    z_index_back: 0,
    image_back: "",
  });
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    // Set selected variant to variant with active (2) state
    const variantList = props.asset.asset_varients;
    const stateList = variantList.filter(x => x.avatar_state_list.find(x => x.pk === 2));
    if (stateList.length > 0) 
      setSelectedVarients(stateList)
  }, [props]);

  useEffect(() => {
    //init temp advanced settings with selected varient
    if (advancedSettingsModal !== null) {
      var tempXposition = advancedSettingsModal.varient.frames[advancedSettingsModal.frameIndex].attachment_points[0].x_position;
      var tempYposition = advancedSettingsModal.varient.frames[advancedSettingsModal.frameIndex].attachment_points[0].y_position;
      var tempZindexBack = advancedSettingsModal.varient.frames[advancedSettingsModal.frameIndex] !== undefined &&
        advancedSettingsModal.varient.frames[advancedSettingsModal.frameIndex].attachment_points[0] !== undefined &&
        advancedSettingsModal.varient.frames[advancedSettingsModal.frameIndex].attachment_points[0].z_index_back !== undefined ?
        advancedSettingsModal.varient.frames[advancedSettingsModal.frameIndex].attachment_points[0].z_index_back : 0;
      var tempImageBack = advancedSettingsModal.varient.frames[advancedSettingsModal.frameIndex].image_back ? advancedSettingsModal.varient.frames[advancedSettingsModal.frameIndex].image_back : ""
      setTempAdvancedSettings({
        width: props.asset.width,
        animation_interval: advancedSettingsModal.varient.animation_interval,
        animation_start_interval_max: advancedSettingsModal.varient.animation_start_interval_max ?
          advancedSettingsModal.varient.animation_start_interval_max : 0,
        animation_start_interval_min: advancedSettingsModal.varient.animation_start_interval_min ?
          advancedSettingsModal.varient.animation_start_interval_min : 0,
        x_position: typeof (tempXposition) === "string" ?
          parseInt(tempXposition) :
          tempXposition,
        y_position: typeof (tempYposition) === "string" ?
          parseInt(tempYposition) :
          tempYposition,
        z_index: advancedSettingsModal.varient.frames[advancedSettingsModal.frameIndex].attachment_points[0].z_index,
        z_index_back: tempZindexBack !== undefined ? tempZindexBack : 0,
        image_back: tempImageBack !== undefined ? tempImageBack : "",
      })
    }
    // eslint-disable-next-line
  }, [advancedSettingsModal])

  function handleDelete() {
    //Actually delete the frame
    if (deleteModal !== null) {
      props.handleDeleteFrame(deleteModal.id, deleteModal.frameIndex)
      setDeleteModal(null)
    }
  }

  function handleAdvancedSettingsChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (e.target.name === "image_back") {
      setTempAdvancedSettings({ ...tempAdvancedSettings, [e.target.name]: e.target.value });
    } else {
      setTempAdvancedSettings({ ...tempAdvancedSettings, [e.target.name]: Number.isNaN(parseInt(e.target.value)) ? e.target.value : parseInt(e.target.value) });
    }
  }

  function hasAdvancedSettingsError(): boolean {
    var hasError = false;
    setErrorMessage("")
    if (tempAdvancedSettings.animation_interval === null ||
      (typeof (tempAdvancedSettings.animation_interval) === "string" && Number.isNaN(parseInt(tempAdvancedSettings.animation_interval))) ||
      tempAdvancedSettings.animation_interval < 0
    ) {
      hasError = true;
      setErrorMessage("Animation Interval is required")
    } else if (tempAdvancedSettings.animation_start_interval_max === null ||
      (typeof (tempAdvancedSettings.animation_start_interval_max) === "string" && Number.isNaN(parseInt(tempAdvancedSettings.animation_start_interval_max))) ||
      tempAdvancedSettings.animation_start_interval_max < 0
    ) {
      hasError = true;
      setErrorMessage("Animation Start Interval Max is required")
    } else if (tempAdvancedSettings.animation_start_interval_min === null ||
      (typeof (tempAdvancedSettings.animation_start_interval_min) === "string" && Number.isNaN(parseInt(tempAdvancedSettings.animation_start_interval_min))) ||
      tempAdvancedSettings.animation_start_interval_min < 0
    ) {
      hasError = true;
      setErrorMessage("Animation Start Interval Min is required")
    } else if (tempAdvancedSettings.width === null ||
      (typeof (tempAdvancedSettings.width) === "string" && Number.isNaN(parseInt(tempAdvancedSettings.width))) ||
      tempAdvancedSettings.width < 1
    ) {
      hasError = true;
      setErrorMessage("Image Width is required")
    } else if (tempAdvancedSettings.x_position === null ||
      (typeof (tempAdvancedSettings.x_position) === "string" && Number.isNaN(parseInt(tempAdvancedSettings.x_position))) ||
      tempAdvancedSettings.x_position < 0
    ) {
      hasError = true;
      setErrorMessage("X Position is required")
    } else if (tempAdvancedSettings.y_position === null ||
      (typeof (tempAdvancedSettings.y_position) === "string" && Number.isNaN(parseInt(tempAdvancedSettings.y_position))) ||
      tempAdvancedSettings.y_position < 0
    ) {
      hasError = true;
      setErrorMessage("Y Position is required")
    } else if (tempAdvancedSettings.z_index === null ||
      (typeof (tempAdvancedSettings.z_index) === "string" && Number.isNaN(parseInt(tempAdvancedSettings.z_index))) ||
      tempAdvancedSettings.z_index < 0
    ) {
      hasError = true;
      setErrorMessage("Z Index is required")
    } else if (tempAdvancedSettings.z_index_back === null ||
      (typeof (tempAdvancedSettings.z_index_back) === "string" && Number.isNaN(parseInt(tempAdvancedSettings.z_index_back)))
    ) {
      hasError = true;
      setErrorMessage("Z Index Back is required")
    } else {
      return hasError
    }
    //Note: Image back is not required
    return hasError
  }


  return (
    <div>
      <div className="avatar-form__new-title">
        <h4>{props.name}</h4>
        <Button variant="contained" onClick={() => {
          props.handleAddFrame(props.asset.asset_type !== null ? props.asset.asset_type : undefined) //hard coded
        }}>
          <AddIcon />
          Add Frame
        </Button>
      </div>

      {selectedVarients.map((varient, varientKey) => {
        return varient.frames.map((frame, key) => {
          return (
            <div
            className="avatar-form__image-upload"
              key={key}
            >
              {/* state name  */}
              <div>Frame {key + 1}</div>
              <div>
                <FileUploader
                  multiple={false}
                  id={`asset_image${props.name}`}
                  name={`asset_image${props.name}`}
                  handleChange={(file: File) => {
                    const element = document.getElementById(
                      `assetFormUploadImage${
                        props.name.replace(/\s/g, "") + varientKey + key
                      }`
                    );

                    if (element)
                      element.innerText =
                        file.name.split("\\")[file.name.split("\\").length - 1];
                    //Only doing active asset types
                    var assetTypeId = 0;
                    assetTypeId = (props.asset.asset_type as AssetType).pk;
                    props.updateAssetVarientFrameImage(
                      file,
                      assetTypeId,
                      key //frame key
                    );
                  }}
                  label={"Upload Image"}
                  types={fileTypes}
                  required
                  maxSize={5}
                >
                  <Button component="label" variant="outlined">
                    <span
                      id={`assetFormUploadImage${props.name.replace(/\s/g, '') + varientKey + key}`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <AddIcon />
                      Upload Image
                    </span>
                  </Button>
                </FileUploader>
              </div>
              <div>
                <h5 style={{textAlign: "center"}}>Preview Image</h5>
                {frame.image !== undefined && typeof (frame.image) !== "string" && (
                  <Box
                    component="img"
                    sx={{
                      maxWidth: { xs: 350, md: 250 },
                    }}
                    className="avatar-form__new-img"
                    alt="Preview Frame"
                    src={URL.createObjectURL(frame.image)}
                  />
                )}
                {frame.image && typeof (frame.image) === "string" && (
                  <Box
                    component="img"
                    sx={{
                      maxWidth: { xs: 350, md: 250 },
                    }}
                    className="avatar-form__new-img"
                    alt="Preview Frame"
                    src={frame.image ? frame.image : ""}
                  />
                )}
                {!varient.frames[advancedSettingsModal ? advancedSettingsModal.frameIndex : 0].image && (
                  <div>No image choosen</div>
                )}
              </div>

              {/* Extra options  */}
              <div>
                <Button variant="outlined" onClick={(e) => {
                  handleClose("Advanced Settings", varient, key)
                }}>
                  <Tooltip title="Advanced Settings">
                    <SettingsIcon />
                  </Tooltip>
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button color="error" variant="outlined" onClick={(e) => {
                  handleClose("Delete", varient, key)
                }}>
                  <Tooltip title="Delete">
                    <DeleteIcon />
                  </Tooltip>
                </Button>
              </div>
            </div>
          )
        })

      })}


      {/* Confirm delete modal  */}
      <Modal
        isOpen={deleteModal !== null}
        onRequestClose={(e: any) => setDeleteModal(null)}
        hideClose={true}
        actions={
          <>
            <Button variant="contained" onClick={() => setDeleteModal(null)}>Cancel</Button>
            <Button variant="contained" color="error" onClick={() => handleDelete()}>Delete</Button>
          </>
        }
      >
        <div>
          <h3>
            {
              `Are you sure you want to delete this frame?`
            }
          </h3>
          <div>Deleting this frame will remove your image(s) and any advanced settings applied.</div>
        </div>
      </Modal>

      {/* Advanced settings modal  */}
      <Modal
        isOpen={advancedSettingsModal !== null}
        title={`${props.name} Advanced Settings`}
        onRequestClose={(e: any) => setAdvancedSettingsModal(null)}
        hideClose={true}
        actions={
          <>
            <Button variant="contained" color="secondary" onClick={() => setAdvancedSettingsModal(null)}>Cancel</Button>
            <Button variant="contained" onClick={() => {
              //handle errors
              if (!hasAdvancedSettingsError()) {
                props.updateAdvancedSettings(
                  tempAdvancedSettings,
                  advancedSettingsModal ? advancedSettingsModal.varient : null, advancedSettingsModal ? advancedSettingsModal.id : null,
                  advancedSettingsModal ? advancedSettingsModal.frameIndex : 0
                )
                setAdvancedSettingsModal(null)
              } else return
            }}
            >
              Save
            </Button>
          </>
        }
      >
        <>
          {errorMessage !== "" && (
            <Alert severity="error">{errorMessage}</Alert>
          )}
          {advancedSettingsModal !== null && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                margin="dense"
                label="Width (Default: 2400px)"
                required
                value={tempAdvancedSettings.width}
                name="width"
                type="number"
                onChange={(e) => handleAdvancedSettingsChange(e)}
                helperText={"Changing width will affect all expressions within this asset type."}
              />
              <TextField
                margin="dense"
                label="Animation Interval"
                required
                value={tempAdvancedSettings.animation_interval}
                name="animation_interval"
                type="number"
                onChange={(e) => handleAdvancedSettingsChange(e)}
              />
              <TextField
                margin="dense"
                label="Animation Start Interval Max"
                required
                value={tempAdvancedSettings.animation_start_interval_max}
                name="animation_start_interval_max"
                type="number"
                onChange={(e) => handleAdvancedSettingsChange(e)}
              />
              <TextField
                margin="dense"
                label="Animation Start Interval Min"
                required
                value={tempAdvancedSettings.animation_start_interval_min}
                name="animation_start_interval_min"
                type="number"
                onChange={(e) => handleAdvancedSettingsChange(e)}
              />
              <TextField
                margin="dense"
                label="X Position"
                required
                value={tempAdvancedSettings.x_position}
                name="x_position"
                type="number"
                onChange={(e) => handleAdvancedSettingsChange(e)}
              />
              <TextField
                margin="dense"
                label="Y Position"
                required
                value={tempAdvancedSettings.y_position}
                name="y_position"
                type="number"
                onChange={(e) => handleAdvancedSettingsChange(e)}
              />
              {/* Possible TODO: show the red dot to change x/y  */}
              <TextField
                margin="dense"
                label="Z Index"
                required
                value={tempAdvancedSettings.z_index}
                name="z_index"
                type="number"
                onChange={(e) => handleAdvancedSettingsChange(e)}
              />
              <TextField
                margin="dense"
                label="Z Index Back"
                required
                value={tempAdvancedSettings.z_index_back}
                name="z_index_back"
                type="number"
                onChange={(e) => handleAdvancedSettingsChange(e)}
              />
              <Button component="label" variant="outlined">
                <span
                  id={`assetFormUploadImage${props.name.replace(/\s/g, '') + advancedSettingsModal ? advancedSettingsModal.frameIndex : 0}Back`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AddIcon />
                  Upload Image
                </span>
                <input
                  type="file"
                  hidden
                  required
                  id={`asset_image${props.name.replace(/\s/g, '') + advancedSettingsModal ? advancedSettingsModal.frameIndex : 0}Back`}
                  name={`asset_image${props.name.replace(/\s/g, '') + advancedSettingsModal ? advancedSettingsModal.frameIndex : 0}Back`}
                  onChange={(e) => {
                    const element = document.getElementById(
                      `assetFormUploadImage${props.name.replace(/\s/g, '') + advancedSettingsModal ? advancedSettingsModal.frameIndex : 0}Back`
                    );
                    const elementInput = document.getElementById(
                      `asset_image${props.name.replace(/\s/g, '') + advancedSettingsModal ? advancedSettingsModal.frameIndex : 0}Back`
                    ) as HTMLInputElement;

                    if (element)
                      element.innerText =
                        e.target.value.split("\\")[
                        e.target.value.split("\\").length - 1
                        ];

                    if ((elementInput === undefined ||
                      elementInput === null ||
                      elementInput.files === undefined ||
                      elementInput.files?.length === 0)) {
                      Popup("Something went wrong uploading the file. Please try again or contact support.", "error");
                    } else {
                      //Update temp advanced settings with back image
                      setTempAdvancedSettings({ ...tempAdvancedSettings, image_back: elementInput && elementInput.files ? elementInput.files[0] : "" });
                    }
                  }}
                />
              </Button>
              {tempAdvancedSettings.image_back !== "" && (
                <div>
                  <h5>Image Back Preview</h5>
                  {tempAdvancedSettings.image_back && typeof (tempAdvancedSettings.image_back) !== "string" && (
                    <Box
                      component="img"
                      sx={{
                        width: 350,
                        maxWidth: { xs: 350, md: 250 },
                      }}
                      alt="Preview Frame"
                      src={URL.createObjectURL(tempAdvancedSettings.image_back)}
                    />
                  )}
                  {tempAdvancedSettings.image_back && typeof (tempAdvancedSettings.image_back) === "string" && (
                    <Box
                      component="img"
                      sx={{
                        width: 350,
                        maxWidth: { xs: 350, md: 250 },
                      }}
                      alt="Preview Frame"
                      src={tempAdvancedSettings.image_back ? tempAdvancedSettings.image_back : ""}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </>
      </Modal>
    </div>
  )
}