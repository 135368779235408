import { Fragment, useContext, useEffect, useState } from "react";
import {
  // AssetStateObject,
  AssetType,
  AssetVarientUser,
  Avatar,
  AvatarAsset,
} from "../../utility/Types";
import { V3UserContext } from "../../utility/context/V3UserContext";
import {
  defaultAvatarType,
  deleteAvatar,
  getAvatarAssetsTypes,
  getAvatarDetails,
  updateAvatarOrgField,
  // getAvatarStates,
} from "../../utility/Endpoints";
import Get from "../../utility/Get";
import { useNavigate, useParams } from "react-router";
import {
  createEmptyAvatar,
  emptyAsset,
  emptyFrame,
  specificAssetTypes,
} from "./SpecificAvatarFormHelpers";
import { Loader } from "../../components/Loader";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { UpdateAvatarBackend } from "./UpdateAvatarFunctions";
import { CreateNewAvatarBackend } from "./CreateAvatarFunctions";
import ExpressionsSection from "./ExpressionsSection";
import Popup from "../../components/Popup";
import StaticPartsSection from "./StaticPartsSection";
import ActivePartsSection from "./ActivePartsSections";
import PreviewDisplay from "./PreviewDisplay";
import { Modal } from "../../components/Modal";
import Post from "../../utility/Post";

export default function AvatarForm() {
  const [selectedAvatar, setSelectedAvatar] = useState<Avatar>();
  const [tempAvatar, setTempAvatar] = useState<Avatar>();
  const { user } = useContext(V3UserContext);
  const navigator = useNavigate();
  const { id } = useParams();
  //list of asset types that are needed for the avatar type
  const [assetTypeList, setAssetTypeList] = useState<Array<AssetType>>([]);
  //list of avatar states
  // const [avatarStateList, setAvatarStateList] = useState<
  //   Array<AssetStateObject>
  // >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showPreview, setShowPreview] = useState<any | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isOrgAvatar, setIsOrgAvatar] = useState<boolean>(false);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);

  useEffect(() => {
    const controller = new AbortController();
    setIsLoading(true);
    getAvatarTypesAndStates(defaultAvatarType(), 1, controller.signal);

    if (id && parseInt(id)) {
      getAvatar(parseInt(id));

      //if the user is a manager, artist, or superuser, they can create/update the is_organization avatar field
      if (
        user &&
        (user.is_artist || user.is_organization_manager || user.is_user_manager)
      ) {
        Get(updateAvatarOrgField(parseInt(id))).then((val1) => {
          if (val1.status && val1.status < 300) {
            if (val1.data && val1.data.form && val1.data.form.data) {
              setIsOrgAvatar(val1.data.form.data.is_organization_avatar);
            }
          } else {
            if (val1.status === 401) {
              navigator("/login");
            }
          }
        });
      }
    }

    return () => {
      controller.abort();
      setAssetTypeList([]);
      // setAvatarStateList([])
      setSelectedAvatar(undefined);
      setTempAvatar(undefined);
      setShowPreview(null);
      setErrorMessage("");
      setIsLoading(true);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //if we are creating a new avatar and we have the asset types, create an empty avatar
    if (
      assetTypeList.length === specificAssetTypes().length &&
      !selectedAvatar &&
      !tempAvatar
    ) {
      setSelectedAvatar(createEmptyAvatar(assetTypeList));
      setTempAvatar(createEmptyAvatar(assetTypeList));
    }
    // eslint-disable-next-line
  }, [assetTypeList]);

  function getAvatarTypesAndStates(
    avatarTypePk: number,
    page: number,
    signal: AbortSignal
  ) {
    Get(getAvatarAssetsTypes(avatarTypePk, page), signal).then((val) => {
      if (val.status && val.status < 300) {
        if (val.data && val.data.data) {
          setAssetTypeList((prev) => [...prev, ...val.data.data]);
        }
        //handle pages
        if (val.data.page_count > page) {
          getAvatarTypesAndStates(avatarTypePk, page + 1, signal);
        } else {
          setIsLoading(false);
          //filter out assets that we need
          setAssetTypeList((prev) =>
            prev.filter((type) => specificAssetTypes().includes(type.pk))
          );
        }
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
      setIsLoading(false);
    });
    // if (page < 2) { //Not currently used since we are part hard coding states
    //   Get(getAvatarStates(), signal).then((val) => {
    //     if (val.status && val.status < 300) {
    //       //Note: Will need to handle pagination when that time comes
    //       if (val.data && val.data.data) {
    //         setAvatarStateList(val.data.data);
    //       }
    //     } else {
    //       if (val.status === 401) {
    //         navigator("/login");
    //       }
    //     }
    //     setIsLoading(false);
    //   });
    // }
  }

  function getAvatar(avatarpk: number) {
    Get(getAvatarDetails(avatarpk)).then((val) => {
      if (val.status && val.status < 300) {
        if (val.data && val.data.data) {
          //stringify and then parse to get a completely different reference to the object
          setSelectedAvatar(JSON.parse(JSON.stringify(val.data.data)));
          //Check if the avatar has an asset for the major sections (ie, body, head, mouth, eyes, eyebrows)
          var prefilledAssets = val.data.data.avatar_assets.filter(
            (asset: AvatarAsset) =>
              asset.asset_type.pk === 55 ||
              asset.asset_type.pk === 66 ||
              asset.asset_type.pk === 103 ||
              asset.asset_type.pk === 97 ||
              asset.asset_type.pk === 98
          );

          prefilledAssets.forEach((asset: AvatarAsset) => {
            if (asset.asset === null) {
              asset.asset = emptyAsset(asset.asset_type);
            } else {
              asset.asset.asset_varients.forEach(
                (varient: AssetVarientUser) => {
                  if (varient.frames.length === 0) {
                    varient.frames = [emptyFrame(asset.asset_type)];
                  }
                }
              );
            }
          });
          setTempAvatar(val.data.data); //<== referenced the same object so we dont need to assign anything
          setIsLoading(false);
        }
      } else {
        if (val.status === 401) {
          navigator("/login");
        }
      }
    });
  }

  //handles change for name
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (tempAvatar) {
      setTempAvatar({ ...tempAvatar, [e.target.name]: e.target.value });
    }
  }

  function handleCheck(e: React.ChangeEvent<HTMLInputElement>) {
    if (tempAvatar) {
      setTempAvatar({ ...tempAvatar, [e.target.name]: e.target.checked });
    }
  }

  function avatarCreatedRedirect(avatarPk: number) {
    getAvatar(avatarPk);
    setIsLoading(false);
    setShowPreview(null);
    navigator(`/manageavatar/${avatarPk}/edit`); // redirect to avatar page
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    setErrorMessage("");
    var error = false;
    //hanlde errors
    if (tempAvatar) {
      if (!tempAvatar.name || tempAvatar.name === "") {
        setErrorMessage("Name Required");
        error = true;
      }
      if (!tempAvatar.image || tempAvatar.image === "") {
        setErrorMessage("Thumbnail Image Required");
        error = true;
      }
      //make sure that there is a body and head
      // var headIndex = tempAvatar.avatar_assets.findIndex(
      //   (asset) => asset.asset_type.pk === 66
      // );
      // var bodyIndex = tempAvatar.avatar_assets.findIndex(
      //   (asset) => asset.asset_type.pk === 55
      // );
      // if (
      //   tempAvatar.avatar_assets[bodyIndex].asset.asset_varients[0].frames[0]
      //     .image == null ||
      //   tempAvatar.avatar_assets[bodyIndex].asset.asset_varients[0].frames[0]
      //     .image === "" ||
      //   tempAvatar.avatar_assets[bodyIndex].asset.asset_varients[0].frames[0]
      //     .image === undefined
      // ) {
      //   setErrorMessage("Body Image Required");
      //   error = true;
      // }
      // if (
      //   tempAvatar.avatar_assets[headIndex].asset.asset_varients[0].frames[0]
      //     .image == null ||
      //   tempAvatar.avatar_assets[headIndex].asset.asset_varients[0].frames[0]
      //     .image === "" ||
      //   tempAvatar.avatar_assets[headIndex].asset.asset_varients[0].frames[0]
      //     .image === undefined
      // ) {
      //   setErrorMessage("Head Image Required");
      //   error = true;
      // }
    }

    if (selectedAvatar === undefined || tempAvatar === undefined || error)
      return;
    if (
      id &&
      parseInt(id) &&
      selectedAvatar !== undefined &&
      tempAvatar !== undefined
    ) {
      setIsLoading(true);
      if (
        user &&
        (user.is_artist || user.is_organization_manager || user.is_user_manager)
      ) {
        UpdateAvatarBackend(
          selectedAvatar,
          tempAvatar,
          navigator,
          avatarCreatedRedirect,
          isOrgAvatar
        );
      } else {
        UpdateAvatarBackend(
          selectedAvatar,
          tempAvatar,
          navigator,
          avatarCreatedRedirect
        );
      }
    } else {
      if (tempAvatar !== undefined && user) {
        setIsLoading(true);
        if (
          user &&
          (user.is_artist ||
            user.is_organization_manager ||
            user.is_user_manager)
        ) {
          CreateNewAvatarBackend(
            tempAvatar,
            user,
            navigator,
            avatarCreatedRedirect,
            isOrgAvatar
          );
        } else {
          CreateNewAvatarBackend(
            tempAvatar,
            user,
            navigator,
            avatarCreatedRedirect
          );
        }
      }
    }
  }

  function updateAssetVarientImage(
    file: File,
    assettype: number,
    expression?: number
  ) {
    //update the frame image with a the new file
    if (file === undefined || file === null) {
      //handle error
      Popup(
        "Something went wrong uploading the file. Please try again or contact support.",
        "error"
      );
    } else {
      //Update the file in the tempavatar state in the right spot depending on expression state and asset type
      setTempAvatar((prev) => {
        if (prev) {
          var avatarAssets = [...prev.avatar_assets];
          //find index of asset to change
          var avatarAssetIndex = avatarAssets.findIndex(
            (asset) =>
              asset?.asset?.asset_type?.pk === assettype
          );
          var assetVarient =
            avatarAssets[avatarAssetIndex].asset.asset_varients;
          //If changing an expression, then get that index. Else just get the first varient
          var varientIndex = 0;
          if (expression) {
            varientIndex = assetVarient.findIndex((varient) => {
             return varient.avatar_state_list.some((state) => state.pk === expression)
            }
            );
          }
          avatarAssets[avatarAssetIndex].asset.asset_varients[
            varientIndex
          ].frames[0].image = file;
          return { ...prev, avatar_assets: avatarAssets };
        }
        return prev;
      });
    }
  }

  function updateAssetVarientFrameImage(
    file: File,
    assettype: number,
    frameIndex: number
  ) {
    //update the frame image with a the new file
    if (file === undefined || file === null) {
      //handle error
      Popup(
        "Something went wrong uploading the file. Please try again or contact support.",
        "error"
      );
    } else {
      //Update the file in the tempavatar state in the right spot depending on expression state and asset type
      setTempAvatar((prev) => {
        if (prev) {
          var avatarAssets = [...prev.avatar_assets];
          //find index of asset to change
          var avatarAssetIndex = avatarAssets.findIndex(
            (asset) =>
              asset?.asset?.asset_type?.pk === assettype
          );
          var assetVarient =
            avatarAssets[avatarAssetIndex].asset.asset_varients;
          var varientIndex = assetVarient.findIndex((varient) =>
            varient.avatar_state_list.some((state) => state.pk === 2)
          ); //active state
          avatarAssets[avatarAssetIndex].asset.asset_varients[
            varientIndex
          ].frames[frameIndex].image = file;
          return { ...prev, avatar_assets: avatarAssets };
        }
        return prev;
      });
    }
  }

  function updateAdvancedSettings(
    advancedSettings: {
      width: number;
      animation_interval: number;
      animation_start_interval_max: number;
      animation_start_interval_min: number;
      x_position: number;
      y_position: number;
      z_index: number;
      z_index_back: number;
      image_back: string | File;
    },
    varient: AssetVarientUser | null,
    assetType: AssetType | null,
    frameIndex: number = 0
  ) {
    if (varient !== null && assetType !== null) {
      setTempAvatar((prev) => {
        if (prev) {
          var avatarAssets = [...prev.avatar_assets];
          //find index of asset to change
          var avatarAssetIndex = avatarAssets.findIndex(
            (asset) => {
              if (asset?.asset?.asset_type)
                return asset.asset.asset_type && asset.asset.asset_type.pk === assetType.pk;
              return null;
            }
          );
          var assetVarient =
            avatarAssets[avatarAssetIndex].asset.asset_varients;
          //Filter based on "active" state
          var varientIndex = 0;
          if (assetType.pk === 55 || assetType.pk === 66) {
            varientIndex = 0; //first varient since there is only 1 for head and body
          } else {
            //make sure the state you are looking for is NOT "passive" "expression" or "mouth"
            varientIndex = assetVarient.findIndex((v) =>
              v.avatar_state_list.some((state) =>
                varient.avatar_state_list.some(
                  (s) =>
                    state.pk === s.pk && s.pk !== 1 && s.pk !== 5 && s.pk !== 4
                )
              )
            ); //same states
          }

          //Update all advanced fields
          avatarAssets[avatarAssetIndex].asset.width = advancedSettings.width;
          avatarAssets[avatarAssetIndex].asset.asset_varients[
            varientIndex
          ].animation_interval = advancedSettings.animation_interval;
          avatarAssets[avatarAssetIndex].asset.asset_varients[
            varientIndex
          ].animation_start_interval_max =
            advancedSettings.animation_start_interval_max;
          avatarAssets[avatarAssetIndex].asset.asset_varients[
            varientIndex
          ].animation_start_interval_min =
            advancedSettings.animation_start_interval_min;
          avatarAssets[avatarAssetIndex].asset.asset_varients[
            varientIndex
          ].frames[frameIndex].image_back = advancedSettings.image_back;
          avatarAssets[avatarAssetIndex].asset.asset_varients[
            varientIndex
          ].frames[frameIndex].attachment_points[0].x_position =
            advancedSettings.x_position;
          avatarAssets[avatarAssetIndex].asset.asset_varients[
            varientIndex
          ].frames[frameIndex].attachment_points[0].y_position =
            advancedSettings.y_position;
          avatarAssets[avatarAssetIndex].asset.asset_varients[
            varientIndex
          ].frames[frameIndex].attachment_points[0].z_index =
            advancedSettings.z_index;
          avatarAssets[avatarAssetIndex].asset.asset_varients[
            varientIndex
          ].frames[frameIndex].attachment_points[0].z_index_back =
            advancedSettings.z_index_back;

          return { ...prev, avatar_assets: avatarAssets };
        }
        return prev;
      });
    }
  }

  function handleDeleteVarient(
    varient: AssetVarientUser,
    assetType: AssetType,
    framePk?: number
  ) {
    //don't actually delete the varient, just override the values
    //pass in frame pk so we can update the backend with an empty string
    const newEmptyVarient: AssetVarientUser = {
      animation_interval: 0,
      animation_start_interval_max: null,
      animation_start_interval_min: null,
      pk: varient.pk,
      avatar_state_list: varient.avatar_state_list,
      frames: [emptyFrame(assetType, framePk)],
    };
    setTempAvatar((prev) => {
      if (prev) {
        var avatarAssets = [...prev.avatar_assets];
        //find index of asset to change
        var avatarAssetIndex = avatarAssets.findIndex(
          (asset) =>
            asset.asset.asset_type && asset.asset.asset_type.pk === assetType.pk
        );
        var assetVarient = avatarAssets[avatarAssetIndex].asset.asset_varients;
        //Filter based on expression and NOT state "passive" or "expression"
        var varientIndex = assetVarient.findIndex((v) =>
          v.avatar_state_list.some((state) =>
            varient.avatar_state_list.some(
              (s) => s.pk === state.pk && s.pk !== 1 && s.pk !== 5
            )
          )
        );
        avatarAssets[avatarAssetIndex].asset.asset_varients[varientIndex] =
          newEmptyVarient;
        return { ...prev, avatar_assets: avatarAssets };
      }
      return prev;
    });
  }

  function handleDeleteFrame(assetType: AssetType, frameIndex: number) {
    //Actually delete the frame
    setTempAvatar((prev) => {
      if (prev) {
        var avatarAssets = [...prev.avatar_assets];
        //find index of asset to change
        var avatarAssetIndex = avatarAssets.findIndex(
          (asset) =>
            asset?.asset?.asset_type?.pk === assetType.pk
        );
        var assetVarient = avatarAssets[avatarAssetIndex].asset.asset_varients;
        //Filter based on "active" state
        var varientIndex = assetVarient.findIndex((v) =>
          v.avatar_state_list.some((state) => state.pk === 2)
        );
        //Remove frame from index
        avatarAssets[avatarAssetIndex].asset.asset_varients[
          varientIndex
        ].frames[frameIndex].image = '';
        return { ...prev, avatar_assets: avatarAssets };
      }
      return prev;
    });
  }

  function handleAddFrame(assetType: AssetType | undefined) {
    if (assetType !== undefined) {
      setTempAvatar((prev) => {
        if (prev) {
          var avatarAssets = [...prev.avatar_assets];
          //find index of asset to change
          var avatarAssetIndex = avatarAssets.findIndex(
            (asset) =>
              asset?.asset?.asset_type?.pk === assetType.pk
          );
          var assetVarient =
            avatarAssets[avatarAssetIndex].asset.asset_varients;
          //Filter based on "active" state
          var varientIndex = assetVarient.findIndex((v) =>
            v.avatar_state_list.some((state) => state.pk === 2)
          );
          //Add frame to end of array
          avatarAssets[avatarAssetIndex].asset.asset_varients[
            varientIndex
          ].frames.push(emptyFrame(assetType));
          return { ...prev, avatar_assets: avatarAssets };
        }
        return prev;
      });
    }
  }

  function startStreaming() {
    if (tempAvatar && showPreview === null) {
      let data = {};
      data = { ...data, avatar: tempAvatar };
      // Set background
      data = { ...data, background: null };

      // Set emotes
      data = { ...data, emotes: null };

      // Set show emotes
      data = { ...data, showEmotes: true };
      setShowPreview(data);
    } else {
      setShowPreview(null);
    }
  }

  const handleDeleteAvatar = () => {
    Post(deleteAvatar(id as string), {}).then((res) => {
      if (res && res.status) {
        if (res.status < 300) {
          Popup("Avatar deleted!", "info");
          navigator("/manageavatar");
        } else if (res.status === 401) {
          navigator("/login");
        } else if (res.status === 422) {
          Popup("There was an issue deleting your avatar!", "error");
        }
      }
    });
  };

  return !isLoading && tempAvatar !== undefined ? (
    <div className="avatar-form">
      <div className="avatar-form__title">
        {id && parseInt(id) && selectedAvatar !== undefined ? (
          <>
            <h2>Edit Avatar</h2>
            <Button color="error" variant="contained" onClick={openDeleteModal}>
              Delete
            </Button>
          </>
        ) : (
          <h2>Create Avatar</h2>
        )}
      </div>
      {errorMessage !== "" && <Alert severity="error">{errorMessage}</Alert>}
      <form onSubmit={handleSubmit}>
        <FormControl sx={{ width: "100%" }}>
          <TextField
            margin="dense"
            label="Name"
            required
            value={tempAvatar ? tempAvatar.name : ""}
            name="name"
            onChange={handleChange}
            disabled={isLoading}
          />
          {/* only show id the user is an artist or manager  */}
          {/* is organization avatar flag here is a seperate state and backend endpoint update */}
          {user?.is_artist ||
          user?.is_organization_manager ||
          user?.is_user_manager ? (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_public"
                    checked={tempAvatar ? tempAvatar.is_public : false}
                    onChange={handleCheck}
                    inputProps={{ "aria-label": "controlled" }}
                    disabled={isLoading}
                  />
                }
                label="Public"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_streaming_avatar"
                    checked={
                      tempAvatar ? tempAvatar.is_streaming_avatar : false
                    }
                    onChange={handleCheck}
                    inputProps={{ "aria-label": "controlled" }}
                    disabled={isLoading}
                  />
                }
                label="Streaming Avatar"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_organization_avatar"
                    checked={isOrgAvatar}
                    onChange={() => setIsOrgAvatar((prev) => !prev)}
                    inputProps={{ "aria-label": "controlled" }}
                    disabled={isLoading}
                  />
                }
                label="Organization Avatar"
              />
              <Button
                variant="contained"
                onClick={() =>
                  navigator(
                    `/avatarmanagement/${
                      selectedAvatar ? selectedAvatar.pk : 0
                    }/edit`
                  )
                }
              >
                View Avatar in old form
              </Button>
              &nbsp;&nbsp;&nbsp;
            </FormGroup>
          ) : (
            <></>
          )}
          <Button component="label" variant="outlined">
            <span
              id={`assetFormUploadThumbnailImage`}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <AddIcon />
              Upload Thumbnail Image
            </span>
            <input
              type="file"
              hidden
              required
              id="thumbnail_image"
              name="thumbnail_image"
              onChange={(e) => {
                const element = document.getElementById(
                  `assetFormUploadThumbnailImage`
                );

                if (element)
                  element.innerText =
                    e.target.value.split("\\")[
                      e.target.value.split("\\").length - 1
                    ];

                //update the frame image with a the new file
                const elementInput = document.getElementById(
                  "thumbnail_image"
                ) as HTMLInputElement;
                if (
                  elementInput === undefined ||
                  elementInput === null ||
                  elementInput.files === undefined ||
                  elementInput.files?.length === 0
                ) {
                  //handle error
                  Popup(
                    "Something went wrong uploading the file. Please try again or contact support.",
                    "error"
                  );
                } else {
                  setTempAvatar((prev) =>
                    prev
                      ? {
                          ...prev,
                          image:
                            elementInput && elementInput.files
                              ? elementInput.files[0]
                              : "",
                        }
                      : prev
                  );
                }
              }}
              disabled={isLoading}
            />
          </Button>
          {tempAvatar && tempAvatar.image && (
            <>
              <h5 style={{ textAlign: "center" }}>Preview Thumbnail Image</h5>
              {tempAvatar.image !== "" &&
                typeof tempAvatar.image !== "string" && (
                  <Box
                    component="img"
                    sx={{
                      maxWidth: { xs: 350, md: 250 },
                    }}
                    className="avatar-form__new-img"
                    alt="Preview Frame"
                    src={URL.createObjectURL(tempAvatar.image)}
                  />
                )}
              {tempAvatar.image !== "" &&
                typeof tempAvatar.image === "string" && (
                  <Box
                    component="img"
                    sx={{
                      maxWidth: { xs: 350, md: 250 },
                    }}
                    className="avatar-form__new-img"
                    alt="Preview Frame"
                    src={tempAvatar.image ? tempAvatar.image : ""}
                  />
                )}
              {tempAvatar.image === "" && <div>No image choosen</div>}
            </>
          )}
          &nbsp;&nbsp;&nbsp;
          <h3>Static Parts</h3>
          <hr />
          {/* head and body  */}
          <StaticPartsSection
            name="Body"
            asset={
              tempAvatar.avatar_assets.filter(
                (asset) => asset.asset_type.pk === 55
              )[0].asset
            }
            updateAssetVarientImage={updateAssetVarientImage}
            updateAdvancedSettings={updateAdvancedSettings}
          />
          {/* <hr /> */}
          &nbsp;&nbsp;&nbsp;
          <StaticPartsSection
            name="Head"
            asset={
              tempAvatar.avatar_assets.filter(
                (asset) => asset.asset_type.pk === 66
              )[0].asset
            }
            updateAssetVarientImage={updateAssetVarientImage}
            updateAdvancedSettings={updateAdvancedSettings}
          />
          {/* active eyes and active mouth  */}
          &nbsp;&nbsp;&nbsp;
          <h3>Animated Frames</h3>
          <hr />
          {tempAvatar.avatar_assets.map((avatarAsset) => {
            if ([55, 66, 103, 97, 98].includes(avatarAsset.asset_type.pk))
              // We only want to these parts for vstreamer (head, body, eyes, mouth, and eyebrows)
              return (
                <ActivePartsSection
                  name={avatarAsset.asset_type.name}
                  asset={avatarAsset.asset}
                  updateAssetVarientFrameImage={updateAssetVarientFrameImage}
                  updateAdvancedSettings={updateAdvancedSettings}
                  handleDeleteFrame={handleDeleteFrame}
                  handleAddFrame={handleAddFrame}
                  key={avatarAsset.pk}
                />
              );
            return <Fragment key={avatarAsset.pk}></Fragment>;
          })}
          &nbsp;&nbsp;&nbsp;
          <h3>Expressions</h3>
          <hr />
          {/* Expressions for eyes, mouth, eyebrows  */}
          <ExpressionsSection
            name="Eyes"
            asset={
              tempAvatar.avatar_assets.filter(
                (asset) => asset.asset_type.pk === 97
              )[0].asset
            }
            updateAssetVarientImage={updateAssetVarientImage}
            updateAdvancedSettings={updateAdvancedSettings}
            handleDeleteVarient={handleDeleteVarient}
          />
          &nbsp;&nbsp;&nbsp;
          <ExpressionsSection
            name="Eyebrows"
            asset={
              tempAvatar.avatar_assets.filter(
                (asset) => asset.asset_type.pk === 98
              )[0].asset
            }
            updateAssetVarientImage={updateAssetVarientImage}
            updateAdvancedSettings={updateAdvancedSettings}
            handleDeleteVarient={handleDeleteVarient}
          />
          &nbsp;&nbsp;&nbsp;
          <ExpressionsSection
            name="Mouth"
            asset={
              tempAvatar.avatar_assets.filter(
                (asset) => asset.asset_type.pk === 103
              )[0].asset
            }
            updateAssetVarientImage={updateAssetVarientImage}
            updateAdvancedSettings={updateAdvancedSettings}
            handleDeleteVarient={handleDeleteVarient}
          />
        </FormControl>
        &nbsp;&nbsp;&nbsp;
        <hr style={{ width: "100%" }} />
        &nbsp;&nbsp;&nbsp;
        {errorMessage !== "" && <Alert severity="error">{errorMessage}</Alert>}
        <div className="avatar-form__new-save-preview">
          <Button variant="outlined" onClick={() => startStreaming()}>
            {showPreview !== null ? "Close Preview Avatar" : "Preview Avatar"}
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant="contained" onClick={handleSubmit}>
            Save Avatar
          </Button>
        </div>
      </form>
      <Modal
        onRequestClose={closeDeleteModal}
        isOpen={isDeleteModalOpen}
        title="Are you sure?"
      >
        <>
          <p>
            Are you sure you want to <b>permanantly</b> delete this avatar?
          </p>
          <div className="avatar-form__buttons">
            <Button
              color="secondary"
              variant="contained"
              onClick={closeDeleteModal}
            >
              Cancel
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={handleDeleteAvatar}
            >
              Delete
            </Button>
          </div>
        </>
      </Modal>
      &nbsp;&nbsp;&nbsp;
      {showPreview !== null ? (
        <>
          <hr style={{ width: "100%" }} />
          &nbsp;&nbsp;&nbsp;
          <PreviewDisplay data={showPreview} />
        </>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <Loader />
  );
}
