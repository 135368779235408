import { useEffect, useState } from "react";
import useAvatarDisplay from "../hooks/useAvatarDisplay";

let expressionTypes = [
  { label: "😐", value: "neutral", hotkey: "" },
  { label: "😀", value: "happy", hotkey: "" },
  { label: "😔", value: "sad", hotkey: "" },
  { label: "😡", value: "angry", hotkey: "" },
  { label: "🤮", value: "disgusted", hotkey: "" },
  { label: "😨", value: "fearful", hotkey: "" },
  { label: "😮", value: "surprised", hotkey: "" },
];
const hotkeys = ["q", "w", "e", "r", "a", "s", "d"];

export default function ExpressionEmotes() {
  const setCurrentExpressionEmote = useAvatarDisplay(
    (state) => state.setCurrentExpressionEmote
  );
  const hotkeyPressed = useAvatarDisplay((state) => state.hotkeyPressed);
  const setHotkeyPressed = useAvatarDisplay((state) => state.setHotkeyPressed);
  const availableExpressions = useAvatarDisplay(
    (state) => state.availableExpressions
  );
  let count = 0;
  const [isLoading, setLoading] = useState<boolean>(true);
  const showEmotes = useAvatarDisplay((state) => state.showEmotes);

  useEffect(() => {
    const matchedExpression = expressionTypes.find(
      (x) => x.hotkey === hotkeyPressed
    );

    if (matchedExpression) {
      setCurrentExpressionEmote(matchedExpression.value);
      // setHotkeyPressed(null);
    }

    return () => setCurrentExpressionEmote("");
  }, [hotkeyPressed, setHotkeyPressed, setCurrentExpressionEmote]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return isLoading ? (
    <></>
  ) : (
    <div className="expression-emotes">
      {expressionTypes.map((mappedExpressionEmote, i) => {
        if (
          availableExpressions.includes(
            mappedExpressionEmote.value as
              | "happy"
              | "sad"
              | "neutral"
              | "angry"
              | "disgusted"
              | "fearful"
              | "surprised"
          )
        ) {
          mappedExpressionEmote.hotkey = hotkeys[count++];
          if (showEmotes) {
            return (
              <div
                className="expression-emotes__emote"
                key={mappedExpressionEmote.value}
                onClick={() =>
                  setCurrentExpressionEmote(mappedExpressionEmote.value)
                }
              >
                <span>{mappedExpressionEmote.label}</span>
                <span className="expression-emotes__hotkey">
                  {mappedExpressionEmote.hotkey}
                </span>
              </div>
            );
          }
        }
        return null;
      })}
    </div>
  );
}
