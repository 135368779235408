import {
  Dispatch,
  SetStateAction,
  useEffect,
  useContext,
  MutableRefObject,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import { V3UserContext } from "../../../utility/context/V3UserContext";

interface NavMenuProps {
  isMenuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
  elRef: MutableRefObject<HTMLButtonElement | null>; // The element that is calling navMenu
}

/**
 * Display all User paths
 * @returns
 */
export default function UserNavigation({
  isMenuOpen,
  setMenuOpen,
  elRef,
}: NavMenuProps): JSX.Element {
  const { user, setUser } = useContext(V3UserContext);
  let navigator = useNavigate();
  const navRef = useRef(null);
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);

  useEffect(() => {
    const navMenuElement = document.getElementsByClassName("nav-menu")[0];
    const handleMouseClick = (e: MouseEvent) => {
      if (!e.composedPath().includes(navMenuElement)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleMouseClick);

    return () => {
      document.removeEventListener("mousedown", handleMouseClick);
    };
  }, [setMenuOpen]);

  useEffect(() => {
    if (isMenuOpen) {
      document
        .querySelectorAll(".nav-menu button")
        .forEach((item) => ((item as HTMLElement).tabIndex = 0));
    } else {
      document
        .querySelectorAll(".nav-menu button")
        .forEach((item) => ((item as HTMLElement).tabIndex = -1));
    }
  }, [isMenuOpen]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    function handleResize() {
      setWindowSize(window.innerWidth);
    }

    return () => {
      window.addEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (elRef.current && navRef.current) {
      (navRef.current as HTMLDivElement).style.left = `${elRef.current.offsetLeft -
        (navRef.current as HTMLDivElement).offsetWidth +
        60
        }px`;
    }
  }, [elRef, navRef, isMenuOpen, windowSize]);

  //handle log out
  function handleLogOut() {
    //Note: dont clear because we still need org info
    // localStorage.clear();
    setUser(null);
    localStorage.removeItem("vstreamer_user");
    localStorage.removeItem("sessionid");
    localStorage.removeItem("avatar-stream");
    navigator("/login");
  }


  return (
    <div
      className={`nav-menu ${isMenuOpen ? "nav-menu--open" : "nav-menu--closed"
        }`}
      ref={navRef}
      tabIndex={isMenuOpen ? 0 : -1}
    >
      <nav className="nav-menu__list">
        <div className="nav-menu__list-item nav-menu__welcome">
          {user?.username}
        </div>
        <button
          className="nav-menu__list-item"
          onClick={() => {
            navigator("/");
          }}
        >
          Dashboard
        </button>
        <button
          className="nav-menu__list-item"
          onClick={() => {
            navigator("/marketplace");
          }}
        >
          Marketplace
        </button>
        <button
          className="nav-menu__list-item"
          onClick={() => {
            navigator("/sell");
          }}
        >
          Sell on Vstreamer
        </button>
        <button
          className="nav-menu__list-item"
          onClick={() => {
            navigator("/purchase-history");
          }}
        >
          Purchase History
        </button>
        <button
          className="nav-menu__list-item"
          onClick={() => {
            window.open("https://beneverending.app/")
          }}
        >
          Character Builder
        </button>
        <button
          className="nav-menu__list-item"
          onClick={() => {
            navigator("/account");
          }}
        >
          Account Settings
        </button>
        <button
          className="nav-menu__list-item nav-menu__logout"
          onClick={handleLogOut}
        >
          Log Out
        </button>
      </nav>
    </div>
  );
}
