import { TextField } from "@mui/material";
import { NumericFormat } from "react-number-format";
// import { Input } from "./Input";

interface MoneyInputProps {
  [key: string]: any;
}

/**
 * Input field that handles anything currency related.
 * Takes in all props that Input takes in plus minVal
 * @param minVal The minimum value of currency
 * @returns JSX.Element
 */
export default function MoneyInput({ ...props }: MoneyInputProps) {
  return (
    <div className="c-money-input">
      <NumericFormat
        customInput={TextField}
        decimalScale={2}
        fixedDecimalScale={true}
        allowNegative={false}
        prefix="$"
        thousandSeparator=","
        {...props}
      />
    </div>
  );
}
