import { Box } from "@mui/material";
import DeleteAccount from "./components/DeleteAccount";
import UpdateInfo from "./components/UpdateInfo";
import UpdateMailingList from "./components/UpdateMailingList";
import UpdatePassword from "./components/UpdatePassword";
import UpdateStore from "./components/UpdateStore";

export default function Account(): JSX.Element {
  return (
    <Box component="div" className="account">
      <UpdatePassword />
      <UpdateInfo />
      <UpdateMailingList />
      <DeleteAccount />
      <UpdateStore />
    </Box>
  );
}
