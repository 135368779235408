import clsx from "clsx";

type VariantOptions = "info" | "error" | "warning";


export function Popup(
  message:string,
  variant: VariantOptions = "info", // error, warning
  duration: number = 3000,
) {
  const classes = clsx("c-popup", "slideIn", {
    [`c-popup__${variant}`]: variant,
  });

  const PopupElement = document.createElement("div");
  PopupElement.className = classes;
  PopupElement.innerHTML = `<div>${message}</div>`;
  PopupElement.tabIndex = 0;

  document.getElementsByClassName("Vstreamer")[0].prepend(PopupElement);

  if (PopupElement.getElementsByTagName("a").length > 0) {
    PopupElement.focus();
  }

  setTimeout(() => {
    PopupElement.classList.remove("slideIn");
    PopupElement.classList.add("slideOut");
    setTimeout(() => {
      PopupElement.remove();
    }, 2000);
  }, duration);
}

export default Popup;
