import { Box } from "@mui/material";

export default function Footer() {
  return (
    <Box component="footer" className="footer">
      <Box component="div" className="footer__item">
        <a className="footer__link" href="/faq">
          FAQ
        </a>
      </Box>
      <Box component="div" className="footer__item">
        <a
          className="footer__link"
          target="blank"
          href="https://docs.google.com/forms/d/e/1FAIpQLSc6MgWKm8RvIpJxWJCGWCyyFvPlph4ZiCl-5RS56oG9KInumg/viewform"
        >
          Support/Feedback
        </a>
      </Box>
      <Box component="div" className="footer__item">
        <a
          className="footer__link"
          target="blank"
          href="https://beneverending.com/privacy-policy"
        >
          Privacy Policy
        </a>
      </Box>
      <Box component="div" className="footer__item">
        <a
          className="footer__link"
          target="blank"
          href="https://beneverending.com/terms-of-service"
        >
          Terms of Service
        </a>
      </Box>
    </Box>
  );
}
