import { NavigateFunction } from "react-router";
import { Avatar } from "../../utility/Types";
import Post from "../../utility/Post";
import {
  createAssetVarientFrame,
  createAttachmentPoint,
  deleteVarientFrame,
  updateAsset,
  updateAttachmentPoint,
  updateAvatarObject,
  updateAvatarOrgField,
  updateVarient,
  updateVarientFrame,
} from "../../utility/Endpoints";

/**
 * This function goes through the avatar object and updates the needed objects in the backend
 * Compare old and new avatars to determine which objects needs to be updated
 * Avatar type and asset types are already pre-set
 * We use the avatar type with pk 12
 * This goes through creating
 *  1) avatar object
 *  2) assets
 *  3) avatar asset (connecting the created assets to the avatar)
 *  4) asset varients
 *  5) asset varient frames
 *  6) attachment points
 */
export function UpdateAvatarBackend(
  oldAvatar: Avatar,
  newAvatar: Avatar,
  navigator: NavigateFunction, //navigator for errors
  avatarCreatedRedirect: (avatarPk: number) => void, //function to do after everything created
  isOrgAvatar?: boolean //if the user is an artist or manager, they can update organizition avatar status
) {
  const FormData = require("form-data");

  //compare avatar objects
  if (
    oldAvatar.name !== newAvatar.name ||
    oldAvatar.image !== newAvatar.image ||
    oldAvatar.is_public !== newAvatar.is_public ||
    oldAvatar.is_streaming_avatar !== newAvatar.is_streaming_avatar
  ) {
    const formDataAvatar = new FormData();
    formDataAvatar.append("name", newAvatar.name);
    formDataAvatar.append(
      "avatar_type",
      newAvatar.avatar_type ? newAvatar.avatar_type.pk : "12"
    );
    formDataAvatar.append("image", newAvatar.image);
    formDataAvatar.append("is_public", newAvatar.is_public);
    formDataAvatar.append("is_streaming_avatar", newAvatar.is_streaming_avatar);

    Post(updateAvatarObject(newAvatar.pk), formDataAvatar).then((val1) => {
      if (val1.status && val1.status < 300) {
        if (val1.data && val1.data.data) {
          // val1 = avatar object
        }
      } else {
        if (val1.status === 401) {
          navigator("/login");
        }
      }
    });
  }

  //handle updating org avatar
  if (isOrgAvatar !== undefined && isOrgAvatar !== null) {
    const formDataOrgStatus = new FormData();
    formDataOrgStatus.append("is_organization_avatar", isOrgAvatar);
    Post(updateAvatarOrgField(newAvatar.pk), formDataOrgStatus).then((res) => {
      if (res.status && res.status < 300) {
        //org status set
      } else {
        if (res.status === 401) {
          navigator("/login");
        }
      }
    });
  }

  setTimeout(() => {
    //just wait until all calls are "done"
    avatarCreatedRedirect(newAvatar.pk);
  }, 500);

  //compare avatar assets
  //Note: should only need to compare and update
  // We shouldnt need to create at all since they should have been created on init create of entire avatar
  newAvatar.avatar_assets.forEach((avatarAsset, avatarAssetKey) => {
    //if the asset was null beforehand and the is still null, do nothing
    if (
      avatarAsset.asset === null &&
      oldAvatar.avatar_assets[avatarAssetKey].asset === null
    )
      return;
    //check if width changed (the other fields shouldnt need to change)
    if (
      avatarAsset.asset.width !==
      oldAvatar.avatar_assets[avatarAssetKey].asset.width
    ) {
      //if width is different, then update the avatar asset
      const formDataAssets = new FormData();
      formDataAssets.append("name", avatarAsset.asset.name);
      formDataAssets.append("width", avatarAsset.asset.width);
      formDataAssets.append("asset_type", avatarAsset.asset_type.pk);
      formDataAssets.append("color_accent_count", 0);
      formDataAssets.append("asset_set", 1);

      Post(updateAsset(avatarAsset.asset.pk), formDataAssets).then((val2) => {
        if (val2.status && val2.status < 300) {
          if (val2.data && val2.data.data) {
            //val2 = asset object
          }
        } else {
          if (val2.status === 401) {
            navigator("/login");
          }
        }
      });
    }

    //compare asset varients
    avatarAsset.asset.asset_varients.forEach((varient, varientKey) => {
      // check if animation interval, animation start interval max, or animation start interval min is different
      if (
        (varient.animation_interval === 0 ||
          varient.animation_interval === null) &&
        oldAvatar.avatar_assets[avatarAssetKey].asset.asset_varients[varientKey]
          .animation_interval === null &&
        (varient.animation_start_interval_max === 0 ||
          varient.animation_start_interval_max === null) &&
        oldAvatar.avatar_assets[avatarAssetKey].asset.asset_varients[varientKey]
          .animation_start_interval_max === null &&
        (varient.animation_start_interval_min === 0 ||
          varient.animation_start_interval_min === null) &&
        oldAvatar.avatar_assets[avatarAssetKey].asset.asset_varients[varientKey]
          .animation_start_interval_min === null
      ) {
        //nothing since 0 is null so nothing has changed
      } else if (
        varient.animation_interval !==
          oldAvatar.avatar_assets[avatarAssetKey].asset.asset_varients[
            varientKey
          ].animation_interval ||
        varient.animation_start_interval_max !==
          oldAvatar.avatar_assets[avatarAssetKey].asset.asset_varients[
            varientKey
          ].animation_start_interval_max ||
        varient.animation_start_interval_min !==
          oldAvatar.avatar_assets[avatarAssetKey].asset.asset_varients[
            varientKey
          ].animation_start_interval_min
      ) {
        //update asset varient object
        const formDataVarient = new FormData();
        varient.avatar_state_list.forEach((state) => {
          formDataVarient.append("avatar_states", state.pk);
        });
        formDataVarient.append(
          "animation_interval",
          varient.animation_interval
        );
        if (varient.animation_start_interval_max) {
          formDataVarient.append(
            "animation_start_interval_max",
            varient.animation_start_interval_max
          );
        }
        if (varient.animation_start_interval_min) {
          formDataVarient.append(
            "animation_start_interval_min",
            varient.animation_start_interval_min
          );
        }
        formDataVarient.append("asset", avatarAsset.asset.pk);

        Post(updateVarient(varient.pk), formDataVarient).then((val3) => {
          if (val3.status && val3.status < 300) {
            if (val3.data && val3.data.data) {
              //val3 = asset varient
            }
          } else {
            if (val3.status === 401) {
              navigator("/login");
            }
          }
        });
      }

      //compare frames
      //Note: this one you might need to create frames (+ attachment points)
      varient.frames.forEach((frame, frameKey) => {
        //if the pk is a temp number (aka negative number) then it is a new frame
        if (frame.pk < 0) {
          if (
            (frame.image !== undefined && typeof frame.image !== "string") ||
            (frame.image_back !== undefined &&
              typeof frame.image_back !== "string")
          ) {
            //create frame image
            const formDataVarientFrame = new FormData();
            formDataVarientFrame.append("image", frame.image);
            if (frame.image_back !== undefined) {
              formDataVarientFrame.append("image_back", frame.image_back);
            }
            formDataVarientFrame.append("priority", frameKey.toString());
            //use the order that they uploaded
            //Note: this will return an error for null images for pre-made frames and it is ok
            Post(
              createAssetVarientFrame(varient.pk),
              formDataVarientFrame
            ).then((val5) => {
              if (val5.status && val5.status < 300) {
                if (val5.data && val5.data.data) {
                  //val5 = asset varient frame object(s)

                  //for each attachment point
                  /**
                   * Attachment points:
                   * asset varient frame (from above)
                   * name (avatar name + attachment point name)
                   * x position
                   * y position
                   * z index
                   * z index back
                   */
                  frame.attachment_points.forEach(async (point) => {
                    const formDataAttachmentPoint = new FormData();
                    formDataAttachmentPoint.append(
                      "name",
                      newAvatar.name + point.name
                    );
                    formDataAttachmentPoint.append(
                      "x_position",
                      point.x_position
                    );
                    formDataAttachmentPoint.append(
                      "y_position",
                      point.y_position
                    );
                    formDataAttachmentPoint.append("z_index", point.z_index);
                    formDataAttachmentPoint.append(
                      "z_index_back",
                      point.z_index_back ? point.z_index_back : 0
                    );
                    //Note: point.asset_type should never be null in this case, but if it is, attachment point attaches to itself (which is wrong)
                    formDataAttachmentPoint.append(
                      //asset type this attaches to
                      "asset_type",
                      point.asset_type
                        ? point.asset_type.pk
                        : avatarAsset.asset_type.pk
                    );

                    var promiseArray: Array<any> = [];
                    promiseArray.push(
                      Post(
                        createAttachmentPoint(val5.data.data.pk),
                        formDataAttachmentPoint
                      ).then((val6) => {
                        if (val6.status && val6.status < 300) {
                          if (val6.data && val6.data.data) {
                            // val6 = attachment point object(s)
                            // Avatar created!
                          }
                        } else {
                          if (val6.status === 401) {
                            navigator("/login");
                          }
                        }
                      })
                    );
                    await Promise.allSettled(promiseArray).then(() => {
                      //return and redirect to avatar seller page
                      // with avatar pk
                    });
                  });
                }
              } else {
                if (val5.status === 401) {
                  navigator("/login");
                }
              }
            });
          }
        } else {
          //update the frame if something changed
          // if a file has been uploaded (aka not a string)
          //or if the priority of the frames change (ie framekey not the same as priority)
          // delete the frame if the user removes the image
          if (frame.image === "" && frame.pk) {
            Post(deleteVarientFrame(frame.pk), {}).then((val5) => {
              if (val5.status && val5.status < 300) {
                if (val5.data && val5.data.data) {
                  //val5 = asset frame object
                }
              } else {
                if (val5.status === 401) {
                  navigator("/login");
                }
              }
            });
          } else if (
            (frame.image !== undefined && frame.image !== "") ||
            frame.image_back !== undefined ||
            frameKey !== frame.priority
          ) {
            //update frame image
            const formDataVarientFrame = new FormData();
            if (frame.image !== "") {
              formDataVarientFrame.append("image", frame.image);
            }
            if (frame.image_back !== undefined && frame.image_back !== "") {
              formDataVarientFrame.append("image_back", frame.image_back);
            }
            formDataVarientFrame.append("priority", frameKey.toString());
            Post(updateVarientFrame(frame.pk), formDataVarientFrame).then(
              (val5) => {
                if (val5.status && val5.status < 300) {
                  if (val5.data && val5.data.data) {
                    //val5 = asset frame object
                  }
                } else {
                  if (val5.status === 401) {
                    navigator("/login");
                  }
                }
              }
            );
          }

          //check attachment points
          //Note: this should also only be updating and not creating
          frame.attachment_points.forEach((point, pointKey) => {
            if (
              point.x_position !==
                oldAvatar.avatar_assets[avatarAssetKey].asset.asset_varients[
                  varientKey
                ].frames[frameKey].attachment_points[pointKey].x_position ||
              point.y_position !==
                oldAvatar.avatar_assets[avatarAssetKey].asset.asset_varients[
                  varientKey
                ].frames[frameKey].attachment_points[pointKey].y_position ||
              point.z_index !==
                oldAvatar.avatar_assets[avatarAssetKey].asset.asset_varients[
                  varientKey
                ].frames[frameKey].attachment_points[pointKey].z_index ||
              point.z_index_back !==
                oldAvatar.avatar_assets[avatarAssetKey].asset.asset_varients[
                  varientKey
                ].frames[frameKey].attachment_points[pointKey].z_index_back
            ) {
              const formDataAttachmentPoint = new FormData();
              formDataAttachmentPoint.append("name", point.name);
              formDataAttachmentPoint.append(
                "x_position",
                point.x_position ? point.x_position : 0
              );
              formDataAttachmentPoint.append(
                "y_position",
                point.y_position ? point.y_position : 0
              );
              formDataAttachmentPoint.append("z_index", point.z_index);
              formDataAttachmentPoint.append(
                "z_index_back",
                point.z_index_back ? point.z_index_back : 0
              );
              formDataAttachmentPoint.append(
                "asset_type",
                point.asset_type !== null
                  ? point.asset_type.pk
                  : avatarAsset.asset_type.pk
              );
              Post(
                updateAttachmentPoint(point.pk),
                formDataAttachmentPoint
              ).then((val) => {
                if (val.status && val.status < 300) {
                  if (val.data && val.data.data) {
                    //val = attachment point object
                    //Avatar updated!
                  }
                } else {
                  if (val.status === 401) {
                    navigator("/login");
                  }
                }
              });
            }
          });
        }
      });
    });
  });
}
