interface WatermarkProps {
  color?: "black" | "white";
}

export default function Watermark({ color = "black" }: WatermarkProps) {
  return (
    <div className="watermark">
      <img
        src={`/images/ne-watermark-${color}.png`}
        alt="watermark"
        style={{ width: "100%" }}
      />
    </div>
  );
}
