import { ChangeEvent } from "react";
import clsx from "clsx";
import { Icon } from "./Icon";

type WidthSizeOptions = "sm" | "md" | "lg";

interface SearchbarProps {
  className?: string;
  onClear: () => void;
  onSubmit: (e: React.MouseEvent) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  widthSize?: WidthSizeOptions;
  label?: string;
  [key: string]: any;
}

export function Searchbar({
  className,
  onClear,
  onSubmit,
  onChange,
  label,
  ...props
}: SearchbarProps) {
  const classes = clsx("c-searchbar", className);

  return (
    <form className={classes}>
      <label className="for-screen-readers-only">
        {label ? label : "search"}
      </label>
      <div className="c-searchbar__group">
        <input className="c-searchbar__input" onChange={onChange} {...props} />
        <button
          className={`c-searchbar__clear-btn ${
            props.value
              ? "c-searchbar__clear-btn--visible"
              : "c-searchbar__clear-btn--hidden"
          }`}
          onClick={onClear}
          type={"button"}
          tabIndex={props.value ? 0 : -1}
        >
          <label className="for-screen-readers-only">Close Search</label>
          <Icon name="close" variant="black" size="xs" />
        </button>
        <button
          className="c-searchbar__search-btn"
          onClick={onSubmit}
          type={"submit"}
          aria-label="Search"
        >
          <Icon name="search" variant="black" size="sm" />
        </button>
      </div>
    </form>
  );
}
