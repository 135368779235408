import { Alert, Box, IconButton, Snackbar } from "@mui/material";
import { useState } from "react";
import Logo from "../../components/Logo";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import Register from "./Register";
import CloseIcon from "@mui/icons-material/Close";
import Footer from "../Footer/Footer";

interface AuthProps {
  setUser: (user: any) => void;
}

/**
 * Contains all Authentication related components
 */
export default function Authentication(props: AuthProps): JSX.Element {
  const [screen, setScreen] = useState<string>("login"); // login, forgot-password
  const [loginError, setLoginError] = useState<boolean>(false);

  return (
    <Box className="authentication" component="div">
      <Logo stacked subtitle="VStreamer"/>

      {/* Login Screen */}
      {screen === "login" && (
        <Login
          setScreen={(screen) => setScreen(screen)}
          setUser={props.setUser}
        />
      )}

      {/* Forgot Password Screen */}
      {screen === "forgot-password" && (
        <ForgotPassword
          setScreen={(screen) => setScreen(screen)}
          setUser={props.setUser}
        />
      )}

      {/* Register */}
      {screen === "register" && (
        <Register
          setUser={props.setUser}
          setLoginError={(x) => setLoginError(x)}
          setScreen={(screen) => setScreen(screen)}
        />
      )}

      {loginError && (
        <Snackbar open={loginError} onClose={() => setLoginError(false)}>
          <Alert
            severity="error"
            sx={{ display: "flex", alignItems: "center" }}
          >
            Login failed. Please try again.
            <IconButton onClick={() => setLoginError(false)}>
              <CloseIcon />
            </IconButton>
          </Alert>
        </Snackbar>
      )}

      <Box component="div" className="bottom-logo">
        <Box component="p">By</Box>
        <Box
          component="img"
          src="/images/NeverEnding-Logo-Drawn.png"
          alt="beneverending logo"
        ></Box>
      </Box>

      <Footer />
    </Box>
  );
}
