import { Icon } from "../../../components/Icon";

export default function FAQ(): JSX.Element {
  return (
    <div className="faq">
      <div className="faq__container">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Icon name="faq" size="lg" />
          &nbsp;&nbsp;&nbsp;
          <h2>FAQ</h2>
        </div>
        &nbsp;&nbsp;&nbsp;
        <h4>Frequently Asked Questions</h4>
        &nbsp;&nbsp;&nbsp;
        <div className="faq__list">
          <div className="faq__col">
            <h3>How do fees work?</h3>
            &nbsp;&nbsp;&nbsp;
            <p>Sellers keep 86% of the revenue from sales. We keep 14% of each purchase to help us maintain the site and provide upgrades and services. Sellers are not charged directly. All fees are taken from the purchase</p>
            &nbsp;&nbsp;&nbsp;

            <h3>What do I need to do to create a shop?</h3>
            &nbsp;&nbsp;&nbsp;
            <p>Creating a shop is easy! Fill out the form to set up your very own store. After completing the form, you’ll need to finish the activation process with our payment partner, Stripe. Stripe is a well respected third party patent processing platform that also helps you track your sales metrics and other information.</p>
            <p>Stripe requires you to enter your banking information with their secure link to make sure you can get payouts. This banking information is not accessible by NeverEnding, and is stored securely within Stripe’s platform.</p>
            &nbsp;&nbsp;&nbsp;

            <h3>What can I sell?</h3>
            &nbsp;&nbsp;&nbsp;
            <p>Right now you can sell an original Avatar. You can also sell multiple Avatars in Avatar packs at a discounted price.</p>
            &nbsp;&nbsp;&nbsp;
            

          </div>
          <div className="faq__col">
            <h3>How do I get paid?</h3>
            &nbsp;&nbsp;&nbsp;
            <p>If you have an active store and make sales, you can see the available balance in your seller’s dashboard on Stripe. Any positive balance will automatically be sent to your account in 3-4 weeks.</p>
            &nbsp;&nbsp;&nbsp;

            <h3>Is there a product review process?</h3>
            &nbsp;&nbsp;&nbsp;
            <p>Yes! We do this as a free service to offer our store’s support to produce the highest quality products. Don’t worry - our approval process only takes a few days.</p>
            &nbsp;&nbsp;&nbsp;

            <h3>How are sales and taxes reported?</h3>
            &nbsp;&nbsp;&nbsp;
            <p>Stripe handles all sales tracking, and 1099 reporting. You should receive any 1099 or other relevant tax forms for filing purposes at the end of the year.</p>
            &nbsp;&nbsp;&nbsp;
          </div>
        </div>
      </div>
    </div>
  );
}
