import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Loader } from "../../components/Loader";
import { Pagination } from "../../components/Pagination";
import Popup from "../../components/Popup";
import { Searchbar } from "../../components/Searchbar";
// import { Button } from "@mui/material";
// import Sidebar from "../../components/Sidebar";
import { ThumbnailCard } from "../../components/ThumbnailCard";
import { getMarketplaceProducts } from "../../utility/MarketEndpoints";
import Get from "../../utility/Get";
import { FilterType, ProductListType } from "../../utility/MarketplaceTypes";

/**
 * This is the marketplace starting point. Users will be able to see which items
 * are available for purchase with search, sort, filter
 */
export default function Marketplace(): JSX.Element {
  const navigator = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(true);
  // const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.outerWidth);
  const [npcList, setNpcList] = useState<ProductListType>({
    count: 0,
    data: [],
    page: 0,
    page_count: 0,
  });
  // const [tagList, setTagList] = useState<Array<TagType>>([]);
  const [filter, setFilter] = useState<FilterType>({
    search: "",
    sort: "",
    in_favorites: false,
    has_voiceover: false,
    has_art: false,
    tags: [],
    page: 0,
    packs_only: false,
  });
  const [tempFilter, setTempFilter] = useState<FilterType>({
    search: "",
    sort: "",
    in_favorites: false,
    has_voiceover: false,
    has_art: false,
    tags: [],
    page: 0,
    packs_only: false,
  });

  useEffect(() => {
    const handleResize = (e: Event) => {
      setWindowWidth((e.target as Window).outerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handles product list
  useEffect(() => {
    const controller = new AbortController();

    setLoading(true)
    // Get the user's list
    Get(
      getMarketplaceProducts(
        filter.search,
        filter.sort,
        filter.in_favorites,
        filter.has_voiceover,
        filter.has_art,
        filter.tags,
        filter.page,
        filter.packs_only
      ),
      controller.signal
    )
      .then((res) => {
        if (res && res.status < 300) {
          setNpcList(res.data);
        } else if (res.status === 401) {
          navigator("/login");
        } else {
          Popup("There was an error getting the list of Avatars", "error");
        }
        setLoading(false);
      })
      .catch((e) => {
        // This probably happened because the cancel signal was sent
      });

    return () => {
      controller.abort();
    };
  }, [filter, navigator]);

  function handleSearch() {
    setFilter(tempFilter);
    // setSidebarOpen(false);
  }

  // function handleCancel() {
  //   setTempFilter({
  //     ...filter,
  //     sort: filter.sort,
  //     in_favorites: filter.in_favorites,
  //     has_voiceover: filter.has_voiceover,
  //     has_art: filter.has_art,
  //     tags: filter.tags,
  //     page: filter.page,
  //   });
  //   setSidebarOpen(false);
  // }

  // function clearFiltering() {
  //   setFilter({
  //     search: "",
  //     sort: "",
  //     in_favorites: false,
  //     has_voiceover: false,
  //     has_art: false,
  //     tags: [],
  //     page: 0,
  //   });
  //   setTempFilter({
  //     search: "",
  //     sort: "",
  //     in_favorites: false,
  //     has_voiceover: false,
  //     has_art: false,
  //     tags: [],
  //     page: 0,
  //   });
  //   setSidebarOpen(false);
  // }

  return !isLoading ? (
    <div className="npc-list">
      <div className="npc-list__sort-search">
        {windowWidth <= 768 ? (
          <>
            {/* <div className="npc-list__container">
              <div className="npc-list__sort-filter">
                <Button onClick={() => setSidebarOpen((prev) => !prev)}>
                  Sort/Filter
                </Button>
              </div>
            </div> */}
            {/* 
            <Sidebar
              isOpen={isSidebarOpen}
              handleClose={() => setSidebarOpen(false)}
              title={
                <>
                  <span>Filter & Sort</span>
                  <button
                    onClick={() => {
                      clearFiltering();
                    }}
                  >
                    Clear All
                  </button>
                </>
              }
              excludeList={["c-dropdown"]}
            >
              <>
                <hr />
                <Sort setFilter={setTempFilter} />
                <hr />
                <Filter filter={tempFilter} setFilter={setTempFilter} />

                <div className="npc-list__mobile-buttons">
                  <Button
                    onClick={() => {
                      handleCancel();
                      setSidebarOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button onClick={handleSearch}>Search</Button>
                </div>
              </>
            </Sidebar> */}

            <Searchbar
              className="npc-list__searchbar"
              onClear={() => setTempFilter((prev) => ({ ...prev, search: "" }))}
              value={tempFilter.search}
              placeholder={"Search by name or occupation"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTempFilter((prev) => ({ ...prev, search: e.target.value }))
              }
              onSubmit={(e: React.MouseEvent) => {
                e.preventDefault();
                handleSearch();
              }}
            />
          </>
        ) : (
          <>
            {/* <div className="npc-list__sort-filter">
              <Button onClick={() => setSidebarOpen((prev) => !prev)}>
                Sort/Filter
              </Button>
            </div> */}
            {/* This is to give correct spacing */}
            <span style={{ opacity: "0", width: "100px" }}></span>

            {/* <Sidebar
              isOpen={isSidebarOpen}
              handleClose={() => setSidebarOpen(false)}
              title={
                <>
                  <span>Filter & Sort</span>
                  <button
                    onClick={() => {
                      clearFiltering();
                    }}
                  >
                    Clear All
                  </button>
                </>
              }
              excludeList={["c-dropdown"]}
            >
              <>
                <hr />
                <Sort setFilter={setTempFilter} />
                <hr />
                <Filter filter={tempFilter} setFilter={setTempFilter} />
                <div className="npc-list__mobile-buttons">
                  <Button
                    onClick={() => {
                      handleCancel();
                      setSidebarOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button onClick={handleSearch}>Search</Button>
                </div>
              </>
            </Sidebar> */}

            <Searchbar
              className="npc-list__searchbar"
              onClear={() => {
                setFilter((prev) => ({ ...prev, search: "" }));
                setTempFilter((prev) => ({ ...prev, search: "" }));
              }}
              value={tempFilter.search}
              placeholder={"Search by name or occupation"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTempFilter((prev) => ({ ...prev, search: e.target.value }))
              }
              onSubmit={(e: React.MouseEvent) => {
                e.preventDefault();
                handleSearch();
              }}
            />
            {/* This is to give correct spacing */}
            <span style={{ opacity: "0", width: "100px" }}></span>
          </>
        )}
      </div>

      {npcList.data ? (
        <>
          <div className="npc-list__list">
            {npcList.data.map((product, i) => (
              <ThumbnailCard
                className="npc-list__item"
                thumbnail={product.image as string}
                title={product.product_name}
                subtitle={
                  product.previews && !Array.isArray(product.previews)
                    ? product.previews.occupation
                    : product.description
                }
                price={product.price}
                href={`/marketplace/product/${product.pk}`}
                key={i}
              />
            ))}
          </div>
          <div className="npc-list__pagination">
            {npcList.count === 0 ? (
              <h4 style={{ textAlign: "center", width: "100%" }}>
                No products found!
              </h4>
            ) : (
              <Pagination
                numPages={npcList.page_count}
                currPage={npcList.page}
                onChange={(page: number) =>
                  setFilter((prev) => ({ ...prev, page: page }))
                }
                pagesToDisplay={windowWidth <= 768 ? 7 : 3}
              />
            )}
          </div>
        </>
      ) : (
        <div>There are no Products</div>
      )}
    </div>
  ) : (
    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <Loader size={5} variant={"block"} />
    </div>
  );
}
