import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import React, { ChangeEvent, FC, ReactNode } from "react";
import { Divider, MenuItem, SvgIcon } from "@mui/material";
export interface ConformationModel {
  title?: string;
  handleClose: () => void;
  mainBtnTitle: string;
  url?: string;
  icon?: ReactNode;
  handleConfirm(): void;
}

const CopyUrl: FC<ConformationModel> = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };
  const handleConfirm = () => {
    const video_url = JSON.parse(localStorage.getItem("video_url") || "");
    navigator.clipboard.writeText(video_url);
    props?.handleConfirm();
    // props.handleClose();
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      handleClickOpen();
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",

            fontWeight: "600",
            maxWidth: "calc(100% - 40px)",
            p: 1,
          }}
        >
          {props.title}
        </Typography>
        <IconButton
          sx={{ color: "text.primary" }}
          aria-label="close"
          onClick={handleClose}
        >
          <SvgIcon>
            <path d="M6.4 19 5 17.6l5.6-5.6L5 6.4 6.4 5l5.6 5.6L17.6 5 19 6.4 13.4 12l5.6 5.6-1.4 1.4-5.6-5.6Z" />
          </SvgIcon>
        </IconButton>
      </Box>

      <Divider />

      <Box
        sx={{
          border: "1px dashed #CCCCCC",
          p: 1,
          bgcolor: "#FBFBFF",
          borderRadius: "8px",
          display: "flex",

          gap: 2,
          my: 2,
        }}
      >
        <Box>
          <Typography
            variant="body1"
            sx={{
              wordBreak: "break-all",
            }}
          >
            {JSON.parse(localStorage.getItem("video_url") || "")}
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={handleConfirm}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="copy">
                <path
                  id="Vector"
                  d="M17 13.4V16.4C17 20.4 15.4 22 11.4 22H7.6C3.6 22 2 20.4 2 16.4V12.6C2 8.6 3.6 7 7.6 7H10.6"
                  stroke="#436AF3"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_2"
                  d="M16.9996 13.4H13.7996C11.3996 13.4 10.5996 12.6 10.5996 10.2V7L16.9996 13.4Z"
                  stroke="#436AF3"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_3"
                  d="M11.5996 2H15.5996"
                  stroke="#436AF3"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_4"
                  d="M7 5C7 3.34 8.34 2 10 2H12.62"
                  stroke="#436AF3"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_5"
                  d="M21.9995 8V14.19C21.9995 15.74 20.7395 17 19.1895 17"
                  stroke="#436AF3"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_6"
                  d="M22 8H19C16.75 8 16 7.25 16 5V2L22 8Z"
                  stroke="#436AF3"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default CopyUrl;
